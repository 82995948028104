import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import "./OzelArama.css";
import SideMenuPanel from "../SideMenu/SideMenuPanel";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import Header from "../System/header";
import {
  BsHeartFill,
  BsPencilFill,
  BsSearch,
  BsTrash,
  BsTrash2,
} from "react-icons/bs";
import SonucSec from "../svgs/sonucSec";
import StarIcon from "../svgs/star";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import araCizgi from "../../assets/img/ara-cizgi.png";
import ArrowDown from "../svgs/arrowDown";
import ArrowUp from "../svgs/arrowUp";
import ShareAlarm from "../svgs/shareAlarm";
import { PiShareFatFill } from "react-icons/pi";
import { TbArrowsMoveVertical } from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import { SlUserUnfollow } from "react-icons/sl";
import { dataBorsa } from "../../utils/dummyData";
import SonucSecildi from "../svgs/sonucSecildi";
import formulLike from "../../assets/img/formulLike.png";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { AiFillDashboard, AiFillCarryOut, AiFillSignal } from "react-icons/ai";
import {
  FORMULAS,
  FORMULASHARE,
  FORMULAUNSHARE,
  FORMULDELETE,
  FORMULAUNFOLLOW,
  FORMULLIKE,
  MARKETS,
  FORMULASETALARM,
  FORMULAKTAR,
  USERPROFILE,
  INDICATORS,
  FORMULAFOLLOW,
  PANOFORMULLER,
  UPDATELISTE,
  ADDLISTE,
  GETLISTE,
  FAVORIADD,
  TARAMAV2,
  FORMULASORT,
} from "../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import { timeout } from "../../utils/helperFunction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ButtonClose from "../svgs/buttonClose";
import SonucTekEkle from "../svgs/sonucTekEkle";
import ArrowSort from "../svgs/arrowSort";
import SonucAlarm from "../svgs/sonucAlarm";
import SonucGrafik from "../svgs/sonucGrafik";
import PuanComponent from "../puan/puan";
import ChartComponent from "../chart/chart";
import PanoNote from "../svgs/panoNote";

import {
  setFollowFormulas,
  setFollowUsers,
  setFormulas,
  setMyboard,
  setUsers,
} from "../../store/reducers/panoSlice";
import StarTop from "../svgs/starTop";
import UserFollow from "../svgs/userFollow";
import UserFollowing from "../svgs/userFollowing";
import UserProfileZiyaret from "../svgs/userProfilZiyaret";
import UserTakipci from "../svgs/userTakipci";
import UserMemnuniyet from "../svgs/userMemnuniyet";
import UserSirius from "../svgs/userSirius";
import UserStarNull from "../svgs/userStarNull";
import userFrame from "../../assets/img/userFrama.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function objectLength(obj) {
  var result = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // or Object.prototype.hasOwnProperty.call(obj, prop)
      result++;
    }
  }
  return result;
}

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function countMarket(objs) {
  var result = _.countBy(objs.flatMap((obj) => obj.market));
  return result;
}
let date = new Date();
var timestamp = date.getTime();
const zamansal = ["BAYZ", "BAYZ(YAKIN)", "GÖLGE", "ABC=DEF"];
function FormulArama() {
  const dispatch = useDispatch();
  const { formulId } = useParams();
  const theme = useSelector((state) => state.theme);
  const { users, users_follows, formulas_follows } = useSelector(
    (state) => state.pano
  );
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [periyots, setPeriyots] = useState(
    JSON.parse(localStorage.getItem("periyots")) || []
  );
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [dahaGoster, setDahaGoster] = useState(false);
  const [dahaGosterId, setDahaGosterId] = useState(0);
  const [selectedSembol, setSelectedSembol] = useState([]);
  const [liste, setListe] = useState("");
  const [filtreFormationLists2, setFiltreFormationLists2] = useState([]);
  const [alarm, setAlarm] = useState("");
  const [bayzJustClosed, setBayzJustClosed] = useState(0);
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [dataFilter, setDataFilter] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [dataFormation, setDataFormation] = useState([]);
  const [dataIndicator, setDataIndicator] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [sortTypeChange, setSortTypeChange] = useState("default");
  const urlApi = localStorage.getItem("urlApi");
  const [hisseType, setHisseType] = useState("Hissede");
  const [hisseAdet, setHisseAdet] = useState(0);
  const [ayrimi, setAyrimi] = useState(0);
  const [stratejiAdet, setStratejiAdet] = useState(0);
  const [taramaAdet, setTaramaAdet] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFormul, setSelectedFormul] = useState(0);
  const [formationId, setFormationId] = useState(0);
  const [puanName, setPuanName] = useState("");
  const [loadChart, setLoadChart] = useState("formation");
  const [selectedBorsa, setSelectedBorsa] = useState(
    localStorage.getItem("selectedBorsa") || "BIST"
  );
  const [selectedBorsaId, setSelectedBorsaId] = useState(
    localStorage.getItem("selectedBorsaId") || 1
  );
  const [listeler, setListeler] = useState([]);
  const [formulas, setFormulaLists] = useState([]);
  const [formulasData, setFormulasData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectListe, setSelectListe] = useState(0);
  const [selectListeTab, setSelectListeTab] = useState(1);
  const [listeName, setListeName] = useState("");
  const [listeNameId, setListeNameId] = useState(0);
  const [selectedAktar, setSelectedAktar] = useState(null);
  const [search, setSearch] = useState("");
  const [share, setShare] = useState("");
  const [shareNote, setShareNote] = useState("");
  const [shareType, setShareType] = useState("followers");
  const [sonucYok, setSonucYok] = useState(false);
  const [activeTab, setActiveTab] = useState("tumu");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isTakipci, setIsTakipci] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const handleFormulClose = () => setVisibleFormul(false);
  const [visibleShare, setVisibleShare] = useState(false);
  const handleShareClose = () => setVisibleShare(false);
  const [visibleUnShare, setVisibleUnShare] = useState(false);
  const handleUnShareClose = () => setVisibleUnShare(false);
  const [visibleBorsa, setVisibleBorsa] = useState(false);
  const handleBorsaClose = () => setVisibleBorsa(false);
  const [visibleAlarm, setVisibleAlarm] = useState(false);
  const handleAlarmClose = () => setVisibleAlarm(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const handleDeleteClose = () => setVisibleDelete(false);
  const [visibleAktar, setVisibleAktar] = useState(false);
  const handleAktarClose = () => setVisibleAktar(false);
  const [visibleUnfollow, setVisibleUnfollow] = useState(false);
  const handleUnfollowClose = () => setVisibleUnfollow(false);
  const [modalIndicator, setModalIndicator] = useState(false);
  const handleIndicatorClose = () => setModalIndicator(false);
  const [modalGrafik, setModalGrafik] = useState(false);
  const handleGrafikClose = () => setModalGrafik(false);
  const [visibleLikeFormul, setVisibleLikeFormul] = useState(false);
  const handleLikeFormulClose = () => setVisibleLikeFormul(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const handleUserClose = () => _setVisibleUserClose(false);
  const [visibleListelerim, setVisibleListelerim] = useState(false);
  const handleListelerimClose = () => _setVisibleListelerim();

  const _setVisibleListelerim = () => {
    setVisibleListelerim(false);
    setListe("");
    setSelectListe(0);
  };

  const funcListeEkle = async (form) => {
    setListeName(form.name);
    setListeNameId(parseInt(form.stock_id));
    await getListeler();
    setVisibleListelerim(true);
  };
  const handleListemeEkle = async () => {
    if (selectListe == "new") {
      funcIsProcessing(true, "handleListemeEkle");
      const postData = {
        access_token: user.access_token,
        name: liste,
        markets: [listeNameId],
        // j1407b: 1,
      };
      await postDataWithHeaders(ADDLISTE, postData, dispatch).then((res) => {
        // console.log("ADDLISTE", res);
        if (res.code === 0) {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          setListe("");
          setSelectListe(0);

          toast.success(res.data);
        } else {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          toast.error(res.data);
        }
      });
      funcIsProcessing(false);
      setVisibleListelerim(false);
      return;
    }
    selectListe.markets.push(parseInt(listeNameId));
    const postData = {
      id: selectListe.id,
      access_token: user.access_token,
      name: selectListe.name,
      markets: selectListe.markets,
      // j1407b: 1,
    };
    // // console.log("postData", postData);
    await postDataWithHeaders(UPDATELISTE, postData, dispatch).then((res) => {
      // console.log("ADDLISTE", res);
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        funcIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  const handleUyari = (name) => {
    toast.error(
      `${name} formülü özel Zamansallık Unsurları içermektedir. Premium+ paket özellikleri için 0530 238 60 18' e ulaşabilirsiniz.`
    );
  };
  const _listeSelect = (id) => {
    setSelectListe(id);
  };
  const handleFavoriEkle = async () => {
    funcIsProcessing(true, "handleFavoriEkle");
    // // console.log("item", selectListe, listeNameId, selectListe.markets);
    const postData = {
      access_token: user.access_token,
      market_id: listeNameId,
      // j1407b: 1,
    };
    // // console.log("postData", postData);
    await postDataWithHeaders(FAVORIADD, postData, dispatch).then((res) => {
      // console.log("FAVORIADD", res);
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListe("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        funcIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  const getListeler = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETLISTE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        setListeler(res.data);
      }
    });
  };
  const _setVisibleUserClose = () => {
    setVisibleUser(false);
    setIsTakipci(0);
  };
  const _setVisibleUser = (username) => {
    console.log("user", username);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: username,
    };
    postDataWithHeaders(USERPROFILE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        setUserProfile(res.data);
        setSelectedUser(username);
        let takipte = _.filter(users_follows, {
          name: username,
        }).length;
        if (takipte > 0) {
          setIsTakipci(1);
        } else {
          setIsTakipci(0);
        }
        setVisibleUser(true);
      } else {
        toast.error(res.data);
      }
    });
  };
  const _handleEditFormul = (item) => {
    console.log("item", item);
    navigate("/ana-kumanda/" + item.id);
  };
  function marketCallback(item) {
    return {
      value: item.id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      xindex: item.xindex,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    setIsProcessing(true);

    await postDataWithHeaders(
      MARKETS,
      { access_token: user.access_token },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        var dataDummy = [];
        dataDummy.push({
          items: res.data.map(marketCallback),
        });
        setMarkets(dataDummy[0]);
        let newMarket = [];
        let newValue = 1;
        newMarket = _.filter(dataDummy[0].items, function (o) {
          return o.exchange_id == newValue;
        });
        setMarketler(newMarket);
        setSearchMarket(newMarket);
        setIsProcessing(false);
      }
    });
  };
  const funcSelectBorsa = async (label, id) => {
    setSelectedBorsa(label);
    localStorage.setItem("selectedBorsa", label);
    setSelectedBorsaId(id);
    localStorage.setItem("selectedBorsaId", id);
    console.log("selectedBorsa", label);
    console.log("selectedBorsaId", id);
    setIsProcessing(true);
    let newMarket = [];
    let newValue = id;
    if (parseInt(newValue)) {
      newMarket = _.filter(markets.items, function (o) {
        return o.exchange_id == newValue;
      });
    } else {
      if (newValue == "XU030") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu030 == 1;
        });
      }
      if (newValue == "XU050") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu050 == 1;
        });
      }
      if (newValue == "XU100") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu100 == 1;
        });
      }
      if (newValue == "XBANK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xbank == 1;
        });
      }
      if (newValue == "XKTUM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xktum == 1;
        });
      }
      // XUSIN, XUTEK, XILTM, XGIDA, XTRZM, XSGRT
      if (newValue == "XUSIN") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xusin == 1;
        });
      }
      if (newValue == "XUTEK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xutek == 1;
        });
      }
      if (newValue == "XILTM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xiltm == 1;
        });
      }
      if (newValue == "XGIDA") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xgida == 1;
        });
      }
      if (newValue == "XTRZM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xtrzm == 1;
        });
      }
      if (newValue == "XSGRT") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xsgrt == 1;
        });
      }
      if (newValue == "XINDEX") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xindex == 1;
        });
      }
      if (newValue == "CIRCLE") {
        newMarket = _.filter(markets.items, function (o) {
          return o.circle == 1;
        });
      }
    }
    setMarketler(newMarket);
    setSearchMarket(newMarket);
    setIsProcessing(false);
    if (selectedBorsaId != id) {
      _getIndicators(id);
    }
  };
  const handleNameFormation = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const handleName = (name) => {
    return _.filter(updateDataAll, { slug: name }).map((v) => v.label);
  };
  const handleFormulEkle = () => {
    setIsProcessing(true);
    if (selectedBorsa == null || selectedBorsa == "") {
      toast.error("Borsa Seçiniz");
      setIsProcessing(false);
      return;
    }
    setIsProcessing(false);
    setVisibleFormul(false);
    timeout(2000);
    navigate("/ozel-arama/" + selectedItem.id);
  };

  const _setVisibleDelete = (item) => {
    setSelectedItem(item);
    setVisibleDelete(true);
  };
  const _setVisibleUnfollow = (item) => {
    setSelectedItem(item);
    setVisibleUnfollow(true);
    console.log("_setVisibleUnfollow", item);
  };
  const handleUnfollow = () => {
    setIsProcessing(true);
    timeout(2000);
    const postData = {
      id: selectedItem.id,
      access_token: user.access_token,
      // j1407b: 1,
    };

    postDataWithHeaders(FORMULAUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code == 0) {
        toast.success(res.data);
        setIsProcessing(false);
        setVisibleUnfollow(false);
        _getFormulLists();
      } else {
        setIsProcessing(false);
        setVisibleUnfollow(false);
        toast.error(res.data);
      }
    });
  };
  const handleDelete = () => {
    setIsProcessing(true);

    timeout(2000);
    const postData = {
      id: selectedItem.id,
      access_token: user.access_token,
      // j1407b: 1,
    };

    postDataWithHeaders(FORMULDELETE, postData, dispatch).then((res) => {
      if (res.code == 0) {
        toast.success("Silindi");
        setIsProcessing(false);
        setVisibleDelete(false);
        _getFormulLists();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
  };
  const _setVisibleFormul = (item) => {
    setSelectedItem(item);
    console.log("_setVisibleFormul", item);
    localStorage.setItem("selectedItem", JSON.stringify(item));
    setVisibleFormul(true);
  };
  const _setVisibleShare = (item, type) => {
    if (user.hidden == 1) {
      toast.error("Hesabınız Gizli. Bu işlemi yapamazsınız.");
      return;
    }
    console.log("item", item);
    setSelectedItem(item);
    setShare(item.name);
    setShareNote(item.note);
    setShareType(type);
    setVisibleShare(true);

    //setVisibleShare(true);
  };
  const _setVisibleUnShare = (item, type) => {
    console.log("item", item);
    setSelectedItem(item);
    setShare(item.name);
    setShareNote(item.note);
    setShareType(type);
    setVisibleUnShare(true);

    //setVisibleShare(true);
  };
  const handleShareEkle = () => {
    setIsProcessing(true);
    console.log("selectedItem", selectedItem);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      share: shareType,
      note: shareNote,
      id: selectedItem.id,
    };
    console.log(postData);
    postDataWithHeaders(FORMULASHARE, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        setVisibleShare(false);
        setSelectedItem(null);
        setShare(null);
        setShareNote(null);
        toast.success(res.data);
        _getFormulLists();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
  };
  const handleUnShareEkle = () => {
    setIsProcessing(true);
    console.log("selectedItem", selectedItem);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: selectedItem.id,
    };
    console.log(postData);
    postDataWithHeaders(FORMULAUNSHARE, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        setVisibleUnShare(false);
        setSelectedItem(null);
        setShare(null);
        setShareNote(null);
        toast.success(res.data);
        dispatch(setMyboard([]));
        _getFormulLists();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
  };
  const _setActiveTab = (value) => {
    setActiveTab(value);
    let newDatas = formulasData; // Change const to let
    if (value !== "tumu") {
      newDatas = newDatas.filter((item) => item.type === value);
    }
    setFormulaLists(newDatas);
  };

  const _getFormulLists = () => {
    setIsProcessing(true);
    setLoadSkeleton(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(FORMULAS, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        setLoadSkeleton(false);
        let newDatas = res.data;
        if (activeTab !== "tumu") {
          newDatas = newDatas.filter((item) => item.type === activeTab);
        }

        const sortedObj = _.sortBy(newDatas, [
          function (o) {
            return o.order;
          },
        ]);

        // console.log(sortedObj);

        setFormulaLists(sortedObj);
        setFormulasData(res.data);
        getFormulasData();
      } else {
        setIsProcessing(false);
        setLoadSkeleton(false);
        toast.error(res.data);
      }
    });
  };
  const filtre2item = async (item) => {
    let searchData = {
      value: handleFormasyon(item.value)[0].slug,
      yon: item.yon == 1 ? "BULL" : "BEAR",
      periyot: item.periyot,
    };
    return searchData;
  };
  const filtre2item2 = async (item) => {
    console.log("filtre2item2", item);
    let searchData = {};
    if (item.market == 0) {
      searchData = {
        formation: handleFormasyon(item.value)[0].slug,
        period: item.period,
        bayz_just_closed: item.bayz_just_closed ? item.bayz_just_closed : "0",
      };
    } else {
      searchData = {
        formation: handleFormasyon(item.value)[0].slug,
        market: item.market == 1 ? "BULL" : "BEAR",
        period: item.period,
        bayz_just_closed: item.bayz_just_closed ? item.bayz_just_closed : "0",
      };
    }

    return searchData;
  };
  const _setVisibleAlarm = (item) => {
    if (item.alarm == "1") {
      toast.error(
        "Bu formüle daha önce alarm eklediğiniz için yeni alarm ekleyemezsiniz! Lütfen mevcut alarmı kaldırıp yeniden ekleyiniz."
      );
      return;
    }
    console.log("_setVisibleAlarm", item);
    setSelectedItem(item);
    localStorage.setItem("selectedItem", JSON.stringify(item));
    setVisibleAlarm(true);
  };
  const handleAlarmEkle = () => {
    if (alarm.length == 0) {
      toast.error("Alarm adı giriniz!");
      return;
    }
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      name: alarm,
      id: formulId ? formulId : selectedItem.id,
      exchanges: [selectedBorsaId],
      markets: selectedSembol,
      seperate: 0,
      // j1407b: 1,
    };

    postDataWithHeaders(FORMULASETALARM, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        setVisibleAlarm(false);
        setSelectedItem(null);
        setSelectedSembol([]);
        setSelectedBorsa(null);
        setSelectedBorsaId(null);
        localStorage.setItem("selectedBorsa", "");
        localStorage.setItem("selectedBorsaId", 0);
        setAlarm(null);
        toast.success(res.data);
        _getFormulLists();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
    // setIsProcessing(true);
    // setVisibleAlarm(false);
  };
  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _setSearch2 = (text) => {
    setSearch(text);

    let newDatas = formulasData; // Change const to let
    if (text) {
      newDatas = _.filter(newDatas, function (o) {
        return o.name.includes(text);
      });
    }
    setFormulaLists(newDatas);
  };
  const _setSearch = (text) => {
    setSearch(text);

    let newDatas = formulasData;
    if (text) {
      text = text
        .toLocaleLowerCase("tr-TR")
        .normalize("NFKD")
        .replace(/[\u0300-\u036f]/g, "");
      newDatas = _.filter(newDatas, function (o) {
        return o.name
          .toLocaleLowerCase("tr-TR")
          .normalize("NFKD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(text);
      });
    }
    setFormulaLists(newDatas);
  };
  async function _getIndicators(setId) {
    let date = new Date();
    let timestamp = date.getTime();
    const postData = {
      access_token: user.access_token,
      exchange_id: setId ? setId : selectedBorsaId,
    };
    let res = await postDataWithHeaders(
      INDICATORS + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = res.data;
      //// console.log("resData Indicators", resData);
      // // console.log(
      //   "resData[0].indicators.length)",
      //   resData[0].indicators.length
      // );
      if (formulId) {
        _getDataFormations(setId);
      }
      localStorage.setItem("dataIndicator", JSON.stringify(resData));
      localStorage.setItem("stratejiAdet", resData[0].indicators.length);
      setDataIndicator(resData);
      setStratejiAdet(resData[0].indicators.length);
    }
  }
  async function _getDataFormations(setId) {
    let date = new Date();
    let timestamp = date.getTime();
    setSonucYok(true);
    setDataBody(null);
    setDataBody2(null);
    setLoadData(false);
    setIsProcessing(true);
    setDataFormation(null);

    const postData = {
      access_token: user.access_token,
      exchange_id: setId ? setId : selectedBorsaId,
      formula_id: formulId,
      bayz_just_closed: 0,
    };
    let res = await postDataWithHeaders(
      TARAMAV2 + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = [];
      resData = _.groupBy(res.data.data, function (n) {
        return n.stock_code;
      });
      setDataFormation(resData);
      console.log("res.data.formula", res.data.formula);
      console.log("search-all", res.data.formula);
      if (res.data.formula) {
        localStorage.setItem("selectedItem", JSON.stringify(res.data.formula));
        setSelectedItem(res.data.formula);
        setAyrimi(res.data.formula.additionals.seperate);
        setBayzJustClosed(res.data.formula.additionals.bayz_just_closed);
      }

      let dataIndicator = localStorage.getItem("dataIndicator");

      let filterDatas = [];
      if (dataIndicator) {
        let resDataIndicator = JSON.parse(dataIndicator);
        await Promise.all(
          resDataIndicator.map((dataInd, ix) => {
            let bull = 0;
            let bear = 0;
            let formations = [];

            if (resData[dataInd.stock_code]) {
              formations = resData[dataInd.stock_code];
              bull = countMarket(resData[dataInd.stock_code]).BULL
                ? countMarket(resData[dataInd.stock_code]).BULL
                : countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR * -1
                : 0;
              bear = countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR
                : 0;
            }
            formations.length > 0 &&
              filterDatas.push({
                stock_id: dataInd.stock_id,
                name: dataInd.stock_code,
                exchange_id: dataInd.exchange_id,
                puan: dataInd.puan,
                bull: bull,
                bear: bear,
                indicators: dataInd.indicators,
                formations: formations,
              });
          })
        );
      }

      console.log("filterDatas x", filterDatas);
      setDataFilter(filterDatas);
      // setDataBody([filterDatas]);
      setDataBody2([filterDatas]);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      setIsProcessing(false);

      let hisseAdet = objectLength(resData);
      let taramaAdet = Object.values(resData).reduce(
        (count, current) => count + current.length,
        0
      );
      //console.log(hisseAdet, taramaAdet);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setLoadFilter(true);
      setLoadData(true);
    } else {
      if (res.code === -21) {
        toast.error(res.data);
        setVisibleLikeFormul(true);
        setIsProcessing(false);
        return;
      }

      setIsProcessing(false);
      toast.error(res.data);
      setSonucYok(false);
      if (res.code === "-1") {
        // setInterval(() => {
        //   window.location.href = "/uye-cikis";
        // }, 2000);
      }
    }
  }

  const handlePeiods = async (value) => {
    // console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = await periyots.items.filter((v) => v.value == value);
    // console.log("filteredItems", filteredItems);
    return filteredItems[0].label;
  };
  const handlePeiods2 = async (value) => {
    // console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = await periyots.items.filter((v) => v.value == value);
    // console.log("filteredItems", filteredItems);
    return filteredItems[0].slug;
  };
  function findCommonItems(arrays) {
    if (arrays.length === 0) {
      return [];
    }
    const commonItems = arrays[0].filter((item) =>
      arrays.every((array) => array.some((aItem) => aItem.name === item.name))
    );

    return commonItems;
  }
  const handleFormasyon = (value) => {
    // console.log("handleFormasyon: ", updatedDataFormations, value);
    return updatedDataFormations.filter((v) => v.slug == value);
  };

  const handleSortType = (type) => {
    setSortType(type);
    setSortTypeChange("default");
  };
  function handleSortingChange(sorting, column) {
    // console.log("sorting", sorting, "column", column);
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    if (sorting === "default") {
      setSortTypeChange("down");
      data = _.orderBy(newData, [column], ["desc"]);
    }
    if (sorting === "down") {
      setSortTypeChange("up");
      data = _.orderBy(newData, [column], ["asc"]);
    }
    if (sorting === "up") {
      setSortTypeChange("default");
      data = newData;
    }
    setDataBody([data]);
  }
  const handleIndicatorName = (value) => {
    // console.log("handleIndicators: ", updatedDataIndicators, value);
    return updatedDataIndicators.filter((v) => v.slug == value);
  };
  const handleOrtalamaName = (value) => {
    // console.log("handleOrtalamalar: ", updatedDataOrtalamalar, value);
    return updatedDataOrtalamalar.filter((v) => v.slug == value);
  };

  const handleHacimName = (value) => {
    // console.log("handleHacimName: ", updatedDataHacim, value);
    return updatedDataHacim.filter((v) => v.slug == value);
  };
  function mergeByName(objectsArray) {
    const merged = {};

    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (!merged[name]) {
          merged[name] = { ...obj };
        } else {
          merged[name] = { ...merged[name], ...obj };
        }
      });
    });

    return Object.values(merged);
  }
  function findCommonByName(objectsArray) {
    const nameCount = {};
    const commonObjects = {};

    // Tüm objeleri dolaşarak "name" sayısını hesapla
    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (!nameCount[name]) {
          nameCount[name] = 0;
        }
        nameCount[name]++;
      });
    });

    // Ortak olan objeleri bul ve birleştir
    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (nameCount[name] === objectsArray.length) {
          if (!commonObjects[name]) {
            commonObjects[name] = { ...obj };
          }
        }
      });
    });

    return Object.values(commonObjects);
  }
  const funcIsProcessing = async (deger, text = "") => {
    console.log("funcIsProcessing", deger, text);
    setIsProcessing(deger);
  };
  function findCommonElementsByName(...arrays) {
    if (arrays.length === 0) return [];

    // İlk dizideki name değerlerini bir Set'e ekleyin
    const firstArrayNames = new Set(arrays[0].map((item) => item.name));

    // Ortak elemanları bulmak için diğer dizileri kontrol edin
    for (let i = 1; i < arrays.length; i++) {
      const currentArrayNames = new Set(arrays[i].map((item) => item.name));
      for (const name of firstArrayNames) {
        if (!currentArrayNames.has(name)) {
          firstArrayNames.delete(name);
        }
      }
    }

    // Ortak name değerlerini bulduk, şimdi bu name değerlerine sahip tüm nesneleri döndürelim
    const commonNames = Array.from(firstArrayNames);
    const commonElements = arrays[0].filter((item) =>
      commonNames.includes(item.name)
    );

    return commonElements;
  }
  const funcSelectSonuc = async () => {
    funcIsProcessing(true, "funcSelectSonuc");
    setDataBody(null);
    setDataFilter(null);
    console.log("test selectedItem", selectedItem);
    if (selectedItem) {
      let formasyons = Object.keys(selectedItem.formations);
      let indicators = Object.keys(selectedItem.indicators);
      let hacims = Object.keys(selectedItem.volumes);
      let ortalamas = Object.keys(selectedItem.averages);
      let data = [];
      let formasyon_remove = 0;
      let other_remove = 0;
      let dataOrtak = [];
      let ortak_indicators = [];
      let ortak_hacim = [];
      let ortak_ortalama = [];
      let sonucSifir = 0;
      let ortakFormasyon = [];
      let ortakIndicator = [];
      let ortakHacim = [];
      let ortakOrtalamalar = [];
      // console.log("test formasyons", formasyons);
      // console.log("test indicators", indicators);
      // console.log("test ortalamas", ortalamas);
      // console.log("test hacims", hacims);
      const dataDummy = dataBody2[0].filter((item1) =>
        marketler.some((item2) => item2.name === item1.label)
      );
      console.log("dataDummy", dataDummy);

      if (formasyons.length > 0) {
        let tempData = [];
        let forms = [];

        if (selectedItem.additionals.seperate == 1) {
          await Promise.all(
            formasyons.map(async (formasyon) => {
              let searchData = {};
              selectedItem.formations[formasyon].periods.map(
                async (returnPeriyot) => {
                  if (selectedItem.formations[formasyon].side == 0) {
                    if (
                      formasyon == "BAYZ" &&
                      selectedItem.additionals.bayz_just_closed == 1
                    ) {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        period: returnPeriyot,
                        bayz_just_closed: "1",
                      };
                    } else {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        period: returnPeriyot,
                      };
                    }
                  } else {
                    if (
                      formasyon == "BAYZ" &&
                      selectedItem.additionals.bayz_just_closed == 1
                    ) {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        market:
                          selectedItem.formations[formasyon].side == 1
                            ? "BULL"
                            : "BEAR",
                        period: returnPeriyot,
                        bayz_just_closed: "1",
                      };
                    } else {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        market:
                          selectedItem.formations[formasyon].side == 1
                            ? "BULL"
                            : "BEAR",
                        period: returnPeriyot,
                      };
                    }
                  }

                  let searching = _.filter(dataDummy, {
                    formations: [searchData],
                  });
                  //// console.log("searching ayrı ayrı formasyons",searchData,searching);
                  forms.push(searching);
                }
              );
            })
          );

          await Promise.all(
            forms.map((subForms) => {
              subForms.map((sub) => {
                tempData.push(sub);
              });
            })
          );
          ortakFormasyon = _.uniqBy(tempData, "name");
        } else {
          let searching = [];
          let formationShow = [];

          await Promise.all(
            updatedDataFormations.map(async (formasyon) => {
              forms[formasyon.slug] = [];
            })
          );
          let DummyDataBody = dataDummy;
          await Promise.all(
            formasyons.map(async (formasyon) => {
              let searchData = {};
              selectedItem.formations[formasyon].periods.map(
                async (returnPeriyot) => {
                  if (selectedItem.formations[formasyon].side == 0) {
                    if (
                      formasyon == "BAYZ" &&
                      selectedItem.additionals.bayz_just_closed == 1
                    ) {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        period: returnPeriyot,
                        bayz_just_closed: "1",
                      };
                    } else {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        period: returnPeriyot,
                      };
                    }
                  } else {
                    if (
                      formasyon == "BAYZ" &&
                      selectedItem.additionals.bayz_just_closed == 1
                    ) {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        market:
                          selectedItem.formations[formasyon].side == 1
                            ? "BULL"
                            : "BEAR",
                        period: returnPeriyot,
                        bayz_just_closed: "1",
                      };
                    } else {
                      searchData = {
                        formation: handleFormasyon(formasyon)[0].slug,
                        market:
                          selectedItem.formations[formasyon].side == 1
                            ? "BULL"
                            : "BEAR",
                        period: returnPeriyot,
                      };
                    }
                  }

                  searching = _.filter(DummyDataBody, {
                    formations: [searchData],
                  });
                  searching.map((item) => {
                    item.formations.map((form) => {
                      form.show = 0;
                    });
                  });
                  forms[formasyon].push(searching);
                }
              );
            })
          );
          if (formasyons.length == 1) {
            Object.keys(forms).map((subForms) => {
              if (forms[subForms].length > 0) {
                forms[subForms].map((sub) => {
                  tempData.push(sub);
                });
              }
            });
            console.log("tempData 0", tempData);
            ortakFormasyon = mergeByName(tempData);
          } else {
            let tempData2 = [];
            Object.keys(forms).map((subForms, index) => {
              if (forms[subForms].length > 0) {
                tempData[subForms] = [];
                console.log("tempData forms[subForms][0]", forms[subForms]);
                forms[subForms].map((sub) => {
                  tempData[subForms].push(sub);
                });
                tempData2.push(mergeByName(tempData[subForms]));
              }
            });
            console.log("tempData 1", tempData);
            console.log("tempData 2", tempData2);
            ortakFormasyon = findCommonByName(tempData2);
          }

          // // console.log("formationShow: ", searching, formationShow);
        }
        // // console.log("_filteler: ", tempData, dataOrtak);
      } else {
        //// console.log("no Filters", dataDummy);
        formasyon_remove = 1;
        dataOrtak = dataDummy;
      }
      console.log("inid ortakFormasyon", ortakFormasyon);
      const ortak_hisseler = [];
      if (indicators.length > 0) {
        let tempData = [];
        let indi = [];
        indicators.map((indicator) => {
          let slug_value = indicator;
          indi[slug_value] = [];
          let searchData = {};
          if (selectedItem.indicators[indicator].side == 0) {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger:
                selectedItem.indicators[indicator].side == 1 ? "green" : "red",
            };
          }

          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakIndicator = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        console.log("inid ortakIndicator", ortakIndicator);
      }

      if (hacims.length > 0) {
        let tempData = [];
        let indi = [];
        hacims.map((hacim) => {
          let slug_value = hacim;

          indi[slug_value] = [];
          let searchData = {};
          if (selectedItem.volumes[hacim].side == 0) {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger: selectedItem.volumes[hacim].side == 1 ? "green" : "red",
            };
          }
          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakHacim = _.intersectionBy(...tempData, "name");
        // Sonuçları yazdırın
        console.log("inid ortakHacim", ortakHacim);
      }

      if (ortalamas.length > 0) {
        let tempData = [];
        let indi = [];
        ortalamas.map((ortalama) => {
          let slug_value = ortalama;
          indi[slug_value] = [];
          let searchData = {};
          if (selectedItem.averages[ortalama].side == 0) {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger:
                selectedItem.averages[ortalama].side == 1 ? "green" : "red",
            };
          }
          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakOrtalamalar = _.intersectionBy(...tempData, "name");

        console.log("inid ortakOrtalamalar:", ortakOrtalamalar);
      }
      let arrays = [
        ortakFormasyon,
        ortakIndicator,
        ortakHacim,
        ortakOrtalamalar,
      ];

      // console.log("arrays", arrays);
      const filteredGroup = arrays.filter((arr) => arr.length > 0);
      // console.log("filteredGroup", filteredGroup);
      let commonElements = findCommonByName(filteredGroup);

      // console.log("commonElements", commonElements);

      dataOrtak = commonElements;
      if (ortakFormasyon.length == 0 && formasyons.length > 0) {
        dataOrtak = [];
      }
      if (ortakIndicator.length == 0 && indicators.length > 0) {
        dataOrtak = [];
      }
      if (ortakHacim.length == 0 && hacims.length > 0) {
        dataOrtak = [];
      }
      if (ortakOrtalamalar.length == 0 && ortalamas.length > 0) {
        dataOrtak = [];
      }
      // ////// console.log("_filteler: ", data, dataBody2);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      console.log("indi data", dataOrtak);
      let hisseAdet = objectLength(dataOrtak);
      let taramaAdet = Object.values(dataOrtak).reduce(
        (count, current) => count + current.formations.length,
        0
      );
      if (hisseAdet == 0) {
        data = [];
        taramaAdet = 0;
        hisseAdet = 0;
      }
      setDataFilter(dataOrtak);
      setDataBody([dataOrtak]);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      // setVisibleFiltre(false);
      timeout(1000);
      console.log("finish filtering");
      funcIsProcessing(false);
    }
  };
  const handleShowGrafik = (formation_id, loadChartType) => {
    console.log(formation_id, loadChartType);
    setModalGrafik(true);
    setFormationId(formation_id);
    setLoadChart(loadChartType);
  };
  const handleButton = (fMarket) => {
    let newColor = "default";
    if (fMarket == "BULL") {
      newColor = "success";
    } else {
      newColor = "danger";
    }
    return newColor;
  };
  const handleShowPuan = (hisseName) => {
    setModalIndicator(true);
    // //console.log(hisseName)
    setPuanName(hisseName);
  };

  const handleLikeFormul = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formulId,
    };
    postDataWithHeaders(FORMULLIKE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        _getFormulLists();
        setVisibleLikeFormul(false);
      } else {
        toast.error(res.data);
      }
    });
  };
  const _setDahaGoster = (id) => {
    setDahaGoster(!dahaGoster);
    setDahaGosterId(id);
  };
  useEffect(() => {
    if (!formulId) {
      _getFormulLists();
    }
  }, [formulId]);

  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const getSembolName = (id) => {
    return _.filter(markets.items, { value: String(id) }).map((v) => v.label);
  };
  const funcSelectSembol2 = (id) => {
    if (selectedSembol.includes(id)) {
      setSelectedSembol(selectedSembol.filter((item) => item !== id));
    } else {
      setSelectedSembol([...selectedSembol, parseInt(id)]);
    }
    console.log(selectedSembol);
  };
  const funcSelectSembol = (id) => {
    const index = selectedSembol.indexOf(parseInt(id));
    if (index !== -1) {
      setSelectedSembol(selectedSembol.filter((item, idx) => idx !== index));
    } else {
      setSelectedSembol([...selectedSembol, parseInt(id)]);
    }
    console.log(selectedSembol);
  };
  const _functionAktar = (item) => {
    console.log("_functionAktar", item);
    setSelectedAktar(item);
    setVisibleAktar(true);
  };
  const handleAktar = (item) => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: item.id,
    };
    postDataWithHeaders(FORMULAKTAR, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        _getFormulLists();
        setVisibleAktar(false);
      } else {
        toast.error(res.data);
        _getFormulLists();
      }
    });
  };

  const _searchSembol = (text) => {
    setIsProcessing(true);
    let newMarkets = searchMarket;
    if (text) {
      newMarkets = _.filter(newMarkets, function (o) {
        return o.label.includes(turkceIngilizceBuyukHarfCevir(text));
      });
    }
    setSearch(text);
    setMarketler(newMarkets);
    setIsProcessing(false);
  };
  const formation2item = async (selectedFormul, bayzJustClosedKey) => {
    let filtre2 = [];
    Object.entries(selectedFormul).forEach(async ([key, value]) => {
      if (key == "formations") {
        console.log("first", key, value);
        Object.entries(value).forEach(async (item, key2) => {
          console.log("key2, item", key2, item[1], bayzJustClosedKey);
          item[1].periods.map(async (period) => {
            let searchItem = [];
            if (bayzJustClosedKey == 1 && item[0] == "BAYZ") {
              searchItem = {
                value: item[0],
                period: period,
                market: item[1].side,
                bayz_just_closed: "1",
              };
            } else {
              searchItem = {
                value: item[0],
                period: period,
                market: item[1].side,
                bayz_just_closed: "1",
              };
            }
            console.log("searchItem", searchItem);
            let searchData = await filtre2item2(searchItem);
            filtre2.push(searchData);
          });
        });
      }
    });

    return filtre2;
  };
  const getFormulasData = async () => {
    postDataWithHeaders(PANOFORMULLER, {
      access_token: user.access_token,
      // j1407b: 1,
    })
      .then((res) => {
        if (res.code == 0) {
          console.log("res.data.myboard pano", res.data.myboard);
          dispatch(setFormulas(res.data.board));
          dispatch(setUsers(res.data.users));
          console.log("res.data.myboard.name", res.data.myboard.name);
          if (res.data.myboard.name.length > 0) {
            dispatch(setMyboard(res.data.myboard));
          } else {
            dispatch(setMyboard([]));
          }
          dispatch(setFollowUsers(res.data.users_follows));
          dispatch(setFollowFormulas(res.data.formulas_follows));
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const _functTakipEt = async (formul) => {
    setIsProcessing(true);
    setSelectedFormul(formul);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleFormul(false);
        setVisibleUser(false);
      } else {
        if (res.code == "-91") {
          toast.error(res.data);
          setKullanici(formul.owner);
          setIsProcessing(false);
          setVisibleTakipKullanici(true);
          setVisibleFormul(false);
          return;
        }
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  useEffect(() => {
    const setFilterData = async () => {
      timeout(2000);
      console.log("2sn");
      let filtre2 = [];
      let selectedFormul = JSON.parse(localStorage.getItem("selectedItem"));
      console.log("filtreFormationLists change", selectedFormul);
      console.log("basliyor");
      const filtre2item = await formation2item(
        selectedFormul,
        selectedFormul.additionals.bayz_just_closed
      );
      filtre2 = filtre2item;

      console.log("bitiyor");
      console.log("filtre2 3", filtre2);
      setFiltreFormationLists2(filtre2);
    };
    console.log("selectedItem", selectedItem);
    if (selectedItem) {
      setFilterData();
    }
  }, [selectedItem]);

  // useEffect(() => {
  //   console.log("loadData", loadData);
  //   if (loadData) {
  //     console.log("funcSelectSonuc", loadData);
  //     funcSelectSonuc();
  //   }
  // }, [loadData]);
  async function fetchData() {
    await _getMarkets();
    await _getIndicators();
    setUpdatedDataFormations(updatedDataFormations);
  }
  useEffect(() => {
    console.log("formulId", formulId);
    fetchData();
  }, [formulId]);

  useEffect(() => {
    console.log("selectedBorsaId", selectedBorsaId);
    if (dataBody2 != null) {
      funcSelectSonuc();
    }
  }, [dataBody2]);

  useEffect(() => {
    if (formulId) {
      document.title = "Özel Arama Detay Sayfası - Sirius Algo";
    } else {
      document.title = "Özel Arama Sayfası - Sirius Algo";
    }
  }, []);
  // const onSortEnd = (oldIndex, newIndex) => {
  //   console.log(formulas, oldIndex, newIndex);
  // };
  const onSortEnd = (oldIndex, newIndex) => {
    const newFormulas = arrayMove(formulas, oldIndex, newIndex);
    const updatedFormulas = newFormulas.map((formula, index) => ({
      id: formula.id,
      type: formula.type,
      neworder: `${index + 1}`,
    }));

    console.log("updatedFormulas", updatedFormulas);
    const postData = {
      access_token: user.access_token,
      formulas: updatedFormulas,
    };
    postDataWithHeaders(FORMULASORT, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        setFormulaLists(newFormulas);
      } else {
        toast.error(res.data);
      }
    });
  };
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          {formulId ? (
            <>
              <Row className="OzelArama__row">
                <Col lg={12}>
                  <div
                    className="OzelArama__back"
                    onClick={() => navigate("/alarmlar")}
                  >
                    <IoMdArrowBack /> Alarmlara Dön
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="AnaKumanda__filtre">
                  <div
                    className="AnaKumanda__filtre__click"
                    onClick={() => setVisibleBorsa(true)}
                  >
                    {selectedItem != null && (
                      <div className="AnaKumanda__filtre__body">
                        <div className="AnaKumanda__filtre__title">
                          Formül Adı
                        </div>
                        <div className="AnaKumanda__filtre__text">
                          {selectedItem.name}
                        </div>
                      </div>
                    )}
                    {selectedBorsa != null && (
                      <div className="AnaKumanda__filtre__body">
                        <div className="AnaKumanda__filtre__title">Borsa</div>
                        <div className="AnaKumanda__filtre__text">
                          {selectedBorsa}
                        </div>
                      </div>
                    )}
                    {selectedItem &&
                      Object.keys(selectedItem.formations).length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              Formasyon
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div className="AnaKumanda__filtre__bottom__left">
                                {handleNameFormation(
                                  Object.keys(selectedItem.formations)[0]
                                )}{" "}
                                <span className="AnaKumanda__filtre__bottom__left__periods">
                                  {selectedItem.formations[
                                    Object.keys(selectedItem.formations)[0]
                                  ].periods.map((item, index) => (
                                    <span
                                      className="AnaKumanda__filtre__bottom__left__period"
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </span>
                                {selectedItem.formations[
                                  Object.keys(selectedItem.formations)[0]
                                ].side == 1 ? (
                                  <ArrowUp color="#5fb045" />
                                ) : selectedItem.formations[
                                    Object.keys(selectedItem.formations)[0]
                                  ].side == 0 ? (
                                  <ArrowSort color="#9a9a9a" />
                                ) : (
                                  <ArrowDown color="#E12222" />
                                )}
                                <ButtonClose />
                              </div>
                              {Object.keys(selectedItem.formations).length - 1 >
                                0 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +
                                  {Object.keys(selectedItem.formations).length -
                                    1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                    {selectedItem &&
                      Object.keys(selectedItem.indicators).length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              İndikatörler
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div className="AnaKumanda__filtre__bottom__left">
                                {handleName(
                                  Object.keys(selectedItem.indicators)[0]
                                )}{" "}
                                {selectedItem.indicators[
                                  Object.keys(selectedItem.indicators)[0]
                                ].side == 1 ? (
                                  <ArrowUp color="#5fb045" />
                                ) : selectedItem.indicators[
                                    Object.keys(selectedItem.indicators)[0]
                                  ].side == 0 ? (
                                  <ArrowSort color="#9a9a9a" />
                                ) : (
                                  <ArrowDown color="#E12222" />
                                )}
                                <ButtonClose />
                              </div>
                              {Object.keys(selectedItem.indicators).length - 1 >
                                0 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +
                                  {Object.keys(selectedItem.indicators).length -
                                    1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                    {selectedItem &&
                      Object.keys(selectedItem.averages).length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              Ortalamalar
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div className="AnaKumanda__filtre__bottom__left">
                                {handleName(
                                  Object.keys(selectedItem.averages)[0]
                                )}{" "}
                                {selectedItem.averages[
                                  Object.keys(selectedItem.averages)[0]
                                ].side == 1 ? (
                                  <ArrowUp color="#5fb045" />
                                ) : selectedItem.averages[
                                    Object.keys(selectedItem.averages)[0]
                                  ].side == 0 ? (
                                  <ArrowSort color="#9a9a9a" />
                                ) : (
                                  <ArrowDown color="#E12222" />
                                )}
                                <ButtonClose />
                              </div>
                              {Object.keys(selectedItem.averages).length - 1 >
                                0 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +
                                  {Object.keys(selectedItem.averages).length -
                                    1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                    {selectedItem &&
                      Object.keys(selectedItem.volumes).length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">Hacim</div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div className="AnaKumanda__filtre__bottom__left">
                                {handleName(
                                  Object.keys(selectedItem.volumes)[0]
                                )}{" "}
                                {selectedItem.volumes[
                                  Object.keys(selectedItem.volumes)[0]
                                ].side == 1 ? (
                                  <ArrowUp color="#5fb045" />
                                ) : selectedItem.volumes[
                                    Object.keys(selectedItem.volumes)[0]
                                  ].side == 0 ? (
                                  <ArrowSort color="#9a9a9a" />
                                ) : (
                                  <ArrowDown color="#E12222" />
                                )}
                                <ButtonClose />
                              </div>
                              {Object.keys(selectedItem.volumes).length - 1 >
                                0 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +
                                  {Object.keys(selectedItem.volumes).length - 1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                  </div>
                  <div className="AnaKumanda__filtre__container gap24 flex-row ml-auto">
                    <SonucAlarm
                      color="#fff"
                      onClick={() => setVisibleAlarm(true)}
                    />
                  </div>
                </div>
                {isProcessing && (
                  <div style={{ width: "100%" }}>
                    <div className="AnaKumanda__sonuc">
                      <div className="AnaKumanda__sonucyok">
                        <PulseLoader
                          color="#fff"
                          aria-label="Sonuçlar Getiriliyor..."
                        />
                        <div className="AnaKumanda__sonucyok__text">
                          Sonuçlar yükleniyor...
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {dataBody && (
                  <div className="Sonuclar_container">
                    <div className="Sonuclar_left">
                      <div className="Sonuclar_left__title">Sonuçlar</div>
                      <div className="Sonuclar_left__text">
                        {hisseAdet} {hisseType} - {taramaAdet} Formasyon
                      </div>
                    </div>
                    <div className="Sonuclar_right">
                      <div className="Sonuclar_right__container">
                        <div
                          className={
                            sortType == 2
                              ? "Sonuclar_right__sort active"
                              : "Sonuclar_right__sort"
                          }
                          onClick={() =>
                            sortType == 2
                              ? handleSortingChange(sortTypeChange, "puan")
                              : handleSortType(2)
                          }
                        >
                          Stratejiye Göre Sırala
                          {sortType == 2 && (
                            <div className="Sonuclar_right__sort__icon">
                              {sortTypeChange == "default" ? (
                                <ArrowSort />
                              ) : sortTypeChange == "up" ? (
                                <ArrowDown color="red" />
                              ) : (
                                <ArrowUp color="#5fb045" />
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            sortType == 1
                              ? "Sonuclar_right__sort active"
                              : "Sonuclar_right__sort"
                          }
                          onClick={() =>
                            sortType == 1
                              ? handleSortingChange(sortTypeChange, "bull")
                              : handleSortType(1)
                          }
                        >
                          Boğaya Göre Sırala
                          {sortType == 1 && (
                            <div className="Sonuclar_right__sort__icon">
                              {sortTypeChange == "default" ? (
                                <ArrowSort />
                              ) : sortTypeChange == "up" ? (
                                <ArrowDown color="red" />
                              ) : (
                                <ArrowUp color="#5fb045" />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="Sonuclar__body">
                  {dataBody &&
                    dataBody.map((data, index) =>
                      Object.values(data).map((forms, indix) => {
                        let count = 0;

                        forms.formations &&
                          forms.formations.map((formArray, indx) => {
                            let isShow = false;
                            console.log(
                              "filtreFormationLists2",
                              filtreFormationLists2
                            );
                            for (
                              let i = 0;
                              i < filtreFormationLists2.length;
                              i++
                            ) {
                              // Her iki koşulu da kontrol et
                              let item = filtreFormationLists2[i];
                              {
                                /* console.log("isShow 1", formArray, item); */
                              }
                              if (
                                item.market &&
                                formArray.formation == item.formation &&
                                formArray.period == item.period &&
                                formArray.market == item.market
                              ) {
                                {
                                  /* console.log("isShow 2", formArray, item); */
                                }
                                if (
                                  bayzJustClosed == 1 &&
                                  item.formation == "BAYZ" &&
                                  formArray.bayz_just_closed == 1
                                ) {
                                  isShow = true;
                                  formArray.show = 1;
                                  break;
                                } else {
                                  isShow = true;
                                  formArray.show = 1;
                                  break;
                                }
                              }
                              if (
                                !item.market &&
                                formArray.formation == item.formation &&
                                formArray.period == item.period
                              ) {
                                {
                                  /* console.log("isShow 3", formArray, item); */
                                }
                                if (
                                  bayzJustClosed == 1 &&
                                  item.formation == "BAYZ" &&
                                  formArray.bayz_just_closed == 1
                                ) {
                                  isShow = true;
                                  formArray.show = 1;
                                  break;
                                } else {
                                  isShow = true;
                                  formArray.show = 1;
                                  break;
                                }
                              }
                            }
                            console.log(
                              "filtreFormationLists2: ",
                              forms.name,
                              formArray,
                              filtreFormationLists2,
                              bayzJustClosed,
                              isShow
                            );

                            if (isShow) {
                              count = count + 1;
                              formArray.show = 1;
                            }
                            return count;
                          });
                        let showCount = forms.formations.length - count;
                        {
                          /* console.log("showCount", showCount, count); */
                        }
                        let exchange_id = forms.exchange_id;
                        let puan = forms.puan;

                        let formsName = forms.name.replace("/", "_") || "";
                        return (
                          <div
                            key={indix}
                            className="Sonuclar__body__container"
                          >
                            <div className="Sonuclar__body__container__left">
                              {/* <SelectIcon
                                hisseName={forms.name}
                                fStocks={topluEkleLists}
                                func={handleTopluEkle}
                              /> */}

                              <SonucTekEkle
                                className="Sonuclar__body__container__left__close"
                                onClick={() => funcListeEkle(forms)}
                              />
                              {/* <SonucAlarm
                                className="Sonuclar__body__container__left__alarm"
                                onClick={() => funcAlarmFiyat(forms.name)}
                              /> */}
                              <Link
                                to={`/grafik-paneli/${formsName}`}
                                target="_blank"
                              >
                                <SonucGrafik />
                              </Link>
                              <span className="Sonuclar__body__container__left__text">
                                {forms.name}
                              </span>
                              <div className="Sonuclar__body__container__left_border ml-auto"></div>
                            </div>

                            <div className="Sonuclar__body__container__right">
                              {forms.formations &&
                                forms.formations.map((formArray, indx) => {
                                  let formasyonName = handleNameFormation(
                                    formArray.formation
                                  );
                                  let foundItem = null;

                                  if (user.bayz == "0") {
                                    foundItem = zamansal.find(
                                      (element) => element === formasyonName[0]
                                    );
                                  }

                                  let icon =
                                    formArray.market === "BULL" ? (
                                      <BiSolidUpArrow color="#5fb045" />
                                    ) : (
                                      <BiSolidDownArrow color="#E12222" />
                                    );
                                  let boxButton = handleButton(
                                    formArray.market
                                  );
                                  let filePath = "";
                                  {
                                    /* if (bayzClosed == 0 && bayzJustClosed == 1) {
                                  formArray.show = 0;
                                } */
                                  }
                                  let showFormation =
                                    formArray.show == 1 ? "d-block" : "d-none";
                                  if (
                                    dahaGoster &&
                                    dahaGosterId === forms.name
                                  ) {
                                    showFormation = "d-block";
                                  }
                                  if (count == 0) {
                                    showFormation = "d-block";
                                  }
                                  {
                                    /* console.log("formArray", formArray); */
                                  }
                                  return (
                                    <Button
                                      key={indx}
                                      className={`Sonuclar__body__button iShow ${showFormation}  ${formArray.formation} btn-sm ${boxButton}`}
                                      onClick={() =>
                                        foundItem
                                          ? handleUyari(formasyonName[0])
                                          : handleShowGrafik(
                                              formArray.id,
                                              formArray.chart
                                            )
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginBottom: 5,
                                        // backgroundColor: `${handleColor(
                                        //   formArray.formationColor,
                                        //   formArray.formation,
                                        //   formArray.market
                                        // )}`,
                                      }}
                                    >
                                      {icon} {formasyonName[0]}{" "}
                                      {formArray.bayz_percentage
                                        ? `(${formArray.bayz_percentage}%)`
                                        : ""}{" "}
                                      {`(${formArray.period})`}
                                    </Button>
                                  );
                                })}
                            </div>
                            <div className="Sonuclar__body__container__right_count">
                              {count > 0 && (
                                <span
                                  className="dahaGoster"
                                  onClick={() => _setDahaGoster(forms.name)}
                                >
                                  {dahaGosterId === forms.name
                                    ? "Diğerlerini gizle"
                                    : `${showCount} Adet daha var`}
                                </span>
                              )}
                            </div>
                            <div
                              className="Sonuclar__body__strateji"
                              onClick={() => handleShowPuan(forms.name)}
                            >
                              <span className="Sonuclar__body__strateji__text">
                                Strateji
                              </span>
                              <span className="Sonuclar__body__strateji__puan">
                                {puan}/{updateDataAll.length}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    )}
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <div className="OzelArama__container">
                  <div className=" OzelArama__header">
                    <h2>{formulas.length} Kayıtlı Formül</h2>
                    <div className="OzelArama__header__tabs">
                      <div className="OzelArama__tab">
                        <div
                          className={
                            activeTab === "tumu"
                              ? "OzelArama__tab_item active"
                              : "OzelArama__tab_item"
                          }
                          onClick={() => _setActiveTab("tumu")}
                        >
                          Tümü
                        </div>
                        <div
                          className={
                            activeTab === "self"
                              ? "OzelArama__tab_item active"
                              : "OzelArama__tab_item"
                          }
                          onClick={() => _setActiveTab("self")}
                        >
                          Benim Formüllerim
                        </div>
                        <div
                          className={
                            activeTab === "board"
                              ? "OzelArama__tab_item active"
                              : "OzelArama__tab_item"
                          }
                          onClick={() => _setActiveTab("board")}
                        >
                          Panodan Aldığım Formüller
                        </div>
                        <div
                          className={
                            activeTab === "followers"
                              ? "OzelArama__tab_item active"
                              : "OzelArama__tab_item"
                          }
                          onClick={() => _setActiveTab("followers")}
                        >
                          Takip Ettiklerimden Aldığım Formüller
                        </div>
                      </div>
                      <div className="modalSearch__input ml-auto">
                        <BsSearch color="#fff" />
                        <input
                          type="text"
                          placeholder="Arama Yap"
                          value={search}
                          onChange={(e) => _setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OzelArama_lists_">
                    {formulas.length > 0 ? (
                      <>
                        <SortableList
                          onSortEnd={onSortEnd}
                          className="OzelArama_lists"
                          draggedItemClassName="dragged"
                        >
                          {formulas.map((item, index) => {
                            let countButton = 0;

                            if (Object.keys(item.formations).length > 0) {
                              countButton =
                                countButton +
                                Object.keys(item.formations).length -
                                1;
                            }
                            if (Object.keys(item.averages).length > 0) {
                              countButton =
                                countButton +
                                Object.keys(item.averages).length -
                                1;
                            }
                            if (Object.keys(item.indicators).length > 0) {
                              countButton =
                                countButton +
                                Object.keys(item.indicators).length -
                                1;
                            }
                            if (Object.keys(item.volumes).length > 0) {
                              countButton =
                                countButton +
                                Object.keys(item.volumes).length -
                                1;
                            }

                            return (
                              <SortableItem key={index} index={index}>
                                <div className="OzelArama_list_item">
                                  <div className="OzelArama_list_item_left">
                                    <TbArrowsMoveVertical
                                      color={
                                        theme.darkmode == "dark"
                                          ? "#fff"
                                          : "#000"
                                      }
                                      size={24}
                                    />
                                    <div
                                      className="OzelArama_list_item_title cursorPointer"
                                      onClick={() => _setVisibleFormul(item)}
                                      data-tooltip-place="bottom-end"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={item.name}
                                    >
                                      <span>{item.name}</span>
                                    </div>
                                    <div className="OzelArama_list_item_desc">
                                      {item.type != "self" && (
                                        <div
                                          className="OzelArama_list_item_desc_top cursorPointer"
                                          onClick={() =>
                                            item.owner
                                              ? _setVisibleUser(item.owner)
                                              : {}
                                          }
                                        >
                                          Formülizer:{" "}
                                          <span>
                                            {item.owner ? item.owner : "Anonim"}
                                          </span>
                                        </div>
                                      )}
                                      <div className="OzelArama_list_item_desc_bottom">
                                        <PanoNote
                                          color={"#9A9A9A"}
                                          data-tooltip-place="bottom-start"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={item.note}
                                        />
                                        <span>
                                          <AiFillDashboard size={16} />{" "}
                                          {item.queries ? item.queries : 0}
                                        </span>
                                        <span>
                                          <BsHeartFill size={16} />{" "}
                                          {item.likes ? item.likes : 0}
                                        </span>
                                        <span>
                                          <AiFillSignal size={16} />{" "}
                                          {item.rating ? item.rating : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <img src={araCizgi} />
                                    </div>
                                    <div className="OzelArama_list_item_formation">
                                      {Object.keys(item.formations).length >
                                        0 &&
                                        Object.keys(item.formations)
                                          .slice(0, 1)
                                          .map((key) => (
                                            <div
                                              className="OzelArama_list_item_formation_item"
                                              key={`for_${key}`}
                                            >
                                              <span className="title">
                                                {handleNameFormation(key)}
                                              </span>
                                              <span className="OzelArama_periods">
                                                {item.formations[
                                                  key
                                                ].periods.map((period) => (
                                                  <span
                                                    className="period"
                                                    key={`formul_${key}_${period}`}
                                                  >
                                                    {period}
                                                  </span>
                                                ))}{" "}
                                              </span>

                                              {item.formations[key].side ==
                                              0 ? (
                                                <ArrowSort color="#9a9a9a" />
                                              ) : item.formations[key].side ==
                                                1 ? (
                                                <ArrowUp color="#5fb045" />
                                              ) : (
                                                <ArrowDown color="#E12222" />
                                              )}
                                            </div>
                                          ))}
                                      {Object.keys(item.averages).length > 0 &&
                                        Object.keys(item.averages)
                                          .slice(0, 1)
                                          .map((key) => (
                                            <div
                                              className="OzelArama_list_item_formation_item"
                                              key={`for_${key}`}
                                            >
                                              <span className="title">
                                                {handleName(key)}
                                              </span>
                                              {item.averages[key].side == 0 ? (
                                                <ArrowSort color="#9a9a9a" />
                                              ) : item.averages[key].side ==
                                                1 ? (
                                                <ArrowUp color="#5fb045" />
                                              ) : (
                                                <ArrowDown color="#E12222" />
                                              )}
                                            </div>
                                          ))}
                                      {Object.keys(item.indicators).length >
                                        0 &&
                                        Object.keys(item.indicators)
                                          .slice(0, 1)
                                          .map((key) => (
                                            <div
                                              className="OzelArama_list_item_formation_item"
                                              key={`for_${key}`}
                                            >
                                              <span className="title">
                                                {handleName(key)}
                                              </span>
                                              {item.indicators[key].side ==
                                              0 ? (
                                                <ArrowSort color="#9a9a9a" />
                                              ) : item.indicators[key].side ==
                                                1 ? (
                                                <ArrowUp color="#5fb045" />
                                              ) : (
                                                <ArrowDown color="#E12222" />
                                              )}
                                            </div>
                                          ))}
                                      {countButton > 0 && (
                                        <div className="AnaKumanda__filtre__bottom__right">
                                          +{countButton}
                                        </div>
                                      )}
                                      <div>
                                        <img src={araCizgi} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="OzelArama__list_item_share">
                                    {item.type == "self" ? (
                                      <>
                                        <div
                                          className={
                                            item.shared == "board"
                                              ? "OzelArama__list_item_share_item paylasildi"
                                              : "OzelArama__list_item_share_item"
                                          }
                                          onClick={() =>
                                            item.shared == "board"
                                              ? _setVisibleUnShare(
                                                  item,
                                                  "board"
                                                )
                                              : _setVisibleShare(item, "board")
                                          }
                                        >
                                          <PiShareFatFill />
                                          <span>
                                            {item.shared == "board"
                                              ? "Panodan kaldır"
                                              : "Panoya paylaş"}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            item.shared == "followers"
                                              ? "OzelArama__list_item_share_item paylasildi"
                                              : "OzelArama__list_item_share_item"
                                          }
                                          onClick={() =>
                                            item.shared == "followers"
                                              ? _setVisibleUnShare(
                                                  item,
                                                  "followers"
                                                )
                                              : _setVisibleShare(
                                                  item,
                                                  "followers"
                                                )
                                          }
                                        >
                                          <PiShareFatFill />
                                          <span>
                                            {item.shared == "followers"
                                              ? "Takipçilerimden kaldır"
                                              : "Takipçilerime paylaş"}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item.type != "self" && !item.owner ? (
                                      <>
                                        <div
                                          className="OzelArama__list_item_share_item_aktar"
                                          onClick={() => _functionAktar(item)}
                                        >
                                          <PiShareFatFill />
                                          <span>Formülü Kendine Aktar</span>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <div
                                      className={
                                        item.alarm == 0
                                          ? "OzelArama__list_item_share_alarm"
                                          : "OzelArama__list_item_share_alarm alarmEkli"
                                      }
                                      onClick={() => _setVisibleAlarm(item)}
                                    >
                                      <ShareAlarm
                                        color={
                                          item.alarm == "1"
                                            ? "#5fb045"
                                            : theme.darkmode == "light"
                                            ? "#9a9a9a"
                                            : "#fff"
                                        }
                                      />
                                    </div>
                                    {item.type == "self" ? (
                                      <>
                                        {/* {item.shared == null && (
                                      <div
                                        className="OzelArama__list_item_share_delete"
                                        onClick={() => _handleEditFormul(item)}
                                      >
                                        <BsPencilFill size={24} color="#3ab22f" />
                                      </div>
                                    )} */}

                                        <div
                                          className="OzelArama__list_item_share_delete"
                                          onClick={() =>
                                            _setVisibleDelete(item)
                                          }
                                        >
                                          <BsTrash size={24} color="red" />
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="OzelArama__list_item_share_delete"
                                        onClick={() =>
                                          _setVisibleUnfollow(item)
                                        }
                                      >
                                        <SlUserUnfollow size={24} color="red" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </SortableItem>
                            );
                          })}
                        </SortableList>
                      </>
                    ) : (
                      formulas.length == 0 &&
                      loadSkeleton == true && (
                        <div className="OzelArama_lists_skelaton">
                          <SkeletonTheme
                            baseColor={
                              theme.darkmode == "dark" ? "#202020" : "#f0f0f0"
                            }
                            highlightColor={
                              theme.darkmode == "dark" ? "#444" : "#f4f4f4"
                            }
                          >
                            {[1, 2, 3, 4].map((i) => (
                              <Skeleton
                                key={i}
                                className="OzelArama_list_item"
                                style={{ width: "100%" }}
                              />
                            ))}
                          </SkeletonTheme>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Row>
            </>
          )}
        </Col>
      </Row>

      {visibleBorsa && (
        <Modal
          show={visibleBorsa}
          onHide={handleBorsaClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Borsa Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectBorsa(borsa.label, borsa.value)}
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {selectedItem.name}
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div
              className="AramaSelect active"
              onClick={() => setVisibleBorsa(true)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Borsa Seç</div>
                <div className="AramaSelect__text">
                  {selectedBorsa != null ? selectedBorsa : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            {selectedItem && (
              <>
                {Object.keys(selectedItem.formations).length > 0 && (
                  <div className="AramaFiltre">
                    <div className="AramaFiltre__title">
                      <h2>Formasyonlar</h2>
                      <div className="modalSubDiv__footer__type">
                        {selectedItem.additionals.bayz_just_closed == 1 ? (
                          <SonucSecildi
                            onClick={() => handleAyri(0, "Ayırılmış")}
                          />
                        ) : (
                          <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                        )}
                        Bayz Üstünde Kapatanlar
                      </div>
                    </div>
                    <div className="AramaFiltre__body">
                      {Object.keys(selectedItem.formations).map((key) => (
                        <div className="AramaFiltre_item" key={`formul_${key}`}>
                          <span className="title">
                            <SonucSecildi />
                            {handleNameFormation(key)}
                          </span>
                          <span className="OzelArama_periods">
                            {selectedItem.formations[key].periods.map(
                              (period) => (
                                <span
                                  className="period"
                                  key={`formul_${key}_${period}`}
                                >
                                  {period}
                                </span>
                              )
                            )}{" "}
                          </span>
                          {selectedItem.formations[key].side == 0 ? (
                            <ArrowSort color="#9a9a9a" />
                          ) : selectedItem.formations[key].side == 1 ? (
                            <ArrowUp color="#5fb045" />
                          ) : (
                            <ArrowDown color="#E12222" />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="modalSubDiv__footer__type">
                      {selectedItem.additionals.seperate == 1 ? (
                        <SonucSecildi
                          onClick={() => handleAyri(0, "Ayırılmış")}
                        />
                      ) : (
                        <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                      )}
                      Ayrı Olarak Listele
                    </div>
                  </div>
                )}
                {Object.keys(selectedItem.volumes).length > 0 && (
                  <div className="AramaFiltre">
                    <div className="AramaFiltre__title">
                      <h2>Hacim</h2>
                    </div>
                    <div className="AramaFiltre__body">
                      {Object.keys(selectedItem.volumes).map((key) => (
                        <div
                          className="AramaFiltre_item"
                          key={`volumes_${key}`}
                        >
                          <span className="title">
                            <SonucSecildi />
                            {handleName(key)}
                          </span>
                          {selectedItem.volumes[key].side == 0 ? (
                            <ArrowSort color="#9a9a9a" />
                          ) : selectedItem.volumes[key].side == 1 ? (
                            <ArrowUp color="#5fb045" />
                          ) : (
                            <ArrowDown color="#E12222" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {Object.keys(selectedItem.averages).length > 0 && (
                  <div className="AramaFiltre">
                    <div className="AramaFiltre__title">
                      <h2>Ortalamalar</h2>
                    </div>
                    <div className="AramaFiltre__body">
                      {Object.keys(selectedItem.averages).map((key) => (
                        <div
                          className="AramaFiltre_item"
                          key={`averages_${key}`}
                        >
                          <span className="title">
                            <SonucSecildi />
                            {handleName(key)}
                          </span>
                          {selectedItem.averages[key].side == 0 ? (
                            <ArrowSort color="#9a9a9a" />
                          ) : selectedItem.averages[key].side == 1 ? (
                            <ArrowUp color="#5fb045" />
                          ) : (
                            <ArrowDown color="#E12222" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {Object.keys(selectedItem.indicators).length > 0 && (
                  <div className="AramaFiltre">
                    <div className="AramaFiltre__title">
                      <h2>İndikatörler</h2>
                    </div>
                    <div className="AramaFiltre__body">
                      {Object.keys(selectedItem.indicators).map((key) => (
                        <div
                          className="AramaFiltre_item"
                          key={`indicators_${key}`}
                        >
                          <span className="title">
                            <SonucSecildi />
                            {handleName(key)}
                          </span>
                          {selectedItem.indicators[key].side == 0 ? (
                            <ArrowSort color="#9a9a9a" />
                          ) : selectedItem.indicators[key].side == 1 ? (
                            <ArrowUp color="#5fb045" />
                          ) : (
                            <ArrowDown color="#E12222" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <Button
              onClick={() => handleFormulEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Sonuçları Getir"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {visibleShare && (
        <Modal
          show={visibleShare}
          onHide={handleShareClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    <i>{share}</i> Formülünü{" "}
                    {shareType == "board" ? "Panoya" : "Takipçilerime"} Paylaş
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="shareNote"
                  placeholder="Formül notu giriniz"
                  value={shareNote}
                  onChange={(e) => setShareNote(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <Button
              onClick={() => handleShareEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Formülü Paylaş"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {visibleUnShare && (
        <Modal
          show={visibleUnShare}
          onHide={handleUnShareClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    <i>{share}</i> Formülünü{" "}
                    {shareType == "board" ? "Panodan" : "Takipçilerimden"}{" "}
                    Kaldır
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <p>Kaldırma metni buraya gelecek</p>
            </div>
            <Button
              onClick={() => handleUnShareEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Formülü Kaldır"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleAlarm && (
        <Modal
          show={visibleAlarm}
          onHide={handleAlarmClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Alarm Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body" style={{ width: "30%" }}>
                <Form.Control
                  type="text"
                  name="alarm"
                  placeholder="Alarm adı giriniz"
                  value={alarm}
                  onChange={(e) => setAlarm(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label,
                      borsa.value
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => _searchSembol(e.target.value)}
                />
              </div>
            </div>
            <div className="modalSelecteds">
              {selectedSembol.length > 0 &&
                selectedSembol.map((sembolId) => {
                  return (
                    <div
                      key={sembolId}
                      className="modalSelectButton active"
                      onClick={() => funcSelectSembol(sembolId)}
                    >
                      {getSembolName(sembolId)}
                      <span className="modalSelectButton__close">
                        <ButtonClose />
                      </span>
                    </div>
                  );
                })}
            </div>
            <div className="modalSelectButtons">
              {marketler.map((sembol) => (
                <Button
                  key={sembol.label}
                  className={
                    selectedSembol == sembol.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectSembol(sembol.value)}
                >
                  {sembol.label}
                </Button>
              ))}
            </div>
            <Button
              onClick={() => handleAlarmEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Alarm Ekle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {visibleDelete && (
        <Modal
          show={visibleDelete}
          onHide={handleDeleteClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Sil
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">{selectedItem.name}</div>
            </div>
            {selectedItem.shared != null && (
              <div className="modalAlarmContent">
                Dikkat: Formülü silmeniz durumunda formüldeki sahipliğiniz
                düşecek ve formül <b>anonime</b> dönüşecektir.
              </div>
            )}
            <div className="modalAlarmButtons">
              <Button
                onClick={() => handleDeleteClose()}
                variant="warning"
                className="modalSonucButton"
              >
                İptal
              </Button>
              <Button
                onClick={() => handleDelete()}
                variant="info"
                className="modalSonucButton"
              >
                {isProcessing ? (
                  <PulseLoader
                    color="#fff"
                    aria-label="Sonuçlar Getiriliyor..."
                  />
                ) : (
                  "Formül Sil"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleAktar && (
        <Modal
          show={visibleAktar}
          onHide={handleAktarClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Kendine Aktar
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">{selectedAktar.name}</div>
            </div>

            <div className="modalAlarmContent">
              Dikkat: Formülü size aktarıldığında, formülün sahipliği size
              geçmiş olacaktadır. Pano da formül paylaşımınız var ise ya da
              takipçilerinize paylaştığınız formül limitine ulaşmışsanız aktarım
              gerçekleşmeyecektir.
            </div>

            <div className="modalAlarmButtons">
              <Button
                onClick={() => handleAktarClose()}
                variant="warning"
                className="modalSonucButton"
              >
                İptal
              </Button>
              <Button
                onClick={() => handleAktar(selectedAktar)}
                variant="info"
                className="modalSonucButton"
              >
                {isProcessing ? (
                  <PulseLoader
                    color="#fff"
                    aria-label="Sonuçlar Getiriliyor..."
                  />
                ) : (
                  "Formül Aktar"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleUnfollow && (
        <Modal
          show={visibleUnfollow}
          onHide={handleUnfollowClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Takipten Çık
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">{selectedItem.name}</div>
            </div>
            <Button
              onClick={() => handleUnfollow()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Takipten Çık"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {modalGrafik && (
        <Modal
          show={modalGrafik}
          onHide={handleGrafikClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ChartComponent
              urlApi={urlApi}
              id={formationId}
              load={loadChart}
              theme={theme.darkmode}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalIndicator && (
        <Modal
          show={modalIndicator}
          onHide={handleIndicatorClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PuanComponent
              urlApi={urlApi}
              name={puanName}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {visibleLikeFormul && (
        <Modal
          show={visibleLikeFormul}
          onHide={handleLikeFormulClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modalLikeFormul">
              <img src={formulLike} />
              <h2>Üzgünüz!</h2>
              <h4>5 hakkınızıda doldurdunuz.</h4>
              <p>
                Formülü daha fazla görüntülemek için bu Formülü puanlamanız
                gerekmektedir.
                <br />
                <br />
                Eğer beğeni yapmazsanız bu Formül listenizden kaldırılacak ve
                bir daha erişemeyeceksiniz
              </p>
              <div className="modalLikeFormul__buttons">
                <Button variant="success" onClick={() => handleLikeFormul()}>
                  <BsHeartFill /> Formülü Beğen
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleUser && (
        <Modal
          show={visibleUser}
          onHide={handleUserClose}
          dialogClassName="modal-863w modalWithNoPadding"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle userTitle"
                  >
                    Kişi Profili
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="UserProfile">
              <div className="UserProfile__top">
                <div className="UserProfile__top__left">
                  <div className="UserProfile__top__title">
                    <StarTop />
                    {selectedUser}
                  </div>
                </div>
                <div
                  className="UserProfile__top__right"
                  onClick={() =>
                    isTakipci == 0
                      ? followUser(selectedUser)
                      : _unFollowUser(selectedUser)
                  }
                >
                  {isTakipci == 0 ? (
                    <UserFollow color={"#fff"} />
                  ) : (
                    <UserFollowing color={"#fff"} />
                  )}
                  {isTakipci == 0 ? "Takip Et" : "Takip Ediliyor"}
                </div>
              </div>
              <div className="userFrame">
                <img src={userFrame} />
              </div>
              <div className="UserProfile__puans">
                <div className="UserProfile__OverviewCard bgOverCard1">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserProfileZiyaret />
                    Profil Ziyareti
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.visits ? userProfile.visits : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard2">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserTakipci />
                    Takipçi
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.followers ? userProfile.followers : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard3">
                  <div className="UserProfile__OverviewCard__Top">
                    <AiFillSignal />
                    Sorgulanma Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.queries ? userProfile.queries : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard4">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserMemnuniyet />
                    Beğeni Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.likes ? userProfile.likes : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard5">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserSirius />
                    Reyting Puanı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.rating ? userProfile.rating : 0}
                  </div>
                </div>
              </div>
              {userProfile.myboard.name != null && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Panoya Paylaştığı Formül
                    </div>
                  </div>
                  <div className="PanoTop__publish">
                    <div className="PanoTop__publish__left">
                      {userProfile.myboard.name}
                      <PanoNote
                        color="#fff"
                        data-tooltip-place="bottom-start"
                        data-tooltip-id="my-tooltip2"
                        data-tooltip-content={userProfile.myboard.note}
                      />
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Sorgulama Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillDashboard size={16} />{" "}
                          {userProfile.myboard.queries
                            ? userProfile.myboard.queries
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.queries_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Beğeni Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <BsHeartFill size={16} />{" "}
                          {userProfile.myboard.likes
                            ? userProfile.myboard.likes
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.likes_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Reyting Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillSignal size={16} />{" "}
                          {userProfile.myboard.rating
                            ? userProfile.myboard.rating
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.rating_rank}
                        </span>
                      </div>
                    </div>

                    {_.filter(formulas_follows, {
                      id: userProfile.myboard.id,
                    }).length == 0 ? (
                      <>
                        <SonucTekEkle
                          onClick={() => _functTakipEt(userProfile.myboard)}
                          color="#fff"
                        />
                      </>
                    ) : (
                      <>
                        <SonucSecildi
                          onClick={() =>
                            _setVisibleUnfollow(userProfile.myboard)
                          }
                          color="#22DE2A"
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {userProfile.formulas.length > 0 && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Takipçileriyle Paylaştığı Formüller
                    </div>
                    {userProfile.formulas.map((item, index) => {
                      if (item.type == "board") {
                        return;
                      }
                      let takipte = _.filter(formulas_follows, {
                        id: item.id,
                      }).length;
                      return (
                        <div
                          key={`popup${index}`}
                          className="UserProfile__bottom__div"
                        >
                          <div className="UserProfile__bottom__div__left">
                            <div
                              className="UserProfile__bottom__div__title cursorPointer"
                              onClick={() => _setVisibleFormul(item)}
                            >
                              {item.name}
                            </div>
                            <div className="UserProfile__bottom__div__puan">
                              <span>
                                <AiFillDashboard size={16} />{" "}
                                {item.queries ? item.queries : 0}
                              </span>
                              <span>
                                <BsHeartFill size={16} />{" "}
                                {item.likes ? item.likes : 0}
                              </span>
                              <span>
                                <AiFillSignal size={16} />{" "}
                                {item.rating ? item.rating : 0}
                              </span>
                            </div>
                          </div>
                          <div className="UserProfile__bottom__div__right">
                            <PanoNote
                              color="#fff"
                              data-tooltip-place="bottom-start"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item.note}
                            />
                            {takipte == 0 ? (
                              <>
                                <SonucTekEkle
                                  onClick={() => _functTakipEt(item)}
                                  color="#fff"
                                />
                              </>
                            ) : (
                              <>
                                <SonucSecildi
                                  onClick={() => _setVisibleUnfollow(item)}
                                  color="#22DE2A"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleListelerim && (
        <Modal
          show={visibleListelerim}
          onHide={handleListelerimClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {listeName} Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalListeTabDiv">
              <div className="modalListeTabDiv__body">
                <div
                  className={
                    selectListeTab == 1
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(1)}
                >
                  Listelerime Ekle
                </div>
                <div
                  className={
                    selectListeTab == 2
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(2)}
                >
                  Favorilerime Ekle
                </div>
              </div>
            </div>
            <div className="modalListeTabDiv__list">
              {selectListeTab == 1 ? (
                <>
                  <div className="modalListeScroll">
                    {listeler &&
                      listeler.map((item) => (
                        <div
                          key={item.id}
                          className="modalListeTabDiv__list__item"
                          onClick={() => _listeSelect(item)}
                        >
                          {selectListe.id == item.id ? (
                            <SonucSecildi />
                          ) : (
                            <SonucSec />
                          )}
                          <div>{item.name}</div>
                        </div>
                      ))}
                  </div>
                  <div
                    className="modalListeTabDiv__list__item"
                    onClick={() => _listeSelect("new")}
                  >
                    {selectListe == "new" ? <SonucSecildi /> : <SonucSec />}
                    <Form.Control
                      type="text"
                      name="liste"
                      placeholder="Yeni Liste Oluştur"
                      value={liste}
                      onChange={(e) => setListe(e.target.value)}
                      className="modalAlarmDiv__body__input"
                    />
                  </div>
                  <Button
                    onClick={() => handleListemeEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Listeye Ekle"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {selectListeTab == 2 && (
                <>
                  <Button
                    onClick={() => handleFavoriEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Favoriye Ekle"
                    )}
                  </Button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "#35353A",
          color: "#fff",
          fontSize: 14,
          padding: 16,
          borderRadius: 8,
          maxWidth: 300,
        }}
      />
    </Container>
  );
}

export default FormulArama;
