import * as React from "react";
const SidemenuAlarm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.097 16.357h-1.464v-1.465a.488.488 0 0 0-.489-.488h-.488V9a4.4 4.4 0 0 0-4.395-4.395A4.4 4.4 0 0 0 4.867 9v5.404h-.488a.488.488 0 0 0-.489.488v1.465H2.426a.488.488 0 0 0 0 .976h13.671a.488.488 0 1 0 0-.976ZM5.844 9A3.422 3.422 0 0 1 9.26 5.582 3.422 3.422 0 0 1 12.68 9v5.404H5.844V9Zm-.977 6.38h8.789v.977h-8.79v-.977Z"
    />
    <path
      fill="#fff"
      d="M9.261 12.418a.488.488 0 1 0 0-.977.488.488 0 0 0 0 .977ZM9.261 7.535a.488.488 0 0 0-.488.488v1.954a.488.488 0 0 0 .977 0V8.023a.488.488 0 0 0-.489-.488ZM2.914 9a.488.488 0 0 0-.489-.488H1.417a.488.488 0 0 0 0 .976h1.01c.27 0 .488-.218.488-.488ZM17.107 8.512h-1.01a.488.488 0 0 0 0 .976h1.01a.488.488 0 1 0 0-.976ZM9.261 2.652c.27 0 .489-.218.489-.488V1.155a.488.488 0 0 0-.977 0v1.01c0 .269.219.487.488.487ZM4.082 4.512a.488.488 0 1 0 .691-.69l-.69-.691a.488.488 0 1 0-.691.69l.69.69ZM14.44 4.512l.691-.69a.488.488 0 1 0-.69-.691l-.691.69a.488.488 0 1 0 .69.69Z"
    />
  </svg>
);
export default SidemenuAlarm;
