import React, { useEffect, useState } from "react";
import "./ProfilTop.css";
import panoCup from "../../../assets/img/top_cup.png";
import panoStar from "../../../assets/img/top_star.png";
import UserEdit from "../../svgs/userEdit";

import { AiFillDashboard, AiFillSignal } from "react-icons/ai";
import { BsHeartFill } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";

import { timeout } from "../../../utils/helperFunction";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  IoEye,
  IoEyeOff,
  IoKey,
  IoMail,
  IoPerson,
  IoPersonCircle,
} from "react-icons/io5";
import MaskedInput from "react-text-mask";
import { PulseLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { USERHIDE, USERINFO, USERUPDATE } from "../../../utils/urls";
import { authLogin, setProfile } from "../../../store/reducers/authSlice";
import { setMyboard } from "../../../store/reducers/panoSlice";

function ProfilTop() {
  const { user } = useSelector((state) => state.auth);
  const { myboard } = useSelector((state) => state.pano);
  const theme = useSelector((state) => state.theme.darkmode);
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [profilGizle, setProfilGizle] = useState(user.hidden);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visiblePasswordAgain, setVisiblePasswordAgain] = useState(false);
  const [visibleProfil, setVisibleProfil] = useState(false);
  const handleProfilClose = () => setVisibleProfil(false);

  const functProfilGizle = () => {
    console.log("user.username", user.username);
    if (user.username) {
      let postData = {};
      let hidden = 0;
      if (profilGizle == 0) {
        postData = {
          access_token: user.access_token,
          // j1407b: 1,
          hidden: 1,
        };
        hidden = 1;
      } else {
        postData = {
          access_token: user.access_token,
          // j1407b: 1,
          hidden: 0,
        };
        hidden = 0;
      }

      postDataWithHeaders(USERHIDE, postData, dispatch).then((res) => {
        if (res.code === 0) {
          toast.success(res.data);
          dispatch(authLogin({ ...user, hidden: hidden }));
          setProfilGizle(hidden);
        } else {
          toast.error(res.data);
        }
      });

      dispatch(authLogin({ ...user, hidden: 0 }));
      setProfilGizle(0);
    } else {
      toast.error("Lütfen kullanıcı adınızı giriniz.");
    }
  };

  const submiteProfile = async (e) => {
    e.preventDefault();
    if (password !== passwordAgain) {
      toast.error("Şifreler uyuşmuyor");
      return;
    }
    setIsProcessing(true);
    let postData = {};
    if (password) {
      postData = {
        access_token: user.access_token,
        // j1407b: 1,
        name: name,
        email: email,
        username: userName,
        phone: phone,
        password1: password,
        password2: passwordAgain,
      };
    } else {
      postData = {
        access_token: user.access_token,
        // j1407b: 1,
        name: name,
        email: email,
        username: userName,
        phone: phone,
      };
    }
    postDataWithHeaders(USERUPDATE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        setIsProcessing(false);
        setVisibleProfil(false);
        dispatch(authLogin({ ...user, username: userName }));
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  const getUserInfo = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(USERINFO, postData, dispatch)
      .then((res) => {
        if (res.code === 0) {
          dispatch(setProfile(res.data));
          dispatch(setMyboard(res.data.myboard));
          setName(res.data.name);
          setEmail(res.data.email);
          setUserName(res.data.username);
          setPhone(res.data.phone);
        } else {
          if (res.data.code === -1) {
            toast.error(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  console.log("theme", theme);
  return (
    <div className="ProfilTop">
      <ToastContainer />
      <div className="ProfilTop__left">
        <div className="ProfilTop__left__user">
          <div className="ProfilTop__left__user__title">Kullanıcı Adı</div>
          <div className="ProfilTop__left__user__name">
            <div className="ProfilTop__left__user__name__text">
              {user.username ? user.username : "Kullanıcı Adı Giriniz"}
            </div>
            <div
              className="ProfilTop__left__user__name__edit"
              onClick={() => setVisibleProfil(true)}
            >
              Profili Düzenle{" "}
              <UserEdit color={theme == "dark" ? "#fff" : "#000"} />
            </div>
          </div>
        </div>
        {myboard.name && myboard.name.length > 0 && (
          <div className="ProfilTop__left__pano">
            <h4>Panoya Paylaştığım Formül</h4>

            <div className="PanoTop__publish">
              <div className="PanoTop__publish__left">{myboard.name}</div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Sorgulama Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <AiFillDashboard size={16} />{" "}
                    {myboard.queries ? myboard.queries : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.queries_rank}
                  </span>
                </div>
              </div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Beğeni Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <BsHeartFill size={16} />{" "}
                    {myboard.likes ? myboard.likes : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.likes_rank}
                  </span>
                </div>
              </div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Reyting Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <AiFillSignal size={16} />{" "}
                    {myboard.rating ? myboard.rating : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.rating_rank}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="ProfilTop__right ml-auto">
          {/* <div
            className={
              profilGizle == 1
                ? "ProfilTop__right__left active"
                : "ProfilTop__right__left"
            }
            onClick={() => functProfilGizle()}
          >
            <HiLockClosed /> Profili Gizle
          </div>
          <div
            className={
              profilGizle == 0
                ? "ProfilTop__right__right active"
                : "ProfilTop__right__right"
            }
            onClick={() => functProfilGizle()}
          >
            <HiLockOpen /> Profili Aç
          </div> */}
        </div>
      </div>
      {visibleProfil && (
        <Modal
          show={visibleProfil}
          onHide={handleProfilClose}
          dialogClassName="modal-863w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Profil Bilgilerini Düzenle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="ProfilTop__modal">
              <Form onSubmit={submiteProfile}>
                <Row>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="label">
                        Adınız Soyadınız
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <IoPerson />
                        <Form.Control
                          type="text"
                          value={name}
                          placeholder="ad soyad giriniz"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Kullanıcı adı</Form.Label>
                      <InputGroup className="mb-3">
                        <IoPersonCircle />
                        <Form.Control
                          type="text"
                          value={userName}
                          placeholder="kullanıcı adı giriniz"
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>E-posta</Form.Label>
                      <InputGroup className="mb-3">
                        <IoMail />
                        <Form.Control
                          type="email"
                          value={email}
                          placeholder="E-posta giriniz"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Telefon</Form.Label>
                      <InputGroup className="mb-3">
                        <BsTelephoneFill />
                        <MaskedInput
                          mask={[
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          className="form-control"
                          placeholder="Telefon numarasını giriniz"
                          guide={false}
                          id="my-input-id"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Şifre</Form.Label>
                      <InputGroup className="mb-3">
                        <IoKey />
                        <Form.Control
                          type="text"
                          placeholder="şifre giriniz"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <IoEyeOff />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Şifre Tekrarı</Form.Label>
                      <InputGroup className="mb-3">
                        <IoKey />
                        <Form.Control
                          type="text"
                          placeholder="şifre tekrarı giriniz"
                          onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                        <IoEye />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Button
                      variant="primary"
                      className="modalSonucButton"
                      type="submit"
                    >
                      {isProcessing ? (
                        <PulseLoader
                          color="#fff"
                          aria-label="Güncelleniyor..."
                        />
                      ) : (
                        "Kaydet"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default ProfilTop;
