import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formulas: [],
  users: [],
  myboard: [],
  formulas_follows: [],
  users_follows: [],
};

const panoSlice = createSlice({
  name: "panoSlice",
  initialState,
  reducers: {
    setFormulas(state, action) {
      state.formulas = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setMyboard(state, action) {
      state.myboard = action.payload;
    },
    setFollowUsers(state, action) {
      state.users_follows = action.payload;
    },
    setFollowFormulas(state, action) {
      state.formulas_follows = action.payload;
    },
  },
});

export const {
  setFormulas,
  setUsers,
  setMyboard,
  setFollowUsers,
  setFollowFormulas,
} = panoSlice.actions;

export default panoSlice.reducer;
