import React, { useState, useEffect } from "react";
import "../assets/css/dashboard.css";
import "../components/EgitimOdasi/EgitimOdasi.css";
import { Modal, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../components/System/header";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import videoImg from "../assets/img/video.png";
import videoIcon from "../assets/img/videoIcon.png";
import { BsSearch } from "react-icons/bs";
import { timeout } from "../utils/helperFunction";
import ModalVideo from "react-modal-video";
import { postDataWithHeaders } from "../utils/apiUtils";
import { VIDEOS } from "../utils/urls";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function EgitimOdasi() {
  const theme = useSelector((state) => state.theme.darkmode);
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const handleVideoClose = () => setModalVideo(false);
  const [videos, setVideos] = useState(null);
  const [videos2, setVideos2] = useState(null);
  const [video, setVideo] = useState(null);

  function funcModalVideo(videoId) {
    setVideo(videoId);
    setModalVideo(true);
  }
  const [search, setSearch] = useState("");

  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _searchSembol = (text) => {
    let searchText = text.toUpperCase();
    setIsProcessing(true);
    timeout(1000);
    let newMarkets = videos2;
    if (text) {
      newMarkets = _.filter(videos2, function (o) {
        return o.title.includes(searchText);
      });
    }
    setSearch(searchText);
    setVideos(newMarkets);
    setIsProcessing(false);
  };

  const getVideos = () => {
    setIsProcessing(true);
    postDataWithHeaders(VIDEOS, {}, dispatch).then((res) => {
      console.log("videos", res);
      setVideos(res);
      setVideos2(res);
      timeout(1000);
      setIsProcessing(false);
    });
  };

  useEffect(() => {
    getVideos();
    document.title = "Eğitim Odası - Sirius Algo";
  }, []);
  return (
    <Container fluid className="EgitimOdasi">
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="EgitimOdasi_container">
            <div className="EgitimOdasi__header">
              <h1>Eğitim Odası</h1>
              <div className="EgitimOdasi__header__buttons ml-auto">
                <div className="modalSearch">
                  <div className="modalSearch__input">
                    <BsSearch color="#fff" />
                    <input
                      type="text"
                      placeholder="Arama Yap"
                      value={search}
                      onChange={(e) => _searchSembol(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="EgitimOdasi__body">
              <div className="EgitimOdasi__body__item">
                {videos ? (
                  <div className="EgitimOdasi__body__item__body">
                    {videos.map((video, i) => {
                      let thumbnailUrl = `https://img.youtube.com/vi/${video.link}/hqdefault.jpg`;
                      return (
                        <div
                          key={i}
                          className="EgitimOdasi__body__item__body__video"
                        >
                          <div
                            className="EgitimOdasi__body__item__body__video__img"
                            onClick={() => funcModalVideo(video.link)}
                          >
                            <img src={thumbnailUrl} className="video" alt="" />
                            <img src={videoIcon} className="videoIcon" alt="" />
                          </div>
                          <div className="EgitimOdasi__body__item__body__video__info">
                            <h2 onClick={() => funcModalVideo("cMBvscfhkMs")}>
                              {video.title}
                            </h2>
                            <p>{video.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="PanoFormul__content__skelaton">
                    <SkeletonTheme
                      className="PanoFormul__content__skelaton"
                      baseColor={theme == "dark" ? "#202020" : "#f0f0f0"}
                      highlightColor={theme == "dark" ? "#444" : "#f4f4f4"}
                    >
                      {[1, 2, 3, 4, 5].map((i) => (
                        <Skeleton key={i} />
                      ))}
                    </SkeletonTheme>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {modalVideo && (
        <>
          <button className="modal-video-close-btn"></button>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={modalVideo}
            videoId={video}
            onClose={() => setModalVideo(false)}
          />
        </>
      )}
    </Container>
  );
}

export default EgitimOdasi;
