import * as React from "react";
const BildirimSistem = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g fill={props.color || "#fff"} clipPath="url(#a)">
      <path d="m17.723 13.143-1.6 1.6L5.07 3.69l.85-.85L2.396.002.386 2.01l2.84 3.522.849-.85 11.054 11.055-1.6 1.6 2.282 2.282 4.195-4.195-2.283-2.282ZM21 16.42l-4.195 4.194 2.517 2.517c.56.56 1.305.869 2.097.869.793 0 1.537-.308 2.098-.869a2.97 2.97 0 0 0 0-4.195L21 16.42Z" />
      <path d="m12.533 15.13-3.277-3.276-2.398 2.398a4.965 4.965 0 0 0-5.011 1.236 4.953 4.953 0 0 0-1.46 3.526c0 1.331.518 2.584 1.46 3.526a4.971 4.971 0 0 0 3.526 1.458 4.97 4.97 0 0 0 3.525-1.459 4.965 4.965 0 0 0 1.236-5.01l2.399-2.399Zm-6.02 5.024a1.614 1.614 0 1 1-2.281-2.28 1.614 1.614 0 0 1 2.282 2.28ZM24.06 3.008l-.393-1.001-2.515 2.516-1.016-.273-.272-1.016L22.379.72l-1-.392a4.74 4.74 0 0 0-6.29 5.695l-2.85 2.849 3.277 3.276 2.849-2.849a4.74 4.74 0 0 0 5.695-6.29Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color || "#fff"} d="M.386 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
export default BildirimSistem;
