import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  subscription: null,
  alarms: 0,
  notifications: 0,
  profile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    authSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    authAlarms: (state, action) => {
      state.alarms = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    authNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    authLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.subscription = null;
      state.alarms = 0;
      state.notifications = 0;
    },
  },
});

export const {
  authLogin,
  authLogout,
  authAlarms,
  authNotifications,
  authSubscription,
  setProfile,
} = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
