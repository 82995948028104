import * as React from "react";
const SonucAlarm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#6C6C6C"}
      d="M18.316 18.828H16.56V17.07a.586.586 0 0 0-.586-.586h-.586V10a5.28 5.28 0 0 0-5.274-5.273A5.28 5.28 0 0 0 4.84 10v6.484h-.586a.586.586 0 0 0-.586.586v1.758H1.91a.586.586 0 0 0 0 1.172h16.406a.586.586 0 1 0 0-1.172ZM6.012 10a4.106 4.106 0 0 1 4.101-4.102A4.106 4.106 0 0 1 14.215 10v6.484H6.012V10ZM4.84 17.656h10.547v1.172H4.84v-1.172Z"
    />
    <path
      fill={props.color ? props.color : "#6C6C6C"}
      d="M10.113 14.102a.586.586 0 1 0 0-1.172.586.586 0 0 0 0 1.172ZM10.113 8.242a.586.586 0 0 0-.586.586v2.344a.586.586 0 0 0 1.172 0V8.828a.586.586 0 0 0-.586-.586ZM2.496 10a.586.586 0 0 0-.586-.586H.7a.586.586 0 0 0 0 1.172h1.21A.586.586 0 0 0 2.496 10ZM19.527 9.414h-1.21a.586.586 0 0 0 0 1.172h1.21a.586.586 0 1 0 0-1.172ZM10.113 2.383a.586.586 0 0 0 .586-.586V.586a.586.586 0 1 0-1.172 0v1.21c0 .324.263.587.586.587ZM3.899 4.614a.586.586 0 1 0 .828-.829L3.9 2.957a.586.586 0 1 0-.829.828l.829.829ZM16.328 4.614l.829-.829a.586.586 0 0 0-.829-.828l-.828.828a.586.586 0 0 0 .828.829Z"
    />
  </svg>
);
export default SonucAlarm;
