import * as React from "react";
const SonucTekEkle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#6C6C6C"}
      fillRule="evenodd"
      d="M12 2.25A9.75 9.75 0 0 0 2.25 12 9.75 9.75 0 0 0 12 21.75 9.75 9.75 0 0 0 21.75 12 9.75 9.75 0 0 0 12 2.25ZM12.75 9a.75.75 0 1 0-1.5 0v2.25H9a.75.75 0 1 0 0 1.5h2.25V15a.75.75 0 1 0 1.5 0v-2.25H15a.75.75 0 1 0 0-1.5h-2.25V9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SonucTekEkle;
