import React from "react";
import "./Sozlesmeler.css";
import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../System/header";

function SorumlulukReddiScreen() {
  React.useEffect(() => {
    document.title = "Sorumluluk Reddi Beyanı - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenu />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard_container sozlesmeler">
            <h1>Sorumluluk Reddi Beyanı</h1>
            <p>
              Bu web sitesinde yer alan Sorumluluk Reddi Beyanı Orphan Yazılım
              Bilişim Ar-Ge Akademi Ltd. Şti’ne (Kısaca Orphan Akademi olarak
              adlandırılacaktır) ait bayZ yazılım hizmetlerini kapsamaktadır.{" "}
            </p>
            <p>
              Bu siteye girerek, aşağıda belirtilen tüm şartları onaylamış ve
              kabul etmiş sayılırsınız:
            </p>
            <p>
              Bu sitedeki materyal herhangi bir kişisel, tüzel, ticari vb amaçla
              kullanılamaz. Bunu göz önünde bulundurarak siteden herhangi bir
              şekilde kopya almak, siteyi kullanarak yaptığınız herhangi bir
              materyalde, buradaki telif hakkı, marka ve mal sahipliğine ait tüm
              duyuruları dikkate aldığınızı kabul etmiş sayılırsınız. Herhangi
              bir şekilde materyallerin izinsiz kullanımı, kullanıcıyı ceza ve
              tazminatla karşı karşıya getirir. Bu cezalar yayıncılık izni,
              gizliliği, marka ve telif hakkının çiğnememesine bağlı olanları
              içerdiği gibi sadece onlarla da sınırlı değildir. Bu sitede
              bulunan tüm marka ve logolar Orphan Akademi ve onun iş ortağı
              firmalarının tescilli ve tescilsiz markalarıdır. Bu sitedeki
              hiçbir şey, patent, kullanma hakkı veya lisansı, marka teşhiri ve
              marka sahibinin yazılı izni olmadan kullanılamaz.
            </p>
            <p>
              Bu sitedeki bilgiler sadece paylaşılmak amacıyla verilmiştir. Bu
              sitedeki bilgilerin doğruluğu ve kesinliği konusunda Orphan
              Akademi kesin bir güvence vermekle yükümlü değildir.
            </p>
            <p>
              Orphan Akademi, web içeriğindeki hatalar, yazım yanlışları için
              veya bilgileri güncelleme ve düzeltme konularında herhangi bir
              yükümlük ve sorumluk sahibi değildir. Orphan Akademi duyuru
              yapmadan bilgi, içerik, kullanılabilirlik konularında istediği
              zaman değişiklik yapma hakkına sahiptir.
            </p>
            <p>
              Bu siteye girişinizden veya bu siteyle bağlantısı olan başka bir
              siteye girişlerinizden kaynaklanan dolaylı, dolaysız soruna, kayba
              veya zarara yol açabilecek durumlardan Orphan Akademi sorumlu
              değildir.
            </p>
            <p>
              Bu site Orphan Akademi tarafından yürütülmeyen başka sitelere
              bağlanabilir. Orphan Akademi o sitelerin içeriğinden sorumlu
              değildir. Orphan Akademi tarafından tasdik edilmesi beklenemez.
              Orphan Akademi bu sitelerin içeriklerini kontrol etmez ve
              sitelerin kullanımından doğan sonuçlardan sorumlu değildir.
            </p>
            <p>
              Siteyi ziyaret eden kişi, görüş, yorum, soru gibi geribildirimlere
              cevap verirse; bu herhangi bir gizliği olmayan, tescilsiz öneriler
              Orphan Akademi tarafından sınırlaması olmaksızın kopyalanabilir,
              kullanılabilir ve dağıtabilir. Orphan Akademi yeni ürünlerin imal
              edilmesi, ürünlerin geliştirilmesi ve pazarlama faaliyetleri için
              bu geri bildirimlerdeki görüş, genel kavram ve teknikleri
              kullanmakta özgürdür.
            </p>
            <p>Orphan Yazılım Bilişim Ar-Ge Akademi</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SorumlulukReddiScreen;
