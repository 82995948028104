import React from "react";
import "./Sozlesmeler.css";
import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../System/header";

function KvkkScreen() {
  React.useEffect(() => {
    document.title = "Kişisel Verilerin Korunması Kanunu - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenu />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard_container sozlesmeler">
            <h1>KİŞİSEL VERİLERİN KORUNMASI KANUNU</h1>
            <p>(Aydınlatma ve Rıza Metni)</p>
            <p>
              Orphan Yazılım, Bilişim, Arge, Akademi, Dijital Yayın Paz. Reklam
              ve Organizasyon Dış Tic. Ltd. Şti Kişisel Verilerin Korunması
              Kanunu Aydınlatma Metni kapsamında, hizmet verilen müşterilere
              çeşitli kampanyalar sunar. Bunların gerçekleştirilebilmesi için
              veri toplar ve bildirimler gönderir. Orphan Akademi Sirius Algo
              websitesi (yazılım) üyeliğiniz kapsamındaki faaliyetlerimizin
              yürütülebilmesi için; isim-soyadı, iletişim, meslek, şehir
              bilgilerinizin aşağıda yer alan amaçlarla işlenmesini ve bu
              amaçlarla kanunen yetkili kamu kurumlarına ve özel kişilere
              aktarılmasını kabul etmiş sayılırsınız.
            </p>
            <p>
              Orphan Akademi tarafından sunulan hizmetlerden sizleri
              faydalandırmak için gerekli çalışmaların iş birimlerimiz
              tarafından yapılması, Şirketimiz tarafından sunulan ürün ve
              hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve
              ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi,
              Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan kişilerin
              hukuki ve ticari güvenliğinin temini, Şirketimizin ticari ve iş
              stratejilerinin belirlenmesi ve uygulanması amaçlarıyla;
            </p>
            <p>Sirius Algo yazılımı için başvuru alınması,</p>
            <p>
              Kişisel verilerin kullanıcı alışkanlıklarına göre analiz edilmesi,
              fırsatların sunulması ve Şirket içi raporlanması,
            </p>
            <p>Dönemsel olarak kullanıcı memnuniyet anketi yapılması,</p>
            <p>
              Özel kampanyaların oluşturulması, SMS, e-posta, uygulama, sosyal
              medya ve internet reklamları ile duyurulması,
            </p>
            <p>
              İsim - Soyadı
              <br />
              Üye Bilgisi
              <br />
              İletişim Bilgisi
              <br />
              Üye İşlem Bilgisi
              <br />
              İşlem Güvenliği Bilgisi
              <br />
              Fiziksel Mekân Güven Bilgisi
              <br />
              İtibar Yönetim Bilgisi
              <br />
              Risk Yönetim Bilgisi
              <br />
              Denetim ve Teftiş Belgesi
              <br />
            </p>
            <p>
              gibi bilgilerin toplanması, işlenmesi, saklanması konusuna binaen
              Sirius Algo websitesi (yazılımı) üyeliği kapsamında Aydınlatma
              Metni’ni okudum, bu kapsamda Rıza Metni’ne onay veriyorum.
            </p>
            <h2>
              Orphan Yazılım, Bilişim, Arge, Akademi, Dijital Yayın Paz. Reklam
              ve Organizasyon Dış Tic. Ltd. Şti KİŞİSEL VERİLERİN İŞLENMESİ
              AYDINLATMA METNİ
            </h2>
            <p>a) Veri Sorumlusu ve Temsilcisi</p>
            <p>
              6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı
              Kanun”) uyarınca kişisel verileriniz, veri sorumlusu olarak Orphan
              Yazılım, Bilişim, Arge, Akademi, Dijital Yayın Paz. Reklam ve
              Organizasyon Dış Tic. Ltd. Şti (Şirket) tarafından aşağıda
              açıklanan kapsamda işlenebilecektir.
            </p>
            <p>b) Kişisel Verilerin Hangi Amaçla İşleneceği</p>
            <p>
              Orphan Akademi tarafından sunulan hizmetlerden sizleri
              faydalandırmak için gerekli çalışmaların iş birimlerimiz
              tarafından yapılması, Şirketimiz tarafından sunulan ürün ve
              hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve
              ihtiyaçlarına göre özelleştirilerek çeşitlendirilmesi,
              Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan kişilerin
              hukuki ve ticari güvenliğinin temini, Şirketimizin ticari ve iş
              stratejilerinin belirlenmesi ve uygulanması amaçlarıyla 6698
              sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri
              işleme şartları ve amaçları dâhilinde işlenecektir.
            </p>
            <p>
              c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
              Aktarılabileceği
            </p>
            <p>
              Toplanan kişisel verileriniz; yeni içeriklerin oluşturulması,
              işlenmesi ve sonuçlandırılması amacıyla ve ayrıca Şirketimiz
              tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak
              için gerekli çalışmaların iş birimlerimiz tarafından yapılması,
              Şirketimiz tarafından sizin için sunulan ürün ve hizmetlerin
              sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre
              özelleştirilerek çeşitlendirilmesi ve size özel kampanyalar
              hazırlanması, kanunen yetkili kamu kurumları ve özel kişilere,
              6698 sayılı Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri
              işleme şartları ve amaçları çerçevesinde belirtilen amaçlarla
              sınırlı olarak aktarılabilecektir.
            </p>
            <p>ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</p>
            <p>
              Kişisel verileriniz, Şirketimiz tarafından farklı kanallar ve
              farklı hukuki sebeplere dayanarak, sunduğumuz ürün ile hizmetleri
              geliştirmek ve ticari faaliyetlerimizi yürütmek amacıyla
              toplanmaktadır. Bu süreçte toplanan kişisel verileriniz, internet
              ortamında internet sitemiz ve sosyal medya kanalları ile mobil
              ortamda mobil uygulamalarımız kanallarıyla, şikâyetinizin
              oluşturulması, işlenmesi ve sonuçlandırılması ve bu çerçevede
              oluşturulacak satış sonrasına ilişkin hizmetlerin yürütülmesine
              yönelik yükümlülüklerimizi yerine getirmek hukuki sebebiyle
              şikâyet formları ile toplanmaktadır. Bu hukuki sebeple toplanan
              kişisel verileriniz 6698 sayılı Kanunun 5. ve 6. maddelerinde
              belirtilen kişisel veri işleme şartları ve amaçları kapsamında bu
              Aydınlatma Metni’nin (b) ve (c) maddelerinde belirtilen amaçlarla
              da işlenebilmekte ve aktarılabilmektedir.
            </p>
            <p>
              d)Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. maddesinde
              Sayılan Hakları
            </p>
            <p>
              Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
              Orphan Akademi bayZ websitesi (yazılımında) belirtilen Kişisel
              Verilerin Korunması ve İşlenmesi Politikasında düzenlenen
              yöntemlerle iletmeniz durumunda talebin niteliğine göre talebi en
              kısa sürede ve en geç otuz gün içinde ücretsiz olarak
              sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti
              gerektirmesi hâlinde, tarafından Kişisel Verileri Koruma Kurulunca
              belirlenen tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri
              sahipleri;
            </p>
            <p>
              -Kişisel veri işlenip işlenmediğini öğrenme,
              <br />
              -Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              <br />
              -Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
              <br />
              -Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
              üçüncü kişileri bilme,
              <br />
              -Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
              <br />
              -6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak
              işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
              ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
              edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
              <br />
              -İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
              analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
              ortaya çıkmasına itiraz etme,
              <br />
              -Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğramanız hâlinde zararın giderilmesini talep etme, haklarına
              sahiptir."
              <br />
            </p>
            <p>
              MADDE 10- (1) Kişisel verilerin elde edilmesi sırasında veri
              sorumlusu veya yetkilendirdiği kişi, ilgili kişilere; a) Veri
              sorumlusunun ve varsa temsilcisinin kimliği, b) Kişisel verilerin
              hangi amaçla işleneceği, c) İşlenen kişisel verilerin kimlere ve
              hangi amaçla aktarılabileceği, ç) Kişisel veri toplamanın yöntemi
              ve hukuki sebebi, d) 11’inci maddede sayılan diğer hakları,
              konusunda bilgi vermekle yükümlüdür.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default KvkkScreen;
