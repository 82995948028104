import React, { useEffect } from "react";
import "../components/Pano/Pano.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import PanoTop from "../components/Pano/PanoTop/PanoTop";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Header from "../components/System/header";
import PanoFormul from "../components/Pano/PanoFormul/PanoFormul";
import PanoUser from "../components/Pano/PanoUser/PanoUser";
import PanoButtom from "../components/Pano/PanoButtom/PanoButtom";
import { useDispatch, useSelector } from "react-redux";
import { postDataWithHeaders } from "../utils/apiUtils";
import { PANOFORMULLER } from "../utils/urls";
import { ToastContainer, toast } from "react-toastify";
import {
  setFollowFormulas,
  setFollowUsers,
  setFormulas,
  setMyboard,
  setUsers,
} from "../store/reducers/panoSlice";

function PanoScreen() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const getFormulasData = async () => {
    postDataWithHeaders(PANOFORMULLER, {
      access_token: user.access_token,
      // j1407b: 1,
    })
      .then((res) => {
        if (res.code == 0) {
          console.log("res.data.myboard pano", res.data.myboard);
          dispatch(setFormulas(res.data.board));
          dispatch(setUsers(res.data.users));
          console.log("res.data.myboard.name", res.data.myboard.name);
          if (res.data.myboard.name) {
            dispatch(setMyboard(res.data.myboard));
          } else {
            dispatch(setMyboard([]));
          }
          dispatch(setFollowUsers(res.data.users_follows));
          dispatch(setFollowFormulas(res.data.formulas_follows));
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFormulasData();
  }, []);

  useEffect(() => {
    console.log("first, setFormulas");
    dispatch(setFormulas([]));
    dispatch(setUsers([]));
  }, []);

  useEffect(() => {
    document.title = "Pano - Sirius Algo";
  }, []);
  return (
    <Container fluid className="Pano">
      <ToastContainer />
      <Row className="Pano_container">
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard_container">
            <PanoTop />
            <PanoFormul />
            <PanoUser />
            {/* <PanoButtom /> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PanoScreen;
