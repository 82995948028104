import * as React from "react";
const ArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#fff"}
      d="M22.317 13.362 11.877.89a.576.576 0 0 0-.198-.139.615.615 0 0 0-.682.14L.557 13.361a.485.485 0 0 0-.063.547.54.54 0 0 0 .207.213.595.595 0 0 0 .296.079h20.88a.595.595 0 0 0 .296-.08.54.54 0 0 0 .207-.211.484.484 0 0 0-.063-.548Z"
    />
  </svg>
);
export default ArrowUp;
