import React, { useEffect, useState } from "react";
import "./ProfilOdemeGecmisi.css";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Table, Button, Card } from "react-bootstrap";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { ODEMEGECMISI } from "../../../utils/urls";
import { ToastContainer, toast } from "react-toastify";

function ProfilOdemeGecmisi() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.theme.darkmode);
  const [transactionsData, setTransactionsData] = useState([]);
  let date = new Date();
  var timestamp = date.getTime();

  const getTransactions = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(ODEMEGECMISI, postData, dispatch).then((res) => {
      if (res.status === 200) {
        console.log(res.status);
      }
      if (res.code === 0) {
        console.log("getTransactions", res.data.subscriptions);
        setTransactionsData(res.data.subscriptions);
      } else {
        toast.error(res.data);
      }
    });
  };

  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <Container className="Profile_OdemeGecmisi" style={{ paddingTop: 30 }}>
      <div className="ProfilBottom__container__left__title">Ödeme Geçmişi</div>
      <Table
        striped
        bordered
        hover
        variant={theme == "light" ? "light" : "dark"}
      >
        <thead>
          <tr className="brd1">
            <th>Paket Adı</th>
            <th style={{ width: "20%" }}>Başlangıç</th>
            <th style={{ width: "20%" }}>Bitiş</th>
          </tr>
        </thead>
        <tbody>
          {transactionsData &&
            transactionsData.map((data, index) => (
              <tr key={index}>
                <td>{data.package}</td>
                <td>{data.start}</td>
                <td>{data.finish}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ProfilOdemeGecmisi;
