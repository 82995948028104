import * as React from "react";
const SonucSec = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#797979"
      d="M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 17.25A8.25 8.25 0 1 1 17.25 9 8.26 8.26 0 0 1 9 17.25Z"
    />
  </svg>
);
export default SonucSec;
