import React, { useEffect, useState } from "react";
import "./ListeFavori.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowSort from "../svgs/arrowSort";
import ArrowDown from "../svgs/arrowDown";
import ArrowUp from "../svgs/arrowUp";
import SonucTekEkle from "../svgs/sonucTekEkle";
import SonucGrafik from "../svgs/sonucGrafik";
import _ from "lodash";
import PuanComponent from "../puan/puan";
import ChartComponent from "../chart/chart";
import {
  FAVORIREMOVE,
  GETFAVORI,
  INDICATORS,
  TARAMA,
  TARAMAV2,
} from "../../utils/urls";
import { postDataWithHeaders } from "../../utils/apiUtils";
import ButtonClose from "../svgs/buttonClose";
import { toast, ToastContainer } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
let date = new Date();
var timestamp = date.getTime();

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function countMarket(objs) {
  var result = _.countBy(objs.flatMap((obj) => obj.market));
  return result;
}
function objectLength(obj) {
  var result = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // or Object.prototype.hasOwnProperty.call(obj, prop)
      result++;
    }
  }
  return result;
}
const zamansal = ["BAYZ", "BAYZ(YAKIN)", "GÖLGE", "ABC=DEF"];
function Favorilerim() {
  const theme = useSelector((state) => state.theme);
  const urlApi = localStorage.getItem("urlApi");
  const { listeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.filters);
  const { favories } = useSelector((state) => state.data);
  // console.log("favories 1", favories);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [dataFilter, setDataFilter] = useState([]);
  const [puanName, setPuanName] = useState("");
  const [loadChart, setLoadChart] = useState("formation");
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [sortTypeChange, setSortTypeChange] = useState("default");
  const [dataFormation, setDataFormation] = useState(null);
  const [dataIndicator, setDataIndicator] = useState([]);
  const [favoritesStocks, setFavoritesStocks] = useState([]);
  const [hisseType, setHisseType] = useState("Hissede");
  const [listeName, setListeName] = useState("");
  const [listeItem, setListeItem] = useState("");
  const [hisseAdet, setHisseAdet] = useState(0);
  const [formationId, setFormationId] = useState(0);
  const [taramaAdet, setTaramaAdet] = useState(0);
  const [stratejiAdet, setStratejiAdet] = useState(0);
  const [listeler, setListeler] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [search, setSearch] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [selectedBorsa, setSelectedBorsa] = useState("");
  const [selectedSembol, setSelectedSembol] = useState([]);
  const [selectedBorsaId, setSelectedBorsaId] = useState(1);
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [loadFilter, setLoadFilter] = useState(false);
  const [liste, setListe] = useState("");
  const [visibleListe, setVisibleListe] = useState(false);
  const handleListeClose = () => _setVisibleListe();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const handleConfirmClose = () => _setVisibleConfirm();
  const [modalIndicator, setModalIndicator] = useState(false);
  const handleIndicatorClose = () => setModalIndicator(false);
  const [modalGrafik, setModalGrafik] = useState(false);
  const handleGrafikClose = () => setModalGrafik(false);

  const handleUyari = (name) => {
    toast.error(
      `${name} formülü özel Zamansallık Unsurları içermektedir. Premium+ paket özellikleri için 0530 238 60 18' e ulaşabilirsiniz.`
    );
  };
  const handleSortType = (type) => {
    setSortType(type);
    setSortTypeChange("default");
  };
  function handleSortingChange(sorting, column) {
    // console.log("sorting", sorting, "column", column);
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    if (sorting === "default") {
      setSortTypeChange("down");
      data = _.orderBy(newData, [column], ["desc"]);
    }
    if (sorting === "down") {
      setSortTypeChange("up");
      data = _.orderBy(newData, [column], ["asc"]);
    }
    if (sorting === "up") {
      setSortTypeChange("default");
      data = newData;
    }
    setDataBody([data]);
  }

  const _getFavoriListe = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETFAVORI, postData, dispatch).then(
      async (res) => {
        if (res.code === 0) {
          let listeMarkets = [];
          res.data.map((item) => {
            listeMarkets.push(parseInt(item));
          });
          if (listeMarkets.length > 0) {
            await _getIndicators(listeMarkets);
            await _getTarama(listeMarkets);
          }
        }
      }
    );
  };
  const funcListeCikar = async (id) => {
    const postData = {
      market_id: parseInt(id),
      access_token: user.access_token,
      // j1407b: 1,
    };
    console.log("postData", postData);
    await postDataWithHeaders(FAVORIREMOVE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        _getFavoriListe();
      } else {
        toast.error(res.data);
      }
    });
  };
  async function _getTarama(favoritesStocks) {
    let date = new Date();
    let timestamp = date.getTime();
    setIsProcessing(true);
    setDataFormation(null);
    const postData = {
      new_found: 0,
      bayz_just_closed: 0,
      exchange_id: [],
      yon: [],
      formasyon: JSON.stringify([]),
      periyot: JSON.stringify([]),
      markets: JSON.stringify([]),
      favorites: favoritesStocks,
      access_token: user.access_token,
      tur: "tarama",
    };
    let res = await postDataWithHeaders(
      TARAMAV2 + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      console.log("status", res.status);
    }
    // Don't forget to return something
    // console.log("res", res.data);
    if (res.code === 0) {
      let resData = [];
      resData = _.groupBy(res.data.data, function (n) {
        return n.stock_code;
      });
      // resData = groupBy(res.data.data, function(n) {
      //   return n.stock_code;
      // });
      let dataIndicator = localStorage.getItem("dataIndicator");

      let filterDatas = [];
      if (dataIndicator) {
        let resDataIndicator = JSON.parse(dataIndicator);
        resDataIndicator.map((dataInd, ix) => {
          let bull = 0;
          let bear = 0;
          let formations = [];

          if (resData[dataInd.stock_code]) {
            formations = resData[dataInd.stock_code];
            bull = countMarket(resData[dataInd.stock_code]).BULL
              ? countMarket(resData[dataInd.stock_code]).BULL
              : countMarket(resData[dataInd.stock_code]).BEAR
              ? countMarket(resData[dataInd.stock_code]).BEAR * -1
              : 0;
            bear = countMarket(resData[dataInd.stock_code]).BEAR
              ? countMarket(resData[dataInd.stock_code]).BEAR
              : 0;
          }
          formations.length > 0 &&
            filterDatas.push({
              stock_id: dataInd.stock_id,
              name: dataInd.stock_code,
              exchange_id: dataInd.exchange_id,
              puan: dataInd.puan,
              bull: bull,
              bear: bear,
              indicators: dataInd.indicators,
              formations: formations,
            });
        });
      }

      //console.log("filterDatas", filterDatas);
      setDataFilter(filterDatas);
      setDataBody([filterDatas]);
      setDataBody2([filterDatas]);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      setIsProcessing(false);

      let hisseAdet = objectLength(resData);
      let taramaAdet = Object.values(resData).reduce(
        (count, current) => count + current.length,
        0
      );
      //console.log(hisseAdet, taramaAdet);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setLoadFilter(true);
    } else {
      setDataFormation(null);
      toast.error(res.data.data);
      setIsProcessing(0);
      if (res.data.code === "-1") {
        setInterval(() => {
          //window.location.href="/uye-cikis"
        }, 2000);
      }
    }
  }
  const handleShowGrafik = (formation_id, loadChartType) => {
    setModalGrafik(true);
    // //console.log(formation_id, loadChartType)
    setFormationId(formation_id);
    setLoadChart(loadChartType);
  };
  const handleButton = (fMarket) => {
    let newColor = "default";
    if (fMarket == "BULL") {
      newColor = "success";
    } else {
      newColor = "danger";
    }
    return newColor;
  };
  const handleShowPuan = (hisseName) => {
    setModalIndicator(true);
    // //console.log(hisseName)
    setPuanName(hisseName);
  };
  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  async function _getIndicators(listeMarkets) {
    let date = new Date();
    let timestamp = date.getTime();
    const postData = {
      access_token: user.access_token,
      markets: listeMarkets,
    };
    let res = await postDataWithHeaders(
      INDICATORS + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = res.data;
      //// console.log("resData Indicators", resData);
      // // console.log(
      //   "resData[0].indicators.length)",
      //   resData[0].indicators.length
      // );
      localStorage.setItem("dataIndicator", JSON.stringify(resData));
      localStorage.setItem("stratejiAdet", resData[0].indicators.length);
      setDataIndicator(resData);
      setStratejiAdet(resData[0].indicators.length);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await _getFavoriListe();
    }
    fetchData();
  }, [update]);
  useEffect(() => {
    document.title = "Favorilerim - Sirius Algo";
  }, []);
  return (
    <Container>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <div className="Sonuclar_container">
            <div className="Sonuclar_left">
              <div className="Sonuclar_left__title">Sonuçlar</div>
              <div className="Sonuclar_left__text">
                {hisseAdet} {hisseType} - {taramaAdet} Formasyon
              </div>
            </div>
            <div className="Sonuclar_right">
              <div className="Sonuclar_right__container">
                <div
                  className={
                    sortType == 2
                      ? "Sonuclar_right__sort active"
                      : "Sonuclar_right__sort"
                  }
                  onClick={() =>
                    sortType == 2
                      ? handleSortingChange(sortTypeChange, "puan")
                      : handleSortType(2)
                  }
                >
                  Stratejiye Göre Sırala
                  {sortType == 2 && (
                    <div className="Sonuclar_right__sort__icon">
                      {sortTypeChange == "default" ? (
                        <ArrowSort />
                      ) : sortTypeChange == "up" ? (
                        <ArrowDown color="red" />
                      ) : (
                        <ArrowUp color="#5fb045" />
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={
                    sortType == 1
                      ? "Sonuclar_right__sort active"
                      : "Sonuclar_right__sort"
                  }
                  onClick={() =>
                    sortType == 1
                      ? handleSortingChange(sortTypeChange, "bull")
                      : handleSortType(1)
                  }
                >
                  Boğaya Göre Sırala
                  {sortType == 1 && (
                    <div className="Sonuclar_right__sort__icon">
                      {sortTypeChange == "default" ? (
                        <ArrowSort />
                      ) : sortTypeChange == "up" ? (
                        <ArrowDown color="red" />
                      ) : (
                        <ArrowUp color="#5fb045" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="Sonuclar__body">
            {dataBody ? (
              dataBody.map((data, index) =>
                Object.values(data).map((forms, indix) => {
                  {
                    /* console.log("favorites", favorites); */
                  }
                  const item = favories.find(
                    (item) => item.symbol === forms.name
                  );
                  const price = item ? item.price : null;

                  let exchange_id = forms.exchange_id;
                  let puan = forms.puan;
                  return (
                    <div key={indix} className="Sonuclar__body__container">
                      <div className="Sonuclar__body__container__left">
                        {/* <SelectIcon
                                hisseName={forms.name}
                                fStocks={topluEkleLists}
                                func={handleTopluEkle}
                              /> */}

                        <ButtonClose
                          className="Sonuclar__body__container__left__close favorilerim__close cursorPointer"
                          onClick={() => funcListeCikar(forms.stock_id)}
                        />
                        {/* <SonucAlarm
                                className="Sonuclar__body__container__left__alarm"
                                onClick={() => funcAlarmFiyat(forms.name)}
                              /> */}
                        <Link
                          to={`/grafik-paneli/${forms.name}`}
                          target="_blank"
                        >
                          <SonucGrafik />
                        </Link>
                        <span className="Sonuclar__body__container__left__text">
                          {forms.name} {price}
                        </span>
                        <div className="Sonuclar__body__container__left_border ml-auto"></div>
                      </div>

                      <div className="Sonuclar__body__container__right">
                        {forms.formations &&
                          forms.formations.map((formArray, indx) => {
                            let formasyonName = handleName(formArray.formation);
                            let foundItem = null;

                            if (user.bayz == "0") {
                              foundItem = zamansal.find(
                                (element) => element === formasyonName[0]
                              );
                            }

                            let icon =
                              formArray.market === "BULL" ? (
                                <ArrowUp color="#5fb045" />
                              ) : (
                                <ArrowDown color="#E12222" />
                              );
                            let boxButton = handleButton(formArray.market);
                            let filePath = "";
                            return (
                              <Button
                                key={indx}
                                className={`Sonuclar__body__button  ${formArray.formation} btn-sm ${boxButton}`}
                                onClick={() =>
                                  foundItem
                                    ? handleUyari(formasyonName[0])
                                    : handleShowGrafik(
                                        formArray.id,
                                        formArray.chart
                                      )
                                }
                                style={{
                                  marginLeft: 5,
                                  marginBottom: 5,
                                  // backgroundColor: `${handleColor(
                                  //   formArray.formationColor,
                                  //   formArray.formation,
                                  //   formArray.market
                                  // )}`,
                                }}
                              >
                                {icon} {formasyonName[0]}{" "}
                                {formArray.bayz_percentage
                                  ? `(${formArray.bayz_percentage}%)`
                                  : ""}{" "}
                                {`(${formArray.period})`}
                              </Button>
                            );
                          })}
                      </div>
                      <div
                        className="Sonuclar__body__strateji"
                        onClick={() => handleShowPuan(forms.name)}
                      >
                        <span className="Sonuclar__body__strateji__text">
                          Strateji
                        </span>
                        <span className="Sonuclar__body__strateji__puan">
                          {puan}/{updateDataAll.length}
                        </span>
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <SkeletonTheme baseColor="#373a44" highlightColor="#373a44">
                {[1, 2, 3, 4].map((i) => (
                  <Skeleton
                    key={i}
                    className="Sonuclar__body__container"
                    style={{ width: "100%" }}
                  />
                ))}
              </SkeletonTheme>
            )}
          </div>
        </Col>
      </Row>
      {modalIndicator && (
        <Modal
          show={modalIndicator}
          onHide={handleIndicatorClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PuanComponent
              urlApi={urlApi}
              name={puanName}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalGrafik && (
        <Modal
          show={modalGrafik}
          onHide={handleGrafikClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ChartComponent
              urlApi={urlApi}
              id={formationId}
              load={loadChart}
              theme={theme.darkmode}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default Favorilerim;
