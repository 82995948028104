import React from "react";
import "./HomeKesfet.css";
import { Container, Row, Carousel } from "react-bootstrap";
import langing_kesfet_1 from "../../../assets/img/landing_grafik.png";
import langing_kesfet_2 from "../../../assets/img/landing_endeks.png";
import langing_kesfet_3 from "../../../assets/img/landing_parite.png";
import icon_grafik from "../../../assets/img/icon/grafik_paneli.png";
import icon_endeks from "../../../assets/img/icon/parite_odasi.png";
function HomeKesfet() {
  return (
    <Container className="HomeKesfet__container">
      <Row>
        <div id="kesfet" className="HomeKesfet__title">
          Keşfetmeye Hazır Olun!
        </div>
        <Carousel>
          <Carousel.Item>
            <img src={langing_kesfet_1} />

            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 24,
                display: "inline-flex",
                marginTop: 50,
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: 16,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    paddingTop: 8,
                    paddingBottom: 8.24,
                    paddingLeft: 4.23,
                    paddingRight: 3.77,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{ width: 40, height: 31.76, position: "relative" }}
                  >
                    <img src={icon_grafik} />
                  </div>
                </div>
                <div className="HomeKesfet__slider__title">Grafik Paneli </div>
              </div>
              <div className="HomeKesfet__slider__text">
                Sirius analiz etsin ama ben de bir göz gezdireyim diyenlerden
                misiniz? Tradingview çizim araçlarını kullanarak yabancılık
                çekmeden grafikler üzerinde çizim yapabilir ve çizimlerinizi
                kaydedebilirsiniz. Tek grafiğe sınırsız sayıda osilatör ve
                indikatör ekleyebilir, istediğiniz grafikleri listelerinize
                kaydedebilirsiniz.{" "}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={langing_kesfet_3} />

            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 24,
                display: "inline-flex",
                marginTop: 50,
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: 16,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    paddingLeft: 3.77,
                    paddingRight: 3.77,
                    paddingTop: 4.01,
                    paddingBottom: 4.01,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img src={icon_endeks} />
                </div>
                <div className="HomeKesfet__slider__title">Parite Odası</div>
              </div>
              <div className="HomeKesfet__slider__text">
                Portföy yönetiminde yeni bir metodla tanışın. Hisselerinizin
                birbirine karşı nasıl hareket ettiğini görmek için hisseler ve
                endeksler arasında pariteler oluşturun. Trade ederken hem
                yükselmesi beklenen hem de düşmesi beklenen hisseleri tespit
                ederek doğru zamanda doğru hissede olma şansını
                yakalayabilirsiniz. Parite dersleri Eğitim Odası’nda sizi
                bekliyor…
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={langing_kesfet_2} />

            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 24,
                display: "inline-flex",
                marginTop: 50,
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: 16,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    paddingTop: 8,
                    paddingBottom: 8.24,
                    paddingLeft: 4.23,
                    paddingRight: 3.77,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{ width: 40, height: 31.76, position: "relative" }}
                  >
                    <img src={icon_grafik} />
                  </div>
                </div>
                <div className="HomeKesfet__slider__title">
                  Endeks Frekans Odası
                </div>
              </div>
              <div className="HomeKesfet__slider__text">
                Sirius Frekanslarına bakarak endeksleri çözümlemeye hazır
                mısınız? Sirius ile, Teknik Analizin balta girmemiş ormanlarında
                heyecanlı bir yolculuğa çıkın. BIST ve BINANCE borsalarının,
                başka platformlarda bulamayacağınız frekans analizlerini yaparak
                yeni formüller keşfedin. Frekans ayarlarını Eğitim Odası’nda
                bulabilirsiniz 🙂
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </Row>
    </Container>
  );
}

export default HomeKesfet;
