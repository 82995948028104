import * as React from "react";
const SidemenuOzel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M19.982 8.756 17.63 6.402c-.967-.968-.376-1.99.678-2.052 1.736-.108 2.712-1.962 1.294-3.505C18.06-.57 16.216.402 16.109 2.136c-.066 1.055-1.095 1.65-2.058.687L11.748.518a1.768 1.768 0 0 0-2.5 0L6.915 2.852c-.967.968-1.992.368-2.058-.687C4.75.427 2.89-.562 1.348.857-.074 2.405.914 4.267 2.65 4.374c1.054.067 1.654 1.097.69 2.065L1.019 8.76a1.772 1.772 0 0 0 0 2.503l2.302 2.305c.967.964.393 1.97-.661 2.035-1.736.108-2.708 1.957-1.29 3.5 1.542 1.42 3.381.452 3.489-1.286.066-1.055 1.074-1.634 2.042-.67l2.323 2.325a1.814 1.814 0 0 0 2.554.004l1.629-1.63.698-.7c.914-.955.323-1.96-.719-2.027-1.736-.107-2.72-1.97-1.302-3.512 1.542-1.42 3.402-.43 3.51 1.307.066 1.043 1.066 1.63 2.02.716l.633-.633 1.703-1.705.008-.008.025-.025c.69-.69.69-1.812 0-2.503Z" />
  </svg>
);
export default SidemenuOzel;
