import React from "react";
import Sliders from "../components/HomePage/Slider/Sliders";
import HomeMenu from "../components/HomePage/HomeMenu/HomeMenu";
import HomeAbout from "../components/HomePage/HomeAbout/HomeAbout";
import HomeNelerBekliyor from "../components/HomePage/HomeNelerBekliyor/HomeNelerBekliyor";
import HomeSss from "../components/HomePage/HomeSss/HomeSss";
import HomeFooter from "../components/HomePage/HomeFooter/HomeFooter";
import HomeKesfet from "../components/HomePage/HomeKesfet/HomeKesfet";
import HomeVideo from "../components/HomePage/HomeVideo/HomeVideo";
import HomePackages from "../components/HomePage/HomePackages/HomePackages";

function HomePage() {
  React.useEffect(() => {
    document.title = "Sirius Algo";
  }, []);
  return (
    <React.Fragment>
      <div className="HomePage">
        <HomeMenu />
        <Sliders />
        <HomeAbout />
        <HomeNelerBekliyor />
        <HomeVideo />
        <HomePackages />
        <HomeKesfet />
        <HomeSss />
        <HomeFooter />
      </div>
    </React.Fragment>
  );
}

export default HomePage;
