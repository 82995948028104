import React from "react";
import RegisterPage from "../components/Auth/Register/Register";

function AuthRegister() {
  return (
    <React.Fragment>
      <RegisterPage />
    </React.Fragment>
  );
}

export default AuthRegister;
