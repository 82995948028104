import * as React from "react";
const SonucSecildi = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#3AB22F"}
      d="M9.5 4.125a4.875 4.875 0 1 0 0 9.75 4.875 4.875 0 0 0 0-9.75Zm-2.336 4.26 1.597 1.916 3.087-3.427a.374.374 0 0 1 .554.502l-3.374 3.75a.375.375 0 0 1-.278.124h-.008a.388.388 0 0 1-.28-.135l-1.876-2.25a.376.376 0 0 1 .578-.48ZM9.5 0a9 9 0 1 0 9 9 9.012 9.012 0 0 0-9-9Zm0 17.25A8.25 8.25 0 1 1 17.75 9a8.26 8.26 0 0 1-8.25 8.25Z"
    />
  </svg>
);
export default SonucSecildi;
