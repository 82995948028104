import React, { useState } from "react";
import historyProvider from "./historyProvider.js";
import axios from "axios";
import { makeApiRequest, generateSymbol, parseFullSymbol } from "./helper.js";

const supportedResolutions = ["15", "60", "240", "D"];
var studies = [
  {
    id: "IchimokuCloud@tv-basicstudies",
    version: 2.0,
  },

  {
    id: "BB@tv-basicstudies",
    inputs: {
      length: 25,
    },
  },
  {
    id: "MASimple@tv-basicstudies",
    inputs: {
      length: 200,
    },
  },
  {
    id: "MASimple@tv-basicstudies",
    inputs: {
      length: 100,
    },
  },
  {
    id: "MASimple@tv-basicstudies",
    inputs: {
      length: 50,
    },
  },
  {
    id: "MASimple@tv-basicstudies",
    inputs: {
      length: 9,
    },
  },
  {
    id: "RSI@tv-basicstudies",
  },
  {
    id: "RSI@tv-basicstudies",
    inputs: {
      length: 4,
    },
  },
];

const config = {
  supported_resolutions: supportedResolutions,
  exchanges: [
    { value: "", name: "Hepsi", desc: "Hepsi" },
    { value: "-", name: "-", desc: "-" },
  ],
  // symbols_types: [
  //     { name: "Crypto", value: "crypto" },
  //     { name: "Stock", value: "stock" },
  //     { name: "Index", value: "index" }
  // ],
};

async function getAllSymbols() {
  const access_token = localStorage.getItem("access_token");
  const urlApi = localStorage.getItem("urlApi");
  //const data = await makeApiRequest('data/v3/all/exchanges');
  //const data = await makeApiRequest('tw-own-symbols.php');
  // console.log("urlApi", urlApi);
  const postData = {
    access_token: access_token,
  };
  let res = await axios({
    method: "post",
    url: `${urlApi}/tw-index-symbols.php`,
    data: postData,
  });
  if (res.status === 200) {
    console.log(res.status);
  }
  const data = res.data;

  let allSymbols = [];
  //for (const exchange of config.exchanges) {
  //console.log('exchange',exchange.value)
  const pairs = data.Data.pairs;
  //const typeSymbol = data.Data[exchange.value].type;
  //console.log('pairs',pairs)
  //pairs.map( pair => {
  //console.log('pair',pair)
  const symbols = pairs.map((pair) => {
    const symbol = generateSymbol(
      pair.exchange,
      pair.symbol,
      pair.currency_code
    );
    return {
      symbol: symbol.short,
      full_name: symbol.full,
      description: pair.description,
      exchange: pair.exchange,
      //type: typeSymbol,
    };
  });
  allSymbols = [...allSymbols, ...symbols];
  //})
  //}
  return allSymbols;
}

export default {
  onReady: (cb) => {
    // console.log("=====onReady running cb");
    setTimeout(() => cb(config), 0);
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    console.log("====Search Symbols running", userInput, exchange);
    //console.log('[searchSymbols]: Method call');

    const symbols = await getAllSymbols();
    // console.log("symbols", symbols);
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    // expects a symbolInfo object in response
    // if (symbolName == "-") return;
    console.log("======resolveSymbol running Frekans", symbolName);
    var split_data = symbolName.split(/[:/]/);
    // console.log("extension", extension);
    //console.log("split_data", split_data);
    var symbol_stub = {
      ticker: `${split_data[0]}:${split_data[1]}`,
      name: split_data[1],
      description: `${split_data[0]}:${split_data[1]}`,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: split_data[0],
      fractional: false,
      minmov: 1,
      pricescale: 1000,
      // intraday_multipliers: ["1"],
      intraday_multipliers: ["1", "15", "60", "240", "720", "5040"],
      supported_resolution: supportedResolutions,
      precision: 0,
      volume_precision: 8,
      study_count_limit: 10,
      vt: "vt",
      has_intraday: true,
      //studies_access: config.studies_access
    };

    // if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
    //   symbol_stub.pricescale = 100;
    // }
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
      //console.log('Resolving that symbol....', symbol_stub)
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },
  getBars: function (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) {
    let { from, to, firstDataRequest } = periodParams;
    console.log("getBars symbolInfo", symbolInfo);
    // console.log(
    //   `Requesting bars between ${new Date(
    //     from * 1000
    //   ).toISOString()} and ${new Date(to * 1000).toISOString()}`
    // );
    if (firstDataRequest) {
      historyProvider
        .getBars(symbolInfo, resolution, from, to, firstDataRequest)
        .then((bars) => {
          // console.log('=====Bars', bars)
          if (bars.length) {
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback(bars, { noData: true });
          }
        })
        .catch((err) => {
          console.log({ err });
          // onErrorCallback(err)
        });
    } else {
      onHistoryCallback([], { noData: true });
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    //console.log('=====subscribeBars runnning')
  },
  unsubscribeBars: (subscriberUID) => {
    //console.log('=====unsubscribeBars running')
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    //console.log('=====calculateHistoryDepth running')
    // while optional, this makes sure we request 24 hours of minute data at a time
    // Sirius Algo's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : undefined;
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    //console.log('=====getMarks running')
  },
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    //optional
    //console.log('=====getTimeScaleMarks running')
  },
  getServerTime: (cb) => {
    //console.log('=====getServerTime running')
  },
};
