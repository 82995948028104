import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { refreshCacheAndReload } from "../utils/utils";
import { timeout } from "../utils/helperFunction";
import { useDispatch, useSelector } from "react-redux";
import { USERINFO } from "../utils/urls";
function paymentReceived(props) {
  const urlApi = props.url;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const [izin, setIzin] = useState(0);
  function isCevap() {
    if (paymentId) {
      setIzin(1);
      getUserInfo();
      if (paymentId == 4) {
        navigate("/profil");
        // refreshCacheAndReload();
      }
    }
  }
  const getUserInfo = async () => {
    const postData = {
      access_token: user.access_token,
    };
    postDataWithHeaders(USERINFO, postData, dispatch).then((res) => {
      if (res.code === 0) {
        timeout(4000);
        navigate("/profil");
      } else {
        if (res.data.code === -1) {
          toast.error(res.data.data);
        }
      }
    });
  };
  useEffect(() => {
    isCevap();
    // console.log(izin);
    // document.title = "Ödeme Cevap Sayfası";
  }, []);
  return (
    <Container style={{ paddingTop: 200, paddingBottom: 200 }}>
      <Row>
        <ToastContainer />
        {izin == 1 ? (
          paymentId == 4 ? (
            <Col className="text-center">
              <div
                style={{ fontSize: 52, fontWeight: "bold", color: "#34af46 " }}
              >
                Ödeme İşlemi Başarılı
              </div>
              <p>
                Ödeme yaptığınız için teşekkür ederiz. Paketiniz otomatik
                yenilenecektir.
              </p>
            </Col>
          ) : (
            <Col className="text-center">
              <div
                style={{ fontSize: 52, fontWeight: "bold", color: "#b11e1e" }}
              >
                Ödeme İşlemi Başarısız
              </div>
              <p>
                Ödeme işleminiz gerçekleşmemiştir. Lütfen daha sonra tekrar
                deneyiniz.
              </p>
            </Col>
          )
        ) : (
          <>
            <Col className="text-center">
              <p>İzinsiz alandasınız</p>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}

export default paymentReceived;
