import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library";
import Datafeed from "./api";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function grafikHazir(string) {
  const substring = "USDT";
  const str = string.replace("_", "/") || "";
  if (str.includes(substring)) {
    return `${str}`;
  } else if (str.length > 0) {
    return `${str}`;
  } else {
    return null;
  }
}

export class TVChartContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log("TVChartContainer props", props);
    this.state = {
      grafikName: grafikHazir(props.grafikName),
      urlApi: props.urlApi,
      saveloadData: `${props.urlApi}tw-charts.php?type=graphic`,
      bayz: false,
      theme: props.theme,
    };
  }
  static defaultProps = {
    //symbol: this.grafikName ? this.grafikName :'BIST:XU100/TRY',
    interval: "D",
    containerId: "tv_chart_container",
    libraryPath: "/charting_library/",
    chartsStorageUrl: `tw-charts.php`,
    chartsStorageApiVersion: "1.1",
    clientId: "siriusalgo.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    urlApi: "",
    timezone: "Europe/Istanbul",
    debug: true,
  };

  componentDidMount() {
    const access_token = localStorage.getItem("access_token");

    if (!access_token) {
      window.location.href = "/uye-cikis";
    }

    const { innerWidth: width, innerHeight: height } = window;
    const widgetOptions = {
      debug: false,
      symbol: this.state.grafikName ? this.state.grafikName : "XU100",
      minmov: 0.00000001,
      datafeed: Datafeed,
      interval: "D",
      timezone: "Europe/Istanbul",
      urlApi: this.state.urlApi,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      theme: this.state.theme == "dark" ? "dark" : "light",
      locale: getLanguageFromURL() || "tr",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features:
        width > 768
          ? ["study_templates"]
          : ["study_templates", "hide_left_toolbar_by_default"],
      charts_storage_url: this.state.saveloadData,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: access_token,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      // load_last_chart: true,
      // auto_save_delay: 5,
      studies_overrides: this.props.studiesOverrides,
      // disabled_features: ["header_compare"],
      intraday_multipliers: ["1", "15", "60", "240", "D", "W"],
      time_frames: [
        { text: "1m", resolution: "60", description: "1 Month" },
        { text: "3m", resolution: "240", description: "3 Months" },
        { text: "6m", resolution: "D", description: "6 Months" },
      ],
      overrides: {
        // "mainSeriesProperties.showCountdown": true,
        // "mainSeriesProperties.style": 2,
        // "paneProperties.background": "#131722",
        // "paneProperties.vertGridProperties.color": "#363c4e",
        // "paneProperties.horzGridProperties.color": "#363c4e",
        "symbolWatermarkProperties.transparency": 90,
        "scalesProperties.textColor": "#AAA",
        "mainSeriesProperties.candleStyle.wickUpColor": "#336854",
        "mainSeriesProperties.candleStyle.wickDownColor": "#7f323f",
      },

      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: "_DAYICIM",
            metainfo: {
              _metainfoVersion: 51,

              id: "_DAYICIM@tv-basicstudies-1",
              name: "_DAYICIM",
              description: "_DAYICIM",
              shortDescription: "_DAYICIM",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "DAYICIM 55",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "DAYICIM 89",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "DAYICIM 144",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "DAYICIM 233",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var dayicim55 = PineJS.Std.ema(v, 55, context);
                var dayicim89 = PineJS.Std.ema(v, 89, context);
                var dayicim144 = PineJS.Std.ema(v, 144, context);
                var dayicim233 = PineJS.Std.ema(v, 233, context);
                //console.log(this._context);
                return [dayicim55, dayicim89, dayicim144, dayicim233];
              };
            },
          },
          {
            name: "_Fibonik",
            metainfo: {
              _metainfoVersion: 51,

              id: "_Fibonik@tv-basicstudies-1",
              name: "_Fibonik",
              description: "_Fibonik",
              shortDescription: "_Fibonik",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Fibonik 5",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Fibonik 8",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "Fibonik 13",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "Fibonik 21",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var fibonik5 = PineJS.Std.ema(v, 5, context);
                var fibonik8 = PineJS.Std.ema(v, 8, context);
                var fibonik13 = PineJS.Std.ema(v, 13, context);
                var fibonik21 = PineJS.Std.ema(v, 21, context);
                //console.log(this._context);
                return [fibonik5, fibonik8, fibonik13, fibonik21];
              };
            },
          },
          {
            name: "_EMA 8/20",
            metainfo: {
              _metainfoVersion: 51,

              id: "_EMA820@tv-basicstudies-1",
              name: "_EMA 8/20",
              description: "_EMA 8/20",
              shortDescription: "_EMA 8/20",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "yellow",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Ema 8",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Ema 20",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));

                var ema8 = PineJS.Std.ema(v, 8, context);
                var ema20 = PineJS.Std.ema(v, 20, context);
                //console.log(this._context);
                return [ema8, ema20];
              };
            },
          },
        ]);
      },
    };
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      // 2. Sembol Ekleme Compare Symbol
      // tvWidget
      //   .activeChart()
      //   .createStudy("Compare", false, false, ["close", "THYAO"]);
      // tvWidget.activeChart().createStudy("Volume", true, true),
      //tvWidget.activeChart().createStudy("MACD", false, true),
      // tvWidget
      //   .activeChart()
      //   .createStudy("Relative Strength Index", false, false),
      // tvWidget.activeChart().createStudy("_DAYICIM", false, true),
      // tvWidget.activeChart().createStudy("_Fibonik", false, true),
      tvWidget.headerReady().then(() => {
        // const button = tvWidget.createButton();
        // button.setAttribute("title", "Click to show a notification popup");
        // button.classList.add("apply-common-tooltip");
        // button.addEventListener("click", () =>
        //   tvWidget.showNoticeDialog({
        //     title: "Notification",
        //     body: "TradingView Charting Library API works correctly",
        //     callback: () => {
        //       console.log("Noticed!");
        //     },
        //   })
        // );
        // button.innerHTML = "Check API";
      });
      // tvWidget.chart().createMultipointShape([
      // 	{
      // 		time: 1663891200
      // 		, price: 19500
      // 	},
      // 	{
      // 		time: 1668988800
      // 		, price: 16319
      // 	}
      // ], {
      // 	shape: 'ray',
      // 	text: 'A B Arası',
      // 	lock: true,
      // 	overrides: {
      // 		showLabel: true,
      // 		fontSize: 30,
      // 		linewidth: 2
      // 	}
      // });
      if (this.state.bayz) {
        tvWidget.chart().createMultipointShape(
          [
            {
              time: 1660521600,
              price: 25211.31,
            },
            {
              time: 1663027200,
              price: 22799,
            },
            {
              time: 1655510400,
              price: 17622,
            },
          ],
          {
            shape: "fib_channel",
            text: "A B Arası",
            lock: false,
            overrides: {
              showLabel: true,
              fontSize: 30,
              linewidth: 2,
              showPrices: true,
              extendRight: true,
              fillBackground: false,
              level2: {
                visible: true,
                coeff: 0.33,
              },
              level3: {
                visible: true,
                coeff: -0.3,
              },
              level4: {
                visible: false,
              },
              level5: {
                visible: false,
              },
              level6: {
                visible: false,
              },
              level8: {
                visible: false,
              },
              level9: {
                visible: false,
              },
              level10: {
                visible: false,
              },
              level11: {
                visible: false,
              },
            },
          }
        );
      }
    });
  }

  render() {
    return <div id={this.props.containerId} className={"TVChartContainer"} />;
  }
}
