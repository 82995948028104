import React, { useEffect, useState } from "react";
import "./ProfilBottom.css";
import { Col, Row, Modal, Container, Button } from "react-bootstrap";
import cup_1st from "../../../assets/img/1st_cup.png";
import cup_2nd from "../../../assets/img/2nd_cup.png";
import cup_3rd from "../../../assets/img/3rd_cup.png";
import regular_cup from "../../../assets/img/regular_cup.png";
import { PiHeartFill, PiStarFill } from "react-icons/pi";
import PanoNote from "../../svgs/panoNote";
import SonucTekEkle from "../../svgs/sonucTekEkle";
import { Tooltip } from "react-tooltip";
import { PulseLoader } from "react-spinners";
import { timeout } from "../../../utils/helperFunction";
import ArrowThinDown from "../../svgs/arrowThinDown";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import UserFollow from "../../svgs/userFollow";
import UserFollowing from "../../svgs/userFollowing";
import { useDispatch, useSelector } from "react-redux";
import ArrowThinUp from "../../svgs/arrowThinUp";
import SonucSecildi from "../../svgs/sonucSecildi";
import { toast } from "react-toastify";
import userFrame from "../../../assets/img/userFrama.svg";
import { AiFillDashboard, AiFillCarryOut, AiFillSignal } from "react-icons/ai";
import {
  BsFillStarFill,
  BsFillTrashFill,
  BsHeartFill,
  BsPersonFillCheck,
} from "react-icons/bs";
import {
  FORMULAFOLLOW,
  FORMULAS,
  FORMULAUNFOLLOW,
  PANOFORMULLER,
  USERFOLLOWS,
  USERPROFILE,
  USERUNFOLLOW,
} from "../../../utils/urls";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import StarTop from "../../svgs/starTop";
import UserSirius from "../../svgs/userSirius";
import UserTakipci from "../../svgs/userTakipci";
import UserProfileZiyaret from "../../svgs/userProfilZiyaret";
import UserStarNull from "../../svgs/userStarNull";
import UserMemnuniyet from "../../svgs/userMemnuniyet";
import ArrowDown from "../../svgs/arrowDown";
import ArrowUp from "../../svgs/arrowUp";
import ArrowSort from "../../svgs/arrowSort";
import {
  setFollowFormulas,
  setFollowUsers,
  setFormulas,
  setMyboard,
  setUsers,
} from "../../../store/reducers/panoSlice";
const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function ProfilBottom() {
  const urlApi = localStorage.getItem("urlApi");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { users, users_follows, formulas_follows } = useSelector(
    (state) => state.pano
  );
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFormul, setSelectedFormul] = useState(null);
  const [formulasBenim, setFormulasBenim] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [formulasTakip, setFormulasTakip] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visibleUser, setVisibleUser] = useState(false);
  const handleUserClose = () => _setVisibleUserClose(false);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const handleFormulClose = () => _setVisibleFormulClose(false);
  const [visibleUnfollow, setVisibleUnfollow] = useState(false);
  const handleUnfollowClose = () => setVisibleUnfollow(false);
  const [show, setShow] = useState(false);
  const [showPaylas, setShowPaylas] = useState(false);
  const { height, width } = useWindowDimensions();
  const lgWidth = "col-lg-6";
  const item = width > 1200 ? 8 : 6;

  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const handleIndicatorName = (name) => {
    return _.filter(updateDataAll, { slug: name }).map((v) => v.label);
  };
  const _setVisibleFormulClose = () => {
    setVisibleFormul(false);
    setSelectedFormul(null);
  };
  const _setVisibleUserClose = () => {
    setVisibleUser(false);
  };
  const _setVisibleFormul = (item) => {
    setSelectedFormul(item);
    setVisibleFormul(true);
  };
  const showMoreFormul = () => {
    setShow(!show);
  };
  const showMorePaylas = () => {
    setShowPaylas(!showPaylas);
  };

  const _setVisibleUnfollow = (item) => {
    setSelectedItem(item);
    setVisibleUnfollow(true);
    console.log("_setVisibleUnfollow", item);
  };

  const _getUserList = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(USERFOLLOWS, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        setFormulasTakip(res.data);
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
  };
  const _functTakipEt = async (formul) => {
    setIsProcessing(true);
    setSelectedFormul(formul);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        setIsProcessing(false);
        setVisibleFormul(false);
        setVisibleUser(false);
        _getFormulLists();
      } else {
        if (res.code == "-91") {
          toast.error(res.data);
          setKullanici(formul.owner);
          setIsProcessing(false);
          setVisibleTakipKullanici(true);
          setVisibleFormul(false);
          return;
        }
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  const getFormulasData = async () => {
    postDataWithHeaders(PANOFORMULLER, {
      access_token: user.access_token,
      // j1407b: 1,
    })
      .then((res) => {
        if (res.code == 0) {
          console.log("res.data.myboard pano", res.data.myboard);
          dispatch(setFormulas(res.data.board));
          dispatch(setUsers(res.data.users));
          console.log("res.data.myboard.name", res.data.myboard.name);
          if (res.data.myboard.name) {
            dispatch(setMyboard(res.data.myboard));
          } else {
            dispatch(setMyboard([]));
          }
          dispatch(setFollowUsers(res.data.users_follows));
          dispatch(setFollowFormulas(res.data.formulas_follows));
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const _setVisibleUser = (username) => {
    console.log("user", username);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: username.name,
    };
    postDataWithHeaders(USERPROFILE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        setUserProfile(res.data);
        setSelectedUser(username.name);
        setVisibleUser(true);
      } else {
        toast.error(res.data);
      }
    });
  };
  const _getFormulLists = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(FORMULAS, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        let benim = [];
        let takip = [];
        console.log("res", res.data);
        res.data.map((item) => {
          if (item.type == "self" && item.shared == "followers") {
            benim.push(item);
          }
        });
        setFormulasBenim(benim);
        getFormulasData();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
  };
  const handleUnfollow = () => {
    setIsProcessing(true);
    timeout(2000);
    const postData = {
      id: selectedItem.id,
      access_token: user.access_token,
      // j1407b: 1,
    };

    postDataWithHeaders(FORMULAUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code == 0) {
        toast.success(res.data);
        setIsProcessing(false);
        setVisibleUnfollow(false);
        _getFormulLists();
      } else {
        setIsProcessing(false);
        setVisibleUnfollow(false);
        toast.error(res.data);
      }
    });
  };
  const unFollowUser = (usr) => {
    setIsProcessing(true);
    console.log("username", usr);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: usr.name,
    };
    postDataWithHeaders(USERUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        _getUserList();
        setIsProcessing(false);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  useEffect(() => {
    _getFormulLists();
    _getUserList();
  }, []);
  return (
    <Row>
      <Col lg={12} className="ProfilBottom">
        <div className="ProfilBottom__container">
          <div className="ProfilBottom__container__left">
            <div className="ProfilBottom__container__left__title">
              Takipçilere Paylaştığım Formüller
            </div>

            <div className="ProfilBottom__content">
              {formulasBenim.map((item, idnx) => {
                // Your code here
                let number = idnx + 1;
                let dClass = number > 4 ? "d-none" : "dshow";
                return (
                  <div
                    key={idnx}
                    className={
                      show
                        ? `ProfilBottom__content__item`
                        : `ProfilBottom__content__item ${dClass}`
                    }
                  >
                    <div className="ProfilBottom__content__item__left">
                      <div className="ProfilBottom__content__item__left__title">
                        {item.name}
                      </div>
                      <div className="ProfilBottom__content__item__left__desc">
                        <span>
                          <AiFillDashboard size={16} />{" "}
                          {item.queries ? item.queries : 0}
                        </span>
                        <span>
                          <BsHeartFill size={16} />{" "}
                          {item.likes ? item.likes : 0}
                        </span>
                        <span>
                          <AiFillSignal size={16} />{" "}
                          {item.rating ? item.rating : 0}
                        </span>
                      </div>
                    </div>
                    <div className="ProfilBottom__content__item__right">
                      <PanoNote
                        color="#fff"
                        data-tooltip-place="bottom-start"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={item.note}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="ProfilBottom__bottom">
              <div
                className="PanoFormul__bottom__text"
                onClick={() => showMoreFormul()}
              >
                {show ? "Daha az göster " : "Daha Fazla göster "}{" "}
                {show ? <ArrowThinUp /> : <ArrowThinDown />}
              </div>
            </div>
            <Tooltip
              id="my-tooltip"
              style={{
                backgroundColor: "#35353A",
                color: "#fff",
                fontSize: 14,
                padding: 16,
                borderRadius: 8,
                maxWidth: 300,
              }}
            />
          </div>
          <div className="ProfilBottom__container__right">
            <div className="ProfilBottom__container__left__title">
              Takip Ettiğim Kullanıcılar
            </div>
            <div className="ProfilBottom__container__right__bottom">
              {formulasTakip.map((item, index) => {
                let number = index + 1;
                let dClass = number > 4 ? "d-none" : "dshow";
                return (
                  <div
                    key={`bottom${index}`}
                    className={
                      showPaylas
                        ? `ProfilBottom__container__right__bottom__item`
                        : `ProfilBottom__container__right__bottom__item ${dClass}`
                    }
                  >
                    <SonucSecildi color="#fff" />
                    <div className="ProfilBottom__container__right__bottom__item__left">
                      <div
                        className="ProfilBottom__container__right__bottom__item__left__title cursorPointer"
                        onClick={() => _setVisibleUser(item)}
                      >
                        {item.name}
                      </div>
                      <div className="PanoBottom__container__right__bottom__item__left__text">
                        <span>
                          <BsPersonFillCheck size={16} /> {item.follower_count}{" "}
                          Takipçi
                        </span>
                      </div>
                      <div
                        className="PanoBottom__container__right__bottom__item__right cursorPointer"
                        onClick={() => unFollowUser(item)}
                      >
                        <SonucSecildi color="#fff" /> Takipten Çıkar
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="PanoFormul__bottom">
              <div
                className="PanoFormul__bottom__text"
                onClick={() => showMorePaylas()}
              >
                {showPaylas ? "Daha az göster " : "Daha Fazla göster "}{" "}
                {showPaylas ? <ArrowThinUp /> : <ArrowThinDown />}
              </div>
            </div>
          </div>
        </div>
      </Col>

      {visibleUser && (
        <Modal
          show={visibleUser}
          onHide={handleUserClose}
          dialogClassName="modal-863w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle userTitle"
                  >
                    Kişi Profili
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="UserProfile">
              <div className="UserProfile__top">
                <div className="UserProfile__top__left">
                  <div className="UserProfile__top__title">
                    <StarTop />
                    {selectedUser}
                  </div>
                </div>
                <div
                  className="UserProfile__top__right"
                  onClick={() => unFollowUser(selectedUser)}
                >
                  <UserFollowing color={"#fff"} />
                  Takip Ediliyor
                </div>
              </div>
              <div className="userFrame">
                <img src={userFrame} />
              </div>
              <div className="UserProfile__puans">
                <div className="UserProfile__OverviewCard bgOverCard1">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserProfileZiyaret />
                    Profil Ziyareti
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.visits ? userProfile.visits : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard2">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserTakipci />
                    Takipçi
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.followers ? userProfile.followers : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard3">
                  <div className="UserProfile__OverviewCard__Top">
                    <AiFillSignal />
                    Sorgulanma Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.queries ? userProfile.queries : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard4">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserMemnuniyet />
                    Beğeni Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.likes ? userProfile.likes : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard5">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserSirius />
                    Reyting Puanı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.rating ? userProfile.rating : 0}
                  </div>
                </div>
              </div>
              {userProfile.myboard.name != null && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Panoya Paylaştığı Formül
                    </div>
                  </div>
                  <div className="PanoTop__publish">
                    <div className="PanoTop__publish__left">
                      {userProfile.myboard.name}
                      <PanoNote
                        color="#fff"
                        data-tooltip-place="bottom-start"
                        data-tooltip-id="my-tooltip2"
                        data-tooltip-content={userProfile.myboard.note}
                      />
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Sorgulama Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillDashboard size={16} />{" "}
                          {userProfile.myboard.queries
                            ? userProfile.myboard.queries
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.queries_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Beğeni Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <BsHeartFill size={16} />{" "}
                          {userProfile.myboard.likes
                            ? userProfile.myboard.likes
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.likes_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Reyting Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillSignal size={16} />{" "}
                          {userProfile.myboard.rating
                            ? userProfile.myboard.rating
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.rating_rank}
                        </span>
                      </div>
                    </div>
                    {_.filter(formulas_follows, {
                      id: userProfile.myboard.id,
                    }).length == 0 ? (
                      <>
                        <SonucTekEkle
                          onClick={() => _functTakipEt(userProfile.myboard)}
                          color="#fff"
                        />
                      </>
                    ) : (
                      <>
                        <SonucSecildi
                          onClick={() =>
                            _setVisibleUnfollow(userProfile.myboard)
                          }
                          color="#22DE2A"
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {userProfile.formulas.length > 0 && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Takipçileriyle Paylaştığı Formüller
                    </div>
                    {userProfile.formulas.map((item, index) => {
                      if (item.type == "board") {
                        return;
                      }
                      let takipte = _.filter(formulas_follows, {
                        id: item.id,
                      }).length;
                      return (
                        <div
                          key={`popup${index}`}
                          className="UserProfile__bottom__div"
                        >
                          <div className="UserProfile__bottom__div__left">
                            <div
                              className="UserProfile__bottom__div__title cursorPointer"
                              onClick={() => _setVisibleFormul(item)}
                            >
                              {item.name}
                            </div>
                            <div className="UserProfile__bottom__div__puan">
                              <div className="UserProfile__bottom__div__puan__left">
                                <PiHeartFill /> {item.likes}
                              </div>
                              <div className="UserProfile__bottom__div__puan__right">
                                <PiStarFill /> {item.points}
                              </div>
                            </div>
                          </div>
                          <div className="UserProfile__bottom__div__right">
                            <PanoNote
                              color="#fff"
                              data-tooltip-place="bottom-start"
                              data-tooltip-id="my-tooltip2"
                              data-tooltip-content={item.note}
                            />
                            {takipte == 0 ? (
                              <>
                                <SonucTekEkle
                                  onClick={() => _functTakipEt(item)}
                                  color="#fff"
                                />
                              </>
                            ) : (
                              <>
                                <SonucSecildi
                                  onClick={() => _setVisibleUnfollow(item)}
                                  color="#22DE2A"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <Tooltip
              id="my-tooltip2"
              style={{
                backgroundColor: "#35353A",
                color: "#fff",
                fontSize: 14,
                padding: 16,
                borderRadius: 8,
                maxWidth: 300,
              }}
            />
          </Modal.Body>
        </Modal>
      )}
      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {selectedFormul.name}
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(selectedFormul.formations).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Formasyonlar</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.formations).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleName(key)}
                      </span>
                      <span className="OzelArama_periods">
                        {selectedFormul.formations[key].periods.map(
                          (period) => (
                            <span
                              className="period"
                              key={`formul_${key}_${period}`}
                            >
                              {period}
                            </span>
                          )
                        )}{" "}
                      </span>
                      {selectedFormul.formations[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.formations[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.volumes).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Hacim</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.volumes).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.volumes[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.volumes[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.indicators).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>İndikatörler</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.indicators).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.indicators[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.indicators[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.averages).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Ortalamalar</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.averages).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.averages[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.averages[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedFormul.owner != user.username && (
              <Button
                onClick={() => _functTakipEt(selectedFormul)}
                className="modalSonucButton"
              >
                {!isProcessing && <SonucTekEkle color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Listeye Ekleniyor..." />
                ) : (
                  "Listeye Ekle"
                )}
              </Button>
            )}
          </Modal.Body>
        </Modal>
      )}
      {visibleUnfollow && (
        <Modal
          show={visibleUnfollow}
          onHide={handleUnfollowClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Takipten Çık
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">{selectedItem.name}</div>
            </div>
            <Button
              onClick={() => handleUnfollow()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Takipten Çık"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </Row>
  );
}

export default ProfilBottom;
