import React from "react";
import "./HomeNelerBekliyor.css";
import { Container, Row, Col } from "react-bootstrap";
import icon_pano from "../../../assets/img/icon/pano.png";
import icon_genel_arama from "../../../assets/img/icon/tanitim_ve_kullanim.png";
import icon_ozel_arama from "../../../assets/img/icon/ozel_arama.png";
import icon_alarmlar from "../../../assets/img/icon/alarm.png";
import icon_egitim from "../../../assets/img/icon/egitim_odasi.png";
import icon_listelerim from "../../../assets/img/icon/listelerim.png";
function HomeNelerBekliyor() {
  return (
    <Container className="HomeNelerBekliyor">
      <Col
        id="neler"
        xs
        lg={12}
        className="HomeNelerBekliyor__title text-center"
      >
        Sizi Neler Bekliyor?
      </Col>
      <Row className="HomeNelerBekliyor__row">
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div style={{ width: 40, height: 40, position: "relative" }}>
                  <img src={icon_pano} />
                </div>
              </div>
              <div
                style={{
                  width: 98,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                PANO
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Sirius Eko Sistemi ile tanışın. Kullanıcılar başarılı formüllerini
              Pano’da paylaşır. İsterseniz başarılı analistlerin filtrelerini
              inceleyebilir, kendinize kaydedebilir, ve onları
              puanlayabilirsiniz. Bu sayede uzman analistlerin yol haritalarıyla
              analiz yapabilirsiniz. Sirius’un filtreleme sistemi ile siz de
              kendi formüllerinizi panoda yayınlayabilir, takipçi ve beğeni
              kazanabilirsiniz.
            </div>
          </div>
        </Col>
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div style={{ width: 40, height: 40, position: "relative" }}>
                  <img src={icon_genel_arama} />
                </div>
              </div>
              <div
                style={{
                  width: 180,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Genel Arama
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Farklı Periyotlarda, Formasyon/İndikatör/Hacim/Ortalama bazlı
              filtrelemeler ile istediğiniz tüm şartları sağlayan hisseleri tek
              tuşla karşınıza getirebilirsiniz. Hayal gücünüzü zorlayın ve
              benzersiz kombinasyonlar oluşturun!
              <br />
              Girmiş olduğunuz şartları sağlayan hisse çıkmadı mı ? Tek tuşla
              Alarm kurun 😊 Sirius sizi habersiz bırakmaz 
            </div>
          </div>
        </Col>
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    paddingTop: 3.33,
                    paddingBottom: 3.33,
                    paddingLeft: 3.52,
                    paddingRight: 3.14,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img src={icon_ozel_arama} />
                </div>
              </div>
              <div
                style={{
                  width: 180,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Özel Arama
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
               Beğendiğiniz filtreleme sistemlerini kolayca kaydedin. Tek tuşla
              filtrelerinize uygun olan hisseler karşınıza gelecektir.
              Filtrelerinize notlar yazabilir, alarmlar kurabilirsiniz. Takipçi
              sayınızı ve beğenilerinizi artırmak isterseniz sahne sizin.
              Formüllerinizi panoda paylaşın, diğer kullanıcılar sizi oylasın.
              Tabii siz de onları 😊Sirius Arenası’na hoş geldiniz.
            </div>
          </div>
        </Col>
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    paddingTop: 3.33,
                    paddingBottom: 3.33,
                    paddingLeft: 6.86,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img src={icon_alarmlar} />
                </div>
              </div>
              <div
                style={{
                  width: 120,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Alarmlar
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Grafiklere fiyat alarmı kurmak kolay. Ama bir grafiğe, “X
              formasyonunun son bacağında hacim de gelirse bana haber ver.”
              diyebilmek ? İşte bu ve çok daha fazla alarm seçeneği ancak Sirius
              Kullanıcıları için kolay olabilir.Sirius sayesinde
              Formasyon/Hacim/İndikatör/Ortalama bazlı farklı alarmlar kurabilir
              ve artık ekran başında beklemeyi bırakabilirsiniz.
            </div>
          </div>
        </Col>
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    paddingLeft: 1.67,
                    paddingRight: 1.67,
                    paddingTop: 3.33,
                    paddingBottom: 3.33,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img src={icon_egitim} />
                </div>
              </div>
              <div
                style={{
                  width: 180,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Eğitim Odası
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Analiz yapmak size karışık mı geliyor ? <br />
              Çizgilerin içinde kaybolmak istemiyor musunuz ? Sizin yerinize
              Sirius analiz yapsın ister miydiniz? Lütfen zile basın. Ali Ömür,
              Sirius’ta yapabileceğiniz üst düzey analizleri, istatistikleri ve
              kombinasyonları Eğitim Odası’nda en basit haliyle anlatıyor.{" "}
              <br />
              Bu odada ilginç eğitimlerle karşılaşacaksınız.
            </div>
          </div>
        </Col>
        <Col xs lg={4} className="HomeNelerBekliyor__item">
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: 24,
              background:
                "linear-gradient(180deg, #2B2B2E 0%, rgba(43, 43, 46, 0.21) 100%)",
              borderRadius: 32,
              border: "1px rgba(255, 255, 255, 0.20) solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 16,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  padding: 24,
                  background:
                    "linear-gradient(180deg, #434348 0%, rgba(53, 53, 58, 0.15) 100%)",
                  borderRadius: 16,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div style={{ width: 40, height: 40, position: "relative" }}>
                  <img src={icon_listelerim} />
                </div>
              </div>
              <div
                style={{
                  width: 180,
                  color: "white",
                  fontSize: 32,
                  fontFamily: "Mazzard",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Listelerim
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                color: "white",
                fontSize: 18,
                fontFamily: "Mazzard",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Beğendiğiniz enstrümanlardan liste oluşturabilir, tek tuşla
              listenizdeki hisselerin tüm formasyonlarını ve analizlerini
              önünüze getirebilirsiniz. Portföyünüzü listeleyebilir ve gün
              içerisindeki formasyon oluşumlarını takip edebilirsiniz. Sınırsız
              liste oluşturma seçeneğiyle hiçbir analizi kaçırmayın. Sirius’un
              açık olduğu tek ekran size yetecektir 😉
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeNelerBekliyor;
