import * as React from "react";
const ArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M8.647 2.647a.5.5 0 0 1 .706 0l5 5a.5.5 0 0 1 0 .706l-5 5a.502.502 0 0 1-.83-.153.5.5 0 0 1 .124-.553L12.793 8.5H2a.5.5 0 1 1 0-1h10.793L8.647 3.353a.5.5 0 0 1 0-.706Z"
    />
  </svg>
);
export default ArrowLeft;
