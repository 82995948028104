import * as React from "react";
const BellIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#fff"}
      d="M14.783 19.839c0 .99-.528 1.747-1.385 2.243a2.765 2.765 0 0 1-2.768 0c-.857-.496-1.385-1.253-1.385-2.243M12.013 2.555c.755 0 1.363.466 1.363 1.182 0 .45.016.787.3.873 2.407.727 3.952 2.432 3.952 4.645v3.721c0 1.704.697 2.05 1.575 2.702 1.27.942.98 2.905-.478 2.904H5.301c-1.458.001-1.749-1.962-.478-2.904.877-.652 1.575-.998 1.575-2.702v-3.72c0-2.214 1.545-3.919 3.953-4.646.282-.086.299-.422.299-.873 0-.716.609-1.182 1.363-1.182Z"
    />
  </svg>
);
export default BellIcon;
