import React from "react";
import ResetPassword from "../components/Auth/ResetPassword/ResetPassword";

function AuthResetPassword() {
  return (
    <React.Fragment>
      <ResetPassword />
    </React.Fragment>
  );
}

export default AuthResetPassword;
