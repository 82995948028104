import * as React from "react";
const ArrowThinUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#fff"}
      fillRule="evenodd"
      d="M7.97.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L8.5 2.31 1.53 9.28A.75.75 0 0 1 .47 8.22l7.5-7.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowThinUp;
