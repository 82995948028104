import { combineReducers } from "@reduxjs/toolkit";

import themeReducer from "./reducers/themeSlice";
import authReducer from "./reducers/authSlice";
import filtersReducer from "./reducers/filtersSlice";
import panoReducer from "./reducers/panoSlice";
import dataReducer from "./reducers/dataSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  filters: filtersReducer,
  pano: panoReducer,
  data: dataReducer,
});
