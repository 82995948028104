import * as React from "react";
const SidemenuPaket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M2.875.875a2.5 2.5 0 0 0-2.5 2.5v3.598a2.5 2.5 0 0 0 .732 1.768l7.984 7.984c.767.767 1.992.988 2.957.357a15.709 15.709 0 0 0 4.534-4.534c.631-.966.41-2.19-.357-2.956L8.242 1.607A2.5 2.5 0 0 0 6.473.875H2.875Zm.938 4.375a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SidemenuPaket;
