import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Header from "../components/System/header";
import "../components/GrafikPaneli/GrafikPaneli.css";

import { TVChartContainer } from "../components/TVChartContainer/index";
import SonucTekEkle from "../components/svgs/sonucTekEkle";
import { useSelector } from "react-redux";

function GrafikPaneli() {
  const { grafikName } = useParams();
  const { user } = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.theme.darkmode);
  const grafik_name = localStorage.getItem("index_name");
  const urlApi = localStorage.getItem("urlApi");

  React.useEffect(() => {
    document.title = "Grafik Paneli - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row>
            <div className="DashBoard_container">
              <TVChartContainer
                grafikName={
                  grafikName ? grafikName : grafik_name ? grafik_name : "XU100"
                }
                urlApi={urlApi}
                access_token={user.access_token}
                theme={theme}
              />
            </div>
          </Row>
          <Row className="grafik_paneli_text">
            <Col>
              <p>
                TradingView, dünya genelinde tüccarlar ve yatırımcıların bir
                araya geldiği, piyasaları analiz ettiği ve en son finansal
                haberleri tartıştığı bir grafik platformudur. Gelişmiş araçları
                ve çeşitli verileri sayesinde herhangi bir varlıkla
                ilgilenenler, piyasa araştırmalarını yeni bir seviyeye
                taşıyabilirler. İşlem tutkunları, özellikle TradingView'in
                sunduğu özelliklerden memnun kalacaklar. Detaylı sembol
                grafiklerinin yanı sıra{" "}
                <a
                  href="https://www.tradingview.com/economic-calendar/"
                  target="_blank"
                >
                  ekonomi takvimi
                </a>{" "}
                grafikleri gibi daha kapsamlı verilere de erişebilirler. Bu
                platform, herhangi bir stratejiye uygun daha geniş veriler
                sunarak kullanıcılara yardımcı olur. Ayrıca, hisse senedi
                yatırımcıları için de faydalı göstergeler sunar; örneğin,{" "}
                <a href="https://www.tradingview.com/screener/" target="_blank">
                  Hisse senedi tarayıcısı
                </a>{" "}
                sayesinde hisse senedi piyasalarını gözden geçirebilir ve
                herhangi bir portföy için uygun varlıkları bulabilirsiniz - tüm
                bunlar ve daha fazlası TradingView'de bulunabilir.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default GrafikPaneli;
