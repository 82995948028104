import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { PACKAGESLANDING } from "../../../utils/urls";
import PaketDesc from "../../svgs/paketDesc";
import paketPremium from "../../../assets/img/paket-premium.png";
import paketPremiumPlus from "../../../assets/img/paket-premiumplus.png";
import paket1 from "../../../assets/img/premiumplus_uyari.png";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { ImCross } from "react-icons/im";

function HomePackages() {
  const [paket, setPaket] = useState(0);
  const [paketTuru, setPaketTuru] = useState(4);
  const [paketler, setPaketler] = useState([]);
  const [paketSecilen, setPaketSecilen] = useState(null);
  const getPackages = async () => {
    postDataWithHeaders(PACKAGESLANDING).then((res) => {
      if (res.code === 0) {
        setPaketler(res.data);
        setPaketSecilen(res.data.find((item) => item.id == paketTuru));
      }
    });
  };
  const _setPaketTuru = (value) => {
    setPaketTuru(value);

    setPaketSecilen(paketler.find((item) => item.id == value));
  };
  const _setPaket = (value) => {
    setPaket(value);
    const refreshPaket = value == 0 ? 4 : 1;
    _setPaketTuru(refreshPaket);
  };
  const _satinAl = () => {
    window.location.href = "/uye-kayit";
  };
  useEffect(() => {
    getPackages();
  }, []);
  return (
    <Container fluid className="Packages">
      <div className="Packages_container">
        <Container className="HomeVideo ">
          <Col id="planlar" xs lg={12} className="HomeVideo__title text-center">
            Üyelik Planları
          </Col>
          <Row className="Paketler__row height100">
            <Col className="Paketler__col__left">
              <div className="Paketler__col__left__title">Sirius Algo’nun</div>
              <div className="Paketler__col__left__slogan">
                Yıldızları yakından görebileceğiniz premium fırsatları ! ✨
              </div>
              <div className="Paketler__col__left__desc">
                İki paket ile istediğiniz verilere ulaşmanın imkanını Sirius
                Algo ile keşfedin. Sizin için en uygun paketi seçebilirisiniz.
              </div>
            </Col>
            <Col className="Paketler__col__right">
              <div className="Paketler__col__right__top">
                <div
                  className={
                    "Paketler__col__right__top__left" +
                    (paket == 0 ? " active" : "")
                  }
                  onClick={() => _setPaket(0)}
                >
                  <img src={paketPremium} />
                </div>
                <div
                  className={
                    "Paketler__col__right__top__right" +
                    (paket == 1 ? " active" : "")
                  }
                  onClick={() => _setPaket(1)}
                >
                  <img src={paketPremiumPlus} />
                </div>
              </div>
              {paket == 0 && (
                <div className="Paketler__col__right__body">
                  <div className="Paketler__col__right__body__title">
                    Aylık Seçenek
                  </div>
                  <div className="Paketler__col__right__body__packages">
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 4 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(4)}
                    >
                      1 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 5 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(5)}
                    >
                      6 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 6 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(6)}
                    >
                      1 Yıllık Paket
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__desc">
                    <ul className="Paketler__col__right__body__desc__list">
                      <li>
                        <PaketDesc /> Hacim Analizi
                      </li>
                      <li>
                        <PaketDesc /> Liste Oluşturma
                      </li>
                      <li>
                        <PaketDesc /> İndikatör Analizi
                      </li>
                      <li>
                        <PaketDesc /> Formasyon Analizi
                      </li>
                      <li>
                        <PaketDesc /> Fiyat/Formasyon/Hacim/İndikatör/Ortalama
                        bazlı Alarmlar
                      </li>
                      <li>
                        <PaketDesc /> Frekans Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Korelasyon Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Pano Formüllerine Erişim
                      </li>
                      <li>
                        <PaketDesc /> Hareketli Ortalama Analizi
                      </li>
                      <li>
                        <PaketDesc /> Sirius Analizi Eğitim Videolarına Erişim
                      </li>
                    </ul>
                  </div>
                  <div className="Paketler__Premium__desc">
                    <ImCross />
                    <span>Zamansallık Teorisi Analizleri (bayZ-Gölge)</span>
                  </div>

                  <div className="Paketler__col__right__body__fiyat">
                    {paketSecilen?.price_percent > 0 && (
                      <div className="Paketler__col__right__body__fiyat__title">
                        Bu pakette %{paketSecilen.price_percent} indirim
                        uygulanır
                      </div>
                    )}
                    <div className="Paketler__col__right__body__fiyat__desc">
                      <div className="Paketler__col__right__body__fiyat__desc__title">
                        Toplam
                      </div>
                      <div className="Paketler__col__right__body__fiyat__desc__price">
                        {paketSecilen?.price_percent > 0 && (
                          <span className="toplam">
                            {paketSecilen?.price_discount}₺
                          </span>
                        )}
                        <span className="net">
                          {paketSecilen?.price}₺{" "}
                          <span className="kdv">(KDV Dahil)</span>
                        </span>
                      </div>
                    </div>
                    <div className="Paketler__col__right__body__satinal satinalButton">
                      <Button onClick={() => _satinAl()}>Satın Al</Button>
                    </div>
                  </div>
                </div>
              )}
              {paket == 1 && (
                <div className="Paketler__col__right__body">
                  <div className="Paketler__col__right__body__title">
                    Aylık Seçenek
                  </div>
                  <div className="Paketler__col__right__body__packages">
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 1 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(1)}
                    >
                      1 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 2 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(2)}
                    >
                      6 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 3 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(3)}
                    >
                      1 Yıllık Paket
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__desc">
                    <ul className="Paketler__col__right__body__desc__list">
                      <li>
                        <PaketDesc /> Hacim Analizi
                      </li>
                      <li>
                        <PaketDesc /> Liste Oluşturma
                      </li>
                      <li>
                        <PaketDesc /> İndikatör Analizi
                      </li>
                      <li>
                        <PaketDesc /> Formasyon Analizi
                      </li>
                      <li>
                        <PaketDesc /> Fiyat/Formasyon/Hacim/İndikatör/Ortalama
                        bazlı Alarmlar
                      </li>
                      <li>
                        <PaketDesc /> Frekans Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Korelasyon Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Pano Formüllerine Erişim
                      </li>
                      <li>
                        <PaketDesc /> Hareketli Ortalama Analizi
                      </li>
                      <li>
                        <PaketDesc /> Sirius Analizi Eğitim Videolarına Erişim
                      </li>
                    </ul>
                  </div>
                  {paket == 1 && (
                    <div className="Paketler__Premiumplus__desc">
                      <img
                        src={paket1}
                        alt="paket1"
                        className="Paketler__Premiumplus__desc__img"
                      />
                      <span>Zamansallık Teorisi Analizleri (bayZ-Gölge)</span>
                    </div>
                  )}
                  <div className="Paketler__col__right__body__fiyatplus">
                    {paketSecilen?.price_percent > 0 && (
                      <div className="Paketler__col__right__body__fiyat__title">
                        Bu pakette %{paketSecilen.price_percent} indirim
                        uygulanır
                      </div>
                    )}
                    <div className="Paketler__col__right__body__fiyatplus__desc">
                      <div className="Paketler__col__right__body__fiyat__desc__title">
                        Toplam
                      </div>
                      <div className="Paketler__col__right__body__fiyat__desc__price">
                        {paketSecilen?.price_percent > 0 && (
                          <span className="toplam">
                            {paketSecilen?.price_discount}₺
                          </span>
                        )}
                        <span className="net">
                          {paketSecilen?.price}₺{" "}
                          <span className="kdv">(KDV Dahil)</span>
                        </span>
                      </div>
                    </div>
                    <div className="Paketler__col__right__body__satinal satinalButton">
                      <Button onClick={() => _satinAl()}>Satın Al</Button>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default HomePackages;
