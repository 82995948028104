import React from "react";
import AnaKumandaEdit from "../components/AnaKumanda/AnaKumandaEdit";

function AnaKumandaScreenEdit() {
  return (
    <React.Fragment>
      <AnaKumandaEdit />
    </React.Fragment>
  );
}

export default AnaKumandaScreenEdit;
