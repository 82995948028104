import React from "react";
import "./ProfilBody.css";
import UserMemnuniyet from "../../svgs/userMemnuniyet";
import UserSirius from "../../svgs/userSirius";
import UserTakipci from "../../svgs/userTakipci";
import ProfilBodyZiyaret from "../../svgs/userProfilZiyaret";
import UserStarNull from "../../svgs/userStarNull";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

function ProfilBody() {
  const { profile } = useSelector((state) => state.auth);
  console.log("profile", profile);
  return (
    <Row>
      <Col lg={12}>
        <div className="ProfilBody__container">
          <div className="ProfilBody__puans">
            <div className="ProfilBody__OverviewCard bgOverCard1">
              <div className="ProfilBody__OverviewCard__Top">
                <ProfilBodyZiyaret />
                Profil Ziyareti
              </div>
              <div className="ProfilBody__OverviewCard__bottom">
                {profile && profile.visits ? profile.visits : 0}
              </div>
            </div>
            <div className="ProfilBody__OverviewCard bgOverCard2">
              <div className="ProfilBody__OverviewCard__Top">
                <UserTakipci />
                Takipçi
              </div>
              <div className="ProfilBody__OverviewCard__bottom">
                {profile && profile.followers ? profile.followers : 0}
              </div>
            </div>
            <div className="ProfilBody__OverviewCard bgOverCard3">
              <div className="ProfilBody__OverviewCard__Top">
                <UserMemnuniyet />
                Sorgulama Puanı
              </div>
              <div className="ProfilBody__OverviewCard__bottom">
                {profile && profile.queries ? profile.queries : 0}
              </div>
            </div>
            <div className="ProfilBody__OverviewCard bgOverCard4">
              <div className="ProfilBody__OverviewCard__Top">
                <ProfilBodyZiyaret />
                Beğeni Puanı
              </div>
              <div className="ProfilBody__OverviewCard__bottom">
                {profile && profile.likes ? profile.likes : 0}
              </div>
            </div>
            <div className="ProfilBody__OverviewCard bgOverCard5">
              <div className="ProfilBody__OverviewCard__Top">
                <UserSirius />
                Reyting Puanı
              </div>
              <div className="ProfilBody__OverviewCard__bottom">
                {profile && profile.rating ? profile.rating : 0}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ProfilBody;
