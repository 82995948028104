import * as React from "react";
const UyeCikis = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M13.75 3.625A1.25 1.25 0 0 1 15 4.875v11.25a1.25 1.25 0 0 1-1.25 1.25h-5a1.25 1.25 0 0 1-1.25-1.25V13a.625.625 0 1 0-1.25 0v3.125a2.5 2.5 0 0 0 2.5 2.5h5a2.5 2.5 0 0 0 2.5-2.5V4.875a2.5 2.5 0 0 0-2.5-2.5h-5a2.5 2.5 0 0 0-2.5 2.5V8A.625.625 0 0 0 7.5 8V4.875a1.25 1.25 0 0 1 1.25-1.25h5ZM4.815 7.558a.625.625 0 0 0-.883 0l-2.5 2.5a.625.625 0 0 0 0 .884l2.5 2.5a.625.625 0 0 0 .883-.884l-1.433-1.433h9.116a.625.625 0 1 0 0-1.25H3.383l1.433-1.433a.625.625 0 0 0 0-.884Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UyeCikis;
