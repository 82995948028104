import axios from "axios";
import _ from "lodash";
//const api_root = 'https://min-api.cryptocompare.com'
const api_root = localStorage.getItem("urlApi");
const history = {};

export default {
  history: history,

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    // console.log("[getBars]: History", symbolInfo, resolution, from, to);

    // localStorage.setItem("index_name", symbolInfo.name);
    // console.log("symbolInfo", symbolInfo);
    //const url = resolution === '1D' ? '/data/histoday' :  resolution === '1W' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
    const url = "tw-own-data-parite.php";
    const qs = {
      exchange: symbolInfo.exchange,
      name: symbolInfo.name,
      resolution: resolution,
      from: from,
      toTs: to > 0 ? to : "",
      limit: 500,
    };
    // console.log({ qs });

    return axios({
      method: "post",
      url: `${api_root}${url}`,
      data: qs,
    }).then((data) => {
      let res = data.data.data;
      //console.log("dataData", res.Data.length, res.Data);

      let from = res.TimeFrom;
      let to = res.TimeTo;
      if (
        (res.Response && res.Response === "Error") ||
        res.Data.length === 0 ||
        !res.noData
      ) {
        // "noData" should be set if there is no data in the requested period
        onHistoryCallback([], { noData: true });
        return;
      }
      // console.log("=====data.Data", res.Data);
      if (res.Data.length) {
        // console.log(`Actually returned: ${res.Data.length} bars`);
        var bars = res.Data.map((el) => {
          // if (el.time >= from && el.time <= to) {
          return {
            time: el.time * 1000, //TradingView requires bar time in ms
            low: el.low,
            high: el.high,
            open: el.open,
            close: el.close,
            volume: el.volumefrom,
          };
          // }
        });
        if (firstDataRequest) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        } else {
          onHistoryCallback([], { noData: true });
          return;
        }
        let lastBars = _.uniqWith(bars, _.isEqual);
        // console.log("bars", lastBars);
        return lastBars;
      } else {
        onHistoryCallback([], { noData: true });
        return;
      }
    });
  },
};
