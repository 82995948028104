import * as React from "react";
const UserFollow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M10.88.95C7.39.95 4.534 3.81 4.534 7.3a6.336 6.336 0 0 0 2.7 5.176c-3.79 1.52-6.48 5.227-6.48 9.55-.03 1.364 2.031 1.364 2 0 0-4.59 3.702-8.288 8.292-8.288a8.24 8.24 0 0 1 4.355 1.24c1.138.698 2.185-1.009 1.047-1.707a10.277 10.277 0 0 0-1.824-.865 6.335 6.335 0 0 0 2.6-5.106c0-3.491-2.85-6.35-6.342-6.35Zm0 2.003A4.33 4.33 0 0 1 15.224 7.3c0 2.411-1.931 4.34-4.342 4.34A4.329 4.329 0 0 1 6.533 7.3a4.336 4.336 0 0 1 4.348-4.347Zm6.136 17.015v2c.029 1.304 1.97 1.304 2 0v-2h2.002c1.334 0 1.334-2.002 0-2.002h-2.002v-2a1 1 0 1 0-2 0v2h-2.048c-1.383.064-1.287 2.066.048 2.002h2Z"
    />
  </svg>
);
export default UserFollow;
