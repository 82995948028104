import React, { useState } from "react";
import "./PanoUser.css";
import { Row, Col, Modal, Container, Button, Dropdown } from "react-bootstrap";
import cup_1st from "../../../assets/img/1st_cup.png";
import cup_2nd from "../../../assets/img/2nd_cup.png";
import cup_3rd from "../../../assets/img/3rd_cup.png";
import regular_cup from "../../../assets/img/regular_cup.png";
import userFrame from "../../../assets/img/userFrama.svg";
import ArrowThinDown from "../../svgs/arrowThinDown";
import ArrowThinUp from "../../svgs/arrowThinUp";
import UserFollow from "../../svgs/userFollow";
import UserFollowing from "../../svgs/userFollowing";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { timeout } from "../../../utils/helperFunction";
import StarTop from "../../svgs/starTop";
import UserMemnuniyet from "../../svgs/userMemnuniyet";
import UserSirius from "../../svgs/userSirius";
import { Tooltip } from "react-tooltip";
import UserTakipci from "../../svgs/userTakipci";
import UserProfileZiyaret from "../../svgs/userProfilZiyaret";
import UserStarNull from "../../svgs/userStarNull";
import { PulseLoader } from "react-spinners";
import { AiFillDashboard, AiFillCarryOut, AiFillSignal } from "react-icons/ai";
import PanoNote from "../../svgs/panoNote";
import SonucTekEkle from "../../svgs/sonucTekEkle";
import { useDispatch, useSelector } from "react-redux";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import {
  PANOFORMULLER,
  SEARCHUSER,
  USERFOLLOW,
  USERPROFILE,
  USERUNFOLLOW,
  FORMULAFOLLOW,
  SORTUSERS,
  FORMULAUNFOLLOW,
} from "../../../utils/urls";
import { toast } from "react-toastify";
import {
  setFollowFormulas,
  setFollowUsers,
  setFormulas,
  setMyboard,
  setUsers,
} from "../../../store/reducers/panoSlice";
import { BsFilterLeft, BsHeartFill, BsSearch } from "react-icons/bs";
import SonucSecildi from "../../svgs/sonucSecildi";
import ArrowDown from "../../svgs/arrowDown";
import ArrowUp from "../../svgs/arrowUp";
import ArrowSort from "../../svgs/arrowSort";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SonucSec from "../../svgs/sonucSec";
import { FaFilter } from "react-icons/fa6";

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};

const Siralama = () => {
  return (
    <>
      <FaFilter />
      Sıralama
    </>
  );
};

function PanoUser() {
  const { users, users_follows, formulas_follows } = useSelector(
    (state) => state.pano
  );
  const { user } = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.theme.darkmode);
  const mobile = useSelector((state) => state.theme.mobile);
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [formulSiralama, setFormulSiralama] = useState(
    mobile ? <Siralama /> : "Takipçi Sayısına Göre"
  );
  const [formulSiralamaId, setFormulSiralamaId] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFormul, setSelectedFormul] = useState(null);
  const [kullanici, setKullanici] = useState("");
  const [aramaFormulSonuclar, setAramaFormulSonuclar] = useState([]);
  const [showKullanici, setShowKullanici] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isTakipci, setIsTakipci] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [search, setSearch] = useState("");
  const [visibleSearch, setVisibleSearch] = useState(false);
  const handleSearchClose = () => _setVisibleSearch(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const handleUserClose = () => _setVisibleUserClose(false);
  const [visibleTakipKullanici, setVisibleTakipKullanici] = useState(false);
  const handleTakipKullaniciClose = () => setVisibleTakipKullanici(false);
  const [visibleTakipCik, setVisibleTakipCik] = useState(false);
  const handleTakipCikClose = () => setVisibleTakipCik(false);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const handleFormulClose = () => _setVisibleFormulClose(false);
  const { height, width } = useWindowDimensions();

  const _setVisibleSearch = () => {
    setSearch("");
    setVisibleSearch(false);
    setAramaFormulSonuclar([]);
  };

  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const handleIndicatorName = (name) => {
    return _.filter(updateDataAll, { slug: name }).map((v) => v.label);
  };
  const lgWidth = width > 1200 ? "col-lg-3" : "col-lg-4";
  const item = width > 1200 ? 8 : 6;
  const _setVisibleFormulClose = () => {
    setVisibleFormul(false);
    setSelectedFormul(null);
  };
  const _setVisibleUserClose = () => {
    setVisibleUser(false);
    setIsTakipci(0);
  };
  const _functTakipEt = async (formul) => {
    setIsProcessing(true);
    setSelectedFormul(formul);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleFormul(false);
        setVisibleUser(false);
      } else {
        if (res.code == "-91") {
          toast.error(res.data);
          setKullanici(formul.owner);
          setIsProcessing(false);
          setVisibleTakipKullanici(true);
          setVisibleFormul(false);
          return;
        }
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const _functTakiptenCikar = async (formul) => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleFormul(false);
        setVisibleUser(false);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const _setVisibleUser = (username) => {
    console.log("user", username);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: username,
    };
    postDataWithHeaders(USERPROFILE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        setUserProfile(res.data);
        setSelectedUser(username);
        let takipte = _.filter(users_follows, {
          name: username,
        }).length;
        if (takipte > 0) {
          setIsTakipci(1);
        } else {
          setIsTakipci(0);
        }
        setVisibleUser(true);
      } else {
        toast.error(res.data);
      }
    });
  };
  const showMoreKullanici = () => {
    setShowKullanici(!showKullanici);
  };
  const getFormulasData = async () => {
    postDataWithHeaders(PANOFORMULLER, {
      access_token: user.access_token,
      // j1407b: 1,
    })
      .then((res) => {
        if (res.code == 0) {
          console.log("res.data.myboard panouser", res.data.myboard);
          dispatch(setFormulas(res.data.board));
          dispatch(setUsers(res.data.users));
          if (res.data.myboard.name) {
            dispatch(setMyboard(res.data.myboard));
          } else {
            dispatch(setMyboard([]));
          }
          dispatch(setFollowUsers(res.data.users_follows));
          dispatch(setFollowFormulas(res.data.formulas_follows));
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const unFollowUser = (username) => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: username,
    };
    postDataWithHeaders(USERUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleUser(false);
        setVisibleTakipCik(false);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  const _unFollowUser = (username) => {
    setKullanici(username);
    setVisibleTakipCik(true);
  };

  const followUser = (username) => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: username,
    };
    postDataWithHeaders(USERFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleUser(false);
        setFormulSiralama(mobile ? <Siralama /> : "Takipçi Sayısına Göre");
        setFormulSiralamaId(1);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const _setVisibleFormulPopup = (username) => {
    console.log("username", username);

    timeout(1000).then(() => {
      _setVisibleUser(username);
    });
  };
  const _searchFormul = (text) => {
    setAramaFormulSonuclar(null);
    setSearch(text);
    if (text.length < 3) {
      return;
    }
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      search: text,
    };
    postDataWithHeaders(SEARCHUSER, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        setAramaFormulSonuclar(res.data);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const _setVisibleFormul = (item) => {
    setSelectedFormul(item);
    if (isTakipci == 1) {
      setVisibleFormul(true);
    } else {
      toast.error("Kullanıcıyı takip etmeniz gerekiyor.");
    }
  };
  const takipKullaniciEt = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: kullanici ? kullanici : selectedUser,
    };
    postDataWithHeaders(USERFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        setVisibleTakipKullanici(false);
        _functTakipEt(selectedFormul);
      } else {
        toast.error(res.data);
      }
    });
  };

  const funcFormulSiralama = (id, text) => {
    dispatch(setUsers([]));
    setFormulSiralama(text);
    setFormulSiralamaId(id);
    console.log("funcFormulSiralama", id, text);
    postDataWithHeaders(
      SORTUSERS,
      {
        access_token: user.access_token,
        // j1407b: 1,
        sort: id,
      },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        dispatch(setUsers(res.data));
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  return (
    <Row>
      <Col lg={12} className="PanoUser">
        <div className="PanoUser__container">
          <div className="PanoFormul__content__header">
            <h3>Kullanıcılar</h3>
            <div className="PanoFormul__content__header__right">
              <Dropdown className="formulSiralama">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {formulSiralama}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(1, "Takipçi Sayısına Göre")
                    }
                  >
                    Takipçi Sayısına Göre
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(2, "Profil Ziyareti Sayısına Göre")
                    }
                  >
                    Profil Ziyareti Sayısına Göre
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(
                        3,
                        "Formüllerinin Toplam. Beğeni Puanına Göre"
                      )
                    }
                  >
                    Formüllerinin Toplam. Beğeni Puanına Göre
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(
                        4,
                        "Formüllerinin Toplam. Reyting Puanına Göre"
                      )
                    }
                  >
                    Formüllerinin Toplam. Reyting Puanına Göre{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(
                        5,
                        "Formüllerinin Toplam. Sorgulanma Puanına Göre"
                      )
                    }
                  >
                    Formüllerinin Toplam. Sorgulanma Puanına Göre{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div
                className="modalSearch__input ms-auto"
                onClick={() => setVisibleSearch(true)}
              >
                <BsSearch color={theme == "dark" ? "#fff" : "#000"} />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={""}
                  onChange={(e) => {}}
                />
              </div>
            </div>
          </div>
          <div className="PanoUser__content row">
            {users.length > 0 ? (
              users.map((panouser, index) => {
                {
                  /* console.log("users_follows", users_follows, panouser); */
                }
                let number = index + 1;
                let takipte = _.filter(users_follows, {
                  name: panouser.name,
                }).length;
                // Your code here
                let bgClass =
                  number == 1
                    ? "bg1"
                    : number == 2
                    ? "bg2"
                    : number == 3
                    ? "bg3"
                    : "bgregular";
                let dClass = number > item ? "d-none" : "dshow";
                return (
                  <div
                    key={index}
                    className={
                      showKullanici
                        ? `PanoUser__content__item ${lgWidth}`
                        : `PanoUser__content__item ${lgWidth} ${dClass}`
                    }
                  >
                    <div className="PanoUser__content__item__cup">
                      <img
                        src={
                          number == 1
                            ? cup_1st
                            : number == 2
                            ? cup_2nd
                            : number == 3
                            ? cup_3rd
                            : regular_cup
                        }
                      />
                    </div>
                    <div className={`PanoUser__content__item__body ${bgClass}`}>
                      <div className="PanoUser__content__item__body__text">
                        {number > 3 && (
                          <div className="PanoUser__content__item__body__left">
                            #{number}
                          </div>
                        )}
                        <div className="PanoUser__content__item__body__left_top">
                          <div
                            className="title cursorPointer"
                            onClick={() =>
                              user.username != panouser.name &&
                              _setVisibleUser(panouser.name)
                            }
                          >
                            {panouser.name}
                          </div>
                          <div className="whois">
                            {panouser.follower_count} Takipçi
                          </div>
                        </div>
                      </div>
                      {user.username != panouser.name && (
                        <div
                          className="PanoUser__content__item__body__right cursorPointer"
                          onClick={() =>
                            takipte == 0
                              ? followUser(panouser.name)
                              : _unFollowUser(panouser.name)
                          }
                        >
                          {takipte == 0 ? (
                            <UserFollow
                              color={
                                number > 3
                                  ? theme == "dark"
                                    ? "#fff"
                                    : "#1f1f21"
                                  : "#1f1f21"
                              }
                            />
                          ) : (
                            <UserFollowing
                              color={
                                number > 3
                                  ? theme == "dark"
                                    ? "#fff"
                                    : "#1f1f21"
                                  : "#1f1f21"
                              }
                            />
                          )}
                          {takipte == 0 ? "Takip Et" : "Takip Ediliyor"}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <SkeletonTheme
                className="PanoUser__content__skelaton"
                baseColor={theme == "dark" ? "#202020" : "#f0f0f0"}
                highlightColor={theme == "dark" ? "#444" : "#f4f4f4"}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                  <Skeleton key={i} className="PanoUser__content__item" />
                ))}
              </SkeletonTheme>
            )}
          </div>
          <div className="PanoUser__bottom">
            <div
              className="PanoUser__bottom__text cursorPointer"
              onClick={() => showMoreKullanici()}
            >
              {showKullanici ? "Daha az göster " : "Daha Fazla göster "}{" "}
              {showKullanici ? (
                <ArrowThinUp color={theme == "dark" ? "#fff" : "#1f1f21"} />
              ) : (
                <ArrowThinDown color={theme == "dark" ? "#fff" : "#1f1f21"} />
              )}
            </div>
          </div>
        </div>
      </Col>
      {visibleUser && (
        <Modal
          show={visibleUser}
          onHide={handleUserClose}
          dialogClassName="modal-863w modalWithNoPadding"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle userTitle"
                  >
                    Kişi Profili
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="UserProfile">
              <div className="UserProfile__top">
                <div className="UserProfile__top__left">
                  <div className="UserProfile__top__title">
                    <StarTop />
                    {selectedUser}
                  </div>
                </div>
                <div
                  className="UserProfile__top__right"
                  onClick={() =>
                    isTakipci == 0
                      ? followUser(selectedUser)
                      : _unFollowUser(selectedUser)
                  }
                >
                  {isTakipci == 0 ? (
                    <UserFollow color={"#fff"} />
                  ) : (
                    <UserFollowing color={"#fff"} />
                  )}
                  {isTakipci == 0 ? "Takip Et" : "Takip Ediliyor"}
                </div>
              </div>
              <div className="userFrame">
                <img src={userFrame} />
              </div>
              <div className="UserProfile__puans">
                <div className="UserProfile__OverviewCard bgOverCard1">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserProfileZiyaret />
                    Profil Ziyareti
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.visits ? userProfile.visits : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard2">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserTakipci />
                    Takipçi
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.followers ? userProfile.followers : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard3">
                  <div className="UserProfile__OverviewCard__Top">
                    <AiFillSignal />
                    Sorgulanma Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.queries ? userProfile.queries : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard4">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserMemnuniyet />
                    Beğeni Sayısı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.likes ? userProfile.likes : 0}
                  </div>
                </div>
                <div className="UserProfile__OverviewCard bgOverCard5">
                  <div className="UserProfile__OverviewCard__Top">
                    <UserSirius />
                    Reyting Puanı
                  </div>
                  <div className="UserProfile__OverviewCard__bottom">
                    {userProfile.rating ? userProfile.rating : 0}
                  </div>
                </div>
              </div>
              {userProfile.myboard.name != null && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Panoya Paylaştığı Formül
                    </div>
                  </div>
                  <div className="PanoTop__publish">
                    <div className="PanoTop__publish__left">
                      {userProfile.myboard.name}
                      <PanoNote
                        color="#fff"
                        data-tooltip-place="bottom-start"
                        data-tooltip-id="my-tooltip2"
                        data-tooltip-content={userProfile.myboard.note}
                      />
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Sorgulama Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillDashboard size={16} />{" "}
                          {userProfile.myboard.queries
                            ? userProfile.myboard.queries
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.queries_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Beğeni Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <BsHeartFill size={16} />{" "}
                          {userProfile.myboard.likes
                            ? userProfile.myboard.likes
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.likes_rank}
                        </span>
                      </div>
                    </div>
                    <div className="PanoTop__publish__center">
                      <span className="PanoTop__publish__center__text title">
                        Reyting Puanı
                      </span>
                      <div className="PanoTop__publish__center__points">
                        <span>
                          <AiFillSignal size={16} />{" "}
                          {userProfile.myboard.rating
                            ? userProfile.myboard.rating
                            : 0}
                        </span>
                        <span className="PanoTop__publish__center__text rank">
                          {userProfile.myboard.rating_rank}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userProfile.formulas.length > 0 && (
                <>
                  <div className="userFrame">
                    <img src={userFrame} />
                  </div>
                  <div className="UserProfile__bottom">
                    <div className="UserProfile__bottom__text">
                      Takipçileriyle Paylaştığı Formüller
                    </div>
                    {userProfile.formulas.map((item, index) => {
                      if (item.type == "board") {
                        return;
                      }
                      let takipte = _.filter(formulas_follows, {
                        id: item.id,
                      }).length;
                      return (
                        <div
                          key={`popup${index}`}
                          className="UserProfile__bottom__div"
                        >
                          <div className="UserProfile__bottom__div__left">
                            <div
                              className="UserProfile__bottom__div__title cursorPointer"
                              onClick={() => _setVisibleFormul(item)}
                            >
                              {item.name}
                            </div>
                            <div className="UserProfile__bottom__div__puan">
                              <span>
                                <AiFillDashboard size={16} />{" "}
                                {item.queries ? item.queries : 0}
                              </span>
                              <span>
                                <BsHeartFill size={16} />{" "}
                                {item.likes ? item.likes : 0}
                              </span>
                              <span>
                                <AiFillSignal size={16} />{" "}
                                {item.rating ? item.rating : 0}
                              </span>
                            </div>
                          </div>
                          <div className="UserProfile__bottom__div__right">
                            <PanoNote
                              color="#fff"
                              data-tooltip-place="bottom-start"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item.note}
                            />
                            {takipte == 0 ? (
                              <>
                                <SonucTekEkle
                                  onClick={() =>
                                    takipte == 0
                                      ? _functTakipEt(item)
                                      : _functTakiptenCikar(item)
                                  }
                                  color="#fff"
                                />
                              </>
                            ) : (
                              <>
                                <SonucSecildi
                                  onClick={() =>
                                    takipte == 0
                                      ? _functTakipEt(item)
                                      : _functTakiptenCikar(item)
                                  }
                                  color="#22DE2A"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleSearch && (
        <Modal
          show={visibleSearch}
          onHide={handleSearchClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Kullanıcı Arama
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSearch__input">
              <BsSearch color="#fff" />
              <input
                type="text"
                placeholder="Arama Yap"
                value={search}
                onChange={(e) => _searchFormul(e.target.value)}
              />
            </div>
            <div className="modalListeTabDiv__list">
              {aramaFormulSonuclar &&
                aramaFormulSonuclar.map((v, i) => (
                  <div
                    className="modalListeTabDiv__list__item"
                    key={`sonuc_${i}`}
                    onClick={() => _setVisibleFormulPopup(v.username)}
                  >
                    <div>{v.username}</div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleTakipKullanici && (
        <Modal
          show={visibleTakipKullanici}
          onHide={handleTakipKullaniciClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {kullanici ? kullanici : selectedUser} Takip Et
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              <Button
                onClick={() => takipKullaniciEt()}
                className="modalSonucButton"
              >
                {!isProcessing && <UserFollow color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Takip Ediliyor..." />
                ) : (
                  "Takip Et"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleTakipCik && (
        <Modal
          show={visibleTakipCik}
          onHide={handleTakipCikClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {kullanici ? kullanici : selectedUser} Takipten Çıkart
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              {kullanici} kullanıcısını takipten çıkartırsanız, {kullanici} ait
              kaydettiğiniz formüller, Özel Aramalarınızdan silinecektir.
              Onaylıyor musunuz ?
            </div>
            <div className="modalSelectButtons">
              <Button
                onClick={() => unFollowUser(kullanici)}
                className="modalSonucButton"
              >
                {!isProcessing && <UserFollow color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Takip Ediliyor..." />
                ) : (
                  "Takipten Çıkart"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {selectedFormul.name}
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(selectedFormul.formations).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Formasyonlar</h2>
                  <div className="modalSubDiv__footer__type">
                    {selectedFormul.additionals.bayz_just_closed == 1 ? (
                      <SonucSecildi
                        onClick={() => handleAyri(0, "Ayırılmış")}
                      />
                    ) : (
                      <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                    )}
                    Bayz Üstünde Kapatanlar
                  </div>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.formations).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleName(key)}
                      </span>
                      <span className="OzelArama_periods">
                        {selectedFormul.formations[key].periods.map(
                          (period) => (
                            <span
                              className="period"
                              key={`formul_${key}_${period}`}
                            >
                              {period}
                            </span>
                          )
                        )}{" "}
                      </span>
                      {selectedFormul.formations[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.formations[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
                <div className="modalSubDiv__footer__type">
                  {selectedFormul.additionals.seperate == 1 ? (
                    <SonucSecildi onClick={() => handleAyri(0, "Ayırılmış")} />
                  ) : (
                    <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                  )}
                  Ayrı Olarak Listele
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.volumes).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Hacim</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.volumes).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.volumes[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.volumes[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.indicators).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>İndikatörler</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.indicators).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.indicators[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.indicators[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.averages).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Ortalamalar</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.averages).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.averages[key].side == 0 ? (
                        <ArrowDown color="#E12222" />
                      ) : selectedFormul.averages[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowSort color="#9a9a9a" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedFormul.owner != user.username && (
              <Button
                onClick={() => _functTakipEt(selectedFormul)}
                className="modalSonucButton"
              >
                {!isProcessing && <SonucTekEkle color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Listeye Ekleniyor..." />
                ) : (
                  "Listeye Ekle"
                )}
              </Button>
            )}
          </Modal.Body>
        </Modal>
      )}
      <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "#35353A",
          color: "#fff",
          fontSize: 14,
          padding: 16,
          borderRadius: 8,
          maxWidth: 300,
          zIndex: 1056,
        }}
      />
    </Row>
  );
}

export default PanoUser;
