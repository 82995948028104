import React from "react";
import AnaKumanda from "../components/AnaKumanda/AnaKumanda";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/System/header";
import { ToastContainer } from "react-toastify";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";

function AnaKumandaScreen() {
  return (
    <React.Fragment>
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col lg={2}>
            <SideMenuPanel />
          </Col>
          <Col lg={10}>
            <div className="DashBoard__header">
              <Header />
            </div>
            <AnaKumanda />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default AnaKumandaScreen;
