import React from "react";
import EndeksFrekans from "../components/EndeksFrekans/EndeksFrekans";

function EndeksFrekansPanel() {
  return (
    <React.Fragment>
      <EndeksFrekans />
    </React.Fragment>
  );
}

export default EndeksFrekansPanel;
