import * as React from "react";
const SidemenuEndeks = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path d="M22.402 20.875H1.852v-9.793a.326.326 0 0 0 0-.638V5.469a.326.326 0 0 0 0-.638V.326a.326.326 0 1 0-.65 0v1.691H.825a.326.326 0 1 0 0 .652h.377v2.155H.825a.326.326 0 1 0 0 .651h.377v2.156H.825a.326.326 0 1 0 0 .65h.377v2.156H.825a.326.326 0 1 0 0 .652h.377v2.155H.825a.326.326 0 1 0 0 .651h.377v2.156H.825a.326.326 0 0 0 0 .65h.377v2.156H.825a.326.326 0 1 0 0 .652h.377V21.2c0 .18.145.326.325.326H3.22v.376a.326.326 0 0 0 .651 0v-.376h2.156v.376a.326.326 0 0 0 .65 0v-.376h2.156v.376a.326.326 0 0 0 .651 0v-.376h2.156v.376a.326.326 0 0 0 .651 0v-.376h2.155v.376a.326.326 0 1 0 .652 0v-.376h2.155v.376a.326.326 0 0 0 .651 0v-.376h2.156v.376a.326.326 0 1 0 .651 0v-.376h1.692a.326.326 0 1 0 0-.651ZM20.258 16.899a.326.326 0 0 0-.134.44c1.005 1.884 2.014 2.832 2.056 2.872a.324.324 0 0 0 .46-.017.326.326 0 0 0-.016-.46c-.01-.01-.976-.922-1.925-2.701a.326.326 0 0 0-.44-.134Z" />
    <path d="M3.314 19.742a.326.326 0 0 0 .46.46c.078-.076 1.905-1.927 3.533-5.624.5-1.135.866-2.35 1.18-3.49h.89a.326.326 0 0 0 0-.65h-.713l.054-.201c.084-.312.164-.607.245-.899a.326.326 0 1 0-.628-.174c-.081.294-.161.59-.245.904-.362 1.345-.772 2.87-1.38 4.247-1.574 3.577-3.378 5.409-3.396 5.427ZM17.619 16.515a.326.326 0 1 0-.566.321c1.255 2.211 2.354 3.32 2.4 3.367a.325.325 0 0 0 .46 0 .326.326 0 0 0 0-.46c-.01-.012-1.087-1.101-2.294-3.228Z" />
    <path d="M8.785 7.69a.326.326 0 0 0 .615.214c.6-1.725 1.24-2.429 2.214-2.429 1.615 0 2.18 2.102 2.895 4.762l.054.2h-.011a.326.326 0 1 0 0 .652h.188c.1.36.205.73.317 1.101-.186.478-.344 1.024-.508 1.59-.252.872-.512 1.773-.914 2.62-1.04 2.196-2.232 3.323-2.243 3.334a.326.326 0 0 0 .443.477c.052-.049 1.29-1.214 2.389-3.532.424-.895.704-1.863.95-2.717.076-.26.148-.505.22-.735.158.456.332.91.526 1.35.132.3.272.602.416.897a.326.326 0 0 0 .585-.285 24.85 24.85 0 0 1-.405-.874 19.897 19.897 0 0 1-.767-2.09c.312-.727.675-1.136 1.259-1.136.953 0 1.317 1.091 1.832 2.873.185.639.376 1.3.63 1.962a.325.325 0 1 0 .608-.233c-.244-.636-.431-1.284-.612-1.91-.248-.857-.482-1.666-.82-2.263a2.887 2.887 0 0 0-.294-.43h.512a.326.326 0 1 0 0-.65h-.518a.326.326 0 0 0-.325.325v.005a1.631 1.631 0 0 0-1.013-.33h-.559c-.148 0-.273.099-.313.235-.244.147-.46.366-.652.658a93.318 93.318 0 0 1-.346-1.263c-.52-1.935-.992-3.69-1.924-4.596a.325.325 0 0 0-.042-.648h-.518c-.105 0-.2.05-.259.129a2.317 2.317 0 0 0-.781-.129h-.857a.326.326 0 0 0-.325.322c-.827.505-1.296 1.533-1.647 2.545ZM20.244 5.475h.518a.326.326 0 1 0 0-.651h-.518a.326.326 0 1 0 0 .651ZM18.346 5.475h.518a.326.326 0 1 0 0-.651h-.518a.326.326 0 1 0 0 .651ZM16.45 5.475h.517a.326.326 0 1 0 0-.651h-.518a.326.326 0 1 0 0 .651ZM14.552 5.475h.517a.326.326 0 1 0 0-.651h-.517a.326.326 0 1 0 0 .651ZM8.86 5.475h.517a.326.326 0 1 0 0-.651H8.86a.326.326 0 1 0 0 .651ZM6.962 5.475h.518a.326.326 0 1 0 0-.651h-.518a.326.326 0 1 0 0 .651ZM5.065 5.475h.517a.326.326 0 1 0 0-.651h-.517a.326.326 0 1 0 0 .651ZM3.168 5.475h.517a.326.326 0 1 0 0-.651h-.517a.326.326 0 1 0 0 .651ZM22.141 5.475h.26a.326.326 0 1 0 0-.651h-.26a.326.326 0 1 0 0 .651ZM6.962 11.088h.518a.326.326 0 1 0 0-.65h-.518a.326.326 0 0 0 0 .65ZM3.168 11.088h.517a.326.326 0 1 0 0-.65h-.517a.326.326 0 0 0 0 .65ZM20.244 11.088h.518a.326.326 0 1 0 0-.65h-.518a.326.326 0 1 0 0 .65Z" />
    <path d="M13.172 11.088a.326.326 0 1 0 0-.65h-.518a.326.326 0 1 0 0 .65h.518ZM11.6 10.763a.326.326 0 0 0-.325-.326h-.518a.326.326 0 0 0 0 .652h.518c.18 0 .325-.146.325-.326ZM5.065 11.088h.517a.326.326 0 1 0 0-.65h-.517a.326.326 0 0 0 0 .65ZM22.402 10.437h-.26a.326.326 0 0 0 0 .652h.26a.326.326 0 1 0 0-.652Z" />
  </svg>
);
export default SidemenuEndeks;
