import * as React from "react";
const UserTakipci = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M16.114 11.257c.383 1.147 1.491 1.976 2.8 1.976 1.624 0 2.941-1.28 2.941-2.857 0-1.578-1.317-2.857-2.942-2.857-.658 0-1.266.21-1.757.566a4.167 4.167 0 0 1-1.042 3.172ZM6.67 13.233c1.31 0 2.42-.831 2.8-1.98a4.166 4.166 0 0 1-1.038-3.165 2.986 2.986 0 0 0-1.762-.569c-1.624 0-2.941 1.28-2.941 2.857 0 1.578 1.317 2.857 2.941 2.857Zm13.36.664h-1.737a5.087 5.087 0 0 1 1.232 3.324v.656c0 .42-.141.809-.38 1.123h3.69a.935.935 0 0 0 .947-.92v-.539c0-2.013-1.68-3.644-3.752-3.644ZM6.039 17.22c0-1.228.434-2.39 1.231-3.324H5.534c-2.073 0-3.753 1.631-3.753 3.644v.538c0 .509.425.921.948.921h3.69a1.848 1.848 0 0 1-.38-1.123v-.656Zm8.114-4.444H11.41c-2.527 0-4.576 1.99-4.576 4.444v.656c0 .62.518 1.123 1.156 1.123h9.585c.638 0 1.155-.503 1.155-1.123v-.656c0-2.454-2.048-4.444-4.576-4.444Zm-1.36-.81c1.982 0 3.588-1.56 3.588-3.483C16.381 6.56 14.775 5 12.794 5c-1.982 0-3.588 1.56-3.588 3.484s1.607 3.484 3.588 3.484Z"
    />
  </svg>
);
export default UserTakipci;
