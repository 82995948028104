import { createChart, ColorType, CrosshairMode } from "lightweight-charts";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import { dataHarmonics, dataAll } from "../../utils/dummyData";

const ChartComponent = (props) => {
  const {
    urlApi,
    id,
    load,
    access_token,
    colors: {
      backgroundColor = "transparent",
      lineColor = props.theme == "dark" ? "#334158" : "#d6dbe9",
      nameColor = props.theme == "dark"
        ? "rgba(256, 256, 256, 0.1)"
        : "rgba(0, 0, 0, 0.1)",
      textColor = props.theme == "dark" ? "white" : "black",
      areaTopColor = props.theme == "dark" ? "#cccccc" : "#2962FF",
      areaBottomColor = props.theme == "dark"
        ? "rgba(209, 142, 0, 0.28)"
        : "rgba(41, 98, 255, 0.28)",
    } = {},
  } = props;
  // console.log("props", props);
  //const [data, setData] = useState(null);
  //const [points, setPoints] = useState(null);
  const [chartName, setChartName] = useState(null);
  const [chartUpdate, setChartUpdate] = useState(null);
  //const [minDeger, setMinDeger] = useState(0);
  //const [maxDeger, setMaxDeger] = useState(0);
  const chartContainerRef = useRef();
  const { innerWidth: width, innerHeight: height } = window;

  // const container = document.getElementById('container');

  // function dateToString(date) {
  //   return `${date.year} - ${date.month} - ${date.day}`;
  // }

  // const toolTipWidth = 80;
  // const toolTipHeight = 80;
  // const toolTipMargin = 15;

  // const toolTip = document.createElement('div');
  // toolTip.style = `width: 96px; height: 80px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
  // toolTip.style.background = 'white';
  // toolTip.style.color = 'black';
  // toolTip.style.borderColor = '#2962FF';
  // //container.appendChild(toolTip);

  const handleName = (name) => {
    return _.filter(dataAll, { value: name }).map((v) => v.label);
  };
  async function _getData(id) {
    const postData = {
      id: id,
      access_token: access_token,
    };
    let res = await axios({
      method: "post",
      url:
        load == "harmonic"
          ? `${urlApi}load-harmonic-chart.php`
          : load == "formation"
          ? `${urlApi}load-formation-chart.php`
          : load == "fibonacci"
          ? `${urlApi}load-fibonacci-chart.php`
          : `${urlApi}load-bayz-chart.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // Don't forget to return something
    if (res.data.code === 0) {
      let maxValue = 0;
      let minValue = 0;
      const highs = [];
      const lows = [];
      const getSaveDates = res.data.data.data.map((item) => {
        const dopen = parseFloat(item.open);
        const dclose = parseFloat(item.close);
        const dhigh = parseFloat(item.high);
        const dlow = parseFloat(item.low);
        highs.push(dhigh);
        lows.push(dlow);
        return { ...item, open: dopen, high: dhigh, low: dlow, close: dclose };
      });
      maxValue = _.max(highs.slice(-100));
      minValue = _.min(lows.slice(-100));
      // console.log('data2 :',res.data.data.precision)
      //setData(getSaveDates);
      // setPoints(res.data.data.points);
      setChartName(`${res.data.data.chartName} (${res.data.data.period})`);
      setChartUpdate(`Son Güncellenme: ${res.data.data.updated}`);
      //setMaxDeger(maxValue);
      //setMinDeger(minValue);
      grafikOlustur(
        getSaveDates,
        res.data.data.period,
        res.data.data.side,
        res.data.data.points,
        res.data.data.name,
        maxValue,
        minValue,
        res.data.data.precision,
        props.theme,
        backgroundColor,
        nameColor,
        lineColor,
        textColor,
        areaTopColor,
        areaBottomColor
      );
    } else {
      //setData(null);
      toast.error(res.data.data);
      setInterval(() => {
        // window.location.href = "/uye-cikis";
      }, 2000);
    }
  }

  const grafikOlustur = (
    data,
    period,
    side,
    points,
    name,
    maxDeger,
    minDeger,
    precisionDeger,
    theme,
    backgroundColor,
    nameColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor
  ) => {
    const handleResize = () => {
      //chart.applyOptions({ width: container.clientWidth });
      chart.applyOptions({
        priceScale: {
          position: "right",
          autoScale: true,
          borderVisible: false,
          scaleMargins: { top: 0.1, bottom: 0 },
        },
      });
    };
    function getTimezone() {
      let current_date_time = new Date();
      let currentTimeZoneOffsetInHours =
        current_date_time.getTimezoneOffset() * 60;
      return currentTimeZoneOffsetInHours;
    }
    let chartName = handleName(name)[0];
    let chartWidth = parseInt(width * 0.75) - 40;
    let chartHight = parseInt(height * 0.8);
    let barSpace = 10;
    if (width < 1165) {
      barSpace = 5;
      chartWidth = parseInt(width * 0.95);
    }
    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartWidth,
      height: chartHight,
      localization: {
        timeFormatter: (businessDayOrTimestamp) => {
          let a = getTimezone();
          //console.log('offset',a)
          //console.log('businessDayOrTimestamp',businessDayOrTimestamp)
          const date = new Date((businessDayOrTimestamp + a) * 1000);
          if (period == "G" || period == "H") {
            return (
              date.getFullYear() +
              "-" +
              ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
              "-" +
              ((date.getDate() < 10 ? "0" : "") + date.getDate())
            );
          } else {
            return (
              date.getFullYear() +
              "-" +
              ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
              "-" +
              ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
              " " +
              date.getHours() +
              ":" +
              ((date.getMinutes() < 10 ? "0" : "") + date.getMinutes())
            );
          }
        },
      },
      watermark: {
        text: chartName,
        fontSize: 156,
        color: nameColor,
        visible: true,
      },
      leftPriceScale: {
        borderVisible: false,
        autoScale: true,
      },
      rightPriceScale: {
        borderVisible: false,
        autoScale: true,
      },
      grid: {
        vertLines: {
          color: lineColor,
        },
        horzLines: {
          color: lineColor,
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        minBarSpacing: 0.001,
        borderVisible: false,
        fixLeftEdge: true,
        fixRightEdge: false,
        rightOffset: 5,
      },
      priceScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        minBarSpacing: 0.001,
      },
      priceLineVisible: false,
    });
    // var ww = container.innerWidth;
    // var wh = container.innerHeight;
    // chart.resize(ww, wh);
    chart.applyOptions({
      layout: {
        fontFamily: "Mazzard",
        fontSize: 16,
      },
    });
    chart.timeScale().fitContent();

    // change the current barSpacing
    chart.timeScale().applyOptions({
      barSpacing: barSpace, // default is 6
    });

    // console.log(minDeger, maxDeger)
    const candleSeries = chart.addCandlestickSeries({
      autoscaleInfoProvider: () => ({
        priceRange: {
          minValue: minDeger * 0.95,
          maxValue: maxDeger * 1.05,
        },
      }),
      upColor: "rgb(35,210,185)",
      downColor: "rgb(245,80,95)",
      borderDownColor: "rgb(245,80,95)",
      borderUpColor: "rgb(35,210,185)",
      wickDownColor: "rgb(245,80,95)",
      wickUpColor: "rgb(35,210,185)",
      priceScaleId: "right",
      scaleMargins: { top: 0, bottom: 0, left: 0, right: 0 },
    });
    candleSeries.setData(data);
    // chart.priceScale('right').applyOptions({
    //     autoScale: true,
    //     minValue: 0,
    //     maxValue: 100,
    //   });
    candleSeries.priceScale().applyOptions({
      autoScale: true, // disables auto scaling based on visible content
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
    });
    let minMov = 1 / 10 ** precisionDeger;
    candleSeries.applyOptions({
      priceFormat: {
        autoScale: true,
        type: "price",
        precision: precisionDeger,
        minMove: minMov,
      },
    });
    var volumeSeries = chart.addHistogramSeries({
      color: "#26a69a",
      priceFormat: { type: "volume" },
      priceScaleId: "volume_scale",
    });
    let volumeData = [];
    data.map((item, indx) => {
      let volumeColor =
        item.open <= item.close
          ? "rgba(0, 150, 136, 0.5)"
          : "rgba(255,82,82, 0.5)";
      volumeData.push({
        time: item.time,
        open: item.open,
        close: item.close,
        value: parseInt(item.volume),
        color: volumeColor,
      });
    });
    // console.log(volumeData);

    volumeSeries.setData(volumeData);
    candleSeries.priceScale().applyOptions({
      scaleMargins: { top: 0.2, bottom: 0.2 },
    });
    volumeSeries.priceScale().applyOptions({
      scaleMargins: { top: 0.8, bottom: 0 },
    });
    // chart.subscribeCrosshairMove(param => {
    //   if (
    //     param.point === undefined ||
    //     !param.time ||
    //     param.point.x < 0 ||
    //     param.point.x > container.clientWidth ||
    //     param.point.y < 0 ||
    //     param.point.y > container.clientHeight
    //   ) {
    //     toolTip.style.display = 'none';
    //   } else {
    //     const dateStr = dateToString(param.time);
    //     toolTip.style.display = 'block';
    //     const price = param.seriesPrices.get(candleSeries);
    //     toolTip.innerHTML = `<div style="color: ${'#2962FF'}">Apple Inc.</div><div style="font-size: 24px; margin: 4px 0px; color: ${'black'}">
    //       ${Math.round(100 * price) / 100}
    //       </div><div style="color: ${'black'}">
    //       ${dateStr}
    //       </div>`;

    //     const coordinate = candleSeries.priceToCoordinate(price);
    //     let shiftedCoordinate = param.point.x - 50;
    //     if (coordinate === null) {
    //       return;
    //     }
    //     shiftedCoordinate = Math.max(
    //       0,
    //       Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
    //     );
    //     const coordinateY =
    //       coordinate - toolTipHeight - toolTipMargin > 0
    //         ? coordinate - toolTipHeight - toolTipMargin
    //         : Math.max(
    //           0,
    //           Math.min(
    //             container.clientHeight - toolTipHeight - toolTipMargin,
    //             coordinate + toolTipMargin
    //           )
    //         );
    //     toolTip.style.left = shiftedCoordinate + 'px';
    //     toolTip.style.top = coordinateY + 'px';
    //   }
    // });
    if (load == "harmonic") {
      const harmonicSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "#FF00FF",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      const harmonic2Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "#FF00FF",
        lineStyle: 3,
        lineWidth: 1,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      const harmonic3Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "#FF00FF",
        lineStyle: 3,
        lineWidth: 1,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      if (points.A.v[1]) {
        harmonicSeries.setData([
          {
            time: points.A.t,
            value: Number(points.A.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.B.t,
            value: Number(points.B.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.C.t,
            value: Number(points.C.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v[1].toFixed(precisionDeger)),
          },
        ]);
        harmonic2Series.setData([
          {
            time: points.A.t,
            value: Number(points.A.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.C.t,
            value: Number(points.C.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.A.t,
            value: Number(points.A.v[1].toFixed(precisionDeger)),
          },
        ]);
        harmonic3Series.setData([
          {
            time: points.B.t,
            value: Number(points.B.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v[1].toFixed(precisionDeger)),
          },
        ]);
      } else {
        harmonicSeries.setData([
          {
            time: points.A.t,
            value: Number(points.A.v.toFixed(precisionDeger)),
          },
          {
            time: points.B.t,
            value: Number(points.B.v.toFixed(precisionDeger)),
          },
          {
            time: points.C.t,
            value: Number(points.C.v.toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v.toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v.toFixed(precisionDeger)),
          },
        ]);
        harmonic2Series.setData([
          {
            time: points.A.t,
            value: Number(points.A.v.toFixed(precisionDeger)),
          },
          {
            time: points.C.t,
            value: Number(points.C.v.toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v.toFixed(precisionDeger)),
          },
          {
            time: points.A.t,
            value: Number(points.A.v.toFixed(precisionDeger)),
          },
        ]);
        harmonic3Series.setData([
          {
            time: points.B.t,
            value: Number(points.B.v.toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v.toFixed(precisionDeger)),
          },
        ]);
      }
      if (name == "abcd") {
        harmonicSeries.setData([
          {
            time: points.B.t,
            value: Number(points.B.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.C.t,
            value: Number(points.C.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v[1].toFixed(precisionDeger)),
          },
        ]);
        harmonic2Series.setData([
          {
            time: points.C.t,
            value: Number(points.C.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v[1].toFixed(precisionDeger)),
          },
        ]);
        harmonic3Series.setData([
          {
            time: points.B.t,
            value: Number(points.B.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v[1].toFixed(precisionDeger)),
          },
        ]);
      }
      if (name == "5o") {
        harmonic2Series.setData([
          {
            time: points.C.t,
            value: Number(points.C.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.E.t,
            value: Number(points.E.v[1].toFixed(precisionDeger)),
          },
        ]);
        harmonic3Series.setData([
          {
            time: points.B.t,
            value: Number(points.B.v[1].toFixed(precisionDeger)),
          },
          {
            time: points.D.t,
            value: Number(points.D.v[1].toFixed(precisionDeger)),
          },
        ]);
      }
      chart.applyOptions({
        watermark: { text: handleName(name)[0], fontSize: 100 },
      });
    }
    if (name == "BAYZ") {
      if (points.bayZ1) {
        const bayz1Series = chart.addLineSeries({
          color: theme == "dark" ? "yellow" : "#FF00FF",
          lineStyle: 0,
          lineWidth: 2,
          crosshairMarkerVisible: true,
          crosshairMarkerRadius: 6,
          lineType: 0,
          priceScaleId: "right",
          priceLineVisible: false,
        });
        bayz1Series.setData([
          {
            time: points.bayZ1.a.t,
            value: Number(points.bayZ1.a.v.toFixed(precisionDeger)),
          },
          {
            time: points.bayZ1.b.t,
            value: Number(points.bayZ1.b.v.toFixed(precisionDeger)),
          },
        ]);
      }
      if (points.bayZ2) {
        const bayz2Series = chart.addLineSeries({
          color: theme == "dark" ? "yellow" : "#FF00FF",
          lineStyle: 0,
          lineWidth: 2,
          crosshairMarkerVisible: true,
          crosshairMarkerRadius: 6,
          lineType: 0,
          priceScaleId: "right",
          priceLineVisible: false,
        });
        bayz2Series.setData([
          {
            time: points.bayZ2.a.t,
            value: Number(points.bayZ2.a.v.toFixed(precisionDeger)),
          },
          {
            time: points.bayZ2.b.t,
            value: Number(points.bayZ2.b.v.toFixed(precisionDeger)),
          },
        ]);
      }
      if (points.bayZ3) {
        const bayz3Series = chart.addLineSeries({
          color: theme == "dark" ? "yellow" : "#FF00FF",
          lineStyle: 0,
          lineWidth: 2,
          crosshairMarkerVisible: true,
          crosshairMarkerRadius: 6,
          lineType: 0,
          priceScaleId: "right",
          priceLineVisible: false,
        });
        bayz3Series.setData([
          {
            time: points.bayZ3.a.t,
            value: Number(points.bayZ3.a.v.toFixed(precisionDeger)),
          },
          {
            time: points.bayZ3.b.t,
            value: Number(points.bayZ3.b.v.toFixed(precisionDeger)),
          },
        ]);
      }
      const guideSeries = chart.addLineSeries({
        color: "green",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      guideSeries.setData([
        {
          time: points.guide.a.t,
          value: Number(points.guide.a.v.toFixed(precisionDeger)),
        },
        {
          time: points.guide.b.t,
          value: Number(points.guide.b.v.toFixed(precisionDeger)),
        },
      ]);
      const knifeSeries = chart.addLineSeries({
        color: "red",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      knifeSeries.setData([
        {
          time: points.knife.a.t,
          value: Number(points.knife.a.v.toFixed(precisionDeger)),
        },
        {
          time: points.knife.b.t,
          value: Number(points.knife.b.v.toFixed(precisionDeger)),
        },
      ]);
      const markers = [];
      if (points.zero1 && !points.zero2) {
        markers.push({
          time: points.zero1.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 1",
          fontSize: 20,
        });
      }
      if (points.zero2 && !points.zero3) {
        markers.push({
          time: points.zero2.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 2",
          fontSize: 20,
        });
        markers.push({
          time: points.zero1.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 1",
          fontSize: 20,
        });
      }

      if (points.zero3) {
        markers.push({
          time: points.zero3.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 3",
          fontSize: 20,
        });
        markers.push({
          time: points.zero2.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 2",
          fontSize: 20,
        });
        markers.push({
          time: points.zero1.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          shape: "circle",
          size: 1,
          text: "Zero 1",
          fontSize: 20,
        });
      }

      candleSeries.setMarkers(markers);
    }

    if (name == "GÖLGE") {
      const golge1Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      golge1Series.setData([
        { time: points.a.t, value: Number(points.a.v.toFixed(precisionDeger)) },
        { time: points.b.t, value: Number(points.b.v.toFixed(precisionDeger)) },
      ]);
      const golge2Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      golge2Series.setData([
        { time: points.c.t, value: Number(points.c.v.toFixed(precisionDeger)) },
        { time: points.d.t, value: Number(points.d.v.toFixed(precisionDeger)) },
      ]);
      const markers = [
        {
          time: points.b.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 2,
          text: `${points.text.a}`,
          fontSize: 30,
        },
        {
          time: points.d.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 2,
          text: `${points.text.b}`,
          fontSize: 30,
        },
      ];

      candleSeries.setMarkers(markers);
      // change the current barSpacing
      if (width < 1165) {
        chart.timeScale().applyOptions({
          barSpacing:
            points.text.a > 50 ? 8 : points.text.a < 30 ? 10 : barSpace, // default is 6
        });
      } else {
        chart.timeScale().applyOptions({
          barSpacing:
            points.text.a > 50 ? 8 : points.text.a < 30 ? 18 : barSpace, // default is 6
        });
      }
    }

    if (name == "ABC=DEF") {
      const def1Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      def1Series.setData([
        { time: points.a.t, value: Number(points.a.v.toFixed(precisionDeger)) },
        { time: points.c.t, value: Number(points.c.v.toFixed(precisionDeger)) },
      ]);

      const def2Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      def2Series.setData([
        { time: points.d.t, value: Number(points.d.v.toFixed(precisionDeger)) },
        { time: points.f.t, value: Number(points.f.v.toFixed(precisionDeger)) },
      ]);

      const markers = [
        {
          time: points.c.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: `${points.text.a}`,
          fontSize: 10,
        },
        {
          time: points.f.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: `${points.text.b}`,
          fontSize: 20,
        },
      ];

      candleSeries.setMarkers(markers);
      // change the current barSpacing
      if (width < 1165) {
        chart.timeScale().applyOptions({
          barSpacing:
            points.text.a > 50 ? 5 : points.text.a < 30 ? 8 : barSpace, // default is 6
        });
      } else {
        chart.timeScale().applyOptions({
          barSpacing:
            points.text.a > 50 ? 8 : points.text.a < 30 ? 18 : barSpace, // default is 6
        });
      }

      // const def3Series = chart.addLineSeries({ color: theme == 'dark' ?  'yellow' : 'blue', lineStyle: 0, lineWidth: 2, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // def3Series.setData([
      //   {  time: points.d.t, value:Number((points.d.v).toFixed(precisionDeger))},
      //   {  time: points.e.t, value:Number((points.e.v).toFixed(precisionDeger))},
      // ]);

      // const def4Series = chart.addLineSeries({ color: theme == 'dark' ?  'yellow' : 'blue', lineStyle: 0, lineWidth: 2, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // def4Series.setData([
      //   {  time: points.e.t, value:Number((points.e.v).toFixed(precisionDeger))},
      //   {  time: points.f.t, value:Number((points.f.v).toFixed(precisionDeger))},
      // ]);

      // const markers = [
      //   {
      //       time: points.a.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       shape: 'circle',
      //       size: 1,
      //       text: 'A',
      //       fontSize:20
      //   },
      //   {
      //       time: points.b.t,
      //       position: side == 'BULL' ? 'aboveBar' : 'belowBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       shape: 'circle',
      //       size: 1,
      //       text: 'B',
      //       fontSize:20
      //   },
      //   {
      //       time: points.c.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       shape: 'circle',
      //       size: 1,
      //       text: 'C=D',
      //       fontSize:20
      //   },
      //   {
      //       time: points.e.t,
      //       position: side == 'BULL' ? 'aboveBar' : 'belowBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       shape: 'circle',
      //       size: 1,
      //       text: 'E',
      //       fontSize:20
      //   },
      //   {
      //       time: points.f.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       shape: 'circle',
      //       size: 1,
      //       text: 'F',
      //       fontSize:20
      //   },
      // ];

      // candleSeries.setMarkers(markers);
    }

    if (name == "QM") {
      const qmSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      qmSeries.setData([
        { time: points.A.t, value: Number(points.A.v.toFixed(precisionDeger)) },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
      ]);
      let line886_diff =
        Number(points.C.v.toFixed(precisionDeger)) -
        Number(points.D.v.toFixed(precisionDeger));
      let line886 =
        Number(points.D.v.toFixed(precisionDeger)) + line886_diff * 0.886;
      if (side == "BULL") {
        let line886_diff =
          Number(points.D.v.toFixed(precisionDeger)) -
          Number(points.C.v.toFixed(precisionDeger));
        line886 =
          Number(points.D.v.toFixed(precisionDeger)) - line886_diff * 0.886;
      }
      const c886eries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
        title: "88.6",
      });
      c886eries.setData([
        { time: points.D.t, value: Number(line886.toFixed(precisionDeger)) },
        {
          time: data[data.length - 1].time,
          value: Number(line886.toFixed(precisionDeger)),
        },
      ]);
      const markers = [
        {
          time: points.A.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "1",
          fontSize: 10,
        },
        {
          time: points.B.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "2",
          fontSize: 20,
        },
        {
          time: points.C.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "3",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "4",
          fontSize: 20,
        },
        {
          time: points.E.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "5",
          fontSize: 20,
        },
      ];

      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "Wolfe") {
      const wolfeSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      wolfeSeries.setData([
        { time: points.A.t, value: Number(points.A.v.toFixed(precisionDeger)) },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
      ]);

      const markers = [
        {
          time: points.A.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "1",
          fontSize: 10,
        },
        {
          time: points.B.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "2",
          fontSize: 20,
        },
        {
          time: points.C.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "3",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "4",
          fontSize: 20,
        },
        {
          time: points.E.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "5",
          fontSize: 20,
        },
      ];

      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "26_18") {
      const c2618Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      c2618Series.setData([
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        {
          time: data[data.length - 1].time,
          value: Number(points.C.v.toFixed(precisionDeger)),
        },
      ]);

      const markers = [
        {
          time: points.B.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          shape: side == "BULL" ? "arrowUp" : "arrowDown",
          text: side == "BULL" ? "Dip 1" : "Tepe 1",
          fontSize: 20,
        },

        {
          time: points.D.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          shape: side == "BULL" ? "arrowUp" : "arrowDown",
          text: side == "BULL" ? "Dip 2" : "Tepe 2",
          fontSize: 20,
        },
      ];

      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "OBO") {
      const oboSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      oboSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);

      const markers = [
        {
          time: points.B.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "LS",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "HEAD",
          fontSize: 20,
        },
        {
          time: points.F.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "RS",
          fontSize: 20,
        },
      ];
      const neckSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      neckSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);
      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "TOBO") {
      const c2618Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      c2618Series.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);

      const markers = [
        {
          time: points.B.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "LS",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "HEAD",
          fontSize: 20,
        },
        {
          time: points.F.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "RS",
          fontSize: 20,
        },
      ];

      const neckSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      neckSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);
      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "OMO") {
      const oboSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      oboSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);

      const markers = [
        {
          time: points.B.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "LS",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "HEAD",
          fontSize: 20,
        },
        {
          time: points.F.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "RS",
          fontSize: 20,
        },
      ];
      const neckSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      neckSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);
      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "TOMO") {
      const c2618Series = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      c2618Series.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);

      const markers = [
        {
          time: points.B.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "LS",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "HEAD",
          fontSize: 20,
        },
        {
          time: points.F.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "RS",
          fontSize: 20,
        },
      ];

      const neckSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      neckSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);
      candleSeries.setMarkers(markers);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "FLAG") {
      const flagSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      flagSeries.setData([
        { time: points.A.t, value: Number(points.A.v.toFixed(precisionDeger)) },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        // {  time: points.C.t, value:Number((points.C.v).toFixed(precisionDeger))},
        // {  time: points.D.t, value:Number((points.D.v).toFixed(precisionDeger))},
        // {  time: points.E.t, value:Number((points.E.v).toFixed(precisionDeger))},
      ]);

      // const markers = [
      //   {
      //       time: points.B.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'LS',
      //       fontSize:20
      //   },
      //   {
      //       time: points.D.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'HEAD',
      //       fontSize:20
      //   },
      //   {
      //       time: points.F.t,
      //       position: side == 'BULL' ? 'belowBar' : 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'RS',
      //       fontSize:20
      //   },
      // ];
      // candleSeries.setMarkers(markers);

      const flagTopSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      flagTopSeries.setData([
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
        {
          time: points.P6.t,
          value: Number(points.P6.v.toFixed(precisionDeger)),
        },
        {
          time: points.P6.t,
          value: Number(points.P6.v.toFixed(precisionDeger)),
        },
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
      ]);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "PENNANT") {
      const flagSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      flagSeries.setData([
        { time: points.A.t, value: Number(points.A.v.toFixed(precisionDeger)) },
        { time: points.B.t, value: Number(points.B.v.toFixed(precisionDeger)) },
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        // { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
      ]);

      const markers = [
        {
          time: points.A.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "A",
          fontSize: 10,
        },
        {
          time: points.B.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "B",
          fontSize: 20,
        },
        {
          time: points.C.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "C",
          fontSize: 20,
        },
        {
          time: points.D.t,
          position: side == "BULL" ? "aboveBar" : "belowBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "D",
          fontSize: 20,
        },
        {
          time: points.E.t,
          position: side == "BULL" ? "belowBar" : "aboveBar",
          color: theme == "dark" ? "#f68410" : "#000",
          size: 1,
          text: "E",
          fontSize: 20,
        },
      ];

      //candleSeries.setMarkers(markers);

      const flagTopSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      flagTopSeries.setData([
        {
          time: points.P1.t,
          value: Number(points.P1.v.toFixed(precisionDeger)),
        },
        {
          time: points.P2.t,
          value: Number(points.P2.v.toFixed(precisionDeger)),
        },
      ]);

      const flagBottomSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "green",
        lineStyle: 3,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      flagBottomSeries.setData([
        {
          time: points.P6.t,
          value: Number(points.P6.v.toFixed(precisionDeger)),
        },
        {
          time: points.P7.t,
          value: Number(points.P7.v.toFixed(precisionDeger)),
        },
      ]);
      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "OLASIC") {
      const flagSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });
      // console.log(Number((points.E.v).toFixed(precisionDeger)))
      // console.log(Number((points.F.v).toFixed(precisionDeger)))
      flagSeries.setData([
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
      ]);

      const lineWidth = 2;
      const fibo1Line = {
        price: points.P2.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.786",
      };
      const fibo2Line = {
        price: points.P6.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 1, // LineStyle.Dotted
        axisLabelVisible: true,
        title: "Fibo 0.82",
      };
      const fibo3Line = {
        price: points.P7.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.886",
      };

      candleSeries.createPriceLine(fibo1Line);
      candleSeries.createPriceLine(fibo2Line);
      candleSeries.createPriceLine(fibo3Line);

      // const flag2Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag2Series.setData([
      //   {  time: points.E.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // ]);

      // const flag6Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag6Series.setData([
      //   {  time: points.E.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      // ]);

      // const flag7Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag7Series.setData([
      //   {  time: points.E.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      // ]);

      // flagSeries.createPriceLine({
      //   price: points.P6.v,
      //   color: 'rgba(53, 162, 74, 1)',
      //   lineWidth: 2,
      //   lineStyle: 0,
      //   title: 'FİBO 82',
      //   axisLabelVisible: true,
      // });

      // flagSeries.createPriceLine({
      //   price: points.P2.v,
      //   color: 'rgba(53, 162, 74, 1)',
      //   lineWidth: 2,
      //   lineStyle: 0,
      //   title: 'FİBO 78.6',
      //   axisLabelVisible: true,
      // });

      // flagSeries.createPriceLine({
      //   price: points.P7.v,
      //   color: 'rgba(53, 162, 74, 1)',
      //   lineWidth: 2,
      //   lineStyle: 0,
      //   title: 'FİBO 88.6',
      //   axisLabelVisible: true,
      // });

      // const flag2Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag2Series.setData([
      //   {  time: points.E.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // ]);
      // const markers = [
      //   {
      //       time: points.F.t,
      //       position: 'aboveBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'FİBO 78.6',
      //       fontSize:10
      //   }
      // ];
      // flag2Series.setMarkers(markers);

      // const flag6Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag6Series.setData([
      //   {  time: points.E.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      // ]);

      // const markers2 = [
      //   {
      //       time: points.F.t,
      //       position: 'inBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'FİBO 82',
      //       fontSize:10
      //   }
      // ];
      // flag6Series.setMarkers(markers2);

      // const flag7Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag7Series.setData([
      //   {  time: points.E.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      //   {  time: points.F.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      // ]);

      // const markers3 = [
      //   {
      //       time: points.F.t,
      //       position: 'belowBar',
      //       color: theme == 'dark' ?  '#f68410' : '#000',
      //       size: 1,
      //       text: 'FİBO 88.6',
      //       fontSize:10
      //   }
      // ];
      // flag7Series.setMarkers(markers3);

      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "YELPAZE") {
      const flagSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      flagSeries.setData([
        { time: points.C.t, value: Number(points.C.v.toFixed(precisionDeger)) },
        { time: points.D.t, value: Number(points.D.v.toFixed(precisionDeger)) },
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
      ]);

      // const flag1Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag1Series.setData([
      //   {  time: points.C.t, value:Number((points.P1.v).toFixed(precisionDeger))},
      //   {  time: points.P1.t, value:Number((points.P1.v).toFixed(precisionDeger))},
      // ]);

      // // const flag2Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // // flag2Series.setData([
      // //   {  time: points.C.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // //   {  time: points.P2.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // // ]);

      // const flag6Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag6Series.setData([
      //   {  time: points.C.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      //   {  time: points.P6.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      // ]);

      // const flag7Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag7Series.setData([
      //   {  time: points.C.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      //   {  time: points.P7.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      // ]);

      const lineWidth = 2;
      const fibo1Line = {
        price: points.P1.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.382",
      };
      const fibo2Line = {
        price: points.P6.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 1, // LineStyle.Dotted
        axisLabelVisible: true,
        title: "Fibo 0.618",
      };
      const fibo3Line = {
        price: points.P7.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.786",
      };

      candleSeries.createPriceLine(fibo1Line);
      candleSeries.createPriceLine(fibo2Line);
      candleSeries.createPriceLine(fibo3Line);

      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    if (name == "GUVENLI") {
      const flagSeries = chart.addLineSeries({
        color: theme == "dark" ? "yellow" : "blue",
        lineStyle: 0,
        lineWidth: 2,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 6,
        lineType: 0,
        priceScaleId: "right",
        priceLineVisible: false,
      });

      flagSeries.setData([
        // {  time: points.D.t, value:Number((points.D.v).toFixed(precisionDeger))},
        { time: points.E.t, value: Number(points.E.v.toFixed(precisionDeger)) },
        { time: points.F.t, value: Number(points.F.v.toFixed(precisionDeger)) },
      ]);

      // const flag1Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag1Series.setData([
      //   {  time: points.D.t, value:Number((points.P1.v).toFixed(precisionDeger))},
      //   {  time: points.P1.t, value:Number((points.P1.v).toFixed(precisionDeger))},
      // ]);

      // // const flag2Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // // flag2Series.setData([
      // //   {  time: points.C.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // //   {  time: points.E.t, value:Number((points.P2.v).toFixed(precisionDeger))},
      // // ]);

      // const flag6Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag6Series.setData([
      //   {  time: points.D.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      //   {  time: points.P6.t, value:Number((points.P6.v).toFixed(precisionDeger))},
      // ]);

      // const flag7Series = chart.addLineSeries({ color: theme == 'dark' ?  '#f68410' : 'green', lineStyle: 0, lineWidth: 1, crosshairMarkerVisible: true, crosshairMarkerRadius: 6, lineType: 0, priceScaleId: 'right', priceLineVisible: false });
      // flag7Series.setData([
      //   {  time: points.D.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      //   {  time: points.P7.t, value:Number((points.P7.v).toFixed(precisionDeger))},
      // ]);

      const lineWidth = 2;
      const fibo1Line = {
        price: points.P1.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.382",
      };

      const fibo3Line = {
        price: points.P7.v,
        color: "#f68410",
        lineWidth: lineWidth,
        lineStyle: 2, // LineStyle.Dashed
        axisLabelVisible: true,
        title: "Fibo 0.618",
      };

      candleSeries.createPriceLine(fibo1Line);
      candleSeries.createPriceLine(fibo3Line);

      // change the current barSpacing
      chart.timeScale().applyOptions({
        barSpacing: barSpace, // default is 6
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  };

  useEffect(() => {
    _getData(id);
  }, []);

  return (
    <>
      <ToastContainer />
      <div id="container" ref={chartContainerRef}>
        <div className="snapshot">
          <span>{chartName}</span>
          <br />
          <span>{chartUpdate}</span>
        </div>
      </div>
    </>
  );
};

const initialData1 = [
  { time: "2018-12-22", value: 32.51 },
  { time: "2018-12-23", value: 31.11 },
  { time: "2018-12-24", value: 27.02 },
  { time: "2018-12-25", value: 27.32 },
  { time: "2018-12-26", value: 25.17 },
  { time: "2018-12-27", value: 28.89 },
  { time: "2018-12-28", value: 25.46 },
  { time: "2018-12-29", value: 23.92 },
  { time: "2018-12-30", value: 22.68 },
  { time: "2018-12-31", value: 22.67 },
];

const initialData = [
  {
    time: 1674026100,
    open: 0.0067,
    high: 0.0067,
    low: 0.006673,
    close: 0.006673,
  },
  {
    time: 1674027000,
    open: 0.006672,
    high: 0.006679,
    low: 0.006658,
    close: 0.00666,
  },
  {
    time: 1674027900,
    open: 0.006662,
    high: 0.006662,
    low: 0.006652,
    close: 0.006652,
  },
  {
    time: 1674028800,
    open: 0.006659,
    high: 0.006663,
    low: 0.006647,
    close: 0.006658,
  },
  {
    time: 1674029700,
    open: 0.006651,
    high: 0.006651,
    low: 0.006596,
    close: 0.006613,
  },
  {
    time: 1674030600,
    open: 0.006603,
    high: 0.006628,
    low: 0.006602,
    close: 0.006625,
  },
  {
    time: 1674031500,
    open: 0.006616,
    high: 0.006616,
    low: 0.00657,
    close: 0.006602,
  },
  {
    time: 1674032400,
    open: 0.006602,
    high: 0.006632,
    low: 0.006574,
    close: 0.006632,
  },
  {
    time: 1674033300,
    open: 0.006627,
    high: 0.006641,
    low: 0.006616,
    close: 0.006636,
  },
  {
    time: 1674034200,
    open: 0.006637,
    high: 0.006651,
    low: 0.00663,
    close: 0.00663,
  },
  {
    time: 1674035100,
    open: 0.006629,
    high: 0.006649,
    low: 0.006628,
    close: 0.006646,
  },
  {
    time: 1674036000,
    open: 0.006646,
    high: 0.006665,
    low: 0.006634,
    close: 0.006662,
  },
  {
    time: 1674036900,
    open: 0.006666,
    high: 0.006675,
    low: 0.006653,
    close: 0.006675,
  },
  {
    time: 1674037800,
    open: 0.006673,
    high: 0.006679,
    low: 0.006658,
    close: 0.006672,
  },
  {
    time: 1674038700,
    open: 0.006679,
    high: 0.006686,
    low: 0.006646,
    close: 0.006653,
  },
  {
    time: 1674039600,
    open: 0.006654,
    high: 0.006686,
    low: 0.006647,
    close: 0.006671,
  },
  {
    time: 1674040500,
    open: 0.006671,
    high: 0.006688,
    low: 0.006668,
    close: 0.006671,
  },
  {
    time: 1674041400,
    open: 0.00667,
    high: 0.006685,
    low: 0.00667,
    close: 0.006682,
  },
  {
    time: 1674042300,
    open: 0.006672,
    high: 0.006672,
    low: 0.006665,
    close: 0.00667,
  },
  {
    time: 1674043200,
    open: 0.006672,
    high: 0.006674,
    low: 0.006666,
    close: 0.006672,
  },
  {
    time: 1674044100,
    open: 0.006665,
    high: 0.006679,
    low: 0.00666,
    close: 0.00667,
  },
  {
    time: 1674045000,
    open: 0.006679,
    high: 0.006684,
    low: 0.006666,
    close: 0.006684,
  },
  {
    time: 1674045900,
    open: 0.006682,
    high: 0.006706,
    low: 0.006676,
    close: 0.006706,
  },
  {
    time: 1674046800,
    open: 0.006706,
    high: 0.00671,
    low: 0.0067,
    close: 0.006707,
  },
  {
    time: 1674047700,
    open: 0.006701,
    high: 0.006701,
    low: 0.006683,
    close: 0.006683,
  },
  {
    time: 1674048600,
    open: 0.00668,
    high: 0.006721,
    low: 0.006679,
    close: 0.006716,
  },
  {
    time: 1674049500,
    open: 0.006712,
    high: 0.006775,
    low: 0.0067,
    close: 0.006752,
  },
  {
    time: 1674050400,
    open: 0.006759,
    high: 0.006759,
    low: 0.006707,
    close: 0.006715,
  },
  {
    time: 1674051300,
    open: 0.006712,
    high: 0.006728,
    low: 0.006705,
    close: 0.006721,
  },
  {
    time: 1674052200,
    open: 0.006715,
    high: 0.006756,
    low: 0.006715,
    close: 0.006742,
  },
  {
    time: 1674053100,
    open: 0.006743,
    high: 0.006743,
    low: 0.006735,
    close: 0.006742,
  },
  {
    time: 1674054000,
    open: 0.006735,
    high: 0.006756,
    low: 0.006721,
    close: 0.006756,
  },
  {
    time: 1674054900,
    open: 0.006752,
    high: 0.006755,
    low: 0.006735,
    close: 0.006748,
  },
  {
    time: 1674055800,
    open: 0.006745,
    high: 0.006745,
    low: 0.00665,
    close: 0.006658,
  },
  {
    time: 1674056700,
    open: 0.006661,
    high: 0.006661,
    low: 0.00644,
    close: 0.006547,
  },
  {
    time: 1674057600,
    open: 0.006559,
    high: 0.00656,
    low: 0.00645,
    close: 0.006456,
  },
  {
    time: 1674058500,
    open: 0.006462,
    high: 0.006516,
    low: 0.00636,
    close: 0.006379,
  },
  {
    time: 1674059400,
    open: 0.006388,
    high: 0.00643,
    low: 0.006317,
    close: 0.006354,
  },
  {
    time: 1674060300,
    open: 0.00636,
    high: 0.006399,
    low: 0.006324,
    close: 0.006374,
  },
  {
    time: 1674061200,
    open: 0.006372,
    high: 0.006588,
    low: 0.006372,
    close: 0.006581,
  },
  {
    time: 1674062100,
    open: 0.006567,
    high: 0.00658,
    low: 0.006473,
    close: 0.006525,
  },
  {
    time: 1674063000,
    open: 0.006533,
    high: 0.006546,
    low: 0.006491,
    close: 0.006518,
  },
  {
    time: 1674063900,
    open: 0.006525,
    high: 0.006546,
    low: 0.006517,
    close: 0.006517,
  },
  {
    time: 1674064800,
    open: 0.006515,
    high: 0.006543,
    low: 0.006515,
    close: 0.006534,
  },
  {
    time: 1674065700,
    open: 0.006546,
    high: 0.006666,
    low: 0.006546,
    close: 0.006624,
  },
  {
    time: 1674066600,
    open: 0.006637,
    high: 0.006644,
    low: 0.006571,
    close: 0.006611,
  },
  {
    time: 1674067500,
    open: 0.006608,
    high: 0.006608,
    low: 0.006574,
    close: 0.006591,
  },
  {
    time: 1674068400,
    open: 0.006601,
    high: 0.006602,
    low: 0.006555,
    close: 0.006595,
  },
  {
    time: 1674069300,
    open: 0.006589,
    high: 0.006589,
    low: 0.006541,
    close: 0.006565,
  },
  {
    time: 1674070200,
    open: 0.006569,
    high: 0.006583,
    low: 0.00653,
    close: 0.006537,
  },
  {
    time: 1674071100,
    open: 0.006539,
    high: 0.006539,
    low: 0.006509,
    close: 0.00653,
  },
  {
    time: 1674072000,
    open: 0.006537,
    high: 0.006543,
    low: 0.0065,
    close: 0.006532,
  },
  {
    time: 1674072900,
    open: 0.006525,
    high: 0.006525,
    low: 0.006492,
    close: 0.006503,
  },
  {
    time: 1674073800,
    open: 0.006498,
    high: 0.006498,
    low: 0.006433,
    close: 0.006438,
  },
  {
    time: 1674074700,
    open: 0.006438,
    high: 0.006464,
    low: 0.006431,
    close: 0.006458,
  },
  {
    time: 1674075600,
    open: 0.006455,
    high: 0.006485,
    low: 0.00645,
    close: 0.006478,
  },
  {
    time: 1674076500,
    open: 0.006479,
    high: 0.00648,
    low: 0.006413,
    close: 0.006425,
  },
  {
    time: 1674077400,
    open: 0.006414,
    high: 0.006416,
    low: 0.006391,
    close: 0.006416,
  },
  {
    time: 1674078300,
    open: 0.00642,
    high: 0.006438,
    low: 0.006408,
    close: 0.006408,
  },
  {
    time: 1674079200,
    open: 0.006402,
    high: 0.006411,
    low: 0.006346,
    close: 0.006346,
  },
  {
    time: 1674080100,
    open: 0.006346,
    high: 0.006396,
    low: 0.006345,
    close: 0.006385,
  },
  {
    time: 1674081000,
    open: 0.006385,
    high: 0.006385,
    low: 0.006371,
    close: 0.006373,
  },
  {
    time: 1674081900,
    open: 0.006377,
    high: 0.006402,
    low: 0.006363,
    close: 0.006402,
  },
  {
    time: 1674082800,
    open: 0.006399,
    high: 0.006432,
    low: 0.006396,
    close: 0.006425,
  },
  {
    time: 1674083700,
    open: 0.006426,
    high: 0.006438,
    low: 0.006402,
    close: 0.006402,
  },
  {
    time: 1674084600,
    open: 0.0064,
    high: 0.006405,
    low: 0.006357,
    close: 0.006379,
  },
  {
    time: 1674085500,
    open: 0.00638,
    high: 0.006409,
    low: 0.006344,
    close: 0.006378,
  },
  {
    time: 1674086400,
    open: 0.006374,
    high: 0.006378,
    low: 0.006331,
    close: 0.006351,
  },
  {
    time: 1674087300,
    open: 0.006356,
    high: 0.006386,
    low: 0.006327,
    close: 0.006337,
  },
  {
    time: 1674088200,
    open: 0.006342,
    high: 0.006402,
    low: 0.006335,
    close: 0.00639,
  },
  {
    time: 1674089100,
    open: 0.006393,
    high: 0.006396,
    low: 0.006347,
    close: 0.006347,
  },
  {
    time: 1674090000,
    open: 0.006347,
    high: 0.006378,
    low: 0.006341,
    close: 0.006378,
  },
  {
    time: 1674090900,
    open: 0.006374,
    high: 0.006402,
    low: 0.00637,
    close: 0.006402,
  },
  {
    time: 1674091800,
    open: 0.00639,
    high: 0.006399,
    low: 0.006383,
    close: 0.006399,
  },
  {
    time: 1674092700,
    open: 0.00639,
    high: 0.00639,
    low: 0.006359,
    close: 0.006364,
  },
  {
    time: 1674093600,
    open: 0.006366,
    high: 0.006372,
    low: 0.006342,
    close: 0.00636,
  },
  {
    time: 1674094500,
    open: 0.006366,
    high: 0.006373,
    low: 0.006366,
    close: 0.006368,
  },
  {
    time: 1674095400,
    open: 0.006373,
    high: 0.00639,
    low: 0.006366,
    close: 0.006372,
  },
  {
    time: 1674096300,
    open: 0.006378,
    high: 0.006379,
    low: 0.006362,
    close: 0.006367,
  },
  {
    time: 1674097200,
    open: 0.006372,
    high: 0.006384,
    low: 0.006365,
    close: 0.006384,
  },
  {
    time: 1674098100,
    open: 0.00639,
    high: 0.006412,
    low: 0.006388,
    close: 0.006407,
  },
  {
    time: 1674099000,
    open: 0.006402,
    high: 0.006402,
    low: 0.006379,
    close: 0.006393,
  },
  {
    time: 1674099900,
    open: 0.006389,
    high: 0.006395,
    low: 0.006381,
    close: 0.006389,
  },
  {
    time: 1674100800,
    open: 0.006394,
    high: 0.006441,
    low: 0.006383,
    close: 0.006432,
  },
  {
    time: 1674101700,
    open: 0.006433,
    high: 0.006433,
    low: 0.00642,
    close: 0.006426,
  },
  {
    time: 1674102600,
    open: 0.006426,
    high: 0.006447,
    low: 0.00642,
    close: 0.006444,
  },
  {
    time: 1674103500,
    open: 0.006444,
    high: 0.006446,
    low: 0.006432,
    close: 0.006441,
  },
  {
    time: 1674104400,
    open: 0.006444,
    high: 0.006471,
    low: 0.006444,
    close: 0.006462,
  },
  {
    time: 1674105300,
    open: 0.006462,
    high: 0.006481,
    low: 0.006453,
    close: 0.006471,
  },
  {
    time: 1674106200,
    open: 0.006473,
    high: 0.006474,
    low: 0.006444,
    close: 0.006448,
  },
  {
    time: 1674107100,
    open: 0.006446,
    high: 0.006458,
    low: 0.006445,
    close: 0.00645,
  },
  {
    time: 1674108000,
    open: 0.006456,
    high: 0.006486,
    low: 0.006456,
    close: 0.006462,
  },
  {
    time: 1674108900,
    open: 0.006472,
    high: 0.006473,
    low: 0.006438,
    close: 0.006443,
  },
  {
    time: 1674109800,
    open: 0.00645,
    high: 0.006467,
    low: 0.00645,
    close: 0.006465,
  },
  {
    time: 1674110700,
    open: 0.006468,
    high: 0.006482,
    low: 0.006462,
    close: 0.006468,
  },
  {
    time: 1674111600,
    open: 0.006474,
    high: 0.006482,
    low: 0.006456,
    close: 0.006456,
  },
  {
    time: 1674112500,
    open: 0.006456,
    high: 0.006468,
    low: 0.00645,
    close: 0.00646,
  },
  {
    time: 1674113400,
    open: 0.00646,
    high: 0.00646,
    low: 0.00645,
    close: 0.006457,
  },
  {
    time: 1674114300,
    open: 0.006456,
    high: 0.006466,
    low: 0.006448,
    close: 0.006457,
  },
  {
    time: 1674115200,
    open: 0.006459,
    high: 0.006474,
    low: 0.00645,
    close: 0.006462,
  },
  {
    time: 1674116100,
    open: 0.006468,
    high: 0.006474,
    low: 0.006449,
    close: 0.00645,
  },
  {
    time: 1674117000,
    open: 0.006444,
    high: 0.006456,
    low: 0.006434,
    close: 0.006434,
  },
  {
    time: 1674117900,
    open: 0.006432,
    high: 0.006432,
    low: 0.006387,
    close: 0.006396,
  },
  {
    time: 1674118800,
    open: 0.006399,
    high: 0.006419,
    low: 0.006398,
    close: 0.006415,
  },
  {
    time: 1674119700,
    open: 0.006419,
    high: 0.006421,
    low: 0.006402,
    close: 0.006406,
  },
  {
    time: 1674120600,
    open: 0.006396,
    high: 0.006406,
    low: 0.006372,
    close: 0.006406,
  },
  {
    time: 1674121500,
    open: 0.006408,
    high: 0.006423,
    low: 0.006408,
    close: 0.006409,
  },
  {
    time: 1674122400,
    open: 0.006408,
    high: 0.006414,
    low: 0.006406,
    close: 0.006414,
  },
  {
    time: 1674123300,
    open: 0.006414,
    high: 0.006414,
    low: 0.006414,
    close: 0.006414,
  },
];

export default ChartComponent;
