import React, { useEffect, useState } from "react";
import "./Destek.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { DESTEK, DESTEKCEVAP, DESTEKKAPAT } from "../../utils/urls";
import { Form, Button, Container, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import destekYou from "../../assets/img/destek/destek-you.png";
import destekAdmin from "../../assets/img/destek/destek-admin.png";
import DestekPanel from "./DestekPanel";
import { timeout } from "../../utils/helperFunction";
import { postDataWithHeaders } from "../../utils/apiUtils";
function DestekDetay() {
  const { destekId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [message, setMessage] = useState("");
  const [kapali, setKapali] = useState(0);

  const getDestekDetail = async (destekId) => {
    const postData = {
      access_token: user.access_token,
      ticket_id: destekId,
    };
    postDataWithHeaders(DESTEK, postData, dispatch).then((res) => {
      if (!res) {
        const { error } = res;
        setIsLoading(false);
        throw new Error(error);
      }

      if (res.code === 0) {
        console.log("res", res);
        setMessageData(res.data.messages);
        setKapali(res.data.status);
        setIsLoading(false);
      }
    });
  };
  async function sendMessage(event) {
    event.preventDefault();
    const postData = {
      message: message.trim(),
      ticket_id: destekId,
      access_token: user.access_token,
    };
    postDataWithHeaders(DESTEKCEVAP, postData, dispatch).then((res) => {
      if (!res) {
        const { error } = res;
        throw new Error(error);
      }
      if (res.code === 0) {
        toast.success(res.data.data);
        timeout(2000);
        setMessage("");
        getDestekDetail(destekId);
      } else {
        // console.log("error", res.data.data);
        toast.error(res.data.data);
      }
    });
  }

  const closeClick = async (e) => {
    e.preventDefault();
    const postData = {
      access_token: user.access_token,
      ticket_id: destekId,
    };
    postDataWithHeaders(DESTEKKAPAT, postData, dispatch).then((res) => {
      if (!res) {
        const { error } = res;
        throw new Error(error);
      }
      if (res.code === 0) {
        toast.success(res.data.data);
        timeout(2000);
        navigate("/destek");
      }
    });
  };

  useEffect(() => {
    getDestekDetail(destekId);
  }, []);

  useEffect(() => {
    document.title = "Destek Detay Sayfası - Sirius Algo";
  }, []);
  return (
    <Container>
      <ToastContainer />
      <Row>
        <div className="destek-page-header">
          <Link to="/destek">
            <h3 className="destek-page-return">
              <BsArrowLeft /> Destek Sayfasına Dön
            </h3>
          </Link>
        </div>
      </Row>
      <Row className="DestekDetay__chat">
        <div className="DestekDetay__chat-history">
          {messageData &&
            messageData.map((data, index) =>
              data.sender == "you" ? (
                <div key={index} className="DestekDetay__chat-you">
                  <div className="DestekDetay__chat-ikon">
                    <img src={destekYou} alt="avatar" />
                  </div>
                  <div className="DestekDetay__chat-message my-message">
                    {data.message}
                  </div>
                </div>
              ) : (
                <div key={index} className="DestekDetay__chat-admin">
                  <div className="DestekDetay__chat-message other-message float-right">
                    {data.message}
                  </div>
                  <div className="DestekDetay__chat-ikon text-right">
                    <img src={destekAdmin} alt="avatar" />
                  </div>
                </div>
              )
            )}
        </div>
        {kapali != 2 ? (
          <div className="chat-message clearfix">
            <Form autoComplete="off" className="DesteDetay__bilgiForm">
              <Form.Group className="mb-3 y100" controlId="formMesaj">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Mesajınız"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  onFocus={({ target }) => {
                    target.setAttribute("autocomplete", "off");
                  }}
                />
              </Form.Group>
              <Form.Group className="DestekDetay__chat-button y100">
                <Button
                  variant="warning"
                  type="button"
                  size="sm"
                  onClick={(e) => closeClick(e)}
                >
                  Talebi Kapat
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  size="sm"
                  className="float-right"
                  onClick={(e) => sendMessage(e)}
                >
                  Gönder
                </Button>
              </Form.Group>
            </Form>
          </div>
        ) : (
          <div className="chat-message clearfix">Talebiniz Çözülmüştür.</div>
        )}
      </Row>
    </Container>
  );
}

export default DestekDetay;
