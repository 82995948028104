import React, { useState } from "react";
import historyProvider from "./historyProvider.js";
import axios from "axios";
import { makeApiRequest, generateSymbol, parseFullSymbol } from "./helper.js";

const supportedResolutions = ["15", "60", "240", "D"];
const supported_resolutions = ["15", "60", "240", "1D", "1W"];

const config = {
  supported_resolutions: supported_resolutions,
  exchanges: [
    { value: "", name: "Hepsi", desc: "Hepsi" },
    { value: "BIST", name: "BIST", desc: "BORSA ISTANBUL" },
    { value: "BINANCE", name: "BINANCE", desc: "BINANCE SPOT" },
    { value: "FUTURES", name: "BINANCE FUTURES", desc: "BINANCE FUTURES" },
    { value: "ABD", name: "ABD BORSALARI", desc: "ABD BORSALARI" },
    { value: "INDEX", name: "ENDEKSLER", desc: "ENDEKSLER" },
    { value: "FOREX", name: "FOREX", desc: "FOREX" },
  ],
  // symbols_types: [
  //     { name: "Crypto", value: "crypto" },
  //     { name: "Stock", value: "stock" },
  //     { name: "Index", value: "index" }
  // ],
};

async function getAllSymbols() {
  const access_token = localStorage.getItem("access_token");
  const urlApi = localStorage.getItem("urlApi");
  //const data = await makeApiRequest('data/v3/all/exchanges');
  //const data = await makeApiRequest('tw-own-symbols.php');
  const postData = {
    access_token: access_token,
  };
  let res = await axios({
    method: "post",
    url: `${urlApi}/tw-own-symbols.php`,
    data: postData,
  });
  if (res.status === 200) {
    console.log("status", res.status);
  }
  const data = res.data;

  let allSymbols = [];
  //for (const exchange of config.exchanges) {
  //console.log('exchange',exchange.value)
  const pairs = data.Data.pairs;
  //const typeSymbol = data.Data[exchange.value].type;
  //console.log('pairs',pairs)
  //pairs.map( pair => {
  //console.log('pair',pair)
  const symbols = pairs.map((pair) => {
    const symbol = generateSymbol(
      pair.exchange,
      pair.symbol,
      pair.currency_code
    );
    return {
      symbol: symbol.short,
      full_name: symbol.full,
      description: pair.description,
      exchange: pair.exchange,
      decimal_places: pair.decimal_places,
      //type: typeSymbol,
    };
  });
  allSymbols = [...allSymbols, ...symbols];
  //})
  //}
  return allSymbols;
}

export default {
  onReady: (cb) => {
    console.log("=====onReady running cb");
    setTimeout(() => cb(config), 0);
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    console.log("====Search Symbols running", userInput);
    //console.log('[searchSymbols]: Method call');

    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    console.log("newSymbols", newSymbols);
    onResultReadyCallback(newSymbols);
  },
  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    const symbols = await getAllSymbols();
    let symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
    if (!symbolItem) {
      symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
    }
    console.log("[resolveSymbol]: Method call", symbolItem, symbolName);
    if (!symbolItem) {
      // console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("Cannot resolve symbol");
      return;
    }
    // Symbol information object
    // const symbolInfo = {
    //   ticker: symbolItem.full_name,
    //   name: symbolItem.symbol,
    //   description: symbolItem.description,
    //   type: symbolItem.type,
    //   session: "24x7",
    //   timezone: "Etc/UTC",
    //   exchange: symbolItem.exchange,
    //   minmov: 1,
    //   pricescale: 100,
    //   has_intraday: true,
    //   visible_plots_set: "ohlc",
    //   has_weekly_and_monthly: true,
    //   supported_resolutions: supported_resolutions,
    //   volume_precision: 2,
    //   data_status: "streaming",
    // };
    // console.log(1 / 10 ** (symbolItem.decimal_places + 1));
    // console.log(10 ** symbolItem.decimal_places);
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Europe/Istanbul",
      exchange: symbolItem.exchange,
      minmov: 1 / 10 ** symbolItem.decimal_places,
      pricescale:
        symbolItem.exchange == "FOREX"
          ? null
          : 10 ** (symbolItem.decimal_places + 1),
      minmove2: 1 / 10 ** (symbolItem.decimal_places + 2),
      volume_precision: 2,
      priceFormat: {
        type: "price",
        precision: symbolItem.decimal_places ? symbolItem.decimal_places : 2,
        minMove: 1 / 10 ** symbolItem.decimal_places,
      },
      has_intraday: true,
      visible_plots_set: "ohlc",
      has_weekly_and_monthly: true,
      supported_resolutions: supported_resolutions,
      volume_precision: 2,
    };
    // console.log("[resolveSymbol]: Symbol resolved", symbolInfo);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    console.log("countBack ", periodParams);
    // console.log("[getBars]: index", symbolInfo, resolution, from, to);
    // console.log("=====getBars running", symbolInfo);
    // console.log("=====getBars running", resolution);
    // console.log("=====getBars running", periodParams);
    // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)

    try {
      historyProvider
        .getBars(
          symbolInfo,
          resolution,
          periodParams,
          onHistoryCallback,
          onErrorCallback
        )
        .then((bars) => {
          // console.log("=====Bars", bars, bars.length);
          if (bars.length > 0) {
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback(bars, { noData: true });
          }
        })
        .catch((err) => {
          console.log("[getBars]: Get err", { err });
          onErrorCallback(err);
        });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    //console.log('=====subscribeBars runnning')
  },
  unsubscribeBars: (subscriberUID) => {
    //console.log('=====unsubscribeBars running')
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    //console.log('=====calculateHistoryDepth running')
    // while optional, this makes sure we request 24 hours of minute data at a time
    // Sirius Algo's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : undefined;
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    //console.log('=====getMarks running')
  },
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {
    //optional
    //console.log('=====getTimeScaleMarks running')
  },
  getServerTime: (cb) => {
    //console.log('=====getServerTime running')
  },
};
