import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./ResetPassword.css";
import logo from "../../../assets/img/sirius-logo.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsEnvelope, BsEye, BsEyeSlash, BsKey } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { authLogin, authLogout } from "../../../store/reducers/authSlice";
import axios from "axios";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";
function uyeParolaSifirlama(props) {
  const navigate = useNavigate();
  const { code } = useParams();
  const urlApi = localStorage.getItem("urlApi");
  const [showPassword, setShowPassword] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const showPasswordHandler = (value) => {
    setShowPassword(value);
  };
  const getParolaSifirlama = async (event) => {
    setIsLoading(true);

    event.preventDefault();
    if (password1 !== password2) {
      toast.error("Sifrenizler birbiri ile aynı olmalıdır.");
      setInterval(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    const token = await props.googleReCaptchaProps.executeRecaptcha("sendUser");
    // console.log('sendUser:',token)
    const postData = {
      recaptcha: token,
      code: code,
      password1: password1,
      password2: password2,
      platform: "web",
      // // j1407b: 1,
    };
    let res = await axios({
      method: "post",
      url: `${urlApi}password-reset.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // console.log("userInfo", res.data);
    // Don't forget to return something
    if (res.data.code === 0) {
      toast.success("Şifreniz başarıyla değiştirilmiştir.");
      //console.log(res.data.data);
      navigate("/uye-giris");
    } else {
      toast.error(res.data.data);
      setInterval(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  React.useEffect(() => {
    document.title = "Üye Parola Sıfırlama Onaylama - Sirius Algo";
  }, []);

  return (
    <div className="resetPage">
      <ToastContainer />
      <div className="resetPage__container">
        <div className="resetPage__body">
          <div className="resetPage__logo">
            <img style={{ width: 194, height: 68 }} src={logo} />
          </div>
          <div className="resetPage__form">
            <h2 className="resetPage__form_title">Şifre Sıfırlama</h2>

            <div className="resetPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword">
                Yeni Şifre*
              </Form.Label>
              <InputGroup className="input-form-control mb-3">
                <BsKey color="#6c757d" />
                <Form.Control
                  type={showPassword == 1 ? "text" : "password"}
                  name="password1"
                  placeholder="Yeni Şifreniz"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifreyi giriniz!",
                    },
                  ]}
                />
                {showPassword == 1 ? (
                  <BsEyeSlash
                    color="#6c757d"
                    onClick={() => showPasswordHandler(0)}
                  />
                ) : (
                  <BsEye
                    color="#6c757d"
                    onClick={() => showPasswordHandler(1)}
                  />
                )}
              </InputGroup>
            </div>
            <div className="resetPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword2">
                Yeni Şifre Tekrarı*
              </Form.Label>
              <InputGroup className="input-form-control mb-3">
                <BsKey color="#6c757d" />
                <Form.Control
                  type={showPassword == 2 ? "text" : "password"}
                  name="password2"
                  placeholder="Yeni Şifreniz Tekrarı"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifre Tekrarını giriniz!",
                    },
                  ]}
                />
                {showPassword == 2 ? (
                  <BsEyeSlash
                    color="#6c757d"
                    onClick={() => showPasswordHandler(0)}
                  />
                ) : (
                  <BsEye
                    color="#6c757d"
                    onClick={() => showPasswordHandler(2)}
                  />
                )}
              </InputGroup>
            </div>

            <div className="resetPage_form_body">
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={getParolaSifirlama}
              >
                {isLoading ? "Lütfen bekleyiz..." : "Şifre Sıfırla!"}
              </Button>
            </div>
          </div>
        </div>
        <div className="resetPage_form_body">
          <Link to="/uye-giris" className="btn btn-outline-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7115 6.22332C14.4138 5.92556 13.931 5.92556 13.6333 6.22333L7.89838 11.9582C7.6006 12.256 7.6006 12.7387 7.89838 13.0365L13.6333 18.7714C13.931 19.0691 14.4138 19.0691 14.7116 18.7714L14.9768 18.5062C15.2745 18.2084 15.2745 17.7256 14.9768 17.4279L10.0462 12.4973L14.9768 7.56684C15.2745 7.26906 15.2745 6.78627 14.9767 6.48849L14.7115 6.22332ZM14.9951 7.10256C14.9793 7.22064 14.9261 7.33467 14.8353 7.42542L9.7634 12.4973L9.76338 12.4973L14.8353 7.42537C14.9261 7.33464 14.9793 7.22063 14.9951 7.10256ZM7.87505 12.4973C7.87505 12.6413 7.92997 12.7852 8.03981 12.8951L13.7747 18.6299C13.9944 18.8496 14.3505 18.8496 14.5701 18.6299L14.8353 18.3647C14.9148 18.2853 14.9655 18.188 14.9875 18.0858C14.9655 18.188 14.9147 18.2853 14.8353 18.3647L14.5701 18.6299C14.3505 18.8496 13.9944 18.8496 13.7747 18.6299L8.03981 12.895C7.92998 12.7852 7.87506 12.6413 7.87505 12.4973Z"
                fill="#3AB22F"
              />
            </svg>
            Üye Girişi{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
const WrappedSifirlama = withGoogleReCaptcha(uyeParolaSifirlama);

function ResetPassword(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedSifirlama url={props.url} />
    </GoogleReCaptchaProvider>
  );
}
export default ResetPassword;
