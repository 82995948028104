import React, { useEffect, useState } from "react";
import "./HomeVideo.css";
import { Container, Row, Col } from "react-bootstrap";
import icon_pano from "../../../assets/img/icon/pano.png";
import icon_genel_arama from "../../../assets/img/icon/tanitim_ve_kullanim.png";
import icon_ozel_arama from "../../../assets/img/icon/ozel_arama.png";
import icon_alarmlar from "../../../assets/img/icon/alarm.png";
import icon_egitim from "../../../assets/img/icon/egitim_odasi.png";
import icon_listelerim from "../../../assets/img/icon/listelerim.png";
import { VIDEOS } from "../../../utils/urls";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import ModalVideo from "react-modal-video";
import videoIcon from "../../../assets/img/videoIcon.png";
function HomeVideo() {
  const [modalVideo, setModalVideo] = useState(false);
  const handleVideoClose = () => setModalVideo(false);
  const [videos, setVideos] = useState(null);
  const [videos2, setVideos2] = useState(null);
  const [video, setVideo] = useState(null);

  function funcModalVideo(videoId) {
    setVideo(videoId);
    setModalVideo(true);
  }
  const getVideos = () => {
    postDataWithHeaders(VIDEOS).then((res) => {
      setVideos(res);
      setVideos2(res);
    });
  };

  useEffect(() => {
    getVideos();
  }, []);
  return (
    <Container className="HomeVideo">
      <Col id="video" xs lg={12} className="HomeVideo__title text-center">
        Eğitim Odası’nda Neler Var!
      </Col>
      <Row className="HomeVideo__row">
        {videos && (
          <div className="EgitimOdasi__body__item__body">
            {videos.map((video, i) => {
              let thumbnailUrl = `https://img.youtube.com/vi/${video.link}/hqdefault.jpg`;
              return (
                <div key={i} className="EgitimOdasi__body__item__body__video">
                  <div
                    className="EgitimOdasi__body__item__body__video__img"
                    onClick={() => funcModalVideo(video.link)}
                  >
                    <img src={thumbnailUrl} className="video" alt="" />
                    <img src={videoIcon} className="videoIcon" alt="" />
                  </div>
                  <div className="EgitimOdasi__body__item__body__video__info">
                    <h2 onClick={() => funcModalVideo("cMBvscfhkMs")}>
                      {video.title}
                    </h2>
                    <p>{video.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Row>
      {modalVideo && (
        <>
          <button className="modal-video-close-btn"></button>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={modalVideo}
            videoId={video}
            onClose={() => setModalVideo(false)}
          />
        </>
      )}
    </Container>
  );
}

export default HomeVideo;
