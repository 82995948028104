import React, { useEffect } from "react";
import "../assets/css/error.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function ErrorPage() {
  useEffect(() => {
    document.title = "Sayfa Bulunamadı - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <Row>
        <ToastContainer />
        <Col lg={12}>
          <div className="ErrorPage">
            <div className="ErrorPage__text">
              <div className="ErrorPage__text__title">Üzgünüz!</div>
              <div className="ErrorPage__text__subtitle">Sayfa bulunamadı.</div>
              <Link className="ErrorPage__text__return" to="/">
                Anasayfaya Dön
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ErrorPage;
