import React, { useEffect, useState } from "react";
import "./PanoFormul.css";
import { Row, Col, Modal, Container, Button, Dropdown } from "react-bootstrap";
import cup_1st from "../../../assets/img/1st_cup.png";
import cup_2nd from "../../../assets/img/2nd_cup.png";
import cup_3rd from "../../../assets/img/3rd_cup.png";
import regular_cup from "../../../assets/img/regular_cup.png";
import { AiFillDashboard, AiFillCarryOut, AiFillSignal } from "react-icons/ai";

import PanoNote from "../../svgs/panoNote";
import SonucTekEkle from "../../svgs/sonucTekEkle";
import ArrowThinDown from "../../svgs/arrowThinDown";
import ArrowThinUp from "../../svgs/arrowThinUp";
import { Tooltip } from "react-tooltip";
import SonucSecildi from "../../svgs/sonucSecildi";
import ArrowUp from "../../svgs/arrowUp";
import ArrowDown from "../../svgs/arrowDown";
import { PulseLoader } from "react-spinners";
import { timeout } from "../../../utils/helperFunction";
import { useDispatch, useSelector } from "react-redux";
import { BsFilterLeft, BsHeartFill, BsSearch } from "react-icons/bs";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { toast, ToastContainer } from "react-toastify";
import {
  FORMULAFOLLOW,
  FORMULAUNFOLLOW,
  PANOFORMULLER,
  SEARCHFORMUL,
  SORTFORMULAS,
  USERFOLLOW,
} from "../../../utils/urls";
import {
  setFollowFormulas,
  setFollowUsers,
  setFormulas,
  setMyboard,
  setUsers,
} from "../../../store/reducers/panoSlice";
import UserFollow from "../../svgs/userFollow";
import ArrowSort from "../../svgs/arrowSort";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SonucSec from "../../svgs/sonucSec";
import { FaFilter } from "react-icons/fa6";

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
const Siralama = () => {
  return (
    <>
      <FaFilter />
      Sıralama
    </>
  );
};
function PanoFormul() {
  const urlApi = localStorage.getItem("urlApi");
  const theme = useSelector((state) => state.theme.darkmode);
  const mobile = useSelector((state) => state.theme.mobile);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { formulas, formulas_follows } = useSelector((state) => state.pano);
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFormul, setSelectedFormul] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [formulSiralama, setFormulSiralama] = useState(
    mobile ? <Siralama /> : "Beğeni Puanına Göre"
  );
  const [formulSiralamaId, setFormulSiralamaId] = useState(1);
  const [kullanici, setKullanici] = useState("");
  const [aramaFormulSonuclar, setAramaFormulSonuclar] = useState([]);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const handleFormulClose = () => _setVisibleFormulClose(false);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const handleSearchClose = () => _setVisibleSearch();
  const [visibleTakipKullanici, setVisibleTakipKullanici] = useState(false);
  const handleTakipKullaniciClose = () => _setVisibleTakipKullanici();

  const _setVisibleTakipKullanici = () => {
    setVisibleTakipKullanici(false);
    setVisibleFormul(true);
  };
  const _setVisibleSearch = () => {
    setSearch("");
    setVisibleSearch(false);
    setAramaFormulSonuclar([]);
  };
  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const handleIndicatorName = (name) => {
    return _.filter(updateDataAll, { slug: name }).map((v) => v.label);
  };
  const _setVisibleFormulClose = () => {
    setVisibleFormul(false);
    setSelectedFormul(null);
  };
  const showMoreFormul = () => {
    setShow(!show);
  };
  const _setVisibleFormul = (item) => {
    setSelectedFormul(item);
    setVisibleFormul(true);
  };
  const _setVisibleFormulPopup = (item) => {
    const newItem = JSON.parse(JSON.stringify(item));
    console.log("item 1", newItem);

    item.formations = JSON.parse(newItem.formations);
    item.indicators = JSON.parse(newItem.indicators);
    item.volumes = JSON.parse(newItem.volumes);
    item.averages = JSON.parse(newItem.averages);

    console.log("item 2", item);
    timeout(1000).then(() => {
      setSelectedFormul(item);
      setVisibleFormul(true);
    });
  };
  const getFormulasData = async () => {
    postDataWithHeaders(
      PANOFORMULLER,
      {
        access_token: user.access_token,
        // j1407b: 1,
      },
      dispatch
    )
      .then((res) => {
        if (res.code == 0) {
          console.log("res.data.myboard panoformul", res.data.myboard);
          dispatch(setFormulas(res.data.board));
          dispatch(setUsers(res.data.users));
          if (res.data.myboard.name) {
            dispatch(setMyboard(res.data.myboard));
          } else {
            dispatch(setMyboard([]));
          }
          dispatch(setFollowUsers(res.data.users_follows));
          dispatch(setFollowFormulas(res.data.formulas_follows));
        } else {
          toast.error(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const _functTakipEt = async (formul) => {
    console.log("formul", formul);
    setIsProcessing(true);
    setSelectedFormul(formul);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
        setVisibleFormul(false);
        setFormulSiralama(mobile ? <Siralama /> : "Beğeni Puanına Göre");
        setFormulSiralamaId(1);
      } else {
        if (res.code == "-91") {
          setKullanici(formul.owner);
          setIsProcessing(false);
          setVisibleTakipKullanici(true);
          setVisibleFormul(false);
          return;
        }
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  const takipKullaniciEt = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      username: kullanici,
    };
    postDataWithHeaders(USERFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        setVisibleTakipKullanici(false);
        _functTakipEt(selectedFormul);
      } else {
        toast.error(res.data);
      }
    });
  };

  const _functTakiptenCikar = async (formul) => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: formul.id,
    };
    postDataWithHeaders(FORMULAUNFOLLOW, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getFormulasData();
        setIsProcessing(false);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const _searchFormul = (text) => {
    setSearch(text);
    if (text.length < 2) {
      return;
    }
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      search: text,
    };
    postDataWithHeaders(SEARCHFORMUL, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        setAramaFormulSonuclar(res.data);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  const funcFormulSiralama = (id, text) => {
    dispatch(setFormulas([]));
    setFormulSiralama(text);
    setFormulSiralamaId(id);
    console.log("funcFormulSiralama", id, text);
    postDataWithHeaders(
      SORTFORMULAS,
      {
        access_token: user.access_token,
        // j1407b: 1,
        sort: id,
      },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        console.log("res.data", res.data);
        dispatch(setFormulas(res.data));
      } else {
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };

  return (
    <Row>
      <ToastContainer />
      <Col lg={12} className="PanoFormul">
        <div className="PanoFormul__container">
          <div className="PanoFormul__content__header">
            <h3>Formüller</h3>
            <div className="PanoFormul__content__header__right">
              <Dropdown className="formulSiralama">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {formulSiralama}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => funcFormulSiralama(2, "Beğeni Puanına Göre")}
                  >
                    Beğeni Puanına Göre
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(1, "Sorgulanma Puanına Göre")
                    }
                  >
                    Sorgulanma Puanına Göre
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(3, "Reyting Puanına Göre")
                    }
                  >
                    Reyting Puanına Göre
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      funcFormulSiralama(4, "Paylaşım Tarihine Göre")
                    }
                  >
                    Paylaşım Tarihine Göre{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div
                className="modalSearch__input ms-auto"
                onClick={() => setVisibleSearch(true)}
              >
                <BsSearch color={theme == "dark" ? "#fff" : "#000"} />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={""}
                  onChange={(e) => {}}
                />
              </div>
            </div>
          </div>
          <div className="PanoFormul__content">
            {formulas.length > 0 ? (
              formulas.map((formul, index) => {
                // Your code here
                let takipte = _.filter(formulas_follows, {
                  id: formul.id,
                }).length;

                let number = index + 1;
                let bgClass =
                  number == 1
                    ? "bg1"
                    : number == 2
                    ? "bg2"
                    : number == 3
                    ? "bg3"
                    : "bgregular";
                let dClass = number > 5 ? "d-none" : "dshow";
                return (
                  <div
                    key={index}
                    className={
                      show
                        ? `PanoFormul__content__item`
                        : `PanoFormul__content__item ${dClass}`
                    }
                  >
                    <div className="PanoFormul__content__item__top">
                      <img
                        src={
                          number == 1
                            ? cup_1st
                            : number == 2
                            ? cup_2nd
                            : number == 3
                            ? cup_3rd
                            : regular_cup
                        }
                      />
                    </div>
                    <div
                      className={`PanoFormul__content__item__bottom ${bgClass}`}
                    >
                      <div className="PanoFormul__content__item__bottom__top">
                        {number > 3 && <div className="sort">#{number}</div>}
                        <div
                          className="title cursorPointer"
                          onClick={() => _setVisibleFormul(formul)}
                        >
                          {formul.name}
                        </div>
                        <div className="whois">
                          Formülizer: <span>{formul.owner}</span>
                        </div>
                        <div className="puan">
                          <span>
                            <AiFillDashboard size={16} />{" "}
                            {formul.queries ? formul.queries : 0}
                          </span>
                          <span>
                            <BsHeartFill size={16} />{" "}
                            {formul.likes ? formul.likes : 0}
                          </span>
                          <span>
                            <AiFillSignal size={16} />{" "}
                            {formul.rating ? formul.rating : 0}
                          </span>
                        </div>
                      </div>
                      <div className="PanoFormul__content__item__bottom__bottom">
                        <PanoNote
                          color={
                            number > 3
                              ? theme == "dark"
                                ? "#fff"
                                : "#1f1f21"
                              : "#1f1f21"
                          }
                          data-tooltip-place="bottom-start"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={formul.note}
                        />
                        {user.username != formul.owner && (
                          <>
                            <div
                              className="PanoFormul__content__item__bottom__bottom__text cursorPointer"
                              onClick={() =>
                                takipte == 0
                                  ? _functTakipEt(formul)
                                  : _functTakiptenCikar(formul)
                              }
                            >
                              {takipte == 0 ? (
                                <>
                                  <SonucTekEkle
                                    color={
                                      number > 3
                                        ? theme == "dark"
                                          ? "#fff"
                                          : "#1f1f21"
                                        : "#1f1f21"
                                    }
                                  />{" "}
                                  <span>Özel Aramalarıma Ekle</span>
                                </>
                              ) : (
                                <>
                                  <SonucSecildi
                                    color={
                                      number > 3
                                        ? theme == "dark"
                                          ? "#fff"
                                          : "#1f1f21"
                                        : "#1f1f21"
                                    }
                                  />{" "}
                                  <span>Özel Aramalarımdan Çıkart</span>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="PanoFormul__content__skelaton">
                <SkeletonTheme
                  className="PanoFormul__content__skelaton"
                  baseColor={theme == "dark" ? "#202020" : "#f0f0f0"}
                  highlightColor={theme == "dark" ? "#444" : "#f4f4f4"}
                >
                  {[1, 2, 3, 4, 5].map((i) => (
                    <Skeleton key={i} />
                  ))}
                </SkeletonTheme>
              </div>
            )}
          </div>
          <div className="PanoFormul__bottom">
            <div
              className="PanoFormul__bottom__text cursorPointer"
              onClick={() => showMoreFormul()}
            >
              {show ? "Daha az göster " : "Daha Fazla göster "}{" "}
              {show ? (
                <ArrowThinUp color={theme == "dark" ? "#fff" : "#1f1f21"} />
              ) : (
                <ArrowThinDown color={theme == "dark" ? "#fff" : "#1f1f21"} />
              )}
            </div>
          </div>
        </div>
      </Col>
      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {selectedFormul.name}
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(selectedFormul.formations).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Formasyonlar</h2>
                  <div className="modalSubDiv__footer__type">
                    {selectedFormul.additionals.bayz_just_closed == 1 ? (
                      <SonucSecildi
                        onClick={() => handleAyri(0, "Ayırılmış")}
                      />
                    ) : (
                      <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                    )}
                    Bayz Üstünde Kapatanlar
                  </div>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.formations).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleName(key)}
                      </span>
                      <span className="OzelArama_periods">
                        {selectedFormul.formations[key].periods.map(
                          (period) => (
                            <span
                              className="period"
                              key={`formul_${key}_${period}`}
                            >
                              {period}
                            </span>
                          )
                        )}{" "}
                      </span>
                      {selectedFormul.formations[key].side == 0 ? (
                        <ArrowSort color="#9a9a9a" />
                      ) : selectedFormul.formations[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowDown color="#E12222" />
                      )}
                    </div>
                  ))}
                </div>

                <div className="modalSubDiv__footer__type">
                  {selectedFormul.additionals.seperate == 1 ? (
                    <SonucSecildi onClick={() => handleAyri(0, "Ayırılmış")} />
                  ) : (
                    <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                  )}
                  Ayrı Olarak Listele
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.volumes).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Hacim</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.volumes).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.volumes[key].side == 0 ? (
                        <ArrowSort color="#9a9a9a" />
                      ) : selectedFormul.volumes[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowDown color="#E12222" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.indicators).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>İndikatörler</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.indicators).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.indicators[key].side == 0 ? (
                        <ArrowSort color="#9a9a9a" />
                      ) : selectedFormul.indicators[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowDown color="#E12222" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {Object.keys(selectedFormul.averages).length > 0 && (
              <div className="AramaFiltre">
                <div className="AramaFiltre__title">
                  <h2>Ortalamalar</h2>
                </div>
                <div className="AramaFiltre__body">
                  {Object.keys(selectedFormul.averages).map((key) => (
                    <div className="AramaFiltre_item" key={`for_${key}`}>
                      <span className="title">
                        <SonucSecildi />
                        {handleIndicatorName(key)}
                      </span>
                      {selectedFormul.averages[key].side == 0 ? (
                        <ArrowSort color="#9a9a9a" />
                      ) : selectedFormul.averages[key].side == 1 ? (
                        <ArrowUp color="#5fb045" />
                      ) : (
                        <ArrowDown color="#E12222" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedFormul.owner != user.username && (
              <Button
                onClick={() => _functTakipEt(selectedFormul)}
                className="modalSonucButton w260"
              >
                {!isProcessing && <SonucTekEkle color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Listeye Ekleniyor..." />
                ) : (
                  "Özel Aramalarıma Ekle"
                )}
              </Button>
            )}
          </Modal.Body>
        </Modal>
      )}

      {visibleTakipKullanici && (
        <Modal
          show={visibleTakipKullanici}
          onHide={handleTakipKullaniciClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {kullanici} Takip Et
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              <Button
                onClick={() => takipKullaniciEt()}
                className="modalSonucButton"
              >
                {!isProcessing && <UserFollow color="#fff" />}
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Takip Ediliyor..." />
                ) : (
                  "Takip Et"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleSearch && (
        <Modal
          show={visibleSearch}
          onHide={handleSearchClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Arama
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSearch__input">
              <BsSearch color="#fff" />
              <input
                type="text"
                placeholder="Arama Yap"
                value={search}
                onChange={(e) => _searchFormul(e.target.value)}
              />
            </div>
            <div className="modalListeTabDiv__list">
              {aramaFormulSonuclar &&
                aramaFormulSonuclar.map((v, i) => (
                  <div
                    className="modalListeTabDiv__list__item"
                    key={`sonuc_${i}`}
                    onClick={() => _setVisibleFormulPopup(v)}
                  >
                    <div>{v.name}</div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "#35353A",
          color: "#fff",
          fontSize: 14,
          padding: 16,
          borderRadius: 8,
          maxWidth: 300,
        }}
      />
    </Row>
  );
}

export default PanoFormul;
