import React, { useState, useEffect } from "react";
import "./EndeksFrekans.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import Header from "../System/header";
import SideMenuPanel from "../SideMenu/SideMenuPanel";
import {
  dataAll,
  dataBorsaFrekans,
  dataEndeksFrekans,
} from "../../utils/dummyData";
import { BsSearch } from "react-icons/bs";
import ButtonClose from "../svgs/buttonClose";
import ArrowUp from "../svgs/arrowUp";
import ArrowDown from "../svgs/arrowDown";
import AnaKumandaSonucYok from "../svgs/anaKumandaSonucYok";
import { PulseLoader } from "react-spinners";
import araCizgi from "../../assets/img/ara-cizgi.png";
import grafik from "../../assets/img/endeks-odasi.png";
import ArrowThinDown from "../svgs/arrowThinDown";
import ArrowSort from "../svgs/arrowSort";
import SonucSec from "../svgs/sonucSec";
import SonucSecildi from "../svgs/sonucSecildi";
import { toast, ToastContainer } from "react-toastify";
import { TVChartIndex } from "../TVChartContainer/chartIndex";
import { useSelector } from "react-redux";

let date = new Date();
var timestamp = date.getTime();
function EndeksFrekans() {
  const urlApi = localStorage.getItem("urlApi");
  const theme = useSelector((state) => state.theme.darkmode);
  const [isProcessing, setIsProcessing] = useState(false);
  const [search, setSearch] = useState("");
  const [markets, setMarkets] = useState([]);
  const [periyots, setPeriyots] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [sonucYok, setSonucYok] = useState(false);
  const [sonucGetir, setSonucGetir] = useState(0);
  const [selectedBorsa, setSelectedBorsa] = useState("BIST");
  const [selectedEndeks, setSelectedEndeks] = useState(null);
  const [selectedUstuneEndeks, setSelectedUstuneEndeks] = useState(null);
  const [visibleBorsa, setVisibleBorsa] = useState(false);
  const [visibleEndeks, setVisibleEndeks] = useState(false);
  const [periyotOpen, setPeriyotOpen] = useState("");
  const [endeksLists, setEndeksLists] = useState("");
  const [visibleUstuneEndeks, setVisibleUstuneEndeks] = useState(false);
  const handleBorsaClose = () => setVisibleBorsa(false);
  const handleEndeksClose = () => setVisibleEndeks(false);
  const handleUstuneEndeksClose = () => setVisibleUstuneEndeks(false);

  const functSonucGetir = () => {
    setSonucGetir(sonucGetir + 1);
  };

  const _setSelectedEndeks = (id) => {
    setSonucGetir(0);
    setSelectedEndeks(id);
  };

  const _setSelectedUstuneEndeks = (id) => {
    setSonucGetir(0);
    setSelectedUstuneEndeks(id);
  };

  function periyotCallback(item) {
    return {
      value: item.name,
      label: item.description,
    };
  }

  const _getPeriyots = async () => {
    setIsProcessing(true);

    let counter = 5;
    while (true) {
      try {
        const urlApiDetay = `${urlApi}intervals.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlApiDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(periyotCallback),
        });

        console.log("periyots completed: ", dataDummy[0]);
        setPeriyots(dataDummy[0]);
        setIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          console.log("periyots network error: ", error);
          setIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };

  function SelectIcon({ hisseName, fStocks, func }) {
    <SonucSec onClick={() => func(hisseName)} />;
    hisseName === fStocks ? (
      <SonucSecildi onClick={() => func(hisseName)} />
    ) : (
      <SonucSec onClick={() => func(hisseName)} />
    );
  }

  const handleEndeks = (selectedSembol) => {
    // if (selectedBorsa == null || selectedBorsa == "") {
    //   toast.error("Lütfen borsa seçiniz.");
    //   return false;
    // }
    setSelectedEndeks(selectedSembol);
  };
  const handleUstuneEndeks = (selectedSembol) => {
    // if (selectedBorsa == null || selectedBorsa == "") {
    //   toast.error("Lütfen borsa seçiniz.");
    //   return false;
    // }
    if (!selectedEndeks) {
      toast.error("Lütfen İlk Frekansı seçiniz.");
      return false;
    }
    if (selectedEndeks == selectedSembol) {
      toast.error("Lütfen farklı bir frekans seçiniz.");
      return false;
    }
    setSelectedUstuneEndeks(selectedSembol);
  };

  const handlePeriyotOpen = (id) => {
    setPeriyotOpen(id);
  };
  const handlePeriyot = () => {
    setPeriyotOpen("");
  };
  const funcSelectBorsa = (id) => {
    setSelectedBorsa(id);
    setVisibleBorsa(false);
    setSonucYok(true);
  };
  const funcSelectEndeks = (id) => {
    setSelectedEndeks(id);
    setVisibleEndeks(false);
    setSonucYok(true);
  };
  const funcSelectUstuneEndeks = (id) => {
    setSelectedUstuneEndeks(id);
    setVisibleUstuneEndeks(false);
    setSonucYok(true);
  };

  useEffect(() => {
    _getPeriyots();
  }, []);

  useEffect(() => {
    document.title = "Endeks Frekans Odası - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row>
            <div className="DashBoard_container">
              <div className="EndeksPaneli">
                {/* <div
                  className="EndeksSelect active"
                  onClick={() => setVisibleBorsa(true)}
                >
                  <div className="EndeksSelect__body">
                    <div className="EndeksSelect__title">Borsa Seç</div>
                    <div className="EndeksSelect__text">
                      {selectedBorsa ? selectedBorsa : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div> */}
                <div
                  className="EndeksSelect active"
                  onClick={() => setVisibleEndeks(true)}
                >
                  <div className="EndeksSelect__body">
                    <div className="EndeksSelect__title">Frekans Ekle</div>
                    <div className="EndeksSelect__text">
                      {selectedEndeks ? selectedEndeks : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                <div
                  className="EndeksSelect active"
                  onClick={() => setVisibleUstuneEndeks(true)}
                >
                  <div className="EndeksSelect__body">
                    <div className="EndeksSelect__title">
                      Üstüne Frekans Ekle
                    </div>
                    <div className="EndeksSelect__text">
                      {selectedUstuneEndeks
                        ? selectedUstuneEndeks
                        : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={12} className="AnaKumanda__container">
              {!selectedEndeks ? (
                <>
                  <div className="AnaKumanda__sonuc">
                    <div className="AnaKumanda__sonucyok">
                      <AnaKumandaSonucYok />
                      <div className="AnaKumanda__sonucyok__text">
                        Henüz arama yapmadınız. <br />
                        hemen borsa seçerek Sirius’un dünyasını keşfetmeye
                        başlayın.
                        <br />
                        <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="AnaKumanda__filtre">
                    {/* <div className="AnaKumanda__filtre__body">
                      <div className="AnaKumanda__filtre__title">Borsa</div>
                      <div className="AnaKumanda__filtre__text">
                        {selectedBorsa}
                      </div>
                    </div> */}
                    {selectedEndeks && (
                      <div className="AnaKumanda__filtre__container">
                        <div className="AnaKumanda__filtre__top">
                          Seçilen Frekans
                        </div>
                        <div className="AnaKumanda__filtre__bottom">
                          <div
                            className="AnaKumanda__filtre__bottom__left"
                            onClick={() => _setSelectedEndeks(null)}
                          >
                            {selectedEndeks}
                            <ButtonClose />
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedUstuneEndeks && (
                      <>
                        <div>
                          <img src={araCizgi} />
                        </div>

                        <div className="AnaKumanda__filtre__container">
                          <div className="AnaKumanda__filtre__top">
                            Eklenen Frekans
                          </div>
                          <div className="AnaKumanda__filtre__bottom">
                            <div
                              className="AnaKumanda__filtre__bottom__left"
                              onClick={() => _setSelectedUstuneEndeks(null)}
                            >
                              {selectedUstuneEndeks} <ButtonClose />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {selectedEndeks != null && (
                      <div className="EndeksSelect__sonuc__button ml-auto">
                        <Button
                          onClick={() => {
                            functSonucGetir();
                          }}
                        >
                          Sonuç Getir
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Col>

            {sonucGetir != 0 && (
              <Col lg={12} className="AnaKumanda__container">
                <TVChartIndex
                  id="TVChartIndex"
                  color={theme}
                  sonucGetir={sonucGetir}
                  selectedBorsa={selectedBorsa}
                  selectedEndeks={selectedEndeks}
                  selectedUstuneEndeks={selectedUstuneEndeks}
                  urlApi={urlApi}
                />
              </Col>
            )}
          </Row>
          {sonucGetir != 0 && (
            <Row className="PariteOdasi_text">
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <p>
                  BİST isim ve logosu &quot;Koruma Marka Belgesi&quot; altında
                  korunmakta olup izinsiz kullanılamaz, iktibas edilemez,
                  değiştirilemez. BİST ismi altında açıklanan tüm bilgilerin
                  telif hakları tamamen BİST&#39;e ait olup, tekrar
                  yayınlanamaz. Veriler Foreks A.Ş. tarafından sağlanmaktadır.
                  BİST hisse verileri 15 dakika gecikmelidir. Frekans verileri
                  her 1 saat periyot kapanışından sonra güncellenmektedir.
                </p>
              </div>
            </Row>
          )}
        </Col>
      </Row>

      {visibleBorsa && (
        <Modal
          show={visibleBorsa}
          onHide={handleBorsaClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Borsa Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataBorsaFrekans.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleEndeks && (
        <Modal
          show={visibleEndeks}
          onHide={handleEndeksClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Frekans Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataEndeksFrekans.map((endeks, index) => (
                <div
                  key={endeks.label}
                  className={
                    periyotOpen == endeks.label
                      ? "endeks modalSelectDiv period"
                      : "endeks modalSelectDiv"
                  }
                  onClick={() => handleEndeks(endeks.label)}
                >
                  <div className="modalSelectDivTop">
                    <div className="modalSelectDiv__left">
                      {selectedEndeks == endeks.label ? (
                        <SonucSecildi />
                      ) : (
                        <SonucSec />
                      )}
                      {endeks.label}
                    </div>
                    <div className="modalSelectDiv__right">
                      {/* <div
                        className="modalSelectDiv__right__period"
                        onClick={() => handlePeriyotOpen(endeks.label)}
                      >
                        Periyot <ArrowThinDown color="#9a9a9a" />
                      </div> */}
                      <div className="modalSelectDiv__right__yon">
                        {endeks.type == 0 ? (
                          <ArrowDown color="#E12222" />
                        ) : endeks.type == 1 ? (
                          <ArrowUp color="#22DE2A" />
                        ) : (
                          <ArrowSort color="#9a9a9a" />
                        )}
                      </div>
                    </div>
                  </div>
                  {periyotOpen == endeks.label && (
                    <div className="modalSelectDiv__Bottom">
                      {periyots.items.map((period) => (
                        <div
                          key={period.value}
                          className="modalSelectDiv__periyot__item"
                          onClick={() => handlePeriyot(period.value)}
                        >
                          {period.value}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <Button
              onClick={() => funcSelectEndeks(selectedEndeks)}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Frekans Ekle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleUstuneEndeks && (
        <Modal
          show={visibleUstuneEndeks}
          onHide={handleUstuneEndeksClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Üstüne Frekans Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataEndeksFrekans.map((endeks, index) => (
                <div
                  key={endeks.label}
                  className={
                    periyotOpen == endeks.label
                      ? "endeks modalSelectDiv period"
                      : "endeks modalSelectDiv"
                  }
                  onClick={() => handleUstuneEndeks(endeks.label)}
                >
                  <div className="modalSelectDivTop">
                    <div className="modalSelectDiv__left">
                      {selectedUstuneEndeks == endeks.label ? (
                        <SonucSecildi />
                      ) : (
                        <SonucSec />
                      )}
                      {endeks.label}
                    </div>
                    <div className="modalSelectDiv__right">
                      {/* <div
                        className="modalSelectDiv__right__period"
                        onClick={() => handlePeriyotOpen(endeks.label)}
                      >
                        Periyot <ArrowThinDown color="#9a9a9a" />
                      </div> */}
                      <div className="modalSelectDiv__right__yon">
                        {endeks.type == 0 ? (
                          <ArrowDown color="#E12222" />
                        ) : endeks.type == 1 ? (
                          <ArrowUp color="#22DE2A" />
                        ) : (
                          <ArrowSort color="#9a9a9a" />
                        )}
                      </div>
                    </div>
                  </div>
                  {periyotOpen == endeks.label && (
                    <div className="modalSelectDiv__Bottom">
                      {periyots.items.map((period) => (
                        <div
                          key={period.value}
                          className="modalSelectDiv__periyot__item"
                          onClick={() => handlePeriyot(period.value)}
                        >
                          {period.value}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <Button
              onClick={() => funcSelectUstuneEndeks(selectedUstuneEndeks)}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Frekans Ekle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default EndeksFrekans;
