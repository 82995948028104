import React, { useEffect, useState } from "react";
import "./AnaKumanda.css";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import Header from "../System/header";
import SideMenuPanel from "../SideMenu/SideMenuPanel";
import AnaKumandaSonucYok from "../svgs/anaKumandaSonucYok";
import { dataBorsa, dataYon2 } from "../../utils/dummyData";
import ButtonClose from "../svgs/buttonClose";
import { BsPencilFill, BsSearch } from "react-icons/bs";
import { FaFilterCircleXmark } from "react-icons/fa6";

import ArrowSort from "../svgs/arrowSort";
import ArrowDown from "../svgs/arrowDown";
import ArrowUp from "../svgs/arrowUp";
import TopluEkle from "../svgs/toplu";
import SonucSec from "../svgs/sonucSec";
import SonucTekEkle from "../svgs/sonucTekEkle";
import SonucAlarm from "../svgs/sonucAlarm";
import SonucGrafik from "../svgs/sonucGrafik";
import SonucSecildi from "../svgs/sonucSecildi";
import ArrowThinDown from "../svgs/arrowThinDown";
import ArrowThinUp from "../svgs/arrowThinUp";
import { PulseLoader } from "react-spinners";
import araCizgi from "../../assets/img/ara-cizgi.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { useDispatch, useSelector } from "react-redux";
import ChartComponent from "../chart/chart";
import PuanComponent from "../puan/puan";
import { timeout } from "../../utils/helperFunction";
import {
  ADDLISTE,
  FAVORIADD,
  FORMULADD,
  FORMULAS,
  FORMULEDIT,
  GETLISTE,
  INDICATORS,
  UPDATELISTE,
} from "../../utils/urls";
import { createGlobalStyle } from "styled-components";
import { IoMdArrowBack } from "react-icons/io";

const thisBogaLists = ["GUVENLI", "OMO", "TOBO", "YELPAZE", "OLASIC"];

function objectLength(obj) {
  var result = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // or Object.prototype.hasOwnProperty.call(obj, prop)
      result++;
    }
  }
  return result;
}

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function countMarket(objs) {
  var result = _.countBy(objs.flatMap((obj) => obj.market));
  return result;
}
let date = new Date();
var timestamp = date.getTime();

function AnaKumandaEdit() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const { formulId } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const urlApi = localStorage.getItem("urlApi");
  const [isProcessing, setIsProcessing] = useState(false);
  const [dahaGoster, setDahaGoster] = useState(false);
  const [dahaGosterId, setDahaGosterId] = useState(0);
  const [liste, setListe] = useState("");
  const [bayzJustClosed, setBayzJustClosed] = useState(0);
  const [last24Hour, setLast24Hour] = useState(0);
  const [periyots, setPeriyots] = useState(
    JSON.parse(localStorage.getItem("periyots")) || []
  );
  const [search, setSearch] = useState("");
  const [alarm, setAlarm] = useState("");
  const [hisseType, setHisseType] = useState("Hissede");
  const [hisseAdet, setHisseAdet] = useState(0);
  const [ayrimi, setAyrimi] = useState(0);
  const [stratejiAdet, setStratejiAdet] = useState(0);
  const [taramaAdet, setTaramaAdet] = useState(0);
  const [selectListe, setSelectListe] = useState(0);
  const [selectListeTab, setSelectListeTab] = useState(1);
  const [alarmName, setAlarmName] = useState("");
  const [listeName, setListeName] = useState("");
  const [listeNameId, setListeNameId] = useState(0);
  const [listeler, setListeler] = useState([]);
  const [alarmFiyat, setAlarmFiyat] = useState("");
  const [periyotOpen, setPeriyotOpen] = useState("");
  const [yonOpen, setYonOpen] = useState("");
  const [yonHacimOpen, setYonHacimOpen] = useState("");
  const [yonOrtalamaOpen, setYonOrtalamaOpen] = useState("");
  const [yonIndicatorOpen, setYonIndicatorOpen] = useState("");
  const [formul, setFormul] = useState("");
  const [formulNote, setFormulNote] = useState("");
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [loadFilter, setLoadFilter] = useState(false);
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [selectedBorsa, setSelectedBorsa] = useState(
    localStorage.getItem("selectedBorsa") || "BIST"
  );
  const [selectedBorsaId, setSelectedBorsaId] = useState(
    localStorage.getItem("selectedBorsaId") || 1
  );
  const [selectedPeriyot, setSelectedPeriyot] = useState(
    localStorage.getItem("selectedPeriyot") || null
  );
  const [selectedSembol, setSelectedSembol] = useState([]);
  const [formationLists, setFormationLists] = useState(
    JSON.parse(localStorage.getItem("formationEditLists")) || []
  );
  const [filtreFormationLists, setFiltreFormationLists] = useState(
    JSON.parse(localStorage.getItem("editFormationLists")) || []
  );
  const [filtreFormationLists2, setFiltreFormationLists2] = useState([]);
  const [hacimLists, setHacimLists] = useState(
    JSON.parse(localStorage.getItem("hacimEditLists")) || []
  );
  const [filtreHacimLists, setFiltreHacimLists] = useState(
    JSON.parse(localStorage.getItem("editHacimLists")) || []
  );
  const [ortalamaLists, setOrtalamaLists] = useState(
    JSON.parse(localStorage.getItem("ortalamaEditLists")) || []
  );
  const [filtreOrtalamaLists, setFiltreOrtalamaLists] = useState(
    JSON.parse(localStorage.getItem("editOrtalamaLists")) || []
  );
  const [indicatorLists, setIndicatorLists] = useState(
    JSON.parse(localStorage.getItem("indicatorEditLists")) || []
  );
  const [filtreIndicatorLists, setFiltreIndicatorLists] = useState(
    JSON.parse(localStorage.getItem("editIndicatorLists")) || []
  );
  const [selectedFormation, setSelectedFormation] = useState([]);
  const [selectedYon, setSelectedYon] = useState(
    localStorage.getItem("selectedYon") || ""
  );
  const [sonucYok, setSonucYok] = useState(false);
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [dataFilter, setDataFilter] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [sortTypeChange, setSortTypeChange] = useState("default");
  const [topluEkleLists, setTopluEkleLists] = useState([]);
  const [visibleBorsa, setVisibleBorsa] = useState(false);
  const [visibleSembol, setVisibleSembol] = useState(false);
  const [visiblePeriyot, setVisiblePeriyot] = useState(false);
  const [visibleYon, setVisibleYon] = useState(false);
  const [visibleFiltre, setVisibleFiltre] = useState(false);
  const [visibleAlarm, setVisibleAlarm] = useState(false);
  const [visibleAlarmFiyat, setVisibleAlarmFiyat] = useState(false);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const [visibleListelerim, setVisibleListelerim] = useState(false);
  const [dataFormation, setDataFormation] = useState([]);
  const [dataIndicator, setDataIndicator] = useState([]);
  const [formationId, setFormationId] = useState(0);
  const [puanName, setPuanName] = useState("");
  const [loadChart, setLoadChart] = useState("formation");
  const [modalIndicator, setModalIndicator] = useState(false);
  const handleIndicatorClose = () => setModalIndicator(false);
  const [modalGrafik, setModalGrafik] = useState(false);
  const handleGrafikClose = () => setModalGrafik(false);
  const handleBorsaClose = () => setVisibleBorsa(false);
  const handleSembolClose = () => setVisibleSembol(false);
  const handlePeriyotClose = () => setVisiblePeriyot(false);
  const handleYonClose = () => setVisibleYon(false);
  const handleFiltreClose = () => setVisibleFiltre(false);
  const handleAlarmClose = () => setVisibleAlarm(false);
  const handleAlarmFiyatClose = () => setVisibleAlarmFiyat(false);
  const handleFormulClose = () => setVisibleFormul(false);
  const handleListelerimClose = () => setVisibleListelerim(false);

  const updatedDataFormationsLength =
    user.bayz == 1
      ? updatedDataFormations.length
      : updatedDataFormations.length - 4;

  const _setDahaGoster = (id) => {
    setDahaGoster(!dahaGoster);
    setDahaGosterId(id);
  };
  const handleAlarmEkle = () => {
    setIsProcessing(true);
    setInterval(() => {
      setIsProcessing(false);
      setVisiblePeriyot(false);
      setVisibleBorsa(false);
      setVisibleSembol(false);
      setVisibleYon(false);
      setVisibleFiltre(false);
    }, 2000);
  };
  const handlePeriyotOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setPeriyotOpen(id);
  };
  const handleAyri = (value, text) => {
    setAyrimi(value);
    console.log("ayrı", value, text);
  };
  const handlePeriyot = (value, period) => {
    const bayzIndex = filtreFormationLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreFormationLists[bayzIndex].periyot = period;
    }
    setFiltreFormationLists([...filtreFormationLists]);
    setPeriyotOpen("");
  };
  const handleYonOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonOpen(id);
  };
  const handleYon = (value, yon) => {
    const bayzIndex = filtreFormationLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreFormationLists[bayzIndex].yon = yon;
    }
    console.log(
      "filtreFormationLists handleYon",
      value,
      yon,
      filtreFormationLists
    );
    setFiltreFormationLists([...filtreFormationLists]);
    setYonOpen("");
  };
  const handleYonHacimOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonHacimOpen(id);
  };
  const handleHacimYon = (value, yon) => {
    const bayzIndex = filtreHacimLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreHacimLists[bayzIndex].yon = yon == "TÜMÜ" ? "" : yon;
    }
    setFiltreHacimLists([...filtreHacimLists]);
    setYonHacimOpen("");
  };
  const handleYonOrtalamaOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonOrtalamaOpen(id);
  };
  const handleOrtalamaYon = (value, yon) => {
    const bayzIndex = filtreOrtalamaLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreOrtalamaLists[bayzIndex].yon = yon == "TÜMÜ" ? "" : yon;
    }
    setFiltreOrtalamaLists([...filtreOrtalamaLists]);
    setYonOrtalamaOpen("");
  };
  const handleYonIndicatorOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonIndicatorOpen(id);
  };
  const handleIndicatorYon = (value, yon) => {
    const bayzIndex = filtreIndicatorLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreIndicatorLists[bayzIndex].yon = yon == "TÜMÜ" ? "" : yon;
    }
    setFiltreIndicatorLists([...filtreIndicatorLists]);
    setYonIndicatorOpen("");
  };
  const _setVisibleFiltre = () => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setVisibleFiltre(true);
  };
  const handleAlarmFiyatEkle = () => {
    setIsProcessing(true);
    setInterval(() => {
      setIsProcessing(false);
      setVisiblePeriyot(false);
      setVisibleBorsa(false);
      setVisibleSembol(false);
      setVisibleYon(false);
      setVisibleFiltre(false);
    }, 2000);
  };

  const handlePeriyotValue = (value) => {
    return periyots.items.filter((item) => item.label == value)[0].value;
  };
  const handleYonValue = (value) => {
    return value == "BOĞA" ? 1 : 0;
  };
  const handleFiltreChange = (lists) => {
    let newLists = {};
    lists.map((item) => {
      newLists[item.value] = {
        periods: [handlePeriyotValue(item.periyot)],
        side: handleYonValue(item.yon),
      };
    });
    return newLists;
  };

  const handleFormulEkle = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      id: formulId,
      name: formul,
      note: formulNote,
      formations: handleFiltreChange(filtreFormationLists),
      volumes: handleFiltreChange(filtreHacimLists),
      averages: handleFiltreChange(filtreOrtalamaLists),
      indicators: handleFiltreChange(filtreIndicatorLists),
      seperate: ayrimi,
      bayz_just_closed: bayzJustClosed,
      // j1407b: 1,
    };
    postDataWithHeaders(FORMULEDIT, postData, dispatch).then((res) => {
      if (res.code === 0) {
        setIsProcessing(false);
        setVisibleFormul(false);
        setFormul("");
        setFormulNote("");
        toast.success(res.data);
      } else {
        toast.error(res.data);
        setIsProcessing(false);
        setVisibleFormul(false);
      }
    });
  };

  const handleListemeEkle = async () => {
    // setIsProcessing(true);
    if (selectListe == "new") {
      setIsProcessing(true);
      const postData = {
        access_token: user.access_token,
        name: liste,
        markets: [listeNameId],
        // j1407b: 1,
      };
      await postDataWithHeaders(ADDLISTE, postData, dispatch).then((res) => {
        console.log("ADDLISTE", res);
        if (res.code === 0) {
          setIsProcessing(false);
          setVisibleListelerim(false);

          toast.success(res.data);
        } else {
          setIsProcessing(false);
          setVisibleListelerim(false);
          toast.error(res.data);
        }
      });
      setIsProcessing(false);
      setVisibleListelerim(false);
      return;
    }
    selectListe.markets.push(parseInt(listeNameId));
    const postData = {
      id: selectListe.id,
      access_token: user.access_token,
      name: selectListe.name,
      markets: selectListe.markets,
      // j1407b: 1,
    };
    // console.log("postData", postData);
    await postDataWithHeaders(UPDATELISTE, postData, dispatch).then((res) => {
      console.log("ADDLISTE", res);
      if (res.code === 0) {
        setIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        setIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  const handleFavoriEkle = async () => {
    // setIsProcessing(true);
    // console.log("item", selectListe, listeNameId, selectListe.markets);
    const postData = {
      access_token: user.access_token,
      market_id: listeNameId,
      // j1407b: 1,
    };
    // console.log("postData", postData);
    await postDataWithHeaders(FAVORIADD, postData, dispatch).then((res) => {
      console.log("FAVORIADD", res);
      if (res.code === 0) {
        setIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        setIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  function periyotCallback(item) {
    return {
      value: item.name,
      label: item.description,
    };
  }
  const funcSelectBorsa = async (label, id) => {
    setSelectedBorsa(label);
    setSelectedBorsaId(id);
    localStorage.setItem("selectedBorsa", label);
    localStorage.setItem("selectedBorsaId", id);
    setSelectedSembol([]);
    setVisibleBorsa(false);
    setLoadData(true);
    setSonucYok(true);
    let newMarket = [];
    let newValue = id;
    if (parseInt(newValue)) {
      newMarket = _.filter(markets.items, function (o) {
        return o.exchange_id == newValue;
      });
    } else {
      if (newValue == "XU030") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu030 == 1;
        });
      }
      if (newValue == "XU050") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu050 == 1;
        });
      }
      if (newValue == "XU100") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu100 == 1;
        });
      }
      if (newValue == "XBANK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xbank == 1;
        });
      }
      if (newValue == "XKTUM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xktum == 1;
        });
      }
      // XUSIN, XUTEK, XILTM, XGIDA, XTRZM, XSGRT
      if (newValue == "XUSIN") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xusin == 1;
        });
      }
      if (newValue == "XUTEK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xutek == 1;
        });
      }
      if (newValue == "XILTM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xiltm == 1;
        });
      }
      if (newValue == "XGIDA") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xgida == 1;
        });
      }
      if (newValue == "XTRZM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xtrzm == 1;
        });
      }
      if (newValue == "XSGRT") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xsgrt == 1;
        });
      }
      if (newValue == "CIRCLE") {
        newMarket = _.filter(markets.items, function (o) {
          return o.circle == 1;
        });
      }
    }
    setMarketler(newMarket);
    setSearchMarket(newMarket);
  };
  const funcSelectPeriyot = (id) => {
    setSelectedPeriyot(id);
    localStorage.setItem("selectedPeriyot", id);
  };

  const funcSelectYon = (id) => {
    setSelectedYon(id);
    localStorage.setItem("selectedYon", id);
  };

  const funcAlarmFiyat = (id) => {
    setAlarmName(id);
    setVisibleAlarmFiyat(true);
  };

  const getListeler = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETLISTE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        setListeler(res.data);
      }
    });
  };
  const funcListeEkle = async (form) => {
    setListeName(form.name);
    setListeNameId(parseInt(form.stock_id));
    await getListeler();
    setVisibleListelerim(true);
  };
  const funcListeTopluEkle = () => {
    console.log("toplu");
    if (topluEkleLists.length > 0) {
      setVisibleListelerim(true);
    } else {
      toast.error("Liste Ekleme İşlemi İçin En Az Bir Sembol Seçmelisiniz");
    }
  };
  const funcSelectSembol = (id) => {
    if (selectedSembol.includes(id)) {
      setSelectedSembol(selectedSembol.filter((item) => item !== id));
    } else {
      setSelectedSembol([...selectedSembol, id]);
    }
    console.log(selectedSembol);
  };

  const handleFormasyon = (value) => {
    // console.log("handleFormasyon: ", updatedDataFormations, value);
    return updatedDataFormations.filter((v) => v.slug == value);
  };
  const handleIndicatorName = (value) => {
    // console.log("handleIndicators: ", updatedDataIndicators, value);
    return updatedDataIndicators.filter((v) => v.slug == value);
  };
  const handleOrtalamaName = (value) => {
    // console.log("handleOrtalamalar: ", updatedDataOrtalamalar, value);
    return updatedDataOrtalamalar.filter((v) => v.slug == value);
  };

  const handleHacimName = (value) => {
    // console.log("handleHacimName: ", updatedDataHacim, value);
    return updatedDataHacim.filter((v) => v.slug == value);
  };
  const handlePeiods = async (value) => {
    console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = await periyots.items.filter((v) => v.label == value);
    console.log("filteredItems", filteredItems[0].value);
    return filteredItems[0].value;
  };
  const handlePeriod2 = async (value) => {
    // console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = periyots.items.filter((v) => v.value === value);
    // console.log("filteredItems", filteredItems[0].label);
    return filteredItems[0].label;
  };
  function findCommonItems(arrays) {
    if (arrays.length === 0) {
      return [];
    }
    const commonItems = arrays[0].filter((item) =>
      arrays.every((array) => array.some((aItem) => aItem.name === item.name))
    );

    return commonItems;
  }
  function uniqueStockIds(arr) {
    const uniqueIds = [...new Set(arr.map((item) => item.stock_id))];
    return uniqueIds;
  }
  function uniqueStockIds(arr) {
    const uniqueArray = arr.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.stock_id === obj.stock_id)
    );
    return uniqueArray;
  }
  function searchByCriteria(data, formation, market, period) {
    return data.filter(
      (item) =>
        item.formation === formation &&
        item.market === market &&
        item.period === period
    );
  }
  function findStockCodes(data) {
    const stockCodeCount = {};
    const duplicateStockCodes = [];

    for (const item of data) {
      const stockCode = item.stock_code;
      if (stockCodeCount[stockCode]) {
        duplicateStockCodes.push(item);
      } else {
        stockCodeCount[stockCode] = 1;
      }
    }

    return duplicateStockCodes;
  }
  const funcSelectSonuc = async () => {
    setIsProcessing(true);
    console.log("funcSelectSonuc");
    setDataBody(null);
    setDataFilter(null);

    //console.log("dataFiltre: ", dataFiltre);
    if (filtreFormationLists) {
      let formasyons = filtreFormationLists;
      let indicators = filtreIndicatorLists;
      let hacims = filtreHacimLists;
      let ortalamas = filtreOrtalamaLists;
      let data = [];
      let formasyon_remove = 0;
      let other_remove = 0;
      let dataOrtak = [];
      let ortak_indicators = [];
      let ortak_hacim = [];
      let ortak_ortalama = [];
      const dataDummy = dataBody2[0].filter((item1) =>
        marketler.some((item2) => item2.name === item1.label)
      );
      if (formasyons.length > 0) {
        let tempData = [];
        let forms = [];
        if (bayzJustClosed == 1) {
          let searching = [];

          await Promise.all(
            updatedDataFormations.map(async (formasyon) => {
              forms[formasyon.slug] = [];
            })
          );
          let DummyDataBody = dataDummy;
          await Promise.all(
            formasyons.map(async (formasyon) => {
              let returnPeriyot = await handlePeiods(formasyon.periyot);
              let searchData = {
                formation: handleFormasyon(formasyon.value)[0].slug,
                market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                period: returnPeriyot,
                bayz_just_closed: "1",
              };
              searching = _.filter(DummyDataBody, {
                formations: [searchData],
              });
              searching.map((item) => {
                item.formations.map((form) => {
                  form.show = 0;
                });
              });
              forms[formasyon.value].push(searching);
            })
          );
          // console.log("forms", forms);
          Object.keys(forms).map((subForms) => {
            if (forms[subForms].length > 0) {
              tempData.push(forms[subForms][0]);
            }
          });

          dataOrtak = findCommonItems(tempData);

          // // console.log("formationShow: ", searching, formationShow);
        } else {
          if (ayrimi == 1) {
            await Promise.all(
              formasyons.map(async (formasyon) => {
                let returnPeriyot = await handlePeiods(formasyon.periyot);
                let searchData = {
                  formation: handleFormasyon(formasyon.value)[0].slug,
                  market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                  period: returnPeriyot,
                };
                let searching = _.filter(dataBody2[0], {
                  formations: [searchData],
                });
                //console.log("searching ayrı ayrı formasyons",searchData,searching);
                forms.push(searching);
              })
            );

            await Promise.all(
              forms.map((subForms) => {
                subForms.map((sub) => {
                  tempData.push(sub);
                });
              })
            );
            dataOrtak = _.uniqBy(tempData, "name");
          } else {
            let searching = [];
            let formationShow = [];

            await Promise.all(
              updatedDataFormations.map(async (formasyon) => {
                forms[formasyon.slug] = [];
              })
            );
            let DummyDataBody = dataBody2[0];
            await Promise.all(
              formasyons.map(async (formasyon) => {
                let returnPeriyot = await handlePeiods(formasyon.periyot);
                let searchData = {
                  formation: handleFormasyon(formasyon.value)[0].slug,
                  market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                  period: returnPeriyot,
                };
                searching = _.filter(DummyDataBody, {
                  formations: [searchData],
                });
                searching.map((item) => {
                  item.formations.map((form) => {
                    form.show = 0;
                  });
                });
                forms[formasyon.value].push(searching);
              })
            );
            console.log("forms", forms);
            Object.keys(forms).map((subForms) => {
              if (forms[subForms].length > 0) {
                tempData.push(forms[subForms][0]);
              }
            });

            dataOrtak = findCommonItems(tempData);

            // console.log("formationShow: ", searching, formationShow);
          }
        }
        // console.log("_filteler: ", tempData, dataOrtak);
      } else {
        //console.log("no Filters", dataBody2[0]);
        formasyon_remove = 1;
        dataOrtak = dataBody2[0];
      }
      const ortak_hisseler = [];
      if (indicators.length > 0) {
        //console.log("data indicators", dataOrtak);
        let tempData = [];
        let indi = [];
        indicators.map((indicator) => {
          let slug_value = handleIndicatorName(indicator.value)[0].slug;
          //console.log("slug_value", slug_value);
          indi[slug_value] = [];
          let searchData = {
            slug: slug_value,
            deger: indicator.yon == "BOĞA" ? "green" : "red",
          };
          let searching = _.filter(dataOrtak, {
            indicators: [searchData],
          });
          //console.log("searching indicators", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        //console.log("indi", indi);
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortak_indicators = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        ortak_hisseler.push(ortak_indicators);
        ////console.log("ortak_indicators", ortak_indicators);
      }
      if (hacims.length > 0) {
        //console.log("data hacim", dataOrtak);
        let tempData = [];
        let indi = [];
        hacims.map((hacim) => {
          let slug_value = handleHacimName(hacim.value)[0].slug;
          ////console.log("slug_value", slug_value);
          indi[slug_value] = [];
          let searchData = {
            slug: slug_value,
            deger: hacim.yon == "BOĞA" ? "green" : "red",
          };
          let searching = _.filter(dataOrtak, {
            indicators: [searchData],
          });
          //console.log("searching hacim", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        ////console.log("indi", indi);
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortak_hacim = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        ortak_hisseler.push(ortak_hacim);
        ////console.log("ortak_hacim:", ortak_hacim);
      }
      if (ortalamas.length > 0) {
        //console.log("data ortalama", dataOrtak);
        let tempData = [];
        let indi = [];
        ortalamas.map((ortalama) => {
          let slug_value = handleOrtalamaName(ortalama.value)[0].slug;
          ////console.log("slug_value", slug_value);
          indi[slug_value] = [];
          let searchData = {
            slug: slug_value,
            deger: ortalama.yon == "BOĞA" ? "green" : "red",
          };
          let searching = _.filter(dataOrtak, {
            indicators: [searchData],
          });
          //console.log("searching ortalama", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        ////console.log("indi", indi);
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortak_ortalama = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        ortak_hisseler.push(ortak_ortalama);
        ////console.log("ortak_ortalama:", ortak_ortalama);
      }
      if (
        ortak_indicators.length > 0 ||
        ortak_hacim.length > 0 ||
        ortak_ortalama.length > 0
      ) {
        ////console.log("ortak_hisseler", ortak_hisseler);
        dataOrtak = _.uniqBy(ortak_hisseler, "name")[0];
      } else {
        other_remove = 1;
      }
      if (formasyon_remove == 1 && other_remove == 1) {
        dataOrtak = dataBody2[0];
      }
      // ////console.log("_filteler: ", data, dataBody2);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      ////console.log("data", dataOrtak);
      let hisseAdet = objectLength(dataOrtak);
      let taramaAdet = Object.values(dataOrtak).reduce(
        (count, current) => count + current.formations.length,
        0
      );
      if (hisseAdet == 0) {
        data = [];
        taramaAdet = 0;
        hisseAdet = 0;
      }

      setDataFilter(dataOrtak);
      setDataBody([dataOrtak]);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      timeout(1000);
      setIsProcessing(false);
      setVisibleFiltre(false);
    }
  };

  const funcSelectPeriyotSonuc = () => {
    // setSonucYok(false);
    setIsProcessing(true);
    timeout(1000);
    setIsProcessing(false);
    setVisiblePeriyot(false);
  };

  const funcSelectYonSonuc = () => {
    // setSonucYok(false);
    setIsProcessing(true);
    timeout(1000);
    setIsProcessing(false);
    setVisibleYon(false);
  };

  const handleSortType = (type) => {
    setSortType(type);
    setSortTypeChange("default");
  };
  const _listeSelect = (id) => {
    setSelectListe(id);
  };
  function handleSortingChange(sorting, column) {
    // console.log("sorting", sorting, "column", column);
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    if (sorting === "default") {
      setSortTypeChange("down");
      data = _.orderBy(newData, [column], ["desc"]);
    }
    if (sorting === "down") {
      setSortTypeChange("up");
      data = _.orderBy(newData, [column], ["asc"]);
    }
    if (sorting === "up") {
      setSortTypeChange("default");
      data = newData;
    }
    setDataBody([data]);
  }

  function marketCallback(item) {
    return {
      value: item.id,
      stock_id: item.stock_id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    setIsProcessing(true);

    let counter = 5;
    while (true) {
      try {
        const urlApiDetay = `${urlApi}markets.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlApiDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(marketCallback),
        });

        // console.log("markets completed: ", dataDummy[0]);
        setMarkets(dataDummy[0]);
        let newMarket = [];
        let newValue = 1;
        newMarket = _.filter(dataDummy[0].items, function (o) {
          return o.exchange_id == newValue;
        });
        setMarketler(newMarket);
        setSearchMarket(newMarket);
        setIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          console.log("markets network error: ", error);
          setIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };
  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _searchSembol = (text) => {
    setIsProcessing(true);
    let newMarkets = searchMarket;
    if (text) {
      newMarkets = _.filter(newMarkets, function (o) {
        return o.label.includes(turkceIngilizceBuyukHarfCevir(text));
      });
    }
    setSearch(text);
    setMarketler(newMarkets);
    setIsProcessing(false);
  };
  const _getPeriyots = async () => {
    setIsProcessing(true);

    let counter = 5;
    while (true) {
      try {
        const urlApiDetay = `${urlApi}intervals.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlApiDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(periyotCallback),
        });

        // console.log("periyots completed: ", dataDummy[0]);
        setPeriyots(dataDummy[0]);
        localStorage.setItem("periyots", JSON.stringify(dataDummy[0]));
        setIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          console.log("periyots network error: ", error);
          setIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };
  const handleJust = (value, text) => {
    console.log("handleJust", value, text, filtreFormationLists);
    handleFormation("None");
    setBayzJustClosed(value);
  };
  const handleFormation = (selectedSembol) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    if (selectedSembol == "All") {
      let filteredData = updatedDataFormations;
      if (user.bayz == 0) {
        filteredData = updatedDataFormations.filter((item) => item.sort > 3);
      }
      console.log("filteredData", filteredData);
      let nameValues = filteredData.map((item) => item.slug);
      setFormationLists(nameValues);
    } else if (selectedSembol == "None") {
      setFormationLists([]);
    } else {
      if (formationLists.includes(selectedSembol)) {
        setFormationLists(
          formationLists.filter((item) => item !== selectedSembol)
        );
      } else {
        setFormationLists([...formationLists, selectedSembol]);
      }
    }
    handleFiltreFormation(selectedSembol);
  };

  const handleFiltreFormation = async (newValue) => {
    console.log("handleFiltreFormation", newValue);
    let filtreValues = [];
    if (newValue == "All") {
      let filteredData = updatedDataFormations;
      if (user.bayz == 0) {
        filteredData = updatedDataFormations.filter((item) => item.sort > 3);
      }
      let nameValues = filteredData.map((item) => item.slug);

      filtreValues = addPropertiesToItems(
        nameValues,
        selectedPeriyot,
        selectedYon
      );
    } else if (newValue == "None") {
      filtreValues = [];
    } else {
      filtreValues = filtreFormationLists;
      let thisAyi = newValue == "OBO" ? 1 : 0;
      let thisBoga = thisBogaLists.findIndex((item) => item === newValue);
      const bayzIndex = filtreValues.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreValues.splice(bayzIndex, 1);
      } else {
        filtreValues.push({
          periyot: selectedPeriyot,
          yon: thisAyi == 1 ? "AYI" : thisBoga != -1 ? "BOĞA" : selectedYon,
          value: newValue,
        });
      }
    }
    console.log("filtreValues", filtreValues);

    _setFiltreValues(filtreValues);
  };
  const handleHacim = (selectedSembol) => {
    console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataHacim.map((item) => item.slug);
      setHacimLists(nameValues);
    } else if (selectedSembol == "None") {
      setHacimLists([]);
    } else {
      if (hacimLists.includes(selectedSembol)) {
        setHacimLists(hacimLists.filter((item) => item !== selectedSembol));
      } else {
        setHacimLists([...hacimLists, selectedSembol]);
      }
    }
    handleFiltreHacim(selectedSembol);
  };

  const handleFiltreHacim = async (newValue) => {
    console.log("value", newValue);
    let filtreValues = [];
    if (newValue == "All") {
      let nameValues = updatedDataHacim.map((item) => item.slug);
      filtreValues = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreValues = [];
    } else {
      filtreValues = filtreHacimLists;

      const bayzIndex = filtreValues.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreValues.splice(bayzIndex, 1);
      } else {
        filtreValues.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    console.log("filtreValues", filtreValues);

    _setFiltreHacim(filtreValues);
  };

  const handleIndicator = (selectedSembol) => {
    console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataIndicators.map((item) => item.slug);
      setIndicatorLists(nameValues);
    } else if (selectedSembol == "None") {
      setIndicatorLists([]);
    } else {
      if (indicatorLists.includes(selectedSembol)) {
        setIndicatorLists(
          indicatorLists.filter((item) => item !== selectedSembol)
        );
      } else {
        setIndicatorLists([...indicatorLists, selectedSembol]);
      }
    }
    handleFiltreIndicator(selectedSembol);
  };

  const handleFiltreIndicator = async (newValue) => {
    console.log("value", newValue);
    let filtreIndicator = [];
    if (newValue == "All") {
      let nameValues = updatedDataIndicators.map((item) => item.slug);
      filtreIndicator = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreIndicator = [];
    } else {
      filtreIndicator = filtreIndicatorLists;

      const bayzIndex = filtreIndicator.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreIndicator.splice(bayzIndex, 1);
      } else {
        filtreIndicator.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    console.log("filtreIndicator", filtreIndicator);

    _setFiltreIndicator(filtreIndicator);
  };

  const handleOrtalama = (selectedSembol) => {
    console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataOrtalamalar.map((item) => item.slug);
      setOrtalamaLists(nameValues);
    } else if (selectedSembol == "None") {
      setOrtalamaLists([]);
    } else {
      if (ortalamaLists.includes(selectedSembol)) {
        setOrtalamaLists(
          ortalamaLists.filter((item) => item !== selectedSembol)
        );
      } else {
        setOrtalamaLists([...ortalamaLists, selectedSembol]);
      }
    }
    handleFiltreOrtalama(selectedSembol);
  };

  const handleFiltreOrtalama = async (newValue) => {
    console.log("value", newValue);
    let filtreOrtalama = [];
    if (newValue == "All") {
      let nameValues = updatedDataOrtalamalar.map((item) => item.slug);
      filtreOrtalama = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreOrtalama = [];
    } else {
      filtreOrtalama = filtreOrtalamaLists;

      const bayzIndex = filtreOrtalama.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreOrtalama.splice(bayzIndex, 1);
      } else {
        filtreOrtalama.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    console.log("filtreOrtalama", filtreOrtalama);

    _setFiltreOrtalama(filtreOrtalama);
  };
  function addPropertiesToItems(filterFormationLists, periyot, yon) {
    return filterFormationLists.map((item) => {
      const value = item; // item'ı direkt olarak value olarak kullanıyoruz
      let thisAyi = value == "OBO" ? 1 : 0;
      let thisBoga = thisBogaLists.findIndex((item) => item === value);
      return {
        periyot,
        yon: thisAyi == 1 ? "AYI" : thisBoga != -1 ? "BOĞA" : yon,
        value,
      };
    });
  }

  const _setFiltreValues = (filtreValues) => {
    setFiltreFormationLists(filtreValues);
    console.log("filtreValues json", JSON.stringify(filtreValues));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("editFormationLists", JSON.stringify(filtreValues));
  };
  const _setFiltreHacim = (filtreHacim) => {
    setFiltreHacimLists(filtreHacim);
    console.log("filtreHacim json", JSON.stringify(filtreHacim));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("editHacimLists", JSON.stringify(filtreHacim));
  };
  const _setFiltreIndicator = (filtreIndicator) => {
    setFiltreIndicatorLists(filtreIndicator);
    console.log("filtreIndicator json", JSON.stringify(filtreIndicator));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("editIndicatorLists", JSON.stringify(filtreIndicator));
  };
  const _setFiltreOrtalama = (filtreOrtalanma) => {
    console.log("filtreOrtalanma json", JSON.stringify(filtreOrtalanma));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("editOrtalamaLists", JSON.stringify(filtreOrtalanma));
    setFiltreOrtalamaLists(filtreOrtalanma);
  };
  async function _getIndicators() {
    let date = new Date();
    let timestamp = date.getTime();
    const postData = {
      access_token: user.access_token,
      exchange_id: selectedBorsaId,
    };
    let res = await postDataWithHeaders(
      INDICATORS + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = res.data;
      //console.log("resData Indicators", resData);
      // console.log(
      //   "resData[0].indicators.length)",
      //   resData[0].indicators.length
      // );
      localStorage.setItem("dataIndicator", JSON.stringify(resData));
      localStorage.setItem("stratejiAdet", resData[0].indicators.length);
      setDataIndicator(resData);
      setStratejiAdet(resData[0].indicators.length);
    }
  }
  async function _getDataFormations() {
    let date = new Date();
    let timestamp = date.getTime();
    setSonucYok(true);
    setIsProcessing(true);
    setDataFormation(null);

    const postData = {
      access_token: user.access_token,
      exchange_id: selectedBorsaId,
      bayz_just_closed: 0,
    };
    let res = await postDataWithHeaders(
      `${urlApi}search-all.php?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = [];
      resData = _.groupBy(res.data.data, function (n) {
        return n.stock_code;
      });
      setDataFormation(resData);
      // localStorage.setItem("dataFormation", JSON.stringify(resData));

      let dataIndicator = localStorage.getItem("dataIndicator");

      let filterDatas = [];
      if (dataIndicator) {
        let resDataIndicator = JSON.parse(dataIndicator);
        await Promise.all(
          resDataIndicator.map((dataInd, ix) => {
            let bull = 0;
            let bear = 0;
            let formations = [];

            if (resData[dataInd.stock_code]) {
              formations = resData[dataInd.stock_code];
              bull = countMarket(resData[dataInd.stock_code]).BULL
                ? countMarket(resData[dataInd.stock_code]).BULL
                : countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR * -1
                : 0;
              bear = countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR
                : 0;
            }
            formations.length > 0 &&
              filterDatas.push({
                stock_id: dataInd.stock_id,
                name: dataInd.stock_code,
                exchange_id: dataInd.exchange_id,
                puan: dataInd.puan,
                bull: bull,
                bear: bear,
                indicators: dataInd.indicators,
                formations: formations,
              });
          })
        );
      }

      console.log("filterDatas", filterDatas);
      setDataFilter(filterDatas);
      setDataBody([filterDatas]);
      setDataBody2([filterDatas]);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      setIsProcessing(false);

      let hisseAdet = objectLength(resData);
      let taramaAdet = Object.values(resData).reduce(
        (count, current) => count + current.length,
        0
      );
      //console.log(hisseAdet, taramaAdet);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setLoadFilter(true);
    } else {
      setIsProcessing(false);
      toast.error(res.data);
      setSonucYok(false);
      if (res.code === "-1") {
        // setInterval(() => {
        //   window.location.href = "/uye-cikis";
        // }, 2000);
      }
    }
  }
  const handleShowGrafik = (formation_id, loadChartType) => {
    setModalGrafik(true);
    // //console.log(formation_id, loadChartType)
    setFormationId(formation_id);
    setLoadChart(loadChartType);
  };
  const handleButton = (fMarket) => {
    let newColor = "default";
    if (fMarket == "BULL") {
      newColor = "success";
    } else {
      newColor = "danger";
    }
    return newColor;
  };

  const handleYonToast = (value, ayi, boga) => {
    let message = "";
    if (ayi == 0) {
      message = `${value} formasyonunda sadece "AYI" yönü seçilebilir.`;
    } else {
      message = `${value} formasyonunda sadece "BOĞA" yönü seçilebilir.`;
    }
    toast.error(message);
  };

  const handleShowPuan = (hisseName) => {
    setModalIndicator(true);
    // //console.log(hisseName)
    setPuanName(hisseName);
  };
  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const filterDataBody = (dataBody, values) => {
    return _.filter(dataBody, (item) => {
      return _.includes(values, item.name);
    });
  };
  const _setVisibleSembolFunc = () => {
    setVisibleSembol(true);
    setLoadData(true);
  };
  const countFormationArray = (filteredData) => {
    return _.sumBy(filteredData, "formations.length");
  };
  const funcSelectReset = () => {
    setIsProcessing(true);

    setSelectedFormation([]);
    setFiltreFormationLists([]);
    setFiltreHacimLists([]);
    setFiltreOrtalamaLists([]);
    setFiltreIndicatorLists([]);
    setOrtalamaLists([]);
    setHacimLists([]);
    setFormationLists([]);
    setIndicatorLists([]);

    localStorage.setItem("formationEditLists", JSON.stringify([]));
    localStorage.setItem("ortalamaEditLists", JSON.stringify([]));
    localStorage.setItem("indicatorEditLists", JSON.stringify([]));
    localStorage.setItem("hacimEditLists", JSON.stringify([]));
    localStorage.setItem("selectedFormation", JSON.stringify([]));
    localStorage.setItem("editFormationLists", JSON.stringify([]));
    localStorage.setItem("editHacimLists", JSON.stringify([]));
    localStorage.setItem("editOrtalamaLists", JSON.stringify([]));
    localStorage.setItem("editIndicatorLists", JSON.stringify([]));
    setAyrimi(0);
    setTaramaAdet(0);
    setHisseAdet(0);
    setSonucYok(false);
    setIsProcessing(false);
  };
  const resetFiltre = () => {
    setSelectedFormation([]);
    setFiltreFormationLists([]);
    setFiltreHacimLists([]);
    setFiltreOrtalamaLists([]);
    setFiltreIndicatorLists([]);
    setOrtalamaLists([]);
    setHacimLists([]);
    setFormationLists([]);
    setIndicatorLists([]);

    localStorage.setItem("formationEditLists", JSON.stringify([]));
    localStorage.setItem("ortalamaEditLists", JSON.stringify([]));
    localStorage.setItem("indicatorEditLists", JSON.stringify([]));
    localStorage.setItem("hacimEditLists", JSON.stringify([]));
    localStorage.setItem("selectedFormation", JSON.stringify([]));
    localStorage.setItem("editFormationLists", JSON.stringify([]));
    localStorage.setItem("editHacimLists", JSON.stringify([]));
    localStorage.setItem("editOrtalamaLists", JSON.stringify([]));
    localStorage.setItem("editIndicatorLists", JSON.stringify([]));
    setAyrimi(0);
    timeout(2000);
    _getDataFormations();
  };
  const funcSelectSymbols = () => {
    setLoadData(true);
    setIsProcessing(true);
    // console.log("selectedSembol", selectedSembol);
    console.log("dataBody", dataBody[0]);
    const filteredData = filterDataBody(dataBody[0], selectedSembol);
    timeout(1000);
    console.log("filteredData", dataBody, filteredData);
    setDataBody([filteredData]);
    let hisseAdet = objectLength(filteredData);
    let taramaAdet = countFormationArray(filteredData);
    // console.log(hisseAdet, taramaAdet);
    setTaramaAdet(taramaAdet);
    setHisseAdet(hisseAdet);
    setVisibleSembol(false);
    setSonucYok(true);
    setLoadData(false);
    timeout(1000);
    setIsProcessing(false);
  };

  const _getDataName = (data, slug) => {
    return _.filter(data, { slug: slug }).map((v) => v.label);
  };
  const fechtMarkets = async () => {
    await _getMarkets();
    await _getPeriyots();
  };

  useEffect(() => {
    if (periyots.length == 0) {
      fechtMarkets();
    }
  }, [periyots]);
  useEffect(() => {
    if (selectedBorsaId != 0 && loadData) {
      console.log(1);
      _getDataFormations();
      _getIndicators();
    }
  }, [selectedBorsaId, loadData]);
  useEffect(() => {
    if (visibleSembol && loadData && selectedSembol.length == 0) {
      console.log(2);
      _getDataFormations();
    }
  }, [selectedSembol, visibleSembol, loadData]);
  useEffect(() => {
    if (selectedBorsaId != 0 && dataBody == null) {
      console.log(3);
      _getDataFormations();
    }
  }, [selectedBorsaId]);
  const filtre2item = async (item) => {
    let returnPeriyot = await handlePeiods(item.periyot);
    let searchData = {
      formation: handleFormasyon(item.value)[0].slug,
      market: item.yon == "BOĞA" ? "BULL" : "BEAR",
      period: returnPeriyot,
    };
    return searchData;
  };
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("filtreFormationLists change", filtreFormationLists);
    localStorage.setItem(
      "editFormationLists",
      JSON.stringify(filtreFormationLists)
    );
    const filtre2 = [];
    filtreFormationLists.map(async (item) => {
      let searchData = await filtre2item(item);
      filtre2.push(searchData);
    });
    console.log("filtre2", filtre2);
    setFiltreFormationLists2(filtre2);

    setUpdatedDataFormations(updatedDataFormations);
  }, [filtreFormationLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("filtreHacimLists change", filtreHacimLists);
    localStorage.setItem("editHacimLists", JSON.stringify(filtreHacimLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreHacimLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("filtreIndicatorLists change", filtreIndicatorLists);
    localStorage.setItem(
      "editIndicatorLists",
      JSON.stringify(filtreIndicatorLists)
    );
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreIndicatorLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("filtreOrtalamaLists change", filtreOrtalamaLists);
    localStorage.setItem(
      "editOrtalamaLists",
      JSON.stringify(filtreOrtalamaLists)
    );
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreOrtalamaLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("ortalamaLists change", ortalamaLists);
    localStorage.setItem("ortalamaEditLists", JSON.stringify(ortalamaLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [ortalamaLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("hacimLists change", hacimLists);
    localStorage.setItem("hacimEditLists", JSON.stringify(hacimLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [hacimLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("indicatorLists change", indicatorLists);
    localStorage.setItem("indicatorEditLists", JSON.stringify(indicatorLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [indicatorLists]);
  useEffect(() => {
    timeout(2000);
    console.log("2sn");
    console.log("formationLists change", formationLists);
    localStorage.setItem("formationEditLists", JSON.stringify(formationLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [formationLists]);
  useEffect(() => {
    console.log(updatedDataFormations);
  }, [updatedDataFormations]);

  useEffect(() => {
    if (dataBody2 && periyots) {
      console.log("yeniden çalıştı");
      console.log(
        "first",
        filtreFormationLists.length,
        filtreHacimLists.length,
        filtreIndicatorLists.length,
        filtreOrtalamaLists.length
      );
      if (
        filtreFormationLists.length > 0 ||
        filtreHacimLists.length > 0 ||
        filtreIndicatorLists.length > 0 ||
        filtreOrtalamaLists.length > 0
      ) {
        console.log("oldu mu");
        funcSelectSonuc();
      }
    }
  }, [dataBody2, periyots]);

  const _getFormulData = async (id) => {
    const postData = {
      id: id,
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(FORMULAS, postData, dispatch).then(async (res) => {
      if (res.code == 0) {
        console.log("res.data formulas", res.data);
        setFormul(res.data.name);
        setFormulNote(res.data.note);
        setAyrimi(res.data.additionals.seperate);
        setBayzJustClosed(res.data.additionals.bayz_just_closed);
        setLast24Hour(res.data.additionals.last_24_hour);
        const formasyonEdit = [];
        const formasyonListEdit = [];
        await Promise.all(
          Object.entries(res.data.formations).map(async (item, key) => {
            let data = {
              periyot: await handlePeriod2(item[1].periods[0]),
              value: item[0],
              yon: item[1].side == 0 ? "AYI" : "BOĞA",
            };
            formasyonEdit.push(data);
            formasyonListEdit.push(item[0]);
          })
        );
        localStorage.setItem(
          "editFormationLists",
          JSON.stringify(formasyonEdit)
        );
        localStorage.setItem(
          "formationEditLists",
          JSON.stringify(formasyonListEdit)
        );
        setFormationLists(formasyonListEdit);
        setFiltreFormationLists(formasyonEdit);

        const volumesEdit = [];
        const volumesListEdit = [];
        await Promise.all(
          Object.entries(res.data.volumes).map(async (item, key) => {
            let data = {
              periyot: await handlePeriod2(item[1].periods[0]),
              value: item[0],
              yon: item[1].side == 0 ? "AYI" : "BOĞA",
            };
            volumesEdit.push(data);
            volumesListEdit.push(item[0]);
          })
        );
        localStorage.setItem("editHacimLists", JSON.stringify(volumesEdit));
        localStorage.setItem("hacimEditLists", JSON.stringify(volumesListEdit));
        setHacimLists(volumesListEdit);
        setFiltreHacimLists(volumesEdit);

        const averagesEdit = [];
        const averagesListEdit = [];
        await Promise.all(
          Object.entries(res.data.averages).map(async (item, key) => {
            let data = {
              periyot: await handlePeriod2(item[1].periods[0]),
              value: item[0],
              yon: item[1].side == 0 ? "AYI" : "BOĞA",
            };
            averagesEdit.push(data);
            averagesListEdit.push(item[0]);
          })
        );
        localStorage.setItem("editOrtalamaLists", JSON.stringify(averagesEdit));
        localStorage.setItem(
          "ortalamaEditLists",
          JSON.stringify(averagesListEdit)
        );
        setOrtalamaLists(averagesListEdit);
        setFiltreOrtalamaLists(averagesEdit);

        const indicatorsEdit = [];
        const indicatorsListEdit = [];
        await Promise.all(
          Object.entries(res.data.indicators).map(async (item, key) => {
            let data = {
              periyot: await handlePeriod2(item[1].periods[0]),
              value: item[0],
              yon: item[1].side == 0 ? "AYI" : "BOĞA",
            };
            indicatorsEdit.push(data);
            indicatorsListEdit.push(item[0]);
          })
        );
        localStorage.setItem(
          "editIndicatorLists",
          JSON.stringify(indicatorsEdit)
        );
        localStorage.setItem(
          "indicatorEditLists",
          JSON.stringify(indicatorsListEdit)
        );
        setIndicatorLists(indicatorsListEdit);
        setFiltreIndicatorLists(indicatorsEdit);
      } else {
        toast.error(res.data);
      }
    });
  };

  useEffect(() => {
    console.log("formulId", formulId);
    _getFormulData(formulId);
  }, [formulId]);

  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row className="OzelArama__row">
            <Col lg={12}>
              <div
                className="OzelArama__back"
                onClick={() => navigate("/ozel-arama")}
              >
                <IoMdArrowBack /> Özel Aramaya Dön
              </div>
            </Col>
          </Row>
          <Row>
            <div className="DashBoard_container">
              <div className="AramaPaneli">
                <div
                  className="AramaSelect active"
                  onClick={() => setVisibleBorsa(true)}
                >
                  <div className="AramaSelect__body">
                    <div className="AramaSelect__title">Borsa Seç</div>
                    <div className="AramaSelect__text">
                      {selectedBorsa ? selectedBorsa : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                <div
                  className="AramaSelect active"
                  onClick={() => _setVisibleSembolFunc(true)}
                >
                  <div className="AramaSelect__body">
                    <div className="AramaSelect__title">Sembol Seç</div>
                    <div className="AramaSelect__text">
                      {selectedSembol.length > 0
                        ? selectedSembol[0] + `, + ${selectedSembol.length - 1}`
                        : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    selectedBorsa ? "AramaSelect active" : "AramaSelect"
                  }
                  onClick={() =>
                    selectedBorsa ? setVisiblePeriyot(true) : null
                  }
                >
                  <div className="AramaSelect__body">
                    <div className="AramaSelect__title">Periyot Seç</div>
                    <div className="AramaSelect__text">
                      {selectedPeriyot ? selectedPeriyot : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    selectedBorsa ? "AramaSelect active" : "AramaSelect"
                  }
                  onClick={() => (selectedBorsa ? setVisibleYon(true) : null)}
                >
                  <div className="AramaSelect__body">
                    <div className="AramaSelect__title">Yön Seç</div>
                    <div className="AramaSelect__text">
                      {selectedYon ? selectedYon : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    selectedPeriyot ? "AramaSelect active" : "AramaSelect"
                  }
                  onClick={() => (selectedPeriyot ? _setVisibleFiltre() : null)}
                >
                  <div className="AramaSelect__body">
                    <div className="AramaSelect__title">Filtre Seç</div>
                    <div className="AramaSelect__text">
                      {filtreFormationLists.length > 0 ||
                      filtreHacimLists.length > 0 ||
                      filtreIndicatorLists.length > 0 ||
                      filtreOrtalamaLists.length > 0
                        ? "Filtreleme Seçildi"
                        : "Henüz Seçilmedi"}
                    </div>
                  </div>
                </div>
                {/* <div
                  className="AramaToplu"
                  onClick={() => funcListeTopluEkle()}
                >
                  <TopluEkle />
                  <div className="AramaToplu__text">Toplu Listeye Ekle</div>
                </div> */}
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={12} className="AnaKumanda__container">
              {!dataBody ? (
                <>
                  <div className="AnaKumanda__sonuc">
                    <div className="AnaKumanda__sonucyok">
                      <AnaKumandaSonucYok />
                      <div className="AnaKumanda__sonucyok__text">
                        Henüz arama yapmadınız. <br />
                        hemen borsa seçerek Sirius’un dünyasını keşfetmeye
                        başlayın.
                        <br />
                        <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="AnaKumanda__filtre">
                    <div
                      className="AnaKumanda__filtre__click"
                      onClick={() => _setVisibleFiltre()}
                    >
                      {selectedBorsa && (
                        <div className="AnaKumanda__filtre__body">
                          <div className="AnaKumanda__filtre__title">Borsa</div>
                          <div className="AnaKumanda__filtre__text">
                            {selectedBorsa}
                          </div>
                        </div>
                      )}
                      {selectedSembol.length > 0 && (
                        <div className="AnaKumanda__filtre__body">
                          <div className="AnaKumanda__filtre__title">
                            Semboller
                          </div>
                          <div className="AnaKumanda__filtre__text">
                            {selectedSembol[0] +
                              `, + ${selectedSembol.length - 1}`}
                          </div>
                        </div>
                      )}
                      {filtreFormationLists.length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              Formasyon
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div
                                className="AnaKumanda__filtre__bottom__left"
                                onClick={() => _setVisibleFiltre()}
                              >
                                {filtreFormationLists[0].value}{" "}
                                <span className="AnaKumanda__filtre__bottom__left__period">
                                  {filtreFormationLists[0].periyot}
                                </span>
                                {filtreFormationLists[0].yon == "BOĞA" ? (
                                  <ArrowUp color="#5fb045" />
                                ) : filtreFormationLists[0].yon == "AYI" ? (
                                  <ArrowDown color="#E12222" />
                                ) : (
                                  <ArrowSort color="#9a9a9a" />
                                )}
                                <ButtonClose />
                              </div>
                              {filtreFormationLists.length - 1 > 0 && (
                                <div
                                  className="AnaKumanda__filtre__bottom__right"
                                  onClick={() => _setVisibleFiltre()}
                                >
                                  +{filtreFormationLists.length - 1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                      {filtreHacimLists.length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">Hacim</div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div
                                className="AnaKumanda__filtre__bottom__left"
                                onClick={() => _setVisibleFiltre()}
                              >
                                {_getDataName(
                                  updatedDataHacim,
                                  filtreHacimLists[0].value
                                )}
                                {filtreHacimLists[0].yon == "BOĞA" ? (
                                  <ArrowUp color="#5fb045" />
                                ) : filtreHacimLists[0].yon == "AYI" ? (
                                  <ArrowDown color="#E12222" />
                                ) : (
                                  <ArrowSort color="#9a9a9a" />
                                )}
                                <ButtonClose />
                              </div>
                              {filtreHacimLists.length > 1 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +{filtreHacimLists.length - 1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                      {filtreOrtalamaLists.length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              Ortalamalar
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div
                                className="AnaKumanda__filtre__bottom__left"
                                onClick={() => _setVisibleFiltre()}
                              >
                                {_getDataName(
                                  updatedDataOrtalamalar,
                                  filtreOrtalamaLists[0].value
                                )}
                                {filtreOrtalamaLists[0].yon == "BOĞA" ? (
                                  <ArrowUp color="#5fb045" />
                                ) : filtreOrtalamaLists[0].yon == "AYI" ? (
                                  <ArrowDown color="#E12222" />
                                ) : (
                                  <ArrowSort color="#9a9a9a" />
                                )}
                                <ButtonClose />
                              </div>
                              {filtreOrtalamaLists.length > 1 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +{filtreOrtalamaLists.length - 1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                      {filtreIndicatorLists.length > 0 && (
                        <>
                          <div className="AnaKumanda__filtre__container">
                            <div className="AnaKumanda__filtre__top">
                              İndikatörler
                            </div>
                            <div className="AnaKumanda__filtre__bottom">
                              <div
                                className="AnaKumanda__filtre__bottom__left"
                                onClick={() => _setVisibleFiltre()}
                              >
                                {_getDataName(
                                  updatedDataIndicators,
                                  filtreIndicatorLists[0].value
                                )}
                                {filtreIndicatorLists[0].yon == "BOĞA" ? (
                                  <ArrowUp color="#5fb045" />
                                ) : filtreIndicatorLists[0].yon == "AYI" ? (
                                  <ArrowDown color="#E12222" />
                                ) : (
                                  <ArrowSort color="#9a9a9a" />
                                )}
                                <ButtonClose />
                              </div>
                              {filtreIndicatorLists.length > 1 && (
                                <div className="AnaKumanda__filtre__bottom__right">
                                  +{filtreIndicatorLists.length - 1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <img src={araCizgi} />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="AnaKumanda__filtre__container gap24 flex-row ml-auto">
                      <BsPencilFill
                        color="#fff"
                        onClick={() => _setVisibleFiltre(true)}
                      />
                    </div>
                  </div>
                  <div className="Sonuclar_container">
                    <div className="Sonuclar_left">
                      <div className="Sonuclar_left__title">Sonuçlar</div>
                      <div className="Sonuclar_left__text">
                        {hisseAdet} {hisseType} - {taramaAdet} Formasyon
                      </div>
                    </div>
                    {hisseAdet != 0 && (
                      <div className="Sonuclar_right">
                        <div className="Sonuclar_right__container">
                          <div
                            className={
                              sortType == 2
                                ? "Sonuclar_right__sort active"
                                : "Sonuclar_right__sort"
                            }
                            onClick={() =>
                              sortType == 2
                                ? handleSortingChange(sortTypeChange, "puan")
                                : handleSortType(2)
                            }
                          >
                            Stratejiye Göre Sırala
                            {sortType == 2 && (
                              <div className="Sonuclar_right__sort__icon">
                                {sortTypeChange == "default" ? (
                                  <ArrowSort />
                                ) : sortTypeChange == "up" ? (
                                  <ArrowDown color="red" />
                                ) : (
                                  <ArrowUp color="#5fb045" />
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              sortType == 1
                                ? "Sonuclar_right__sort active"
                                : "Sonuclar_right__sort"
                            }
                            onClick={() =>
                              sortType == 1
                                ? handleSortingChange(sortTypeChange, "bull")
                                : handleSortType(1)
                            }
                          >
                            Boğaya Göre Sırala
                            {sortType == 1 && (
                              <div className="Sonuclar_right__sort__icon">
                                {sortTypeChange == "default" ? (
                                  <ArrowSort />
                                ) : sortTypeChange == "up" ? (
                                  <ArrowDown color="red" />
                                ) : (
                                  <ArrowUp color="#5fb045" />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="Sonuclar__body">
                    {hisseAdet == 0 && (
                      <div style={{ width: "100%" }}>
                        <div className="AnaKumanda__sonuc">
                          <div className="AnaKumanda__sonucyok">
                            <AnaKumandaSonucYok />
                            <div className="AnaKumanda__sonucyok__text">
                              Sonuç bulunamadı. <br />
                              Lütfen ayarları kontrol ediniz!
                              <br />
                              <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isProcessing && (
                      <div style={{ width: "100%" }}>
                        <div className="AnaKumanda__sonuc">
                          <div className="AnaKumanda__sonucyok">
                            <PulseLoader
                              color="#fff"
                              aria-label="Sonuçlar Getiriliyor..."
                            />
                            <div className="AnaKumanda__sonucyok__text">
                              Sonuçlar yükleniyor...
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {dataBody.map((data, index) =>
                      Object.values(data).map((forms, indix) => {
                        {
                          /* console.log("forms", forms); */
                        }
                        let count = 0;
                        let bayzClosed = 0;
                        forms.formations &&
                          forms.formations.map((formArray, indx) => {
                            if (formArray.bayz_just_closed == 1) {
                              bayzClosed = 1;
                            }
                            let isShow =
                              _.filter(filtreFormationLists2, {
                                formation: formArray.formation,
                                period: formArray.period,
                                market: formArray.market,
                              }).length > 0
                                ? true
                                : false;

                            if (isShow) {
                              count = count + 1;
                              formArray.show = 1;
                            }
                            return count;
                          });
                        let showCount = forms.formations.length - count;
                        let exchange_id = forms.exchange_id;
                        let puan = forms.puan;
                        if (bayzClosed == 0 && bayzJustClosed == 1) {
                          return;
                        }
                        return (
                          <div
                            key={indix}
                            className="Sonuclar__body__container"
                          >
                            <div className="Sonuclar__body__container__left">
                              {/* <SelectIcon
                                hisseName={forms.name}
                                fStocks={topluEkleLists}
                                func={handleTopluEkle}
                              /> */}

                              <SonucTekEkle
                                onClick={() => funcListeEkle(forms)}
                              />
                              {/* <SonucAlarm
                                className="Sonuclar__body__container__left__alarm"
                                onClick={() => funcAlarmFiyat(forms.name)}
                              /> */}
                              <Link
                                to={`/grafik-paneli/${forms.name}`}
                                target="_blank"
                              >
                                <SonucGrafik />
                              </Link>
                              <span className="Sonuclar__body__container__left__text">
                                {forms.name}
                              </span>
                              <div className="Sonuclar__body__container__left_border ml-auto"></div>
                            </div>

                            <div className="Sonuclar__body__container__right">
                              {forms.formations &&
                                forms.formations.map((formArray, indx) => {
                                  let icon =
                                    formArray.market === "BULL" ? (
                                      <ArrowUp color="#5fb045" />
                                    ) : (
                                      <ArrowDown color="#E12222" />
                                    );
                                  let boxButton = handleButton(
                                    formArray.market
                                  );
                                  let filePath = "";
                                  let showFormation =
                                    dahaGoster && dahaGosterId === forms.name
                                      ? "d-block"
                                      : formArray.show == 1
                                      ? "d-block"
                                      : "d-none";

                                  if (count == 0) {
                                    showFormation = "d-block";
                                  }
                                  return (
                                    <Button
                                      key={indx}
                                      className={`Sonuclar__body__button iShow ${showFormation}  ${formArray.formation} btn-sm ${boxButton}`}
                                      onClick={() =>
                                        // handleShow(
                                        //   filePath,
                                        //   formArray.stock_code,
                                        //   formArray.formation,
                                        //   formArray.inserted
                                        // )
                                        handleShowGrafik(
                                          formArray.id,
                                          formArray.chart
                                        )
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginBottom: 5,
                                        // backgroundColor: `${handleColor(
                                        //   formArray.formationColor,
                                        //   formArray.formation,
                                        //   formArray.market
                                        // )}`,
                                      }}
                                    >
                                      {icon} {handleName(formArray.formation)}{" "}
                                      {formArray.bayz_percentage
                                        ? `(${formArray.bayz_percentage}%)`
                                        : ""}{" "}
                                      {`(${formArray.period})`}
                                    </Button>
                                  );
                                })}
                            </div>
                            <div className="Sonuclar__body__container__right_count">
                              {count > 0 && (
                                <span
                                  className="dahaGoster"
                                  onClick={() => _setDahaGoster(forms.name)}
                                >
                                  {showCount} Adet daha var
                                </span>
                              )}
                            </div>
                            <div
                              className="Sonuclar__body__strateji"
                              onClick={() => handleShowPuan(forms.name)}
                            >
                              <span className="Sonuclar__body__strateji__text">
                                Strateji
                              </span>
                              <span className="Sonuclar__body__strateji__puan">
                                {puan}/{updateDataAll.length}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {visibleBorsa && (
        <Modal
          show={visibleBorsa}
          onHide={handleBorsaClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Borsa Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label,
                      borsa.value
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleSembol && (
        <Modal
          show={visibleSembol}
          onHide={handleSembolClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Sembol Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => _searchSembol(e.target.value)}
                />
              </div>
            </div>
            <div className="modalSelecteds">
              {selectedSembol.length > 0 &&
                selectedSembol.map((sembol) => {
                  return (
                    <div
                      key={sembol}
                      className="modalSelectButton active"
                      onClick={() => funcSelectSembol(sembol)}
                    >
                      {sembol}
                      <span className="modalSelectButton__close">
                        <ButtonClose />
                      </span>
                    </div>
                  );
                })}
            </div>

            <div className="modalSelectButtons">
              {marketler.map((sembol) => (
                <Button
                  key={sembol.label}
                  className={
                    selectedSembol == sembol.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectSembol(sembol.label)}
                >
                  {sembol.label}
                </Button>
              ))}
            </div>
            <Button
              onClick={() => funcSelectSymbols()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Sonuçları Listele"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visiblePeriyot && (
        <Modal
          show={visiblePeriyot}
          onHide={handlePeriyotClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Periyot Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {periyots.items.map((period) => (
                <Button
                  key={period.label}
                  className={
                    selectedPeriyot == period.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectPeriyot(
                      selectedPeriyot == period.label ? "" : period.label
                    )
                  }
                >
                  {period.label}
                  {selectedPeriyot == period.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
            <Button
              onClick={() => funcSelectPeriyotSonuc()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Periyot Belirle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleYon && (
        <Modal
          show={visibleYon}
          onHide={handleYonClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Yön Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              <Button
                key={"BOĞA"}
                className={
                  selectedYon == "BOĞA"
                    ? "Sonuclar__body__button2 success active"
                    : "Sonuclar__body__button2 success"
                }
                onClick={() => funcSelectYon("BOĞA")}
              >
                {"BOĞA"}
              </Button>
              <Button
                key={"AYI"}
                className={
                  selectedYon == "AYI"
                    ? "Sonuclar__body__button2 danger active"
                    : "Sonuclar__body__button2 danger"
                }
                onClick={() => funcSelectYon("AYI")}
              >
                {"AYI"}
              </Button>
            </div>
            <Button
              onClick={() => funcSelectYonSonuc()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Yön Belirle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleFiltre && (
        <Modal
          show={visibleFiltre}
          onHide={handleFiltreClose}
          dialogClassName="modal-65w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Güncelle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  type="text"
                  name="formul"
                  placeholder="Formül adı giriniz"
                  value={formul}
                  onChange={(e) => setFormul(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="formulNote"
                  placeholder="Formül notu giriniz"
                  value={formulNote}
                  onChange={(e) => setFormulNote(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>

            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Formasyon</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz formasyonları seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>

            <div className="modalSelectDivUst">
              <div className="modalSelectDiv all">
                <div className="modalSelectDivTop">
                  <div className="modalSelectDiv__left mr-auto">
                    {formationLists.length == updatedDataFormationsLength ? (
                      <SonucSecildi
                        onClick={(e) => handleFormation("None", e)}
                      />
                    ) : (
                      <SonucSec onClick={(e) => handleFormation("All", e)} />
                    )}
                    Tümünü Seç
                  </div>
                </div>
              </div>
              <div className="modalSelectDiv all  ml-auto">
                <div className="modalSelectDivTop">
                  <div className="modalSelectDiv__right">
                    {user.bayz == 1 && (
                      <div className="modalSubDiv__footer__type">
                        {bayzJustClosed == 1 ? (
                          <SonucSecildi
                            onClick={() => handleJust(0, "bayzJustClosed")}
                          />
                        ) : (
                          <SonucSec
                            onClick={() => handleJust(1, "bayzJustNotClosed")}
                          />
                        )}
                        BayZ üstünde İlk Kapanış Yapanlar
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataFormations.map((formation, index) => {
                {
                  /* if (bayzJustClosed == 1 && !formation.slug.includes("BAYZ")) {
                  return null;
                } */
                }
                let thisFiltre = filtreFormationLists.findIndex(
                  (item) => item.value === formation.slug
                );

                let thisBoga = thisBogaLists.findIndex(
                  (item) => item === formation.slug
                );

                let thisAyi = formation.label == "OBO" ? 0 : 1;

                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreFormationLists[thisFiltre].yon;

                let bayz =
                  user.bayz == 1
                    ? true
                    : user.bayz == 0 && index < 4
                    ? false
                    : true;
                let secilen = formationLists.some(
                  (item) => item === formation.slug
                );
                return bayz ? (
                  <div
                    key={index}
                    className={
                      periyotOpen == formation.label
                        ? "modalSelectDiv period "
                        : "modalSelectDiv "
                    }
                  >
                    <div
                      className={
                        secilen
                          ? "modalSelectDivTop secilen"
                          : "modalSelectDivTop"
                      }
                    >
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleFormation(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleFormation(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__period"
                          onClick={() => handlePeriyotOpen(formation.label)}
                        >
                          {thisFiltre == -1
                            ? "Periyot"
                            : filtreFormationLists[thisFiltre].periyot}{" "}
                          <ArrowThinDown color="#9a9a9a" />
                        </div>
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() =>
                            thisAyi == 0 || thisBoga != -1
                              ? handleYonToast(
                                  formation.label,
                                  thisAyi,
                                  thisBoga
                                )
                              : handleYonOpen(formation.label)
                          }
                        >
                          {thisAyi == 0 ? (
                            <ArrowDown color="#E12222" />
                          ) : thisBoga != -1 ? (
                            <ArrowUp color="#22DE2A" />
                          ) : thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {periyotOpen == formation.label && (
                      <div className="modalSelectDiv__Bottom">
                        {periyots.items.map((period) => (
                          <div
                            key={period.value}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handlePeriyot(formation.slug, period.label)
                            }
                          >
                            {period.value}
                          </div>
                        ))}
                      </div>
                    )}
                    {yonOpen == formation.label && (
                      <div className="modalSelectDiv__Bottom">
                        {thisAyi == 0 ? (
                          <div
                            className="modalSelectDiv__periyot__item"
                            onClick={() => handleYon(formation.slug, "AYI")}
                          >
                            AYI
                          </div>
                        ) : thisBoga != -1 ? (
                          <div
                            className="modalSelectDiv__periyot__item"
                            onClick={() => handleYon(formation.slug, "BOĞA")}
                          >
                            BOĞA
                          </div>
                        ) : (
                          dataYon2.map((yon) => (
                            <div
                              key={yon.label}
                              className="modalSelectDiv__periyot__item"
                              onClick={() =>
                                handleYon(formation.slug, yon.label)
                              }
                            >
                              {yon.label}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div key={index} className={"modalSelectDiv opacity05"}>
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        <SonucSec />
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div className="modalSelectDiv__right__period">
                          Periyot
                          <ArrowThinDown color="#9a9a9a" />
                        </div>
                        <div className="modalSelectDiv__right__yon">
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Hacim</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz hacimleri seçerek filtreleme işlemine devam
                ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {hacimLists.length == updatedDataHacim.length ? (
                    <SonucSecildi onClick={(e) => handleHacim("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleHacim("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataHacim.map((formation, index) => {
                let secilen = hacimLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreHacimLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreHacimLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleHacim(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleHacim(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonHacimOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonHacimOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleHacimYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Ortalamalar</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz ortalamaları seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {ortalamaLists.length == updatedDataOrtalamalar.length ? (
                    <SonucSecildi onClick={(e) => handleOrtalama("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleOrtalama("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataOrtalamalar.map((formation, index) => {
                let secilen = ortalamaLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreOrtalamaLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreOrtalamaLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleOrtalama(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleOrtalama(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonOrtalamaOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonOrtalamaOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleOrtalamaYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">İndikatörler</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz indikatörleri seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {indicatorLists.length == updatedDataIndicators.length ? (
                    <SonucSecildi onClick={(e) => handleIndicator("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleIndicator("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataIndicators.map((formation, index) => {
                let secilen = indicatorLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreIndicatorLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreIndicatorLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleIndicator(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleIndicator(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonIndicatorOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonIndicatorOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleIndicatorYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__footer">
              <div className="modalSubDiv__footer__type">
                {ayrimi == 1 ? (
                  <SonucSecildi onClick={() => handleAyri(0, "Ayırılmış")} />
                ) : (
                  <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
                )}
                Ayrı Olarak Listele
              </div>
              <div className="modalSubDiv__footer__button ml-auto">
                <Button
                  variant="info"
                  onClick={() => funcSelectSonuc()}
                  className="modalSonucButton"
                >
                  {isProcessing ? (
                    <PulseLoader
                      color="#fff"
                      aria-label="Sonuçlar Getiriliyor..."
                    />
                  ) : (
                    "Sonuçları Listele"
                  )}
                </Button>
                <Button
                  variant="warning"
                  onClick={() => handleFormulEkle()}
                  className="modalSonucButton"
                >
                  {isProcessing ? (
                    <PulseLoader
                      color="#fff"
                      aria-label="Sonuçlar Getiriliyor..."
                    />
                  ) : (
                    "Formülü Güncelle"
                  )}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleAlarm && (
        <Modal
          show={visibleAlarm}
          onHide={handleAlarmClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Alarm Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <Form.Control
                  type="text"
                  name="alarm"
                  placeholder="Alarm adı giriniz"
                  value={alarm}
                  onChange={(e) => setAlarm(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <Button
              onClick={() => handleAlarmEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Alarmı Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Düzenle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  type="text"
                  name="formul"
                  placeholder="Formül adı giriniz"
                  value={formul}
                  onChange={(e) => setFormul(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="formulNote"
                  placeholder="Formül notu giriniz"
                  value={formulNote}
                  onChange={(e) => setFormulNote(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <Button
              onClick={() => handleFormulEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Formülü Güncelle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleAlarmFiyat && (
        <Modal
          show={visibleAlarmFiyat}
          onHide={handleAlarmFiyatClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {alarmName} Fiyat Alarm Takibi Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <Form.Control
                  type="text"
                  name="alarm"
                  placeholder="Fiyat Yazınız"
                  value={alarmFiyat}
                  onChange={(e) => setAlarmFiyat(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
                <Form.Select aria-label="Default select example">
                  <option>Tipini Seçiniz</option>
                  <option value="1">{"> Üstünde"}</option>
                  <option value="2">{"< Altında"}</option>
                  <option value="3">{"= Eşittir"}</option>
                </Form.Select>
              </div>
            </div>
            <Button
              onClick={() => handleAlarmFiyatEkle()}
              className="modalSonucButton"
              style={{ width: 215 }}
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Fiyat Alarmını Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleListelerim && (
        <Modal
          show={visibleListelerim}
          onHide={handleListelerimClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {listeName} Listeme Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalListeTabDiv">
              <div className="modalListeTabDiv__body">
                <div
                  className={
                    selectListeTab == 1
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(1)}
                >
                  Listelere Ekle
                </div>
                <div
                  className={
                    selectListeTab == 2
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(2)}
                >
                  Favoriye Ekle
                </div>
              </div>
            </div>
            <div className="modalListeTabDiv__list">
              {selectListeTab == 1 ? (
                <>
                  {listeler &&
                    listeler.map((item) => (
                      <div
                        key={item.id}
                        className="modalListeTabDiv__list__item"
                        onClick={() => _listeSelect(item)}
                      >
                        {selectListe.id == item.id ? (
                          <SonucSecildi />
                        ) : (
                          <SonucSec />
                        )}
                        <div>{item.name}</div>
                      </div>
                    ))}
                  <div
                    className="modalListeTabDiv__list__item"
                    onClick={() => _listeSelect("new")}
                  >
                    {selectListe == "new" ? <SonucSecildi /> : <SonucSec />}
                    <Form.Control
                      type="text"
                      name="liste"
                      placeholder="Liste adı giriniz"
                      value={liste}
                      onChange={(e) => setListe(e.target.value)}
                      className="modalAlarmDiv__body__input"
                    />
                  </div>
                  <Button
                    onClick={() => handleListemeEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Listeye Ekle"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {selectListeTab == 2 && (
                <>
                  <Button
                    onClick={() => handleFavoriEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Favoriye Ekle"
                    )}
                  </Button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {modalGrafik && (
        <Modal
          show={modalGrafik}
          onHide={handleGrafikClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ChartComponent
              urlApi={urlApi}
              id={formationId}
              load={loadChart}
              theme={theme.darkmode}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalIndicator && (
        <Modal
          show={modalIndicator}
          onHide={handleIndicatorClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PuanComponent
              urlApi={urlApi}
              name={puanName}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default AnaKumandaEdit;
