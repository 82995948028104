import * as React from "react";
const InstagramLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M23.976 7.556c-.056-1.275-.262-2.152-.557-2.912a5.857 5.857 0 0 0-1.388-2.128 5.907 5.907 0 0 0-2.124-1.383C19.143.838 18.27.63 16.995.575 15.711.515 15.303.5 12.045.5c-3.26 0-3.667.014-4.947.07-1.275.057-2.152.263-2.911.558a5.856 5.856 0 0 0-2.129 1.388A5.909 5.909 0 0 0 .675 4.64C.38 5.404.173 6.276.117 7.55.057 8.836.042 9.244.042 12.502c0 3.259.014 3.667.07 4.947C.17 18.724.375 19.6.67 20.36a5.919 5.919 0 0 0 1.388 2.129c.6.61 1.327 1.083 2.124 1.383.764.295 1.636.501 2.911.558 1.28.056 1.688.07 4.947.07 3.258 0 3.666-.014 4.946-.07 1.275-.057 2.152-.263 2.911-.558a6.139 6.139 0 0 0 3.512-3.512c.295-.764.502-1.636.558-2.911.056-1.28.07-1.688.07-4.947 0-3.258-.004-3.666-.06-4.946Zm-2.16 9.799c-.052 1.172-.25 1.805-.413 2.227a3.981 3.981 0 0 1-2.28 2.278c-.421.165-1.059.361-2.226.413-1.266.056-1.646.07-4.848.07s-3.587-.014-4.848-.07c-1.172-.052-1.805-.248-2.227-.413a3.693 3.693 0 0 1-1.378-.895 3.73 3.73 0 0 1-.896-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.266-.07-1.646-.07-4.848 0-3.203.013-3.587.07-4.848.051-1.172.248-1.805.412-2.227.193-.52.497-.994.9-1.379a3.726 3.726 0 0 1 1.379-.895C5.4 3 6.039 2.803 7.206 2.75c1.266-.056 1.646-.07 4.848-.07 3.207 0 3.586.014 4.848.07 1.172.052 1.805.249 2.227.413.52.192.993.497 1.378.895.398.39.703.858.895 1.379.165.422.361 1.06.413 2.227.056 1.266.07 1.645.07 4.848 0 3.202-.014 3.577-.07 4.843Z" />
      <path d="M12.045 6.337a6.167 6.167 0 0 0-6.166 6.165 6.167 6.167 0 0 0 6.166 6.165 6.167 6.167 0 0 0 6.165-6.165 6.167 6.167 0 0 0-6.165-6.165Zm0 10.164a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM19.893 6.094a1.44 1.44 0 1 1-2.879 0 1.44 1.44 0 0 1 2.879 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default InstagramLogo;
