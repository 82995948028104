import React from "react";
import "./HomeFooter.css";
import logo from "../../../assets/img/sirius-logo.svg";
import InstagramLogo from "../../svgs/instagram";
import ThreadsLogo from "../../svgs/threads";
import YoutubeLogo from "../../svgs/youtube";
import XLogo from "../../svgs/x";
import { Container, Row, Col } from "react-bootstrap";
function HomeFooter() {
  return (
    <div className="HomeFooter">
      <Container fluid className="HomeFooter__container">
        <Container>
          <Row className="Footer__top">
            <Col lg={6} className="Footer__logo">
              <img src={logo} />
            </Col>
            <Col lg={6} className="FooterSocial">
              <ul className="Footer__social">
                <li>
                  <XLogo className="HomeMenu__social_img" />
                </li>
                <li>
                  <ThreadsLogo className="HomeMenu__social_img" />
                </li>
                <li>
                  <InstagramLogo className="HomeMenu__social_img" />
                </li>
                <li>
                  <YoutubeLogo className="HomeMenu__social_img" />
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <div className="FooterCopyrightLine"></div>
            <div className="FooterCopyright">
              © Sirius Algo, Tüm hakları saklıdır.
            </div>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default HomeFooter;
