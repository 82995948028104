import * as React from "react";
const YoutubeLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} {...props}>
    <g clipPath="url(#a)">
      <path d="m15.694 11.421-5.302-2.9a.966.966 0 0 0-.964.016.965.965 0 0 0-.477.838v5.752c0 .346.177.659.474.836a.972.972 0 0 0 .96.022l5.303-2.851a.974.974 0 0 0 .006-1.713Zm-5.337 2.982v-4.299l3.963 2.169-3.963 2.13Z" />
      <path d="M23.823 7.697v-.011c-.02-.194-.223-1.912-1.059-2.786-.965-1.028-2.06-1.153-2.586-1.213a6.298 6.298 0 0 1-.12-.014l-.041-.004c-3.173-.23-7.965-.262-8.013-.263h-.008c-.048 0-4.84.032-8.041.263l-.043.004-.112.014c-.52.06-1.603.185-2.572 1.25C.433 5.802.203 7.483.18 7.672l-.002.025C.169 7.777 0 9.69 0 11.612v1.796c0 1.92.17 3.834.177 3.915v.012c.021.19.223 1.877 1.055 2.751.908.994 2.055 1.125 2.673 1.196.098.011.182.02.239.03l.055.008c1.832.175 7.576.26 7.82.264h.014c.048 0 4.84-.032 8.013-.262l.042-.005.134-.015c.518-.055 1.595-.169 2.55-1.22.795-.864 1.025-2.546 1.049-2.735l.002-.024c.008-.08.177-1.994.177-3.915v-1.796c0-1.921-.17-3.835-.177-3.915Zm-1.23 5.71c0 1.779-.155 3.61-.17 3.778-.059.463-.302 1.527-.69 1.948-.597.658-1.21.723-1.66.77a8.577 8.577 0 0 0-.149.017c-3.069.222-7.68.256-7.892.258-.238-.004-5.898-.09-7.674-.256a6.323 6.323 0 0 0-.293-.037c-.526-.06-1.245-.143-1.799-.752l-.013-.014c-.38-.396-.616-1.391-.676-1.928-.011-.127-.17-1.98-.17-3.783v-1.796c0-1.776.154-3.606.17-3.777.07-.546.318-1.545.69-1.949.615-.677 1.265-.753 1.694-.802l.115-.014c3.113-.223 7.757-.256 7.924-.257.167 0 4.809.034 7.895.257l.124.015c.441.05 1.11.126 1.722.78l.006.006c.38.397.616 1.409.676 1.957.01.12.17 1.977.17 3.784v1.796Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default YoutubeLogo;
