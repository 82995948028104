import * as React from "react";
const PaketDesc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M18.474 8.222a1.402 1.402 0 0 0-.575-1.767l-1.6-.95a.149.149 0 0 1-.075-.1l-.417-1.825a1.41 1.41 0 0 0-1.5-1.092l-1.858.167a.124.124 0 0 1-.117-.033l-1.408-1.234a1.416 1.416 0 0 0-1.85 0L7.666 2.622a.124.124 0 0 1-.117.033l-1.858-.167a1.403 1.403 0 0 0-1.5 1.095l-.417 1.825a.148.148 0 0 1-.075.1l-1.6.947a1.403 1.403 0 0 0-.575 1.767l.742 1.716a.167.167 0 0 1 0 .125l-.742 1.717a1.395 1.395 0 0 0 .575 1.758l1.608.962c.034.024.058.06.067.1l.417 1.825a1.403 1.403 0 0 0 1.5 1.092l1.858-.175a.157.157 0 0 1 .117.041l1.408 1.234a1.423 1.423 0 0 0 1.85 0l1.408-1.234a.156.156 0 0 1 .117-.041l1.858.175a1.403 1.403 0 0 0 1.5-1.092l.417-1.825a.149.149 0 0 1 .075-.1l1.6-.958a1.395 1.395 0 0 0 .575-1.759l-.742-1.716a.167.167 0 0 1 0-.125l.742-1.72Zm-4.733.1-4.134 4.233a.659.659 0 0 1-.45.183.616.616 0 0 1-.366-.116l-2.45-1.75a.627.627 0 0 1 .733-1.017l2.008 1.442 3.767-3.85a.621.621 0 0 1 1.069.431.629.629 0 0 1-.177.444Z"
    />
  </svg>
);
export default PaketDesc;
