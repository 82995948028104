import * as React from "react";
const SidemenuListe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path d="M6.157 7.107h11.686a.73.73 0 0 0 .718-.74.73.73 0 0 0-.718-.739H6.157a.73.73 0 0 0-.719.74c0 .408.322.739.72.739ZM7.253 4.478h9.494a.73.73 0 0 0 .719-.739.73.73 0 0 0-.719-.739H7.253a.73.73 0 0 0-.72.74c0 .408.323.738.72.738ZM18 8.257H6c-1.51 0-2.678 1.367-2.478 2.91l.986 7.603C4.673 20.04 5.738 21 6.985 21h10.03c1.247 0 2.312-.959 2.477-2.23l.986-7.604c.2-1.54-.968-2.91-2.478-2.91Z" />
  </svg>
);
export default SidemenuListe;
