import * as React from "react";
const ArrowThinDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#fff"}
      fillRule="evenodd"
      d="M13.03 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06l6.97 6.97 6.97-6.97a.749.749 0 1 1 1.06 1.06l-7.5 7.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowThinDown;
