import packageJson from "../../package.json";
export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
    console.log("refreshCacheAndReload: caches deleted");
  }
  const cookies = document.cookie.split(";");
  if (cookies) {
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
    console.log("refreshCacheAndReload: cookies deleted");
  }
  localStorage.clear();
  console.log("refreshCacheAndReload: localStorage cleared");
  // delete browser cache and hard reload
  localStorage.setItem("sirius_version", packageJson.version);
  window.location.reload(true);
};
