import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../components/Auth/PasswordForget/ForgotPassword.css";
import logo from "../../src/assets/img/sirius-logo.svg";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useParams } from "react-router-dom";
import { timeout } from "../utils/helperFunction";

const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function AuthUyeAktivasyon(props) {
  const urlApi = localStorage.getItem("urlApi");
  const { code } = useParams();
  const [sonuc, setSonuc] = useState(0);

  const getAktivasyon = async (event) => {
    setSonuc(1);
    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha(
      "uyeAktivasyon"
    );
    // console.log('uyeAktivasyon:',token)
    const postData = {
      recaptcha: token,
      code: code,
    };
    let res = await axios({
      method: "post",
      url: `${urlApi}activation.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // console.log("userInfo", res.data);
    // Don't forget to return something
    if (res.data.code === 0) {
      toast.success(res.data.data);
      setSonuc(0);
      timeout(3000).then(() => {
        window.location.href = "/uye-giris";
      });
    } else {
      toast.error(res.data.data);
      setSonuc(0);
    }
  };

  useEffect(() => {
    document.title = "Üye Aktivasyon Sayfası - Sirius Algo";
  }, []);

  return (
    <div className="forgotPage">
      <ToastContainer />
      <div className="forgotPage__container">
        <div className="forgotPage__body">
          <div className="forgotPage__logo">
            <img style={{ width: 194, height: 68 }} src={logo} />
          </div>
          <div className="forgotPage__form">
            <h2 className="forgotPage__form_title">Üyelik Aktivasyon</h2>
            <Form
              className="uyeGiris"
              name="normal_login"
              onSubmit={getAktivasyon}
              style={{ padding: 50 }}
            >
              <Row className="mb-3">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label={
                      <span style={{ fontSize: 16 }}>Üyeliğimi Aktif Et</span>
                    }
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="d-flex justify-content-around text-center">
                <Button
                  variant="primary"
                  className="uyeBtn"
                  type="submit"
                  disabled={sonuc == 1 ? true : false}
                >
                  Gönder
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

const WrappedAktivasyon = withGoogleReCaptcha(AuthUyeAktivasyon);

function Aktivasyon(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedAktivasyon url={props.url} />
    </GoogleReCaptchaProvider>
  );
}
export default Aktivasyon;
