import * as React from "react";
const SidemenuSozlesme = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M1.75 7.877h1.43v6.31H1.75v-6.31ZM10.5 8.95a3.766 3.766 0 0 1 1.73-.589h1.703c-1.283-2.292-4.87-2.423-6.55-.495-.133.141-.239.306-.312.485 1.107-.01 2.493-.087 3.429.6ZM10.497 4.356c1.948-.064 1.948-2.887 0-2.951-1.946.064-1.946 2.887 0 2.951ZM10.698 9.627 7.252 12.59a.243.243 0 0 0 .162.431h.754A2.46 2.46 0 0 0 9.92 12.3l.96-.956a1.132 1.132 0 0 1 1.567 0 .668.668 0 0 0 .845-.004c.04-.023.539-.33.58-.35a.858.858 0 0 1 1.073.367l1.073 1.666h1.128V9.041l-4.89-.007a3.143 3.143 0 0 0-1.559.593ZM17.82 7.877h1.43v6.31h-1.43v-6.31Z" />
    <path d="M14.242 11.592a.151.151 0 0 0-.08.007l-.472.286a1.286 1.286 0 0 1-1.716-.068.45.45 0 0 0-.62 0l-.955.956a3.132 3.132 0 0 1-2.231.922h-.754a.924.924 0 0 1-.6-1.615l3.12-2.682a3.033 3.033 0 0 0-1.426-.37L3.853 9.02v4.001H5.16a.334.334 0 0 1 .317.226c.157.434.408.83.733 1.157l4.12 4.116a.247.247 0 0 0 .343.004l4.119-4.12c.115-.112.22-.235.313-.366a2.97 2.97 0 0 0 .36-.64l-1.094-1.7c-.013-.016-.054-.094-.128-.107Z" />
  </svg>
);
export default SidemenuSozlesme;
