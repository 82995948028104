import * as React from "react";
const SidemenuGrafik = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    {...props}
  >
    <path d="M18.406 5.069H20.5V15.88h-2.094V5.07ZM13.93 8.59h2.093v7.29H13.93V8.59ZM9.453 10.818h2.094v5.06H9.453v-5.06ZM4.976 8.508H7.07v7.372H4.976V8.508ZM.5 10.84h2.094v5.039H.5v-5.04ZM1.547 5.772c.285 0 .54.129.71.33L5.115 4.58a.933.933 0 1 1 1.826-.038l2.825 1.567a.93.93 0 0 1 1.507.052l2.779-1.605a.933.933 0 0 1 1.503-.835l3.053-2.401a.933.933 0 1 1 .272.346l-3.054 2.401a.933.933 0 0 1-1.62.908l-2.78 1.605a.933.933 0 1 1-1.844-.069L6.757 4.944a.93.93 0 0 1-1.445.029L2.454 6.496a.933.933 0 1 1-1.841.208.935.935 0 0 1 .934-.932Z" />
  </svg>
);
export default SidemenuGrafik;
