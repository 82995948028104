import React from "react";
import ForgotPassword from "../components/Auth/PasswordForget/ForgotPassword";

function AuthForgotPassword() {
  return (
    <React.Fragment>
      <ForgotPassword />
    </React.Fragment>
  );
}

export default AuthForgotPassword;
