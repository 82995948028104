import * as React from "react";
const UserFollowing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M10.873.973c-3.492 0-6.34 2.858-6.34 6.35a6.339 6.339 0 0 0 2.693 5.175c-3.79 1.52-6.48 5.226-6.48 9.551a1 1 0 0 0 2 0c0-4.59 3.7-8.29 8.291-8.29A8.24 8.24 0 0 1 15.392 15a1.002 1.002 0 0 0 1.506-1.084 1.004 1.004 0 0 0-.45-.623c-.571-.353-1.19-.63-1.829-.867 1.573-1.156 2.604-3.012 2.604-5.104 0-3.491-2.858-6.35-6.35-6.35Zm0 2.002c2.41 0 4.35 1.937 4.35 4.347a4.33 4.33 0 0 1-4.35 4.34c-2.41 0-4.34-1.929-4.34-4.34 0-2.41 1.93-4.347 4.34-4.347Zm10.121 12.002a1 1 0 0 0-.813.459l-3.447 5.166-2.162-1.448a1.003 1.003 0 1 0-1.115 1.67l3.006 2a1 1 0 0 0 1.386-.28l4.002-6.003a1 1 0 0 0-.857-1.564Z"
    />
  </svg>
);
export default UserFollowing;
