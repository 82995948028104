import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Header from "../components/System/header";
import "../components/PariteOdasi/PariteOdasi.css";

import { TVChartContainer } from "../components/TVChartContainer/parite_odasi";
import SonucTekEkle from "../components/svgs/sonucTekEkle";
import { PiArrowDown } from "react-icons/pi";
import AnaKumandaSonucYok from "../components/svgs/anaKumandaSonucYok";
import ArrowDown from "../components/svgs/arrowDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PulseLoader } from "react-spinners";
import _ from "lodash";
import { timeout } from "../utils/helperFunction";
import { MARKETS, PARITES } from "../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { postDataWithHeaders } from "../utils/apiUtils";
import { dataBorsa } from "../utils/dummyData";
import ButtonClose from "../components/svgs/buttonClose";
import { BsSearch } from "react-icons/bs";
import SonucSecildi from "../components/svgs/sonucSecildi";

function PariteOdasi() {
  const urlApi = localStorage.getItem("urlApi");
  const { user } = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.theme.darkmode);
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [parite, setParite] = useState(null);
  const [search, setSearch] = useState("");
  const [parites, setParites] = useState([]);
  const [parite_1, setParite1] = useState(null);
  const [parite_2, setParite2] = useState(null);
  const [parite_1id, setParite1Id] = useState("Sembol 1");
  const [parite_2id, setParite2Id] = useState("Sembol 2");
  const [favori, setFavori] = useState("");
  const [selectedBorsa, setSelectedBorsa] = useState("");
  const [selectedSembol, setSelectedSembol] = useState(null);
  const [selectedBorsaId, setSelectedBorsaId] = useState(1);
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [sonucGetir, setSonucGetir] = useState(false);
  const [pariteVar, setPariteVar] = useState(false);
  const [liste, setListe] = useState("");
  const [visibleParite, setVisibleParite] = useState(false);
  const handlePariteClose = () => setVisibleParite(false);
  const [visibleListe, setVisibleListe] = useState(false);
  const handleListeClose = () => _setVisibleListe();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const handleDeleteClose = () => setVisibleDelete(false);

  const _setVisibleListe = () => {
    setIsProcessing(false);
    setVisibleListe(false);
    setSelectedSembol([]);
    setSelectedBorsa("");
    setSelectedBorsaId(1);
    setListe("");
  };

  const funcSelectBorsa = async (label, id) => {
    setSelectedBorsa(label);
    setSelectedBorsaId(id);
    setLoadData(true);
    let newMarket = [];
    let newValue = id;
    if (parseInt(newValue)) {
      newMarket = _.filter(markets.items, function (o) {
        return o.exchange_id == newValue;
      });
    } else {
      if (newValue == "XU030") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu030 == 1;
        });
      }
      if (newValue == "XU050") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu050 == 1;
        });
      }
      if (newValue == "XU100") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu100 == 1;
        });
      }
      if (newValue == "XBANK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xbank == 1;
        });
      }
      if (newValue == "XKTUM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xktum == 1;
        });
      }
      // XUSIN, XUTEK, XILTM, XGIDA, XTRZM, XSGRT
      if (newValue == "XUSIN") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xusin == 1;
        });
      }
      if (newValue == "XUTEK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xutek == 1;
        });
      }
      if (newValue == "XILTM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xiltm == 1;
        });
      }
      if (newValue == "XGIDA") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xgida == 1;
        });
      }
      if (newValue == "XTRZM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xtrzm == 1;
        });
      }
      if (newValue == "XSGRT") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xsgrt == 1;
        });
      }
      if (newValue == "XINDEX") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xindex == 1;
        });
      }
      if (newValue == "CIRCLE") {
        newMarket = _.filter(markets.items, function (o) {
          return o.circle == 1;
        });
      }
    }
    setMarketler(newMarket);
    setSearchMarket(newMarket);
  };

  const funcSelectSembol = (id, parite) => {
    // console.log("id, parite", id, parite);
    if (parite == 1) {
      setParite1(id);
      setParite1Id(_getSembolName(id));
    } else {
      setParite2(id);
      setParite2Id(_getSembolName(id));
    }
    handleListeClose();
  };
  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _searchSembol = (text) => {
    setIsProcessing(true);
    let newMarkets = searchMarket;
    if (text) {
      newMarkets = _.filter(newMarkets, function (o) {
        return o.label.includes(turkceIngilizceBuyukHarfCevir(text));
      });
    }
    setSearch(text);
    setMarketler(newMarkets);
    setIsProcessing(false);
  };
  function marketCallback(item) {
    return {
      value: item.id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      xindex: item.xindex,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    setIsProcessing(true);

    await postDataWithHeaders(
      MARKETS,
      { access_token: user.access_token },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        var dataDummy = [];
        dataDummy.push({
          items: res.data.map(marketCallback),
        });
        setMarkets(dataDummy[0]);
        let newMarket = [];
        let newValue = 1;
        newMarket = _.filter(dataDummy[0].items, function (o) {
          return o.exchange_id == newValue;
        });
        setMarketler(newMarket);
        setSearchMarket(newMarket);
        setIsProcessing(false);
      }
    });
  };
  const _getParites = async () => {
    const postData = {
      access_token: user.access_token,
      process: "list",
    };
    postDataWithHeaders(PARITES, postData, dispatch).then((res) => {
      if (res.code == 0) {
        if (res.data.length > 0) {
          setParites(res.data);
        }
      } else {
        toast.error(res.data);
      }
    });
  };

  const handlePariteEkle = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      parity1: parseInt(parite_1),
      parity2: parseInt(parite_2),
      process: "add",
    };
    postDataWithHeaders(PARITES, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        toast.success(res.data);
        _getParites();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });

    timeout(2000);
    setIsProcessing(false);
    setVisibleParite(false);
  };

  const handlePariteDelete = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      parity1: parseInt(parite_1),
      parity2: parseInt(parite_2),
      process: "delete",
    };
    postDataWithHeaders(PARITES, postData, dispatch).then((res) => {
      if (res.code == 0) {
        setIsProcessing(false);
        toast.success(res.data);
        setVisibleDelete(false);
        _getParites();
      } else {
        setIsProcessing(false);
        toast.error(res.data);
      }
    });

    timeout(2000);
    setIsProcessing(false);
    setVisibleParite(false);
  };
  const _setVisibleParite = () => {
    if (parite_1 == null || parite_2 == null) {
      toast.error("Lütfen pariteleri seçiniz!");
      return false;
    } else if (parite_1 == parite_2 && parite_1 != "" && parite_2 != "") {
      toast.error("Lütfen pariteleri aynı seçmeyiniz!");
      return false;
    } else {
      setVisibleParite(true);
    }
  };
  const _pariteControl = () => {
    if (parite_1 == parite_2 && parite_1 == null && parite_2 == null) {
      toast.error("Lütfen pariteleri aynı seçmeyiniz!");
      return false;
    } else {
      setSonucGetir(true);
    }
  };
  const _setParite = (parite) => {
    setParite(parite);
    // console.log("parite", parite);
    setVisibleListe(true);
  };
  const sonucGetirFunc = () => {
    if (parite_1 == null || parite_2 == null) {
      toast.error("Lütfen pariteleri seçiniz!");
      return false;
    }
    setSonucGetir(false);
    setFavori(parite_1 + "/" + parite_2);
  };
  const _getSembolName = (id) => {
    let newMarket = _.filter(markets.items, function (o) {
      return o.value == id;
    });
    return newMarket[0].label;
  };

  const _setFavoriParite = (item) => {
    setSonucGetir(false);
    setParite1(item.parity1);
    setParite1Id(item.symbol1);
    setParite2(item.parity2);
    setParite2Id(item.symbol2);
    setFavori(item.symbol1 + "/" + item.symbol2);
  };
  const _setFavoriDelete = (item) => {
    setSonucGetir(false);
    setParite1(item.parity1);
    setParite1Id(item.symbol1);
    setParite2(item.parity2);
    setParite2Id(item.symbol2);
    // setFavori(item.symbol1 + "/" + item.symbol2);
    setVisibleDelete(true);
  };
  useEffect(() => {
    if (favori != "") {
      // console.log(parite_1, parite_2);
      _pariteControl();
    }
  }, [favori]);

  useEffect(() => {
    async function fetchData() {
      await _getMarkets();
      await _getParites();
    }
    fetchData();
  }, []);

  useEffect(() => {
    document.title = "Parite Odası - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row>
            <div className="DashBoard_container">
              <div className="PariteOdasi__top">
                <h1>Parite Odası</h1>
                <div className="PariteOdasi__secim">
                  <div className="PariteOdasi__secim__1">
                    <div className="PariteOdasi_secim_text">Parite 1</div>
                    <div
                      className="PariteOdasi__select"
                      onClick={() => _setParite(1)}
                    >
                      <span>
                        {parite_1 == null
                          ? "Parite Seçiniz"
                          : _getSembolName(parite_1)}
                      </span>
                    </div>
                  </div>
                  <div className="PariteOdasi__secim__2">
                    <div className="PariteOdasi_secim_text">Parite 2</div>
                    <div
                      className="PariteOdasi__select"
                      onClick={() => _setParite(2)}
                    >
                      <span>
                        {parite_2 == null
                          ? "Parite Seçiniz"
                          : _getSembolName(parite_2)}
                      </span>
                    </div>
                  </div>
                  <Button
                    variant="secondary"
                    onClick={() => _setVisibleParite()}
                  >
                    <SonucTekEkle color="white" /> Favoriye Ekle
                  </Button>
                </div>
                <Button variant="primary" onClick={() => sonucGetirFunc()}>
                  Sonuçları Getir
                </Button>
              </div>
              <div className="PariteOdasi__bottom">
                <span>Favori Paritelerim</span>
                {parites.length > 0 &&
                  parites.map((parite, indx) => {
                    let newParite = parite.symbol1 + "/" + parite.symbol2;
                    return (
                      <div key={indx} className="PariteOdasi__bottom__button">
                        <span onClick={() => _setFavoriParite(parite)}>
                          {newParite}
                        </span>
                        <span
                          className="PariteOdasi__bottom__button__close"
                          onClick={() => _setFavoriDelete(parite)}
                        >
                          <ButtonClose />
                        </span>
                      </div>
                    );
                  })}
              </div>
              {sonucGetir ? (
                <TVChartContainer
                  parite1={parite_1id}
                  parite2={parite_2id}
                  urlApi={urlApi}
                  theme={theme}
                />
              ) : (
                <div className="PariteOdasi__sonuc">
                  <div className="PariteOdasi__sonucyok">
                    <AnaKumandaSonucYok />
                    <div className="PariteOdasi__sonucyok__text">
                      Henüz parite seçmediniz. <br />
                      hemen pariteleri seçerek Sirius’un dünyasını keşfetmeye
                      başlayın.
                      <br />
                      <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Row>
          {sonucGetir && (
            <Row className="PariteOdasi_text">
              <Col>
                <p>
                  TradingView, dünya genelinde tüccarlar ve yatırımcıların bir
                  araya geldiği, piyasaları analiz ettiği ve en son finansal
                  haberleri tartıştığı bir grafik platformudur. Gelişmiş
                  araçları ve çeşitli verileri sayesinde herhangi bir varlıkla
                  ilgilenenler, piyasa araştırmalarını yeni bir seviyeye
                  taşıyabilirler. İşlem tutkunları, özellikle TradingView'in
                  sunduğu özelliklerden memnun kalacaklar. Detaylı sembol
                  grafiklerinin yanı sıra{" "}
                  <a
                    href="https://www.tradingview.com/economic-calendar/"
                    target="_blank"
                  >
                    ekonomi takvimi
                  </a>{" "}
                  grafikleri gibi daha kapsamlı verilere de erişebilirler. Bu
                  platform, herhangi bir stratejiye uygun daha geniş veriler
                  sunarak kullanıcılara yardımcı olur. Ayrıca, hisse senedi
                  yatırımcıları için de faydalı göstergeler sunar; örneğin,{" "}
                  <a
                    href="https://www.tradingview.com/screener/"
                    target="_blank"
                  >
                    Hisse senedi tarayıcısı
                  </a>{" "}
                  sayesinde hisse senedi piyasalarını gözden geçirebilir ve
                  herhangi bir portföy için uygun varlıkları bulabilirsiniz -
                  tüm bunlar ve daha fazlası TradingView'de bulunabilir.
                </p>
                <p>
                  BİST hisse verileri 15 dakika gecikmelidir. Grafik verileri
                  her 15 dakika periyot kapanışından sonra güncellenmektedir.
                </p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {visibleParite && (
        <Modal
          show={visibleParite}
          onHide={handlePariteClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Parite Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <div className="modalAlarmDiv__body__input">
                  <span>
                    {_getSembolName(parite_1)}/{_getSembolName(parite_2)}
                  </span>
                </div>
              </div>
            </div>
            <Button
              onClick={() => handlePariteEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Pariteyi Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleDelete && (
        <Modal
          show={visibleDelete}
          onHide={handleDeleteClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Favoriden Parite Kaldır
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <div className="modalAlarmDiv__body__input">
                  <span>
                    {_getSembolName(parite_1)}/{_getSembolName(parite_2)}
                  </span>
                </div>
              </div>
            </div>
            <Button
              onClick={() => handlePariteDelete()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Pariteyi Kaldır"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleListe && (
        <Modal
          show={visibleListe}
          onHide={handleListeClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Parite Oluştur
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label,
                      borsa.value
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => _searchSembol(e.target.value)}
                />
              </div>
            </div>

            <div className="modalSelectButtons">
              {marketler.map((sembol) => (
                <Button
                  key={sembol.label}
                  className={
                    selectedSembol == sembol.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectSembol(sembol.value, parite)}
                >
                  {sembol.label}
                </Button>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default PariteOdasi;
