import * as React from "react";
const UserProfileZiyaret = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M23.15 11.617A12.919 12.919 0 0 0 11.999 5.19c-4.582 0-8.857 2.46-11.152 6.427a.783.783 0 0 0 0 .75 12.917 12.917 0 0 0 11.152 6.443c4.583 0 8.858-2.468 11.153-6.443a.783.783 0 0 0 0-.75Zm-11.152 5.07A4.69 4.69 0 0 1 7.311 12a4.69 4.69 0 0 1 4.687-4.688A4.69 4.69 0 0 1 16.686 12a4.69 4.69 0 0 1-4.688 4.688Z"
    />
    <path
      fill={props.color || "#fff"}
      d="M12 15.188a3.188 3.188 0 1 0 0-6.376 3.188 3.188 0 0 0 0 6.376Z"
    />
  </svg>
);
export default UserProfileZiyaret;
