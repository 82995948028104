import * as React from "react";
const ArrowSort = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g fill={props.color ? props.color : "#fff"}>
      <path d="M5.75 9.75h13.5a.75.75 0 0 0 .53-1.28l-6.75-6.75a.75.75 0 0 0-1.06 0L5.22 8.47a.75.75 0 0 0 .53 1.28ZM19.25 14.25H5.75a.75.75 0 0 0-.53 1.28l6.75 6.75a.75.75 0 0 0 1.06 0l6.75-6.75a.751.751 0 0 0-.53-1.28Z" />
    </g>
  </svg>
);
export default ArrowSort;
