import * as React from "react";
const ShareAlarm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill={props.color || "#fff"} clipPath="url(#a)">
      <path d="M21.985 10.882a1 1 0 0 1-1-1c0-2.805-1.092-5.441-3.075-7.425a.999.999 0 1 1 1.414-1.414 12.417 12.417 0 0 1 3.661 8.839 1 1 0 0 1-1 1ZM1.984 10.882a1 1 0 0 1-1-1c0-3.339 1.3-6.478 3.661-8.84A.999.999 0 1 1 6.06 2.458a10.432 10.432 0 0 0-3.075 7.425 1 1 0 0 1-1 1ZM21.363 16.913a6.698 6.698 0 0 1-2.379-5.125V9c0-3.519-2.614-6.432-6-6.92V1a1 1 0 1 0-2 0v1.08c-3.387.488-6 3.401-6 6.92v2.788a6.706 6.706 0 0 1-2.388 5.133 1.752 1.752 0 0 0 1.138 3.08h16.5c.965 0 1.75-.786 1.75-1.751 0-.512-.223-.996-.62-1.337ZM11.983 24a3.756 3.756 0 0 0 3.674-3H8.309a3.756 3.756 0 0 0 3.674 3Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color || "#fff"} d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ShareAlarm;
