import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobile: false,
  darkmode: localStorage.getItem("theme") || "dark",
};

const body = document.querySelector("body");
const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkTheme(state) {
      body.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
      state.darkmode = "dark";
    },
    setDefaultTheme(state) {
      body.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
      state.darkmode = "light";
    },
    setIsMobile(state, action) {
      state.mobile = action.payload;
    },
  },
});

export const { setDarkTheme, setDefaultTheme, setIsMobile } =
  themeSlice.actions;

export default themeSlice.reducer;
