import React, { useState } from "react";
import "./Destek.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DestekPanel = ({ destek }) => {
  const navigate = useNavigate();
  const setDetay = (id) => {
    navigate("/destek-detay/" + id);
  };
  const durumu =
    destek.status === "0"
      ? "devam"
      : destek.status == "1"
      ? "cevaplandi"
      : "cozuldu";
  const durumuText =
    destek.status === "0"
      ? "Devam Ediyor"
      : destek.status == "1"
      ? "Cevaplandı"
      : "Çözüldü";
  return (
    <div className="DestekPanel">
      <div className="DestekPanel__title w100">
        <div className="DestekPanel__title__name">ID</div>
        <div className="DestekPanel__title__text">{destek.id}</div>
      </div>
      <div className="DestekPanel__title w200">
        <div className="DestekPanel__title__name">Kullanıcı</div>
        <div className="DestekPanel__title__text">{destek.name}</div>
      </div>
      <div className="DestekPanel__title w500">
        <div className="DestekPanel__title__name">Konu</div>
        <div className="DestekPanel__title__text">{destek.title}</div>
      </div>
      <div className="DestekPanel__title w200">
        <div className="DestekPanel__title__name">İşlem Tarihi</div>
        <div className="DestekPanel__title__text">{destek.time}</div>
      </div>
      <div className="DestekPanel__title w200">
        <div className="DestekPanel__title__name">Durumu</div>
        <div className={`DestekPanel__title__text ${durumu}`}>{durumuText}</div>
      </div>
      <div className="DestekPanel__title w200">
        <div className="DestekPanel__title__name">Detay</div>
        <div className="DestekPanel__title__text">
          <Button
            variant="primary"
            className="DestekPanel__title__text__btn"
            onClick={() => setDetay(destek.id)}
          >
            Detayları Gör
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DestekPanel;
