import * as React from "react";
const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#fff"}
      d="M22.092.445C21.82.148 21.497 0 21.125 0H1.875C1.502 0 1.18.148.908.445A1.51 1.51 0 0 0 .5 1.5c0 .406.136.758.408 1.055l9.625 10.5c.273.297.595.445.967.445s.695-.148.967-.445l9.625-10.5A1.51 1.51 0 0 0 22.5 1.5c0-.406-.137-.757-.408-1.055Z"
    />
  </svg>
);
export default ArrowDown;
