import React, { useState } from "react";
import "../assets/css/dashboard.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SideMenu from "../components/SideMenu/SideMenu";
import Header from "../components/System/header";
import Destek from "../components/Destek/Destek";
import DestekDetay from "../components/Destek/DestekDetay";

function DestekScreen() {
  const { destekId } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  console.log("isAuthenticated", isAuthenticated);
  console.log("user", user);

  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenu />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard__container">
            {destekId ? <DestekDetay /> : <Destek />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DestekScreen;
