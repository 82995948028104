import React from "react";
import FormulArama from "../components/OzelArama/FormulArama";

function FormulAramaPanel() {
  return (
    <React.Fragment>
      <FormulArama />
    </React.Fragment>
  );
}

export default FormulAramaPanel;
