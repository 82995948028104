import * as React from "react";
const PanoNote = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="m22.435 7.516-2.431 13.788a1.754 1.754 0 0 1-2.028 1.42L4.19 20.292a1.753 1.753 0 0 1-1.42-2.027L5.2 4.477a1.74 1.74 0 0 1 .72-1.129c.383-.269.849-.37 1.308-.29l6.026 1.063c.014.485.102.96.278 1.409l-.84 1.12a2.255 2.255 0 0 0 .452 3.152c.393.293.859.448 1.348.448.704 0 1.377-.336 1.8-.9l.838-1.116a4.254 4.254 0 0 0 4.289-2.616 1.75 1.75 0 0 1 1.016 1.898ZM17.492 1.25A2.753 2.753 0 0 0 14.742 4c0 .625.218 1.195.57 1.657l-1.42 1.893a.75.75 0 0 0 1.2.9l1.418-1.89c.306.119.635.19.982.19A2.753 2.753 0 0 0 20.242 4a2.753 2.753 0 0 0-2.75-2.75Z"
    />
  </svg>
);
export default PanoNote;
