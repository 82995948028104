import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authLogout } from "../store/reducers/authSlice";

function AuthUyeCikis(props) {
  const urlApi = localStorage.getItem("urlApi");
  const access_token = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  const isAuth = async () => {
    const postData = {
      access_token: access_token,
    };
    let res = await axios({
      method: "post",
      url: `${urlApi}log-out.php`,
      data: postData,
    });
    if (res.status === 200) {
      console.log(res.status);
      dispatch(authLogout());
      toast.warning("Oturumunuz kapatılmıştır!");
      localStorage.removeItem("subscription");
      localStorage.removeItem("remaining");
      localStorage.removeItem("package");
      localStorage.removeItem("favorites");
      localStorage.removeItem("urlApi");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("package_id");
      localStorage.removeItem("browserTypeXUID");
      localStorage.removeItem("access_token");
      setInterval(() => {
        window.location.href = "/";
      }, 500);
    }
  };
  useEffect(() => {
    isAuth();
    document.title = "Üyelik Çıkış Sayfası - Sirius Algo";
  }, []);
  return (
    <Container style={{ paddingTop: 200, paddingBottom: 200 }}>
      <ToastContainer />
      <Row>
        <Col className="text-center">
          <div style={{ fontSize: 52, fontWeight: "bold" }}>Çıkış Sayfası</div>
          <p>Giriş sayfasına yönlendiriliyorsunuz</p>
        </Col>
      </Row>
    </Container>
  );
}

export default AuthUyeCikis;
