import * as React from "react";
const SidemenuPano = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M13.372 9.38c.32.956 1.243 1.647 2.333 1.647 1.354 0 2.452-1.065 2.452-2.38s-1.098-2.381-2.452-2.381c-.549 0-1.056.175-1.464.471a3.454 3.454 0 0 1-.869 2.644Zm-7.87 1.647c1.09 0 2.015-.692 2.333-1.65A3.472 3.472 0 0 1 6.97 6.74a2.489 2.489 0 0 0-1.467-.474c-1.354 0-2.452 1.066-2.452 2.38 0 1.316 1.098 2.381 2.452 2.381Zm11.132.554h-1.447a4.239 4.239 0 0 1 1.027 2.77v.547c0 .35-.118.673-.317.935h3.074c.437 0 .79-.343.79-.767v-.448c0-1.678-1.4-3.037-3.127-3.037ZM4.976 14.35c0-1.023.362-1.993 1.026-2.77H4.555c-1.727 0-3.127 1.36-3.127 3.037v.448c0 .424.354.767.79.767h3.075a1.54 1.54 0 0 1-.317-.935v-.547Zm6.762-3.703H9.451c-2.106 0-3.813 1.658-3.813 3.703v.547c0 .517.431.935.963.935h7.987c.532 0 .964-.418.964-.935v-.547c0-2.045-1.708-3.703-3.814-3.703Zm-1.133-.675c1.651 0 2.99-1.3 2.99-2.903 0-1.604-1.339-2.903-2.99-2.903-1.65 0-2.99 1.3-2.99 2.903s1.34 2.903 2.99 2.903Z" />
  </svg>
);
export default SidemenuPano;
