// Make requests to Sirius Algo API
export async function makeApiRequest(path) {
  try {
    //const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
    const api_root = localStorage.getItem("urlApi");
    const response = await fetch(`${api_root}${path}`);
    return response.json();
  } catch (error) {
    throw new Error(`Sirius Algo request error: ${error.status}`);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  let short = `${fromSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
