import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { RiStockFill } from "react-icons/ri";

import { toast } from "react-toastify";
import ShareAlarm from "../svgs/shareAlarm";
import { ALARMSMESSAGES } from "../../utils/urls";
import { postDataWithHeaders } from "../../utils/apiUtils";

function AlarmMesajlar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [alarms, setAlarms] = useState([]);

  const bildiremGit = (bildirim) => {
    console.log("bildirim", bildirim);
    localStorage.setItem("formulArama", JSON.stringify(bildirim));
    if (bildirim.exchanges.length) {
      localStorage.setItem("selectedBorsa", bildirim.exchanges[0].name);
      localStorage.setItem("selectedBorsaId", bildirim.exchanges[0].id);
    } else {
      localStorage.setItem("selectedBorsa", "");
      localStorage.setItem("selectedBorsaId", 0);
    }
    localStorage.setItem("selectedMarkets", bildirim.markets);
    const formulaId = bildirim.formula_id ? bildirim.formula_id : 0;
    const sharedId = bildirim.share_id ? bildirim.share_id : 0;
    const bildirimId = formulaId != 0 ? formulaId : sharedId;
    navigate("/formul-alarmlar/" + bildirimId);
  };
  const _getAlarms = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    postDataWithHeaders(ALARMSMESSAGES, postData, dispatch).then((res) => {
      if (res.code == 0) {
        console.log("res", res.data);
        setAlarms(res.data);
      } else {
        console.log("res err", res);
        toast.error(res.data);
      }
    });
  };

  useEffect(() => {
    _getAlarms();
    document.title = "Alarm Mesaj Listesi - Sirius Algo";
  }, []);
  return (
    <>
      <div className="Bildirimler__container__content">
        {alarms.length > 0 &&
          alarms.map((alarm, index) => {
            return (
              <div
                key={index}
                className={
                  alarm.read == 0
                    ? "Bildirimler__container__content__itemx"
                    : "Bildirimler__container__content__itemx op5"
                }
              >
                <div className="Bildirimler__container__content__item">
                  <ShareAlarm />
                  <div className="Bildirimler__container__content__item_content">
                    <span className="Bildirimler__container__content__item_text">
                      {alarm.name} için sonuçlar aşağıdaki gibidir.
                    </span>
                    <span className="Bildirimler__container__content__item_date">
                      {alarm.messages.map((message, index) => {
                        return (
                          <span
                            key={index}
                            className="Bildirimler__container__content__item_text"
                          >
                            <FaCalendarAlt />
                            {" : "}
                            {message.date}
                            <br />

                            <RiStockFill />
                            {" : "}
                            {message.names.join(", ")}
                            <hr />
                          </span>
                        );
                      })}
                    </span>
                  </div>
                </div>
                {alarm.id && (
                  <div className="Bildirimler__container__content__itemz">
                    <div
                      className="Bildirimler__container__content__item_formul"
                      onClick={() => bildiremGit(alarm)}
                    >
                      {alarm.formula_name
                        ? alarm.formula_name
                        : "Formül Detayı için tıklayınız"}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}

export default AlarmMesajlar;
