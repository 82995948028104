import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { ALARMS, FORMULAUNSETALARM, MARKETS } from "../../utils/urls";
import { PulseLoader } from "react-spinners";
import { Link, useParams, useNavigate } from "react-router-dom";

function FormulAlarmlar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [alarms, setAlarms] = useState([]);
  const urlApi = localStorage.getItem("urlApi");
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const handleDeleteClose = () => setVisibleDelete(false);
  const [markets, setMarkets] = useState([]);

  function marketCallback(item) {
    return {
      value: item.id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      xindex: item.xindex,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    setIsProcessing(true);

    await postDataWithHeaders(
      MARKETS,
      { access_token: user.access_token },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        var dataDummy = [];
        dataDummy.push({
          items: res.data.map(marketCallback),
        });
        setMarkets(dataDummy[0]);
        setIsProcessing(false);
      }
    });
  };
  const _getAlarms = () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };

    postDataWithHeaders(ALARMS, postData, dispatch).then((res) => {
      if (res.code == 0) {
        console.log("res", res.data);
        setAlarms(res.data);
        setIsProcessing(false);
      } else {
        console.log("res err", res);
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const bildiremGit = (bildirim) => {
    localStorage.setItem("formulArama", JSON.stringify(bildirim));
    if (bildirim.exchanges.length) {
      console.log("bildirim", bildirim.exchanges[0].id);
      localStorage.setItem("selectedBorsa", bildirim.exchanges[0].name);
      localStorage.setItem("selectedBorsaId", bildirim.exchanges[0].id);
    } else {
      localStorage.setItem("selectedBorsa", "");
      localStorage.setItem("selectedBorsaId", 0);
    }
    localStorage.setItem("selectedMarkets", bildirim.markets);
    const formulaId = bildirim.formula_id ? bildirim.formula_id : 0;
    const sharedId = bildirim.share_id ? bildirim.share_id : 0;
    const bildirimId = formulaId != 0 ? formulaId : sharedId;
    navigate("/formul-alarmlar/" + bildirimId);
  };

  const _deleteAlarmConfirm = (item) => {
    setSelectedItem(item);
    setVisibleDelete(true);
  };
  const _deleteAlarm = (id) => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
      id: parseInt(id),
    };
    postDataWithHeaders(FORMULAUNSETALARM, postData, dispatch).then((res) => {
      if (res.code == 0) {
        console.log("res", res.data);
        _getAlarms();
        setIsProcessing(false);
        setVisibleDelete(false);
      } else {
        console.log("res err", res);
        toast.error(res.data);
        setIsProcessing(false);
      }
    });
  };
  const getSembolName = (id) => {
    return _.filter(markets.items, { value: String(id) }).map((v) => v.label);
  };
  useEffect(() => {
    _getMarkets();
    _getAlarms();
    document.title = "Formül Alarm Listesi - Sirius Algo";
  }, []);
  return (
    <>
      {alarms &&
        alarms.map((item, index) => {
          let detay = "";
          let type = 0;
          let markets = JSON.parse(item.markets);
          if (markets.length > 0) {
            type = 1;
            detay = markets.map((market, index) => {
              const isLastItem = index === markets.length - 1;
              return (
                <div key={index}>
                  {" "}
                  {getSembolName(market)}
                  {!isLastItem && ","}
                </div>
              );
            });
          } else {
            let exchanges = item.exchanges;
            detay = exchanges.map((exchange, index) => {
              const isLastItem = index === exchanges.length - 1;
              return (
                <div key={index}>
                  {exchange.name}
                  {!isLastItem && ","}
                </div>
              );
            });
          }

          return (
            <Col key={index} lg={12} className="Alarmlar__item">
              <div className="Alarmlar__item__left">
                <div
                  className="Alarmlar__item__left__title cursorPointer"
                  onClick={() => bildiremGit(item)}
                >
                  {item.name},<br />
                  <span> Formül: {item.formula_name}</span>
                </div>
                <div className="Alarmlar__item__left__text">
                  {type == 0 ? "BORSA: " : "SEMBOLLER: "} {detay}
                </div>
              </div>
              <div className="Alarmlar__item__right">
                <div className="Alarmlar__item__delete">
                  <BsTrash
                    size={24}
                    color="red"
                    onClick={() => _deleteAlarmConfirm(item)}
                  />
                </div>
                {/* <div className="Alarmlar__item__btn">
                  <span className="Alarmlar__item__btn__top">Durum</span>
                  <span className="Alarmlar__item__btn__bottom">
                    24 Hissede Aktif
                  </span>
                </div> */}
              </div>
            </Col>
          );
        })}
      {visibleDelete && (
        <Modal
          show={visibleDelete}
          onHide={handleDeleteClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Alarmı Silmek İstediğinize Emin Misiniz?
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <input type="text" value={selectedItem.name} disabled />
              </div>
            </div>

            <Button
              onClick={() => _deleteAlarm(selectedItem.id)}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Alarmı Sil"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default FormulAlarmlar;
