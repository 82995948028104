export const storeData = async (key, value) => {
  try {
    var jsonValue = value;
    if (typeof value !== "string") {
      jsonValue = JSON.stringify(value);
    }
    await localStorage.setItem(key, jsonValue);
  } catch (e) {
    return e;
  }
};

export const getData = async (key) => {
  try {
    const res = await localStorage.getItem(key);
    return res != null
      ? typeof res !== "string"
        ? JSON.parse(res)
        : res
      : null;
  } catch (e) {
    return e;
    // error reading value
  }
};
/**
 * @param {number} delay
 */
export const timeout = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};
