import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library";
import Datafeed from "./api_index";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const TVChartIndex = ({
  selectedBorsa,
  selectedEndeks,
  selectedUstuneEndeks,
  urlApi,
  id,
  sonucGetir,
  color,
  precision,
  saveloadData = `${urlApi}tw-charts-frequency.php`,
  bayz = false,
  interval = "D",
  containerId = "TVChartIndex",
  libraryPath = "/charting_library/",
  chartsStorageUrl = `tw-charts.php`,
  chartsStorageApiVersion = "1.1",
  clientId = "siriusalgo.com",
  userId = "public_user_id",
  fullscreen = false,
  autosize = true,
  studiesOverrides = {},
}) => {
  const access_token = localStorage.getItem("access_token");
  React.useEffect(() => {
    const widgetOptions2 = {
      debug: false,
      symbol: selectedBorsa + "/" + selectedEndeks,
      datafeed: Datafeed,
      interval: "D",
      exchange: selectedBorsa,
      urlApi: urlApi,
      container_id: id,
      library_path: libraryPath,
      locale: getLanguageFromURL() || "tr",
      disabled_features: [
        // "header_widget",
        "footer_widget",
        "study_templates",
        "studies_overrides",
        // "header_saveload",
        "studies_symbol_search_spread_operators",
        "save_chart_properties_to_local_storage",
        "use_localstorage_for_settings",
        // "header_chart_type",
        "header_compare",
        "header_fullscreen_button",
        "header_resolutions",
        "header_screenshot",
        "header_symbol_search",
        "header_undo_redo",
        "header_layouttoggle",
        "time_frames",
        "chart_template_storage",
        "drawing_templates",
        "go_to_date",
        "scales_date_format",
        "scales_time_hours_format",
        "control_bar",
        "timeframes_toolbar",
      ],
      enabled_features: [],
      charts_storage_url: saveloadData,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: access_token,
      fullscreen: fullscreen,
      autosize: autosize,
      studies_overrides: studiesOverrides,
      priceFormat: {
        type: "price",
        precision: precision ? precision : 0,
        minMove: 1,
      },
      overrides: {
        // "mainSeriesProperties.showCountdown": true,
        // "mainSeriesProperties.style": 2,
        "mainSeriesProperties.lineStyle.styleType": 2,
        "mainSeriesProperties.lineStyle.linestyle": 2,
        "paneProperties.background": color == "dark" ? "#131722" : "#fff",
        "paneProperties.vertGridProperties.color": "#363c4e",
        "paneProperties.horzGridProperties.color": "#363c4e",
        "symbolWatermark.transparency": 90,
        "scalesProperties.textColor": "#AAA",
        "mainSeriesProperties.minTick": "1,1,false",
        "mainSeriesProperties.lineStyle.color":
          color == "green" ? "#336854" : "#7f323f",
      },
      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: "_DAYICIM",
            metainfo: {
              _metainfoVersion: 51,

              id: "_DAYICIM@tv-basicstudies-1",
              name: "_DAYICIM",
              description: "_DAYICIM",
              shortDescription: "_DAYICIM",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "DAYICIM 55",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "DAYICIM 89",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "DAYICIM 144",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "DAYICIM 233",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var dayicim55 = PineJS.Std.ema(v, 55, context);
                var dayicim89 = PineJS.Std.ema(v, 89, context);
                var dayicim144 = PineJS.Std.ema(v, 144, context);
                var dayicim233 = PineJS.Std.ema(v, 233, context);
                //console.log(this._context);
                return [dayicim55, dayicim89, dayicim144, dayicim233];
              };
            },
          },
          {
            name: "_Fibonik",
            metainfo: {
              _metainfoVersion: 51,

              id: "_Fibonik@tv-basicstudies-1",
              name: "_Fibonik",
              description: "_Fibonik",
              shortDescription: "_Fibonik",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Fibonik 5",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Fibonik 8",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "Fibonik 13",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "Fibonik 21",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var fibonik5 = PineJS.Std.ema(v, 5, context);
                var fibonik8 = PineJS.Std.ema(v, 8, context);
                var fibonik13 = PineJS.Std.ema(v, 13, context);
                var fibonik21 = PineJS.Std.ema(v, 21, context);
                //console.log(this._context);
                return [fibonik5, fibonik8, fibonik13, fibonik21];
              };
            },
          },
          {
            name: "_EMA 8/20",
            metainfo: {
              _metainfoVersion: 51,

              id: "_EMA820@tv-basicstudies-1",
              name: "_EMA 8/20",
              description: "_EMA 8/20",
              shortDescription: "_EMA 8/20",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "yellow",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Ema 8",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Ema 20",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));

                var ema8 = PineJS.Std.ema(v, 8, context);
                var ema20 = PineJS.Std.ema(v, 20, context);
                //console.log(this._context);
                return [ema8, ema20];
              };
            },
          },
        ]);
      },
    };

    const { innerWidth: width, innerHeight: height } = window;
    const widgetOptions = {
      debug: true,
      symbol: selectedBorsa + "/" + selectedEndeks,
      symbolCompare: selectedBorsa + "/" + selectedUstuneEndeks,
      selectedUstuneEndeks: selectedUstuneEndeks,
      minmov: 0.00000001,
      datafeed: Datafeed,
      interval: selectedEndeks.includes("Proaktif") ? "240" : "D",
      timezone: "Europe/Istanbul",
      urlApi: urlApi,
      container_id: containerId,
      library_path: libraryPath,
      locale: getLanguageFromURL() || "tr",
      disabled_features: [
        // "header_widget",
        "symbol_info",
        "create_volume_indicator_by_default",
        "footer_widget",
        "study_templates",
        "studies_overrides",
        // "header_saveload",
        "studies_symbol_search_spread_operators",
        "save_chart_properties_to_local_storage",
        "use_localstorage_for_settings",
        "header_chart_type",
        "header_compare",
        "header_fullscreen_button",
        "header_resolutions",
        "header_screenshot",
        "header_symbol_search",
        "header_undo_redo",
        "header_layouttoggle",
        "time_frames",
        "chart_template_storage",
        "drawing_templates",
        "go_to_date",
        "scales_date_format",
        "scales_time_hours_format",
        "control_bar",
        "timeframes_toolbar",
      ],
      enabled_features: [
        width > 768
          ? "side_toolbar_by_default"
          : "hide_left_toolbar_by_default",
      ],
      charts_storage_url: saveloadData,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: access_token,
      type_id: "frequency",
      fullscreen: fullscreen,
      autosize: autosize,
      studies_overrides: studiesOverrides,
      priceFormat: {
        type: "price",
        precision: precision ? precision : 0,
        minMove: 1,
      },
      theme: color == "dark" ? "dark" : "light",
      overrides: {
        // "mainSeriesProperties.showCountdown": true,
        create_volume_indicator_by_default: false,
        "mainSeriesProperties.style": 2,
        "mainSeriesProperties.lineStyle.styleType": 0,
        // "mainSeriesProperties.lineStyle.linestyle": 1,
        // "paneProperties.vertGridProperties.color": "#363c4e",
        // "paneProperties.horzGridProperties.color": "#363c4e",
        "symbolWatermark.transparency": 90,
        "scalesProperties.textColor": "#AAA",
        "mainSeriesProperties.minTick": "1,1,false",
        "mainSeriesProperties.lineStyle.color":
          color == "dark" ? "#39FF14" : "#04D9FF",
      },

      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: "_DAYICIM",
            metainfo: {
              _metainfoVersion: 51,

              id: "_DAYICIM@tv-basicstudies-1",
              name: "_DAYICIM",
              description: "_DAYICIM",
              shortDescription: "_DAYICIM",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "DAYICIM 55",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "DAYICIM 89",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "DAYICIM 144",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "DAYICIM 233",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var dayicim55 = PineJS.Std.ema(v, 55, context);
                var dayicim89 = PineJS.Std.ema(v, 89, context);
                var dayicim144 = PineJS.Std.ema(v, 144, context);
                var dayicim233 = PineJS.Std.ema(v, 233, context);
                //console.log(this._context);
                return [dayicim55, dayicim89, dayicim144, dayicim233];
              };
            },
          },
          {
            name: "_Fibonik",
            metainfo: {
              _metainfoVersion: 51,

              id: "_Fibonik@tv-basicstudies-1",
              name: "_Fibonik",
              description: "_Fibonik",
              shortDescription: "_Fibonik",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
                { id: "plot_3", type: "line" },
                { id: "plot_4", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "lime",
                  },
                  plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "maroon",
                  },
                  plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "blue",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Fibonik 5",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Fibonik 8",
                  histogramBase: 0,
                },
                plot_3: {
                  title: "Fibonik 13",
                  histogramBase: 0,
                },
                plot_4: {
                  title: "Fibonik 21",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var fibonik5 = PineJS.Std.ema(v, 5, context);
                var fibonik8 = PineJS.Std.ema(v, 8, context);
                var fibonik13 = PineJS.Std.ema(v, 13, context);
                var fibonik21 = PineJS.Std.ema(v, 21, context);
                //console.log(this._context);
                return [fibonik5, fibonik8, fibonik13, fibonik21];
              };
            },
          },
          {
            name: "_EMA 8/20",
            metainfo: {
              _metainfoVersion: 51,

              id: "_EMA820@tv-basicstudies-1",
              name: "_EMA 8/20",
              description: "_EMA 8/20",
              shortDescription: "_EMA 8/20",

              is_hidden_study: false,
              is_price_study: true,
              isCustomIndicator: true,
              format: {
                type: "price",
                precision: 2,
              },

              plots: [
                { id: "plot_0", type: "line" },
                { id: "plot_1", type: "line" },
              ],
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "red",
                  },
                  plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 0,

                    // Show price line?
                    trackPrice: false,

                    // Plot color
                    color: "yellow",
                  },
                },
                precision: 2,
                inputs: {},
              },
              styles: {
                plot_0: {
                  title: "Ema 8",
                  histogramBase: 0,
                },
                plot_1: {
                  title: "Ema 20",
                  histogramBase: 0,
                },
              },
              inputs: [],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //console.log(PineJS)
                // Define the symbol to be plotted.
                // Symbol should be a string.
                // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
                // For example,
                //    var symbol = 'AAPL';
                //    var symbol = '#EQUITY';
                //    var symbol = PineJS.Std.ticker(this._context) + '#TEST';
                var symbol = PineJS.Std.ticker(this._context);
                this._context.new_sym(symbol, PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                this._context.select_sym(1);

                // You can use following built-in functions in PineJS.Std object:
                //    open, high, low, close
                //    hl2, hlc3, ohlc4
                var v = this._context.new_var(PineJS.Std.close(this._context));

                var ema8 = PineJS.Std.ema(v, 8, context);
                var ema20 = PineJS.Std.ema(v, 20, context);
                //console.log(this._context);
                return [ema8, ema20];
              };
            },
          },
        ]);
      },
    };
    const tvWidget = new widget(widgetOptions);
    // console.log("tvWidget.version", TradingView.version());
    if (widgetOptions.selectedUstuneEndeks != null) {
      tvWidget.onChartReady(async () => {
        //  2. Sembol Ekleme Compare Symbol
        tvWidget
          .chart()
          .createStudy("Overlay", true, false, [widgetOptions.symbolCompare]);
        // await tvWidget.activeChart().createStudy("Compare", false, false, {
        //   symbol: `'${selectedBorsa}/${selectedUstuneEndeks}'`,
        // });
        // tvWidget.headerReady().then(() => {});
        // tvWidget.activeChart().createStudy("_EMA 8/20", false, false);
      });
    }
  }, [sonucGetir]);
  return <div id={containerId} className={"TVChartIndex"} />;
};
