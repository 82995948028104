import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import logo from "../../../assets/img/sirius-logo.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsEnvelope, BsEye, BsEyeSlash, BsKey } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { authLogin } from "../../../store/reducers/authSlice";
import { LOGIN } from "../../../utils/urls";
import { loginUser, logoutUser } from "../../../store/actions/authAction";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { timeout } from "../../../utils/helperFunction";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

const uyeGiris = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlApi =
    localStorage.getItem("urlApi") || "https://api3.siriusalgo.com/";
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const sendLogin = async (postData) => {
    setIsLoading(true);
    postDataWithHeaders(LOGIN, postData, dispatch).then(async (res) => {
      if (!res) {
        const { error } = res;
        setIsLoading(false);
        throw new Error(error);
      }

      if (res.code === 0) {
        console.log("res", res);

        if (res.subscription.package != null) {
          console.log("res.subscription.package", res.subscription.package);
          dispatch(loginUser(res));
          toast.success(
            "Üyelik Bilgileri doğrulandı. Sirius Algo sistemine giriş yapılıyor..."
          );
          setIsLoading(false);
          navigate("/tanitim-ve-kullanim");
        } else {
          dispatch(authLogin(res.data));
          toast.warning(
            "Üyelik Bilgileri doğrulandı. Paket satın alma paneline yönlendiriliyorsunuz!"
          );
          setIsLoading(false);
          navigate("/paket-satin-al");
        }
      } else {
        console.log(res.data);
        toast.error(res.data);
        logoutUser();
        await timeout(2000);
        setIsLoading(false);
      }
    });
  };
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha("uyeGiris");
    // console.log('login:',token)
    const postData = {
      recaptcha: token,
      email: username,
      password: password,
      platform: "web",
      // // j1407b: 1,
    };
    sendLogin(postData);
  };

  // useEffect(() => {
  //   if (isAuthenticated === true) {
  //     window.location.href = "/tanitim-ve-kullanim";
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    document.title = "Giriş Sayfası - Sirius Algo";
  }, []);
  return (
    <div className="loginPage">
      <ToastContainer />
      <div className="loginPage__container">
        <div className="loginPage__body">
          <div className="loginPage__logo">
            <img style={{ width: 194, height: 68 }} src={logo} />
          </div>
          <div className="loginPage__form">
            <Form onSubmit={handleSubmit}>
              <div className="loginPage_form_body">
                <Form.Label className="form-label" htmlFor="inputPassword">
                  E Posta Adresi*
                </Form.Label>
                <InputGroup className="input-form-control loginPage mb-3">
                  <BsEnvelope color="#6c757d" />
                  <Form.Control
                    className=""
                    type="email"
                    placeholder="E-posta adresinizi yazınız."
                    id="inputPassword"
                    aria-describedby="passwordHelpBlock"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen e-posta adresinizi giriniz!",
                      },
                    ]}
                  />
                </InputGroup>
              </div>
              <div className="loginPage_form_body">
                <Form.Label className="form-label" htmlFor="inputPassword1">
                  Şifre**
                </Form.Label>
                <InputGroup className="input-form-control loginPage mb-3">
                  <BsKey color="#6c757d" />
                  <Form.Control
                    className=""
                    type={showPassword ? "text" : "password"}
                    security="false"
                    placeholder="Şifrenizi giriniz."
                    id="inputPassword1"
                    aria-describedby="passwordHelpBlock"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifreyi giriniz!",
                      },
                    ]}
                  />
                  {showPassword ? (
                    <BsEyeSlash
                      color="#6c757d"
                      onClick={() => showPasswordHandler()}
                    />
                  ) : (
                    <BsEye
                      color="#6c757d"
                      onClick={() => showPasswordHandler()}
                    />
                  )}
                </InputGroup>
              </div>
              <div className="loginPage_form_body">
                <Button variant="primary" disabled={isLoading} type="submit">
                  {isLoading ? "Giriş Yapılıyor..." : "Üye Girişi"}
                </Button>

                <div className="loginPage__forgotPassword">
                  <Link to="/sifremi-unuttum">Şifremi Unuttum</Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="loginPage_form_body">
          <Link to="/uye-kayit" className="btn btn-outline-primary">
            Kayıt Ol{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.48879 18.7714C8.78657 19.0691 9.26929 19.0691 9.56707 18.7714L15.3019 13.0365C15.5997 12.7387 15.5997 12.256 15.3019 11.9582L9.56707 6.22333C9.26929 5.92556 8.78656 5.92556 8.48878 6.22333L8.22358 6.48853C7.9258 6.78631 7.9258 7.26905 8.22358 7.56683L13.1541 12.4974L8.22358 17.4279C7.9258 17.7256 7.9258 18.2084 8.22359 18.5062L8.48879 18.7714ZM8.21715 18.1043C8.17069 17.9188 8.21997 17.7143 8.365 17.5693L13.4369 12.4974L13.437 12.4974L8.36501 17.5693C8.22 17.7143 8.17071 17.9188 8.21715 18.1043ZM15.1605 12.0996C15.2335 12.1726 15.2822 12.2606 15.3067 12.3536C15.2822 12.2606 15.2335 12.1726 15.1605 12.0997L9.42566 6.3648C9.20599 6.14513 8.84989 6.14513 8.63021 6.3648L8.36501 6.63C8.22 6.77502 8.17071 6.97948 8.21715 7.16491C8.17069 6.97947 8.21997 6.77498 8.365 6.62996L8.6302 6.36476C8.84987 6.14508 9.20598 6.14508 9.42565 6.36476L15.1605 12.0996Z"
                fill="#3AB22F"
              />
            </svg>
          </Link>
          <div className="loginPage__forgotPassword">
            <Link to="/" className="loginPage__return__homepage">
              Anasayfa'ya Dön
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const WrappedLogin = withGoogleReCaptcha(uyeGiris);

function LoginPage(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedLogin url={props.url} theme={props.theme} />
    </GoogleReCaptchaProvider>
  );
}
export default LoginPage;
