import axios from "axios";

//const api_root = 'https://min-api.cryptocompare.com'

const history = {};

export default {
  history: history,

  getBars: async function (symbolInfo, resolution, from, to, first, limit) {
    // console.log("getBars symbolInfo", symbolInfo);
    var split_symbol = symbolInfo.name.split(/[:/]/);
    var vt = "";
    localStorage.setItem("frekans_name", split_symbol[0]);
    // console.log("api_root", api_root);
    //const url = resolution === '1D' ? '/data/histoday' :  resolution === '1W' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
    const url = "tw-data-index.php";
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      window.location.href = "/uye-cikis";
    }
    const qs = {
      access_token: access_token,
      exchange: symbolInfo.exchange,
      name: symbolInfo.name,
      ticker: symbolInfo.ticker,
      toTs: to > 0 ? to : "",
      limit: limit ? limit : 500,
      resolution: resolution,
    };
    //console.log({qs})
    const api_root = await new Promise((resolve) => {
      resolve(localStorage.getItem("urlApi"));
    });
    return axios({
      method: "post",
      url: `${api_root}${url}`,
      data: qs,
    }).then((data) => {
      // console.log("data", data);
      if (data.data.Response && data.data.Response === "Error") {
        console.log("Sirius Algo API error:", data.Message);
        return [];
      }

      console.log("=====data.Data", data.data.data.Data);
      if (data.data.data.Data.length) {
        //console.log(`Actually returned: ${new Date(data.data.TimeFrom * 1000).toISOString()} - ${new Date(data.data.TimeTo * 1000).toISOString()}`)
        var bars = data.data.data.Data.map((el) => {
          return {
            time: el.time * 1000, //TradingView requires bar time in ms
            low: el.low,
            high: el.high,
            open: el.open,
            close: el.close,
            volume: el.volumefrom,
          };
        });
        if (first) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        }

        return bars;
      } else {
        return [];
      }
    });
  },
};
