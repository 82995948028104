import React, { useEffect } from "react";
import "../components/Profil/Profil.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Header from "../components/System/header";
import ProfilTop from "../components/Profil/ProfilTop/ProfilTop";
import ProfilBottom from "../components/Profil/ProfilBottom/ProfilBottom";
import ProfilBody from "../components/Profil/ProfilBody/ProfilBody";
import ProfilOdemeGecmisi from "../components/Profil/ProfilOdemeGecmisi/ProfilOdemeGecmisi";

function ProfilScreen() {
  useEffect(() => {
    document.title = "Profilim - Sirius Algo";
  }, []);
  return (
    <Container fluid className="Profil">
      <Row className="Profil_container">
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard_container">
            <ProfilTop />
            <ProfilBody />
            <ProfilBottom />
            <ProfilOdemeGecmisi />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfilScreen;
