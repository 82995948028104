import React from "react";
import "./HomeAbout.css";
import { Container, Row, Col } from "react-bootstrap";
function HomeAbout() {
  return (
    <Container className="HomeAbout">
      <div className="HomeAbout__bg">
        <img src={require("../../../assets/img/about_bg.png")} alt="about" />
      </div>
      <div id="hakkimizda" className="aboutContainer">
        <div className="aboutTitle">Hakkımızda</div>
        <Row className="aboutBody">
          <Col className="about_left">
            Orphan Yazılım Bilişim Ar-Ge Akademi unvanıyla 2019 yılında kurulan
            Orphan Akademi, finans - borsa eğitimi ve yazılım alanlarında,
            'Analizci Yetiştiriyoruz' sloganıyla Türk yatırımcıların yerli-
            yabancı piyasalarda kendi kararlarını alarak isabetli yatırımlar
            yapabilmeleri için ihtiyaç duyduğu bakış açısını kazandırabilmeyi
            amaçlamaktadır.
            <br />
            <br /> 5000+ öğrenci Orphan Akademi’de Ali Ömür Teorisi ve bayZ
            Zamansallık Teorisi teknik eğitimlerini alarak borsa ve piyasalarda
            yatırımlarına yön verebildiler. <br />
            <br />
            Ağustos 2022 itibariyle öğrencilerimize sunduğumuz Zamansallık
            Teorisi (bayZ-Gölge) eğitimi de Ali Ömür Teorisi eğitimi gibi ilgi
            ile karşılandı. Bu eğitim süreci ve öncesinde öğrencilerimizin
            gösterdiği teveccühten cesaret alarak resmi faaliyetlerimiz arasında
            bulunan yazılım alanındaki ilk ürünümüz; Sirius Algo.
          </Col>
          <Col className="about_right">
            Sirius Algo’nun tüm kullanıcılara zamandan tasarruf, kullanım
            kolaylığı ve araştırmacı bakış açıları katacağına inanıyoruz.
            Sirius’u hazırlarken; kullanıcıların özgür olabilmelerini ve
            Sirius’un derin filtreleme özellikleri sayesinde benzersiz analiz
            kombinasyonları oluşturabilmelerini hedefledik. Analiz sürecinde en
            anlaşılır, en kolay şekilde sonuçlara ulaşabilmeniz için her sekmeyi
            özenle tasarladık. Sirius yayın hayatına başladığı günden itibaren
            her zaman kullanıcıların değerlendirmelerini göz önünde bulundurmuş
            ve buna göre aksiyonlar almıştır. Görüş ve önerilerinizi canlı
            yayınlarımızdan veya destek kanallarımızdan bizimle
            paylaşabilirsiniz. <br />
            <br />
            Sirius Algo, ilk etapta yalnızca Orphan Akademi öğrencilerine
            sunulmaktadır.
            <br />{" "}
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default HomeAbout;
