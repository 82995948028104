import React from "react";
import LoginPage from "../components/Auth/Login/LoginPage";

function AuthLogin() {
  return (
    <React.Fragment>
      <LoginPage />
    </React.Fragment>
  );
}

export default AuthLogin;
