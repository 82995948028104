import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Tabs,
  Tab,
  Modal,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import "./Paketler.css";
import paketPremium from "../../assets/img/paket-premium.png";
import paketPremiumPlus from "../../assets/img/paket-premiumplus.png";
import formulLike from "../../assets/img/formulLike.png";
import paket1 from "../../assets/img/premiumplus_uyari.png";
import {
  GETORDERNUMBER,
  GETTAKSIT,
  MAKEPAYMENT,
  PACKAGES,
} from "../../utils/urls";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PaketDesc from "../svgs/paketDesc";
import { timeout } from "../../utils/helperFunction";
import { BiCopy } from "react-icons/bi";
import kredikartMaster from "../../assets/img/front-master.png";
import kredikartBack from "../../assets/img/kredikarti-back.png";
import taksitlerImg from "../../assets/img/taksitler.png";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { ImCross } from "react-icons/im";

export const Paketler = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [validated, setValidated] = useState(false);
  const [satinAl, setSatinAl] = useState(0);
  const [paket, setPaket] = useState(user.bayz);
  const [paketTuru, setPaketTuru] = useState(user.bayz == 0 ? 4 : 1);
  const [paketler, setPaketler] = useState([]);
  const [paketSecilen, setPaketSecilen] = useState(null);
  const [aciklama, setAciklama] = useState("");
  const [taksitler, setTaksitler] = useState(null);
  const [cardType, setCardType] = useState("unknown");
  const [tableIndex, setTableIndex] = useState(0);
  const [cardExpiry, setCardExpiry] = useState("");
  const [surec, setSurec] = useState("kredikarti");
  const [tutar, setTutar] = useState(0);
  const [taksit, setTaksit] = useState(1);
  const [cvc, setCvc] = useState("");
  const [cvc2, setCvc2] = useState("•••");
  const [expiryMonth, setMonth] = useState("");
  const [expiryMonth2, setMonth2] = useState("••");
  const [expiryYear, setYear] = useState("");
  const [expiryYear2, setYear2] = useState("••");
  const [focus, setFocus] = useState(false);
  const [name, setName] = useState("");
  const [name2, setName2] = useState("KART ÜSTÜNDEKİ İSİM");
  const [number, setNumber] = useState("");
  const [number2, setNumber2] = useState("•••• •••• •••• ••••");
  const [uyari, setUyari] = useState(false);
  const [visibleLikeFormul, setVisibleLikeFormul] = useState(false);
  const handleLikeFormulClose = () => setVisibleLikeFormul(false);

  const _setPaketTuru = (value) => {
    setPaketTuru(value);
    console.log(
      "secilen",
      paketler.find((item) => item.id == value)
    );
    setPaketSecilen(paketler.find((item) => item.id == value));
  };
  const handleLikeFormul = () => {
    setUyari(true);
    setVisibleLikeFormul(false);
    setPaket(0);
  };
  const _setPaket = (value) => {
    if (user.bayz == 1 && !uyari) {
      if (value == 0) {
        setVisibleLikeFormul(true);
        return;
      }
    }

    setPaket(value);
    const refreshPaket = value == 0 ? 4 : 1;
    _setPaketTuru(refreshPaket);
  };
  const getPackages = async () => {
    const postData = {
      access_token: user.access_token,
    };
    postDataWithHeaders(PACKAGES, postData).then((res) => {
      if (res.code === 0) {
        console.log("res", res.data);
        setPaketler(res.data);
        setPaketSecilen(res.data.find((item) => item.id == paketTuru));
      }
    });
  };
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success("Kopyalandı!");
    } catch (err) {
      toast.error("Kopyalamada hata oluştu!");
    }
  };
  function getName(value) {
    setName(value);
    setName2(value);
  }
  function getMont(value) {
    setMonth(value);
    setMonth2(value);
  }
  function getYear(value) {
    setYear(value);
    setYear2(value);
  }
  function getCvc(value) {
    setCvc(value);
    setCvc2(value);
  }
  function cvcFocus(durum) {
    setFocus(durum);
  }

  const handleExpiryChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Sadece rakamları kabul edin
    const formattedValue = value.replace(/^(\d{2})(\d{2})$/, "$1/$2"); // MM/YY formatına dönüştürün
    setCardExpiry(formattedValue);
    const month = value.slice(0, 2);
    const year = value.slice(2, 4);
    setMonth(month);
    setMonth2(month);
    setYear(year);
    setYear2(year);
  };
  function getNumber(value) {
    // console.log(validator.isCreditCard(value));
    var chunks = [];

    for (var i = 0, charsLength = value.length; i < charsLength; i += 4) {
      chunks.push(value.substring(i, i + 4));
    }
    setNumber(value);
    setNumber2(chunks.join(" "));
    if (value.length == 6) {
      let number6 = value.substring(0, 6);
      getTaksits(number6);
    } else if (value.length < 6) {
      setTaksitler(null);
      setCardType("unknown");
    }
  }

  function getNumber2(value) {
    if (value.length == 16) {
      let number6 = value.substring(0, 6);
      getTaksits(number6);
      getNumber(value);
    }
  }
  function getCvcLenght(value, uzunluk) {
    if (value.length >= uzunluk) {
      let sonCvc = value.substring(0, uzunluk);
      getCvc(sonCvc);
    }
  }
  function selectChange(value) {
    // console.log(value)
    setTableIndex(value);
    setTaksit(value);
  }
  async function sendCard() {
    const postData = {
      package_id: paketTuru,
      card_name: name.trim(),
      card_number: number.trim(),
      card_month: expiryMonth.trim(),
      card_year: expiryYear.trim(),
      card_cv2: cvc.trim(),
      number_of_installments: taksit,
      access_token: user.access_token,
    };
    postDataWithHeaders(MAKEPAYMENT, postData, dispatch).then((res) => {
      if (res.code === 0) {
        console.log("res", res.data);
        toast.success("Ödeme onayı için bankaya yönlendiriliyorsunuz!");
        timeout(2000);
        window.location.href = res.data.url;
      } else {
        toast.error(res.data);
      }
    });
  }
  const _satinAl = (paketTuru, tutar) => {
    setSatinAl(1);
    setPaketTuru(paketTuru);
    setTutar(tutar);
  };
  const changeTab = async (key) => {
    setSurec(key);
    if (key == "banka") {
      const postData = {
        package_id: paketTuru,
        access_token: user.access_token,
      };
      postDataWithHeaders(GETORDERNUMBER, postData, dispatch).then((res) => {
        if (res.code === 0) {
          setAciklama(res.data);
        } else {
          toast.error(res.data);
        }
      });
    }
  };
  const getTaksits = async (number6) => {
    const postData = {
      card_number: number6,
      package_id: paketTuru,
    };
    postDataWithHeaders(GETTAKSIT, postData, dispatch).then((res) => {
      if (res.code === 0) {
        setTaksitler(res.data.Data);
        let newCard = res.data.Data.BankCardInfo.CardType.toLowerCase();
        if (newCard == "master") {
          newCard = "mastercard";
        }
        setCardType(newCard);
      } else {
        toast.error(res.data);
      }
    });
  };
  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    document.title = "Üye Paket Satın Alma Sayfası - Sirius Algo";
  }, []);
  return (
    <>
      <ToastContainer />
      {satinAl == 0 ? (
        <Container fluid>
          <Row className="Paketler__row">
            <Col className="Paketler__col__left">
              <div className="Paketler__col__left__title">Sirius Algo’nun</div>
              <div className="Paketler__col__left__slogan">
                Yıldızları yakından görebileceğiniz premium fırsatları ! ✨
              </div>
              <div className="Paketler__col__left__desc">
                İki paket ile istediğiniz verilere ulaşmanın imkanını Sirius
                Algo ile keşfedin. Sizin için en uygun paketi seçebilirisiniz.
              </div>
            </Col>
            <Col className="Paketler__col__right">
              <div className="Paketler__col__right__top">
                <div
                  className={
                    "Paketler__col__right__top__left" +
                    (paket == 0 ? " active" : "")
                  }
                  onClick={() => _setPaket(0)}
                >
                  <img src={paketPremium} />
                </div>
                <div
                  className={
                    "Paketler__col__right__top__right" +
                    (paket == 1 ? " active" : "")
                  }
                  onClick={() => (user.bayz == 1 ? _setPaket(1) : _setPaket(0))}
                >
                  <img src={paketPremiumPlus} />
                </div>
              </div>
              {paket == 0 && (
                <div className="Paketler__col__right__body">
                  <div className="Paketler__col__right__body__title">
                    Aylık Seçenek
                  </div>
                  <div className="Paketler__col__right__body__packages">
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 4 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(4)}
                    >
                      1 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 5 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(5)}
                    >
                      6 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__package" +
                        (paketTuru === 6 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(6)}
                    >
                      1 Yıllık Paket
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__desc">
                    <ul className="Paketler__col__right__body__desc__list">
                      <li>
                        <PaketDesc /> Hacim Analizi
                      </li>
                      <li>
                        <PaketDesc /> Liste Oluşturma
                      </li>
                      <li>
                        <PaketDesc /> İndikatör Analizi
                      </li>
                      <li>
                        <PaketDesc /> Formasyon Analizi
                      </li>
                      <li>
                        <PaketDesc /> Fiyat/Formasyon/Hacim/İndikatör/Ortalama
                        bazlı Alarmlar
                      </li>
                      <li>
                        <PaketDesc /> Frekans Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Korelasyon Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Pano Formüllerine Erişim
                      </li>
                      <li>
                        <PaketDesc /> Hareketli Ortalama Analizi
                      </li>
                      <li>
                        <PaketDesc /> Sirius Analizi Eğitim Videolarına Erişim
                      </li>
                    </ul>
                  </div>
                  <div className="Paketler__Premium__desc">
                    <ImCross />
                    <span>Zamansallık Teorisi Analizleri (bayZ-Gölge)</span>
                  </div>
                  <div className="Paketler__col__right__body__fiyat">
                    {paketSecilen?.price_percent > 0 && (
                      <div className="Paketler__col__right__body__fiyat__title">
                        Bu pakette %{paketSecilen.price_percent} indirim
                        uygulanır
                      </div>
                    )}
                    <div className="Paketler__col__right__body__fiyat__desc">
                      <div className="Paketler__col__right__body__fiyat__desc__title">
                        Toplam
                      </div>
                      <div className="Paketler__col__right__body__fiyat__desc__price">
                        {paketSecilen?.price_percent > 0 && (
                          <span className="toplam">
                            {paketSecilen?.price_discount}₺
                          </span>
                        )}
                        <span className="net">
                          {paketSecilen?.price}₺{" "}
                          <span className="kdv">(KDV Dahil)</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__satinal">
                    <div className="Paketler__col__right__body__satinal__title">
                      Satın alım işlemi tek seferliktir, siz işlemi yapmadan
                      güncelleme işlemi gerçekleşmez.
                    </div>
                    <div
                      className="Paketler__col__right__body__satinal__button"
                      onClick={() =>
                        _satinAl(paketSecilen?.id, paketSecilen?.price)
                      }
                    >
                      Satın Al
                    </div>
                  </div>
                </div>
              )}
              {paket == 1 && (
                <div className="Paketler__col__right__body">
                  <div className="Paketler__col__right__body__title">
                    Aylık Seçenek
                  </div>
                  <div className="Paketler__col__right__body__packages">
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 1 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(1)}
                    >
                      1 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 2 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(2)}
                    >
                      6 Aylık Paket
                    </div>
                    <div
                      className={
                        "Paketler__col__right__body__packageplus" +
                        (paketTuru === 3 ? " active" : "")
                      }
                      onClick={() => _setPaketTuru(3)}
                    >
                      1 Yıllık Paket
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__desc">
                    <ul className="Paketler__col__right__body__desc__list">
                      <li>
                        <PaketDesc /> Hacim Analizi
                      </li>
                      <li>
                        <PaketDesc /> Liste Oluşturma
                      </li>
                      <li>
                        <PaketDesc /> İndikatör Analizi
                      </li>
                      <li>
                        <PaketDesc /> Formasyon Analizi
                      </li>
                      <li>
                        <PaketDesc /> Fiyat/Formasyon/Hacim/İndikatör/Ortalama
                        bazlı Alarmlar
                      </li>
                      <li>
                        <PaketDesc /> Frekans Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Korelasyon Analizleri
                      </li>
                      <li>
                        <PaketDesc /> Pano Formüllerine Erişim
                      </li>
                      <li>
                        <PaketDesc /> Hareketli Ortalama Analizi
                      </li>
                      <li>
                        <PaketDesc /> Sirius Analizi Eğitim Videolarına Erişim
                      </li>
                    </ul>
                  </div>
                  {paket == 1 && (
                    <div className="Paketler__Premiumplus__desc">
                      <img
                        src={paket1}
                        alt="paket1"
                        className="Paketler__Premiumplus__desc__img"
                      />
                      <span>Zamansallık Teorisi Analizleri (bayZ-Gölge)</span>
                    </div>
                  )}
                  <div className="Paketler__col__right__body__fiyatplus">
                    {paketSecilen?.price_percent > 0 && (
                      <div className="Paketler__col__right__body__fiyat__title">
                        Bu pakette %{paketSecilen.price_percent} indirim
                        uygulanır
                      </div>
                    )}
                    <div className="Paketler__col__right__body__fiyatplus__desc">
                      <div className="Paketler__col__right__body__fiyat__desc__title">
                        Toplam
                      </div>
                      <div className="Paketler__col__right__body__fiyat__desc__price">
                        {paketSecilen?.price_percent > 0 && (
                          <span className="toplam">
                            {paketSecilen?.price_discount}₺
                          </span>
                        )}
                        <span className="net">
                          {paketSecilen?.price}₺{" "}
                          <span className="kdv">(KDV Dahil)</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Paketler__col__right__body__satinal">
                    <div className="Paketler__col__right__body__satinal__title">
                      Satın alım işlemi tek seferliktir, siz işlemi yapmadan
                      güncelleme işlemi gerçekleşmez.
                    </div>
                    <div
                      className="Paketler__col__right__body__satinalplus__button"
                      onClick={() => _satinAl(paketTuru, paketSecilen?.price)}
                    >
                      Satın Al
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          style={{
            marginTop: 26,
            gap: 40,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Row className="col-lg-10 mx-auto">
            <div className="Paketler__odeme_top">
              <div
                className={
                  "Paketler__odeme_top__col" +
                  (surec === "kredikarti" ? " active" : "")
                }
                onClick={() => changeTab("kredikarti")}
              >
                Kredi Kartı
              </div>
              <div
                className={
                  "Paketler__odeme_top__col" +
                  (surec === "banka" ? " active" : "")
                }
                onClick={() => changeTab("banka")}
              >
                Banka EFT / Havale
              </div>
            </div>
            <div className="Paketler__odeme_bottom row">
              {surec === "kredikarti" ? (
                <>
                  <Row>
                    <Col lg={12} className="Paketler__odeme_bottom__disclaimer">
                      <IoMdInformationCircleOutline size={64} />
                      <p>
                        Satın alma işlemi sonrasında, paket yenileme işlemlerini
                        kendiniz yapmanız gerekmektedir. Satın alma işleminden
                        sonra sistem kendisi yenileme işlemi yapmamaktadır
                      </p>
                    </Col>
                  </Row>
                  <div className="Paketler__odeme_bottom__left col-lg-5">
                    <div
                      className={
                        focus
                          ? "Paketler__odeme_bottom__kredikarti flipped"
                          : "Paketler__odeme_bottom__kredikarti"
                      }
                    >
                      <div className="Paketler__odeme_bottom__kredikarti__front">
                        <img src={kredikartMaster} alt="kredikart" />
                        <div className="Paketler__odeme_bottom__kredikarti__desc">
                          <div className="Paketler__odeme_bottom__kredikarti__number">
                            {number2}
                          </div>
                          <div className="Paketler__odeme_bottom__kredikarti__info">
                            <div className="Paketler__odeme_bottom__kredikarti__name">
                              {name2}
                            </div>
                            <div className="Paketler__odeme_bottom__kredikarti__date">
                              {expiryMonth2}/{expiryYear2}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Paketler__odeme_bottom__kredikarti__back">
                        <img src={kredikartBack} alt="kredikart" />
                        <div className="Paketler__odeme_bottom__kredikarti__desc">
                          <div className="Paketler__odeme_bottom__kredikarti__cvc">
                            {cvc2}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Paketler__odeme_bottom__kredikarti__form">
                      <Form
                        noValidate
                        validated={validated}
                        autoComplete="off"
                        className="formTab"
                      >
                        <Form.Group className="colform" id="formGridAd">
                          <Form.Label>Kart Numarası</Form.Label>
                          <Form.Control
                            required
                            type="tel"
                            name="number"
                            placeholder="Kart Numarası"
                            maxLength="16"
                            defaultValue={number}
                            onChange={(event) => getNumber(event.target.value)}
                            onBlur={(event) => getNumber2(event.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Kart numarasını giriniz
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="colform" id="kartAdi">
                          <Form.Label>Adınız</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="name"
                            placeholder="Kart Üstündeki Adınız"
                            defaultValue={name}
                            onChange={(event) => getName(event.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Kart üzerindeki ismi giriniz
                          </Form.Control.Feedback>
                        </Form.Group>
                        <InputGroup className="colform gap24" id="formGridAd">
                          <div className="col">
                            <Form.Label>Geçerlilik Tarihi</Form.Label>
                            <Form.Control
                              required
                              type="tel"
                              name="cardExpiry"
                              placeholder="__/__"
                              maxLength="5"
                              value={cardExpiry}
                              onChange={(text) => handleExpiryChange(text)}
                            />
                          </div>
                          <div className="col">
                            <Form.Label>Cvc</Form.Label>
                            <Form.Control
                              required
                              type="tel"
                              name="cvc"
                              maxLength="3"
                              placeholder="Kart Cvc"
                              defaultValue={cvc}
                              onKeyPress={(event) =>
                                getCvcLenght(event.target.value, 3)
                              }
                              onChange={(event) => getCvc(event.target.value)}
                              onFocus={() => cvcFocus(true)}
                              onBlur={() => cvcFocus(false)}
                            />
                          </div>
                        </InputGroup>
                      </Form>
                    </div>
                  </div>
                  <div className="Paketler__odeme_bottom__right col-lg-7">
                    {taksitler ? (
                      <div className="Paketler__odeme_bottom__right_taksitler">
                        <h2>Taksit Tablosu</h2>
                        {taksitler && (
                          <p>
                            {taksitler.BankCardInfo.CardLogo.length > 0 && (
                              <div className="Paketler__odeme_bottom__kredikarti__logo">
                                <img
                                  src={taksitler.BankCardInfo.CardLogo}
                                  className="CardImages"
                                  alt="Banka Resmi"
                                />{" "}
                                <span>{taksitler.BankCardInfo.CardName}</span>
                              </div>
                            )}
                          </p>
                        )}
                        <div className="odemeTablo">
                          <div className="odemeTabloHead">
                            <span>*</span>
                            <span className="odemeTabloHeadText">
                              <span>Taksit</span>
                              <span>Aylık Ödeme</span>
                              <span>Toplam</span>
                            </span>
                          </div>
                          <div className="odemeTabloBody">
                            {taksitler.BankPaymentInstallmentInfoList[0].PaymentInstallmentInfoList.map(
                              (tks, index) => (
                                <div
                                  className="odemeTabloRow"
                                  key={index}
                                  onClick={() =>
                                    selectChange(tks.InstallmentNumber)
                                  }
                                >
                                  {tks.InstallmentNumber == 0 ? (
                                    <>
                                      <div>
                                        <Form.Check
                                          className="odemeTablo"
                                          key={index}
                                          type="radio"
                                          name="taksit"
                                          id={`default-${index}`}
                                          checked={
                                            tks.InstallmentNumber == tableIndex
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            selectChange(tks.InstallmentNumber)
                                          }
                                        />
                                      </div>
                                      <div className="odemeTabloRowText">
                                        <div>Peşin Ödeme</div>
                                        <div></div>
                                        <div>{tks.Amount}₺</div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <Form.Check
                                          className="odemeTablo"
                                          key={index}
                                          type="radio"
                                          name="taksit"
                                          checked={
                                            tks.InstallmentNumber == tableIndex
                                              ? true
                                              : false
                                          }
                                          id={`default-${index}`}
                                          onChange={() =>
                                            selectChange(tks.InstallmentNumber)
                                          }
                                        />
                                      </div>
                                      <div className="odemeTabloRowText">
                                        <div>{tks.InstallmentTitle}</div>
                                        <div>{tks.PerInstallmentAmount}₺</div>
                                        <div>{tks.Amount}₺</div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )}
                            <Button
                              className="paketSatinAl"
                              onClick={() => sendCard()}
                            >
                              SATIN AL
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="Paketler__odeme_bottom__right_taksitler">
                        <h2>Taksit Tablosu</h2>
                        <p>
                          Taksit tablosu için lütfen kart bilgilerinizi giriniz.
                          Bankanız taksite uygun ise sizlere taksit imkanlarını
                          göstereceğiz.
                        </p>
                        <img src={taksitlerImg} />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="Paketler__odeme_bottom__table">
                    <div className="Paketler__odeme_bottom__table_top">
                      <div width="100">Bilgiler</div>
                      <div>#</div>
                    </div>
                    <div className="Paketler__odeme_bottom__table_body">
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>Banka</div>
                        <div>İş Bankası</div>
                      </div>
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>Hesap Adı</div>
                        <div>
                          Orphan Yazılım Bilişim Arge Akademi Eğitim Danışmanlık
                          Ve Tercümanlık Hizmetleri Dış Ticaret Limited Şirketi{" "}
                          <BiCopy
                            size={24}
                            style={{ float: "right" }}
                            onClick={() =>
                              copyToClipBoard(
                                "Orphan Yazılım Bilişim Arge Akademi Eğitim Danışmanlık Ve Tercümanlık Hizmetleri Dış Ticaret Limited Şirketi"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>IBAN</div>
                        <div>
                          TR57 0006 4000 0011 1351 2684 44{" "}
                          <BiCopy
                            size={15}
                            style={{ float: "right" }}
                            onClick={() =>
                              copyToClipBoard(
                                "TR57 0006 4000 0011 1351 2684 44"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>Şube</div>
                        <div>BALMUMCU/İSTANBUL(1135)</div>
                      </div>
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>Tutar</div>
                        <div>
                          <b>{tutar} ₺</b>
                        </div>
                      </div>
                      <div className="Paketler__odeme_bottom__table_body_row">
                        <div>Açıklama</div>
                        <div>
                          <b>{aciklama}</b>
                        </div>
                      </div>
                    </div>
                    <div className="Paketler__odeme_bottom__table_footer">
                      * Havale / EFT yaparken lütfen açıklama kısmına yukarıdaki
                      numarayı yazınız!
                      <br />* Havale / EFT yaptıktan 5 dk sonra sol menüden
                      "Profilim" linkine tıklayıp, sayfayı yenileyiniz.
                    </div>
                  </div>
                </>
              )}
            </div>
          </Row>
        </Container>
      )}
      {visibleLikeFormul && (
        <Modal
          show={visibleLikeFormul}
          onHide={handleLikeFormulClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modalLikeFormul">
              <img src={formulLike} />
              <h2>Uyarı!</h2>
              <p>
                Premium Paket satın almak istiyor musunuz?
                <br />
                <br />
                Devam ederseniz Premium+ özelliklerini kullanamayacaksınız!
              </p>
              <Button
                className="modalLikeFormul__buttons"
                onClick={() => handleLikeFormul()}
              >
                Kabul Ediyorum
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Paketler;
