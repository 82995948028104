import React, { useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Header from "../components/System/header";

import Listelerim from "../components/ListeFavori/Listelerim";
import { Link, useLocation, useParams } from "react-router-dom";
import Favorilerim from "../components/ListeFavori/Favorilerim";

function ListeFavoriScreen() {
  const location = useLocation();

  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row>
            <div className="DashBoard_container">
              <div className="ListeFavori__container">
                <div className="ListeFavori__header">
                  <div className="ListeFavori__header__left"></div>
                  <div className="ListeFavori__header__tabs">
                    <Link
                      className={
                        location.pathname == "/favorilerim"
                          ? "ListeFavori__header__tab active"
                          : "ListeFavori__header__tab"
                      }
                      to={"/favorilerim"}
                    >
                      Favorilerim
                    </Link>
                    <Link
                      className={
                        location.pathname.search("/listelerim") > -1
                          ? "ListeFavori__header__tab active"
                          : "ListeFavori__header__tab"
                      }
                      to={"/listelerim"}
                    >
                      Listelerim
                    </Link>
                  </div>

                  <div className="ListeFavori__header__right"></div>
                </div>
                <div className="ListeFavori__header__body">
                  {location.pathname.search("/listelerim") > -1 ? (
                    <Listelerim />
                  ) : (
                    <Favorilerim />
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ListeFavoriScreen;
