import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: [],
};

const filtersSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    setFilters(state, action) {
      localStorage.setItem("filters", JSON.stringify(action.payload));
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
