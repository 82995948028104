import * as React from "react";
const UserSirius = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="m22.063 11.471-2.87-.822a7.842 7.842 0 0 1-3.418-2.007 7.865 7.865 0 0 1-2.003-3.426l-.82-2.876a.548.548 0 0 0-1.013 0l-.82 2.876a7.866 7.866 0 0 1-2.003 3.426 7.841 7.841 0 0 1-3.419 2.007l-2.87.822a.526.526 0 0 0-.275.825c.069.092.166.158.276.19l2.87.821a7.84 7.84 0 0 1 3.418 2.008c.949.952 1.639 2.131 2.003 3.426l.82 2.876a.528.528 0 0 0 .824.276.528.528 0 0 0 .189-.276l.82-2.876a7.864 7.864 0 0 1 2.003-3.426 7.84 7.84 0 0 1 3.418-2.008l2.87-.821a.526.526 0 0 0 .276-.826.526.526 0 0 0-.276-.189Z"
    />
  </svg>
);
export default UserSirius;
