import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favories: [],
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    setFavories(state, action) {
      state.favories = action.payload;
    },
  },
});

export const { setFavories } = dataSlice.actions;

export default dataSlice.reducer;
