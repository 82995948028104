import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./screens/HomePage";
import ErrorPage from "./screens/ErrorPage";
import AuthLogin from "./screens/AuthLogin";
import AuthRegister from "./screens/AuthRegister";
import AuthForgotPassword from "./screens/AuthForgotPassword";
import DashBoard from "./screens/DashBoard";
import Packages from "./screens/Packages";
import PaymentReceived from "./screens/PaymentReceived";
import AuthResetPassword from "./screens/AuthResetPassword";
import AuthUyeCikis from "./screens/AuthUyeCikis";
import { useSelector } from "react-redux";
import AnaKumandaScreen from "./screens/AnaKumanda";
import AnaKumandaScreenEdit from "./screens/AnaKumandaEdit";
import GrafikPaneli from "./screens/GrafikPaneli";
import PariteOdasi from "./screens/PariteOdasi";
import OzelAramaPanel from "./screens/OzelArama";
import FormulAramaPanel from "./screens/FormulArama";
import EndeksFrekansPanel from "./screens/EndeksFrekans";
import PanoScreen from "./screens/Pano";
import ProfilScreen from "./screens/Profil";
import KvkkScreen from "./components/Sozlesmeler/Kvkk";
import SssScreen from "./components/Sozlesmeler/Sss";
import SorumlulukReddiScreen from "./components/Sozlesmeler/SorumlulukReddi";
import KullaniciSozlesmesiScreen from "./components/Sozlesmeler/KullaniciSozlesmesi";
import GizlilikPolitikasiScreen from "./components/Sozlesmeler/GizlilikPolitikasi";
import CerezPolitikasiScreen from "./components/Sozlesmeler/CerezPolitikasi";
import DestekScreen from "./screens/Destek";
import ListeFavoriScreen from "./screens/ListeFavori";
import EgitimOdasi from "./screens/EgitimOdasi";
import Bildirimler from "./screens/Bildirimler";
import Alarmlar from "./screens/Alarmlar";
import Aktivasyon from "./screens/AuthUyeAktivasyon";
const MainRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/uye-giris" element={<AuthLogin />} />
      <Route path="/uye-kayit" element={<AuthRegister />} />
      <Route path="/uye-cikis" element={<AuthUyeCikis />} />
      <Route path="/uye-aktivasyon/:code" element={<Aktivasyon />} />
      <Route path="/sifremi-unuttum" element={<AuthForgotPassword />} />
      <Route
        path="/uye-parola-sifirlama/:code"
        element={<AuthResetPassword />}
      />
      {/** Ana Sistem Sayfaları */}
      {isAuthenticated && (
        <>
          <Route path="/paket-satin-al" element={<Packages />} />
          <Route path="/payment-received" element={<PaymentReceived />} />
          <Route
            path="/payment-received/:paymentId"
            element={<PaymentReceived />}
          />
          <Route path="/destek" element={<DestekScreen />} />
          <Route path="/destek-detay/:destekId" element={<DestekScreen />} />
          <Route path="/tanitim-ve-kullanim" element={<DashBoard />} />
          <Route path="/kvkk" element={<KvkkScreen />} />
          <Route path="/sss" element={<SssScreen />} />
          <Route path="/sorumluluk-reddi" element={<SorumlulukReddiScreen />} />
          <Route path="/cerez-politikasi" element={<CerezPolitikasiScreen />} />
          <Route
            path="/gizlilik-politikasi"
            element={<GizlilikPolitikasiScreen />}
          />
          <Route
            path="/kullanici-sozlesmesi"
            element={<KullaniciSozlesmesiScreen />}
          />
          <Route path="/ana-kumanda" element={<AnaKumandaScreen />} />
          <Route
            path="/ana-kumanda/:formulId"
            element={<AnaKumandaScreenEdit />}
          />
          <Route path="/genel-arama" element={<AnaKumandaScreen />} />
          <Route path="/ozel-arama" element={<OzelAramaPanel />} />
          <Route path="/ozel-arama/:formulId" element={<OzelAramaPanel />} />
          <Route
            path="/endeks-frekans-odasi"
            element={<EndeksFrekansPanel />}
          />
          <Route path="/grafik-paneli" element={<GrafikPaneli />} />
          <Route path="/grafik-paneli/:grafikName" element={<GrafikPaneli />} />
          <Route path="/parite-odasi" element={<PariteOdasi />} />
          <Route path="/pano" element={<PanoScreen />} />
          <Route path="/profil" element={<ProfilScreen />} />
          <Route path="/listelerim" element={<ListeFavoriScreen />} />
          <Route path="/listelerim/:listeId" element={<ListeFavoriScreen />} />
          <Route path="/favorilerim" element={<ListeFavoriScreen />} />
          <Route path="/egitim-odasi" element={<EgitimOdasi />} />
          <Route path="/bildirimler" element={<Bildirimler />} />
          <Route path="/alarmlar" element={<Alarmlar />} />
          <Route path="/formul-alarmlar" element={<Alarmlar />} />
          <Route
            path="/formul-alarmlar/:formulId"
            element={<FormulAramaPanel />}
          />
        </>
      )}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
export default MainRoutes;
