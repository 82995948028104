import * as React from "react";
const SidemenuEgitim = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M22.386 18.333a.334.334 0 0 1-.333.334h-9.334a1.003 1.003 0 0 0-1 1 .333.333 0 1 1-.666 0 1.003 1.003 0 0 0-1-1H.719a.333.333 0 0 1 0-.667h1.667v-1.667A.334.334 0 0 1 2.72 16h7.334a1.67 1.67 0 0 1 1.333.667A1.67 1.67 0 0 1 12.72 16h7.334a.335.335 0 0 1 .333.333V18h1.667a.335.335 0 0 1 .333.333ZM17.386 0h-12a3.005 3.005 0 0 0-3 3v6.667a3.005 3.005 0 0 0 3 3H10.3a.338.338 0 0 1 .314.216l.46 1.234a.335.335 0 0 0 .626 0l.464-1.234a.331.331 0 0 1 .31-.216h4.913a3.005 3.005 0 0 0 3-3V3a3.004 3.004 0 0 0-3-3ZM14.5 7.227 9.99 9.483a.982.982 0 0 1-.97-.043.992.992 0 0 1-.476-.853V4.08a1 1 0 0 1 1.446-.897L14.5 5.44a.998.998 0 0 1 0 1.787Z" />
  </svg>
);
export default SidemenuEgitim;
