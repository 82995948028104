import * as React from "react";
const XLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M14.234 10.662 22.976.5h-2.071l-7.591 8.824L7.25.5H.258l9.168 13.343L.258 24.5H2.33l8.016-9.318 6.403 9.318h6.993l-9.509-13.838Zm-2.838 3.299-.929-1.329L3.077 2.06h3.181l5.965 8.532.93 1.329 7.753 11.09h-3.182l-6.328-9.05Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default XLogo;
