import * as React from "react";
const ButtonClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path d="M12 23.4C5.714 23.4.6 18.286.6 12 .6 5.714 5.714.6 12 .6 18.285.6 23.4 5.714 23.4 12c0 6.286-5.114 11.4-11.4 11.4Zm0-21.6C6.375 1.8 1.8 6.376 1.8 12S6.375 22.2 12 22.2c5.624 0 10.2-4.576 10.2-10.2S17.624 1.8 12 1.8Z" />
    <path d="M7.62 16.98a.6.6 0 0 1-.424-1.024l8.76-8.76a.6.6 0 1 1 .85.848l-8.762 8.76a.598.598 0 0 1-.424.177Z" />
    <path d="M16.38 16.98a.598.598 0 0 1-.423-.175L7.195 8.044a.6.6 0 1 1 .849-.849l8.761 8.761a.6.6 0 0 1-.424 1.025Z" />
  </svg>
);
export default ButtonClose;
