import React, { useEffect, useState } from "react";
import "../components/Bildirimler/Bildirimler.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../components/System/header";
import BildirimSistem from "../components/svgs/bildirimSistem";
import { postDataWithHeaders } from "../utils/apiUtils";
import { NOTIFICATIONS } from "../utils/urls";
import { toast, ToastContainer } from "react-toastify";
import bildirimFormul from "../assets/img/bildirim_formul.png";
import UserStarNull from "../components/svgs/userStarNull";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Bildirimler() {
  const { destekId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.darkmode);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [bildirims, setBildirims] = useState([]);

  const bildiremGit = (bildirim) => {
    const formulaId = bildirim.formula_id ? bildirim.formula_id : 0;
    const sharedId = bildirim.share_id ? bildirim.share_id : 0;
    const bildirimId = formulaId != 0 ? formulaId : sharedId;
    navigate("/ozel-arama/" + bildirimId);
  };

  const _getBildirims = () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };

    postDataWithHeaders(NOTIFICATIONS, postData, dispatch).then((res) => {
      if (res.code == 0) {
        console.log("res", res.data);
        setBildirims(res.data);
      } else {
        console.log("res err", res);
        toast.error(res.data);
      }
    });
  };

  useEffect(() => {
    _getBildirims();
  }, []);

  useEffect(() => {
    document.title = "Bildirim Listesi - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard__container">
            <h2 className="Bildirimler__container__title">Bildirimler</h2>
            <div className="Bildirimler__container__content">
              {bildirims.length > 0 ? (
                bildirims.map((bildirim, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        bildirim.read == 0
                          ? "Bildirimler__container__content__itemx"
                          : "Bildirimler__container__content__itemx op5"
                      }
                    >
                      <div className="Bildirimler__container__content__item">
                        {bildirim.source == 0 ? (
                          <BildirimSistem
                            color={theme == "dark" ? "white" : "black"}
                          />
                        ) : (
                          <UserStarNull
                            color={theme == "dark" ? "white" : "black"}
                          />
                        )}
                        <div className="Bildirimler__container__content__item_content">
                          <span className="Bildirimler__container__content__item_text">
                            {bildirim.message}
                          </span>
                          <span className="Bildirimler__container__content__item_date">
                            Bildirim Tarihi: {bildirim.inserted}
                          </span>
                        </div>
                      </div>
                      {/* {bildirim.source == 1 && (
                        <div className="Bildirimler__container__content__itemz">
                          <div
                            className="Bildirimler__container__content__item_formul"
                            onClick={() => bildiremGit(bildirim)}
                          >
                            {bildirim.title
                              ? bildirim.title
                              : "Formül Detayı için tıklayınız"}
                          </div>
                          <img src={bildirimFormul} />
                        </div>
                      )} */}
                    </div>
                  );
                })
              ) : (
                <div className="Bildirimler__container__content__skelaton">
                  <SkeletonTheme baseColor="#373a44" highlightColor="#373a44">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                      <Skeleton
                        key={i}
                        className="Bildirimler__container__content__itemx"
                      />
                    ))}
                  </SkeletonTheme>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Bildirimler;
