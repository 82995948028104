import * as React from "react";
const UserMemnuniyet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        stroke="#fff"
        d="m23.13 10.565-4.204 4.1a1.26 1.26 0 0 0-.363 1.115l.992 5.787c.064.365-.03.712-.264.988-.238.283-.58.442-.939.442-.195 0-.385-.047-.567-.143l-5.198-2.733h-.002a1.277 1.277 0 0 0-1.17 0h-.001l-5.197 2.733a1.24 1.24 0 0 1-1.506-.298 1.185 1.185 0 0 1-.266-.989l.992-5.787a1.26 1.26 0 0 0-.362-1.115L.87 10.565A1.21 1.21 0 0 1 .56 9.314a1.21 1.21 0 0 1 .986-.831l5.811-.844c.41-.06.766-.317.95-.69l2.598-5.265A1.21 1.21 0 0 1 12 1.004c.47 0 .888.259 1.095.68l2.598 5.265c.184.372.539.63.949.69l5.811.843c.465.068.84.385.986.832a1.21 1.21 0 0 1-.308 1.251Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default UserMemnuniyet;
