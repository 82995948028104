import "./Sliders.css";
import React from "react";
import sliderImg from "../../../assets/img/landing_pc.png";
import sliderAstronot from "../../../assets/img/landing_astronot.png";

function Sliders() {
  return (
    <div className="Slider">
      <div className="sliderTop"></div>
      <div className="sliderBottom">Kendini yenen yenilmezdir. ✨</div>
      <div className="sliderDesc"></div>
      <div className="sliderImg">
        <img src={sliderImg} className="sliderImg" alt="slider" />
        <img
          src={sliderAstronot}
          className="sliderAstronot"
          alt="slider astronot"
        />
      </div>
    </div>
  );
}

export default Sliders;
