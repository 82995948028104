import React, { useEffect, useState } from "react";
import "./Register.css";
import logo from "../../../assets/img/sirius-logo.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  BsEnvelope,
  BsEye,
  BsEyeSlash,
  BsKey,
  BsPerson,
  BsPhone,
} from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { authLogin, authLogout } from "../../../store/reducers/authSlice";
import MaskedInput from "react-text-mask";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { REGISTER } from "../../../utils/urls";
import { postDataWithHeaders } from "../../../utils/apiUtils";
import { timeout } from "../../../utils/helperFunction";
const access_token = localStorage.getItem("access_token");

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function uyeKayit(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlApi = localStorage.getItem("urlApi");
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isLogin, setIsLogin] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [page, setPage] = useState(false);
  const showPasswordHandler = (value) => {
    setShowPassword(value);
  };
  const handleSubmit = async () => {
    const token = await props.googleReCaptchaProps.executeRecaptcha("uyeGiris");
    const phoneTrimed = phone
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .replaceAll(")", "")
      .replaceAll("(", "");
    setIsLoading(true);
    const postData = {
      recaptcha: token,
      name: firstname.trim() + " " + lastname.trim(),
      email: email.trim(),
      phone: phoneTrimed.trim(),
      password1: password.trim(),
      password2: password2.trim(),
    };
    postDataWithHeaders(REGISTER, postData, dispatch).then(async (res) => {
      console.log("res", res);
      if (res.code === 0) {
        console.log("res.data", res.data);
        // dispatch(authLogin(res.data));
        toast.success(
          "Üyelik Bilgileri doğrulandı. E-postanıza gelen üyelik doğrulama linkine tıklayın!"
        );
        setIsLoading(false);
        timeout(3000).then(() => {
          navigate("/uye-giris");
        });
      } else {
        console.log(res.data);
        toast.error(res.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    document.title = "Üye Kayıt Sayfası - Sirius Algo";
  }, []);
  return (
    <div className="registerPage">
      <ToastContainer />
      <div className="registerPage__container">
        <div className="registerPage__body">
          <div className="registerPage__logo">
            <img style={{ width: 194, height: 68 }} src={logo} />
          </div>
          <div className="registerPage__form">
            <h2 className="registerPage__form_title">Yeni Üyelik</h2>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputAd">
                Ad *
              </Form.Label>
              <InputGroup className="input-form-control loginPage mb-1">
                <BsPerson color="#6c757d" />
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Adınızı giriniz."
                  id="inputAd"
                  aria-describedby="adHelpBlock"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen adınızı giriniz!",
                    },
                  ]}
                />
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputSoyad">
                Soyad *
              </Form.Label>
              <InputGroup className="input-form-control loginPage mb-1">
                <BsPerson color="#6c757d" />
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Soyadınızı giriniz."
                  id="inputSoyad"
                  aria-describedby="soyadHelpBlock"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen soyadınızı giriniz!",
                    },
                  ]}
                />
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword">
                E Posta Adresi*
              </Form.Label>
              <InputGroup className="input-form-control loginPage mb-1">
                <BsEnvelope color="#6c757d" />
                <Form.Control
                  className=""
                  type="email"
                  placeholder="E-posta adresinizi yazınız."
                  id="inputPassword"
                  aria-describedby="passwordHelpBlock"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen e-posta adresinizi giriniz!",
                    },
                  ]}
                />
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword2">
                Telefon*
              </Form.Label>
              <InputGroup className="input-form-control loginPage mb-1">
                <BsPhone color="#6c757d" />
                <MaskedInput
                  mask={[
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className="form-control"
                  placeholder="Telefon numarasını giriniz"
                  guide={false}
                  id="inputPassword2"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword1">
                Şifre**
              </Form.Label>
              <InputGroup className="input-form-control loginPage mb-1">
                <BsKey color="#6c757d" />
                <Form.Control
                  className=""
                  type={showPassword == 1 ? "text" : "password"}
                  security="false"
                  placeholder="Şifrenizi giriniz."
                  id="inputPassword1"
                  aria-describedby="password1HelpBlock"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifreyi giriniz!",
                    },
                  ]}
                />
                {showPassword == 1 ? (
                  <BsEyeSlash
                    color="#6c757d"
                    onClick={() => showPasswordHandler(0)}
                  />
                ) : (
                  <BsEye
                    color="#6c757d"
                    onClick={() => showPasswordHandler(1)}
                  />
                )}
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Form.Label className="form-label" htmlFor="inputPassword2">
                Şifre ( Tekrar ) *
              </Form.Label>
              <InputGroup className="input-form-control mb-3">
                <BsKey color="#6c757d" />
                <Form.Control
                  className=""
                  type={showPassword == 2 ? "text" : "password"}
                  security="false"
                  placeholder="Şifre tekrarını giriniz."
                  id="inputPassword2"
                  aria-describedby="passwordHelpBlock"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifre tekrarını giriniz!",
                    },
                  ]}
                />
                {showPassword == 2 ? (
                  <BsEyeSlash
                    color="#6c757d"
                    onClick={() => showPasswordHandler(0)}
                  />
                ) : (
                  <BsEye
                    color="#6c757d"
                    onClick={() => showPasswordHandler(2)}
                  />
                )}
              </InputGroup>
            </div>
            <div className="registerPage_form_body">
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? "Lütfen bekleyiz..." : "Kayıt Ol!"}
              </Button>
            </div>
          </div>
        </div>
        <div className="registerPage_form_body">
          <Link to="/uye-giris" className="btn btn-outline-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7115 6.22332C14.4138 5.92556 13.931 5.92556 13.6333 6.22333L7.89838 11.9582C7.6006 12.256 7.6006 12.7387 7.89838 13.0365L13.6333 18.7714C13.931 19.0691 14.4138 19.0691 14.7116 18.7714L14.9768 18.5062C15.2745 18.2084 15.2745 17.7256 14.9768 17.4279L10.0462 12.4973L14.9768 7.56684C15.2745 7.26906 15.2745 6.78627 14.9767 6.48849L14.7115 6.22332ZM14.9951 7.10256C14.9793 7.22064 14.9261 7.33467 14.8353 7.42542L9.7634 12.4973L9.76338 12.4973L14.8353 7.42537C14.9261 7.33464 14.9793 7.22063 14.9951 7.10256ZM7.87505 12.4973C7.87505 12.6413 7.92997 12.7852 8.03981 12.8951L13.7747 18.6299C13.9944 18.8496 14.3505 18.8496 14.5701 18.6299L14.8353 18.3647C14.9148 18.2853 14.9655 18.188 14.9875 18.0858C14.9655 18.188 14.9147 18.2853 14.8353 18.3647L14.5701 18.6299C14.3505 18.8496 13.9944 18.8496 13.7747 18.6299L8.03981 12.895C7.92998 12.7852 7.87506 12.6413 7.87505 12.4973Z"
                fill="#3AB22F"
              />
            </svg>
            Üye Girişi{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
const WrappedLogin = withGoogleReCaptcha(uyeKayit);

function RegisterPage(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedLogin url={props.url} theme={props.theme} />
    </GoogleReCaptchaProvider>
  );
}
export default RegisterPage;
