import * as React from "react";
const SonucGrafik = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#E12222"
      fillRule="evenodd"
      d="M2 17.009c-.51.047-.99.27-1.356.635A2.205 2.205 0 0 0 0 19.2v5.6c0 .583.232 1.143.644 1.556.366.365.846.588 1.356.635V29a1 1 0 0 0 2 0v-2.009c.51-.047.99-.27 1.356-.635.412-.413.644-.973.644-1.556v-5.6c0-.583-.232-1.143-.644-1.556A2.206 2.206 0 0 0 4 17.009V15a1 1 0 0 0-2 0v2.009ZM18 13.009c-.51.047-.99.27-1.356.635A2.205 2.205 0 0 0 16 15.2v8.6c0 .583.232 1.143.644 1.556.366.365.846.588 1.356.635V28a1 1 0 0 0 2 0v-2.009c.51-.047.99-.27 1.356-.635.412-.413.644-.973.644-1.556v-8.6c0-.583-.232-1.143-.644-1.556A2.206 2.206 0 0 0 20 13.009V11a1 1 0 0 0-2 0v2.009Z"
      clipRule="evenodd"
    />
    <path
      fill="#22DE2A"
      fillRule="evenodd"
      d="M10 9.009c-.51.047-.99.27-1.356.635A2.204 2.204 0 0 0 8 11.2v9.6c0 .583.232 1.143.644 1.556.366.365.846.588 1.356.635V25a1 1 0 0 0 2 0v-2.009c.51-.047.99-.27 1.356-.635.412-.413.644-.973.644-1.556v-9.6c0-.583-.232-1.143-.644-1.556A2.206 2.206 0 0 0 12 9.009V7a1 1 0 0 0-2 0v2.009ZM26 3.009A2.2 2.2 0 0 0 24 5.2v11.6a2.2 2.2 0 0 0 2 2.191V21a1 1 0 0 0 2 0v-2.009a2.2 2.2 0 0 0 2-2.191V5.2a2.2 2.2 0 0 0-2-2.191V1a1 1 0 0 0-2 0v2.009Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SonucGrafik;
