import React, { useEffect, useState } from "react";
import "../assets/css/dashboard.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../store/reducers/authSlice";
import { Link } from "react-router-dom";
import SideMenu from "../components/SideMenu/SideMenu";
import Header from "../components/System/header";
import ModalVideo from "react-modal-video";

function DashBoard() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  console.log("isAuthenticated", isAuthenticated);
  console.log("user", user);
  const [modalVideo, setModalVideo] = useState(false);
  const [video, setVideo] = useState(null);

  function funcModalVideo(videoId) {
    setVideo(videoId);
    setModalVideo(true);
  }
  useEffect(() => {
    document.title = "Tanıtım Sayfası - Sirius Algo";
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenu />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard">
            <div className="DashBoard__content whiteText ">
              <h2>Hakkımızda</h2>
              <p>
                Orphan Yazılım Bilişim Ar-Ge Akademi unvanıyla 2019 yılında
                kurulan Orphan Akademi, finans - borsa eğitimi ve yazılım
                alanlarında, 'Analizci Yetiştiriyoruz' sloganıyla Türk
                yatırımcıların yerli- yabancı piyasalarda kendi kararlarını
                alarak isabetli yatırımlar yapabilmeleri için ihtiyaç duyduğu
                bakış açısını kazandırabilmeyi amaçlamaktadır.
                <br />
                <br /> 5000+ öğrenci Orphan Akademi’de Ali Ömür Teorisi ve bayZ
                Zamansallık Teorisi teknik eğitimlerini alarak borsa ve
                piyasalarda yatırımlarına yön verebildiler. <br />
                <br />
                Ağustos 2022 itibariyle öğrencilerimize sunduğumuz Zamansallık
                Teorisi (bayZ-Gölge) eğitimi de Ali Ömür Teorisi eğitimi gibi
                ilgi ile karşılandı. Bu eğitim süreci ve öncesinde
                öğrencilerimizin gösterdiği teveccühten cesaret alarak resmi
                faaliyetlerimiz arasında bulunan yazılım alanındaki ilk
                ürünümüz; Sirius Algo. Sirius Algo’nun tüm kullanıcılara
                zamandan tasarruf, kullanım kolaylığı ve araştırmacı bakış
                açıları katacağına inanıyoruz. Sirius’u hazırlarken;
                kullanıcıların özgür olabilmelerini ve Sirius’un derin
                filtreleme özellikleri sayesinde benzersiz analiz
                kombinasyonları oluşturabilmelerini hedefledik. Analiz sürecinde
                en anlaşılır, en kolay şekilde sonuçlara ulaşabilmeniz için her
                sekmeyi özenle tasarladık. Sirius yayın hayatına başladığı
                günden itibaren her zaman kullanıcıların değerlendirmelerini göz
                önünde bulundurmuş ve buna göre aksiyonlar almıştır. Görüş ve
                önerilerinizi canlı yayınlarımızdan veya destek kanallarımızdan
                bizimle paylaşabilirsiniz. <br />
                <br />
                Sirius Algo, ilk etapta yalnızca Orphan Akademi öğrencilerine
                sunulmaktadır.
              </p>
              <br />{" "}
              {/* <div className="DashBoard__content__video">
                <button
                  className="btn btn-success"
                  onClick={() => funcModalVideo("muSAWDcsHJQ")}
                  style={{ marginRight: "30px" }}
                >
                  Kullanım Videosu 1
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => funcModalVideo("cMBvscfhkMs")}
                >
                  Kullanım Videosu 2
                </button>
              </div> */}
              {modalVideo && (
                <>
                  <button className="modal-video-close-btn"></button>
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={modalVideo}
                    videoId={video}
                    onClose={() => setModalVideo(false)}
                  />
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DashBoard;
