import * as React from "react";
const StarTop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <g fill={props.color || "#fff"} clipPath="url(#a)">
      <path d="m23.098 17 1.51 2.776L34 17l-9.394-2.776L23.097 17ZM14.223 24.607 16.998 34l2.776-9.393-2.776-1.51-2.775 1.51ZM10.903 17l-1.51-2.776L0 17l9.393 2.776L10.903 17Z" />
      <path d="M25.394 8.605 17 13.171 8.605 8.605 13.171 17l-4.566 8.395L17 20.829l8.394 4.566L20.83 17l4.565-8.395Z" />
      <path d="M19.774 9.393 16.998 0l-2.775 9.393 2.775 1.51 2.776-1.51Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.color || "#fff"} d="M0 0h34v34H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default StarTop;
