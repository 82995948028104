// authActions.js

import {
  authLogin,
  authAlarms,
  authNotifications,
  authSubscription,
  authLogout,
} from "../reducers/authSlice";

// Action creators
export const loginUser = (data) => (dispatch) => {
  localStorage.setItem(
    "access_token",
    JSON.stringify(data.data.access_token).replaceAll('"', "")
  );
  // dispatch(setAlarms(parseInt(data.alarms)));
  // dispatch(setNotifications(parseInt(data.notifications)));
  // dispatch(setSubscription(data.subscription));
  dispatch(authLogin(data.data));
};

export const logoutUser = () => (dispatch) => {
  dispatch(authLogout());
};

export const setAlarms = (alarms) => (dispatch) => {
  dispatch(authAlarms(alarms));
};

export const setNotifications = (notifications) => (dispatch) => {
  dispatch(authNotifications(notifications));
};

export const setSubscription = (subscription) => (dispatch) => {
  console.log("setSubscription", subscription);
  dispatch(authSubscription(subscription));
};
