import React from "react";
import "../components/Alarmlar/Alarmlar.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../components/System/header";
import FormulAlarmlar from "../components/Alarmlar/FormulAlarmlar";
import SideMenuPanel from "../components/SideMenu/SideMenuPanel";
import AlarmMesajlar from "../components/Alarmlar/AlarmMesajlar";

function Alarmlar() {
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenuPanel />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <Row>
            <div className="DashBoard_container">
              <div className="Alarmlar__container">
                <div className="Alarmlar__header">
                  <div className="Alarmlar__header__left"></div>
                  <div className="Alarmlar__header__tabs">
                    <Link
                      className={
                        location.pathname == "/alarmlar"
                          ? "Alarmlar__header__tab active"
                          : "Alarmlar__header__tab"
                      }
                      to={"/alarmlar"}
                    >
                      Alarm Mesajlarım
                    </Link>
                    <Link
                      className={
                        location.pathname.search("/formul-alarmlar") > -1
                          ? "Alarmlar__header__tab active"
                          : "Alarmlar__header__tab"
                      }
                      to={"/formul-alarmlar"}
                    >
                      Formül Alarmlarım
                    </Link>
                  </div>

                  <div className="Alarmlar__header__right"></div>
                </div>
                <div className="Alarmlar__header__body">
                  {location.pathname.search("/formul-alarmlar") > -1 ? (
                    <FormulAlarmlar />
                  ) : (
                    <AlarmMesajlar />
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Alarmlar;
