import * as React from "react";
const SidemenuTanitim = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M10.595 1.667a5.646 5.646 0 0 0-3.55 1.267 6.165 6.165 0 0 0-2.084 3.279 6.442 6.442 0 0 0 .242 3.937 6.054 6.054 0 0 0 2.469 2.969c.485.296.788.75.801 1.207.004.123.046.24.12.336a.528.528 0 0 0 .288.19c.25.062.502.11.76.145.228.03.424-.162.424-.405v-3.468a4.535 4.535 0 0 1-.663-.128.55.55 0 0 1-.393-.462.587.587 0 0 1 .102-.414.54.54 0 0 1 .349-.217.505.505 0 0 1 .207.012 3.54 3.54 0 0 0 1.856 0 .505.505 0 0 1 .41.052.571.571 0 0 1 .261.567.577.577 0 0 1-.215.37.518.518 0 0 1-.192.092c-.217.059-.439.102-.662.128v3.467c0 .244.196.436.424.406a7.14 7.14 0 0 0 .76-.145.528.528 0 0 0 .288-.19.577.577 0 0 0 .12-.336c.013-.457.316-.911.8-1.207a6.054 6.054 0 0 0 2.47-2.97 6.441 6.441 0 0 0 .241-3.936 6.165 6.165 0 0 0-2.084-3.28 5.645 5.645 0 0 0-3.55-1.266Z" />
    <path
      fillRule="evenodd"
      d="M8.509 15.517a.712.712 0 0 1 .076-.228.592.592 0 0 1 .142-.176.476.476 0 0 1 .186-.096.43.43 0 0 1 .203-.002 6.474 6.474 0 0 0 2.92 0 .43.43 0 0 1 .206-.004.472.472 0 0 1 .191.093.587.587 0 0 1 .147.177.76.76 0 0 1-.007.712.58.58 0 0 1-.15.172.467.467 0 0 1-.193.088 7.33 7.33 0 0 1-3.308 0 .524.524 0 0 1-.332-.264.742.742 0 0 1-.081-.472Zm.513 2.054a.732.732 0 0 1 .06-.235.618.618 0 0 1 .13-.189.494.494 0 0 1 .178-.115.431.431 0 0 1 .202-.022 7.8 7.8 0 0 0 1.968 0 .459.459 0 0 1 .38.137.677.677 0 0 1 .19.424.728.728 0 0 1-.113.463.502.502 0 0 1-.349.23 8.647 8.647 0 0 1-2.184 0 .457.457 0 0 1-.193-.073.557.557 0 0 1-.156-.157.685.685 0 0 1-.095-.218.76.76 0 0 1-.018-.245Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SidemenuTanitim;
