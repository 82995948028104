import * as React from "react";
const SidemenuParite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M15.957 19.047v-1.146c0-.632.948-.632.948 0v1.146h2.884c.632 0 .632.948 0 .948H.746a.456.456 0 0 1-.474-.474V.48c0-.632.948-.632.948 0v2.844h1.146c.632 0 .632.948 0 .948H1.22v3.2h1.146c.632 0 .632.949 0 .949H1.22v3.16h1.146c.632 0 .632.948 0 .948H1.22v3.2h1.146c.632 0 .632.949 0 .949H1.22v2.37h2.292v-1.146c0-.632.948-.632.948 0v1.146h3.2v-1.146c0-.632.948-.632.948 0v1.146h3.2v-1.146c0-.632.949-.632.949 0v1.146h3.2ZM4.025 12.291c-.434 0-.632-.513-.316-.79 0-.04.83-.869 2.173-1.501 3.28-1.58 5.808-.435 6.124-.474C15.6 8.143 17.339 3.6 17.379 3.56c.197-.592 1.106-.237.87.316-.633 1.739-2.253 4.267-4.268 5.69 3.042-.396 5.729-2.529 5.729-2.529.514-.395 1.106.316.592.711-.039.04-1.145.948-2.844 1.738-1.896.87-3.714 1.225-5.334 1.028-.158.04-.316.118-.474.158-1.659.434-2.963 1.382-3.99 2.844-.75 1.106-.988 2.134-.988 2.134-.158.592-1.066.355-.948-.238.04-.039.316-1.145 1.146-2.37.87-1.304 2.015-2.291 3.358-2.884-3.437-.435-5.847 1.975-5.847 1.975a.483.483 0 0 1-.356.158Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SidemenuParite;
