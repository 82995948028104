import React from "react";
import "./HomeSss.css";
import { Container, Row, Accordion } from "react-bootstrap";
function HomeSss() {
  return (
    <Container>
      <Row>
        <div className="sssContainer">
          <div id="sss" className="sssTitle">
            Sıkça Sorulan Sorular
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 734,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 24,
              display: "flex",
            }}
          >
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  SİRİUS ALGO’YA NASIL KAYIT OLABİLİRİM ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Üye kayıt bölümünden cep telefonu numaranızı girerek
                    kaydınızı oluşturabilir ve “Paket Satın Al” bölümünden
                    Aylık/6Aylık/1Yıllık paket seçeneklerinden dilediğinizi
                    seçerek Sirius Eko Sistemi’nde analizlere başlayabilirsiniz.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Premium paket ve Premium+ paket arasındaki fark nedir ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Premium+ pakette Zamansallık Teorisi (bayZ/Gölge)
                    sorgularına ulaşabilir ve analizlerinizde kullanabilirsiniz.
                    Zamansallık analizlerine ulaşmak için{" "}
                    <a href="http://www.orphanakademi.com" target="_blank">
                      www.orphanakademi.com
                    </a>{" "}
                    üzerinden Zamansallık Teorisi Eğitimi’ne katılmanız
                    gereklidir.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Mevcut bir paketim varken Sirius’a gelen yeni güncellemelerden
                  yararlanabiliyor muyum?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Sirius Algo içerisinde paketiniz olduğu sürece, yeni
                    güncellemelerin tümü ücretsiz olarak hesabınızda
                    aktifleştirilecektir.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Mevcut bir paketim varken yeni bir paket alarak süreyi uzatmak
                  istersem ne oluyor ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Yeni paketiniz, mevcut paket sürenizin üzerine eklenir.
                    Örnek : 6 aylık paketinizden son 5 gün kaldığında, yeniden 6
                    aylık paket alırsanız Toplam Paket Süreniz: 6 ay+ 5 gün
                    olarak 185 gün’ e yükselecektir.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Mevcuttaki “Premium” paketimi “Premium+” (Zamansallık içeren)
                  pakete nasıl yükseltebilirim?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    <a href="http://www.orphanakademi.com" target="_blank">
                      www.orphanakademi.com
                    </a>{" "}
                    üzerinden Zamansallık Teorisi Eğitimi’ni satın alarak
                    “Premium+” paketin kilidini açabilirsiniz.{" "}
                    <b>“Paket Satın Al”</b> bölümünden Premium+ paketinizi seçip
                    satın alabilirsiniz.{" "}
                  </p>
                  <p>
                    Premium+ paket alımı yaptığınızda mevcut paketinizde kalan
                    süreniz varsa, paketler arasındaki fark oranına göre gün
                    sayısı hesaplanır ve yeni paketinize eklenir. (Örnek:
                    Premium paketinizde 5 gün kalmışken Premium+ paket satın
                    alımı yaparsanız, eski paketinizden kalan 5 gün, yeni
                    paketinize 3 gün olarak eklenecektir.)
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Sirius Algo üzerindeki fiyatlar “Anlık Fiyat” mıdır ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Kripto Paralar ve Foreks Pariteleri dışındaki verilerimiz 15
                    dk gecikmeli gelmektedir. Şimdilik 😊
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Sirius Algo üzerinde grafikte kendi çizimlerimi yapabilir
                  miyim ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Evet, grafik paneli sekmemizden Tradingview grafik ekranında
                    kendi çizimlerinizi yapabilir ve kaydedebilirsiniz.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Paket sürem bittiğinde kartımdan otomatik ödeme çekilecek mi ?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Hayır, Sirius paketiniz bittiğinde otomatik ödeme
                    uygulanmaz. Siz kendi isteğinizle tekrardan paket satın
                    alımı yapabilirsiniz.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default HomeSss;
