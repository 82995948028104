import * as React from "react";
const ThreadsLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M12.186 24.5h-.007c-3.58-.024-6.333-1.205-8.183-3.51-1.647-2.051-2.495-4.905-2.524-8.481v-.018c.029-3.577.877-6.43 2.524-8.481C5.845 1.705 8.598.524 12.178.5h.014c2.745.018 5.041.724 6.826 2.097 1.678 1.29 2.859 3.13 3.51 5.467l-2.04.57c-1.103-3.96-3.897-5.984-8.303-6.016-2.91.02-5.109.935-6.54 2.717-1.339 1.669-2.031 4.08-2.056 7.164.026 3.086.718 5.496 2.057 7.164 1.43 1.782 3.63 2.697 6.54 2.718 2.621-.02 4.357-.63 5.8-2.044 1.648-1.614 1.617-3.593 1.09-4.797-.31-.71-.874-1.301-1.633-1.749-.191 1.35-.62 2.446-1.285 3.272-.885 1.102-2.14 1.704-3.728 1.79-1.203.064-2.36-.22-3.26-.802-1.063-.688-1.685-1.74-1.752-2.964-.064-1.19.408-2.284 1.329-3.081.88-.762 2.12-1.209 3.582-1.29a13.71 13.71 0 0 1 3.02.142c-.123-.743-.374-1.331-.747-1.756-.514-.586-1.308-.885-2.36-.892h-.03c-.844 0-1.99.232-2.722 1.32L7.733 8.347c.978-1.455 2.568-2.255 4.479-2.255h.043c3.195.02 5.098 1.974 5.287 5.387.109.046.215.093.32.143 1.492.7 2.582 1.762 3.154 3.069.797 1.822.87 4.79-1.55 7.159-1.847 1.81-4.09 2.627-7.274 2.648h-.007l.001.001Zm1.003-11.691c-.242 0-.488.006-.739.021-1.836.104-2.98.945-2.916 2.143.069 1.255 1.453 1.838 2.783 1.766 1.225-.065 2.819-.541 3.086-3.71-.676-.144-1.42-.22-2.214-.22Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ThreadsLogo;
