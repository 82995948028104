import * as React from "react";
const UserStarNull = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      fillRule="evenodd"
      d="M9.331 4.5a.75.75 0 0 1 .722.544l.813 2.846a3.749 3.749 0 0 0 2.575 2.576l2.847.813a.75.75 0 0 1 0 1.442l-2.847.813a3.75 3.75 0 0 0-2.575 2.576l-.813 2.846a.75.75 0 0 1-1.443 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.797 7.89l.813-2.846a.75.75 0 0 1 .721-.544Zm9-3a.75.75 0 0 1 .729.568l.258 1.036a2.63 2.63 0 0 0 1.91 1.91l1.035.258a.75.75 0 0 1 0 1.456l-1.036.258a2.63 2.63 0 0 0-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91L14.4 6.728a.75.75 0 0 1 0-1.456l1.037-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036a.75.75 0 0 1 .727-.568Zm-1.5 13.5a.75.75 0 0 1 .713.513l.393 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395a1.5 1.5 0 0 0-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.947-.948l-1.184-.395a.75.75 0 0 1 0-1.422l1.184-.395a1.5 1.5 0 0 0 .947-.948l.396-1.183a.75.75 0 0 1 .71-.513Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UserStarNull;
