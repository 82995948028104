import axios from "axios";
import {
  logoutUser,
  setAlarms,
  setNotifications,
  setSubscription,
} from "../store/actions/authAction";
export async function getHeaders() {
  // let access_token = await localStorage.getItem("access_token");
  // if (access_token) {
  //   return {
  //     "Content-Type": "application/json",
  //     authorization: `Bearer ${access_token}`,
  //     sirius: "algo",
  //   };
  // }
  return {
    "Content-Type": "application/json",
    sirius: `"algo"`,
  };
}
export async function fetchDataWithHeaders(
  url,
  dispatch,
  actionTypes,
  headers
) {
  const getTokenHeader = await getHeaders();

  headers = {
    ...getTokenHeader,
    ...headers,
  };
  axios({
    method: "get",
    url: url,
    headers: headers,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("the error respne", error && error.response);
      if (error && error.response && error.response.status === 401) {
        //logout user
        // userLogout();
        // console.log('code', error.response.data.errors[0].code);
        // return rej(error.response.data.errors[0].code);
      }
      if (error && error.response && error.response.data) {
        if (!error.response.data.message) {
          return rej({
            ...error.response.data,
            message: error.response.data.message || "Network Error",
          });
        }
        return rej(error.response.data);
      } else {
        return rej({ message: "Network Error" });
      }
    });
}

export async function postDataWithHeaders(
  url,
  data,
  dispatch,
  actionTypes,
  headers
) {
  return new Promise(async (res, rej) => {
    const getTokenHeader = await getHeaders();

    headers = {
      ...getTokenHeader,
      ...headers,
    };
    axios({
      method: "post",
      url: url,
      data: data,
      headers: headers,
    })
      .then((result) => {
        //   dispatch({ type: actionTypes.SUCCESS, payload: response.data });
        console.log("api result response", result);
        const { data } = result;

        if (result.status === false) {
          return rej(data);
        }
        if (dispatch) {
          if (data.code == -99) {
            // dispatch(logoutUser());
            window.location.href = "/uye-cikis";
          }
          if (data.code == -98) {
            window.location.href = "/paket-satin-al";
          }
          if (data.subscription) {
            dispatch(setSubscription(data.subscription));
          }
          if (data.alarms) {
            dispatch(setAlarms(parseInt(data.alarms)));
          }
          if (data.notifications) {
            dispatch(setNotifications(parseInt(data.notifications)));
          }
        }
        return res(data);
      })
      .catch((error) => {
        //   dispatch({ type: actionTypes.FAILURE, error: error.message });console.log("error", error);
        console.log("the error respne", error && error.response);
        if (error && error.response && error.response.status === 401) {
          //logout user
          // userLogout();
          // console.log('code', error.response.data.errors[0].code);
          // return rej(error.response.data.errors[0].code);
        }
        if (error && error.response && error.response.data) {
          if (!error.response.data.message) {
            return rej({
              ...error.response.data,
              message: error.response.data.message || "Network Error",
            });
          }
          return rej(error.response.data);
        } else {
          return rej({ message: "Network Error" });
        }
      });
  });
}
