import React, { useEffect, useState } from "react";
import "./Destek.css";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import SonucTekEkle from "../svgs/sonucTekEkle";
import { BsSearch } from "react-icons/bs";
import destekImg from "../../assets/img/destek.png";
import { DESTEK, YENIDESTEK } from "../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import DestekPanel from "./DestekPanel";
import { PulseLoader } from "react-spinners";
import { timeout } from "../../utils/helperFunction";
import { postDataWithHeaders } from "../../utils/apiUtils";

function Destek() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selected, setSelected] = useState(5);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [desteks, setDesteks] = useState([]);
  const [filterData, setFilterData] = useState(desteks);
  const [visibleNewTicket, setVisibleNewTicket] = useState(false);
  const handleNewTicketClose = () => setVisibleNewTicket(false);
  function filterDataFunc(aranan) {
    // console.log(aranan)
    setSearch(aranan);
    const filteredItems = desteks.filter((item) => {
      const query = aranan.toLowerCase();
      // console.log(query)
      return (
        item.name.toLowerCase().indexOf(query) >= 0 || item.status == query
      );
    });
    // const filteredItems = tableData.filter(
    //   item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // );
    setFilterData(filteredItems);
  }

  const selectButton = (status) => {
    setSelected(status);
    let filteredItems = desteks;
    if (status < 5) {
      filteredItems = desteks.filter((item) => {
        return item.status == status;
      });
    }
    setFilterData(filteredItems);
  };

  const getDestek = async () => {
    const postData = {
      access_token: user.access_token,
      type: "all",
    };
    postDataWithHeaders(DESTEK, postData, dispatch).then((res) => {
      if (!res) {
        const { error } = res;
        setIsLoading(false);
        throw new Error(error);
      }

      if (res.code === 0) {
        // console.log("res", res);
        // console.log(res.data.data);
        const sortTickets = res.data.tickets.sort((a, b) => {
          return new Date(b.time) - new Date(a.time);
        });
        setDesteks(sortTickets);
        setFilterData(sortTickets);
      } else {
        setIsLoading(false);
        console.log("error", res.data);
        toast.error(res.data);
      }
    });
  };

  const handleNewTicketEkle = async () => {
    setIsProcessing(true);
    await timeout(2000);
    const postData = {
      message: message.trim(),
      access_token: user.access_token,
    };
    postDataWithHeaders(YENIDESTEK, postData, dispatch).then((res) => {
      if (!res) {
        const { error } = res;
        setIsLoading(false);
        throw new Error(error);
      }

      if (res.code === 0) {
        toast.success(res.data.data);
        setInterval(() => {
          window.location.reload();
        }, 2000);
      } else {
        // console.log("error", res.data);
        toast.error(res.data);
        // setInterval(() => {
        //   window.location.href = "/uye-cikis";
        // }, 2000);
      }
      setIsProcessing(false);
      setVisibleNewTicket(false);
    });
  };
  useEffect(() => {
    getDestek();
  }, []);

  useEffect(() => {
    document.title = "Destek Sayfası - Sirius Algo";
  }, []);
  return (
    <div>
      <ToastContainer />
      <div className="Destek__header">
        <div className="Destek__header__title">Destek Listesi</div>
        <div className="Destek__header__button">
          <Button variant="primary" onClick={() => setVisibleNewTicket(true)}>
            <SonucTekEkle color="#fff" />
            Destek Talebi Oluştur
          </Button>
        </div>
      </div>
      <div className="Destek__header__line"></div>
      <div className="Destek__body">
        <div className="Destek__body_top">
          <div className="Destek__body_top_left">
            <ButtonGroup>
              <Button
                variant={selected == 5 ? "primary" : "secondary"}
                onClick={() => selectButton(5)}
              >
                Tümü
              </Button>
              <Button
                variant={selected == 0 ? "primary" : "secondary"}
                onClick={() => selectButton(0)}
              >
                Devam Edenler
              </Button>
              <Button
                variant={selected == 1 ? "primary" : "secondary"}
                onClick={() => selectButton(1)}
              >
                Cevaplananlar
              </Button>
              <Button
                variant={selected == 2 ? "primary" : "secondary"}
                onClick={() => selectButton(2)}
              >
                Çözülenler
              </Button>
            </ButtonGroup>
          </div>
          <div className="Destek__body_top_right">
            <div className="Destek__Search">
              <div className="Destek__Search__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => filterDataFunc(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Destek__body_bottom">
          {desteks.length == 0 ? (
            <div className="Destek__body_bottom_none">
              <img src={destekImg} />
              <p>Henüz bir talebiniz bulunmamakta.</p>
            </div>
          ) : (
            <div className="Destek__body_bottom_lists">
              {filterData.map((destek, index) => (
                <DestekPanel key={index} destek={destek} />
              ))}
            </div>
          )}
        </div>
      </div>

      {visibleNewTicket && (
        <Modal
          show={visibleNewTicket}
          onHide={handleNewTicketClose}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme="dark"
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Yeni Destek Talebi Oluştur
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ width: "100%" }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Talebinizi gönderdikten sonra en kısa sürede sizlere dönüş
                  sağlayacağız.
                </Form.Label>
                <Form.Control
                  className="textarea"
                  as="textarea"
                  rows={5}
                  onChange={(event) => setMessage(event.target.value)}
                  onFocus={({ target }) => {
                    target.setAttribute("autocomplete", "off");
                  }}
                  placeholder="Talebinizi buraya yazınız."
                />
              </Form.Group>
            </Form>
            <Button
              onClick={() => handleNewTicketEkle()}
              className="modalSonucButton"
            >
              {!isProcessing && <SonucTekEkle color="#fff" />}
              {isProcessing ? (
                <PulseLoader color="#fff" aria-label="Talep Ekleniyor..." />
              ) : (
                "Talep Ekle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Destek;
