import React from "react";
import "./PanoTop.css";
import { Row, Col } from "react-bootstrap";
import panoCup from "../../../assets/img/top_cup.png";
import panoStar from "../../../assets/img/top_star.png";
import { useSelector } from "react-redux";
import { AiFillDashboard, AiFillSignal } from "react-icons/ai";
import { BsHeartFill } from "react-icons/bs";

function PanoTop() {
  const { myboard } = useSelector((state) => state.pano);

  console.log("myboard", myboard);
  return (
    <Row>
      <Col lg={12} className="PanoTop">
        {myboard.name && myboard.name.length > 0 ? (
          <>
            <h4>Panoya Paylaştığım Formül</h4>
            <div className="PanoTop__publish">
              <div className="PanoTop__publish__left">{myboard.name}</div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Sorgulama Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <AiFillDashboard size={16} />{" "}
                    {myboard.queries ? myboard.queries : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.queries_rank}
                  </span>
                </div>
              </div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Beğeni Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <BsHeartFill size={16} />{" "}
                    {myboard.likes ? myboard.likes : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.likes_rank}
                  </span>
                </div>
              </div>
              <div className="PanoTop__publish__center">
                <span className="PanoTop__publish__center__text title">
                  Reyting Puanı
                </span>
                <div className="PanoTop__publish__center__points">
                  <span>
                    <AiFillSignal size={16} />{" "}
                    {myboard.rating ? myboard.rating : 0}
                  </span>
                  <span className="PanoTop__publish__center__text rank">
                    {myboard.rating_rank}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h4>Henüz panoya formül paylaşmadınız</h4>
          </>
        )}
      </Col>
    </Row>
  );
}

export default PanoTop;
