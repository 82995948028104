import React, { useEffect, useState } from "react";
import "./SideMenu.css";
import logo from "../../assets/img/sirius-logo.svg";
import logoLight from "../../assets/img/sirius-logo-light.svg";
import AnaKumanda from "../../components/svgs/anakumada";
import AnaKumandaBack from "../../components/svgs/anakumandaBack";
import { Button } from "react-bootstrap";
import { PiChatsBold } from "react-icons/pi";
import Lottie from "react-lottie";
import * as bellAnimation from "../svgs/bell.json";
import { TiThMenu } from "react-icons/ti";
import { IoCloseSharp } from "react-icons/io5";
import BellIcon from "../svgs/bell";
import { Link, useLocation } from "react-router-dom";
import BellIconWith from "../svgs/bellwith";
import ArrowLeft from "../svgs/arrowLeft";
import packageJson from "../../../package.json";
import SidemenuTanitim from "../svgs/sidemenuTanitim";
import SidemenuSozlesme from "../svgs/sidemenuSozlesme";
import SidemenuPaket from "../svgs/sidemenuPaket";
import SidemenuAlarm from "../svgs/sidemenuAlarm";
import { useDispatch, useSelector } from "react-redux";
import { setDarkTheme, setDefaultTheme } from "../../store/reducers/themeSlice";
import dark_switcher from "../../assets/img/switcher_dark.png";
import light_switcher from "../../assets/img/switcher_light.png";
import UyeCikis from "../svgs/uyeCikis";
function SideMenu() {
  const { innerWidth: width, innerHeight: height } = window;
  const location = useLocation();
  const isActive = location.pathname;
  const theme = useSelector((state) => state.theme.darkmode);
  const { isAuthenticated, user, subscription, notifications, alarms } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [version, setVersion] = useState(
    localStorage.getItem("sirius_version")
  );
  const [backAnaKumanda, setBackAnaKumanda] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [bildirim, setBildirim] = useState(false);
  const [isSub, setIsSub] = useState(0);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bellAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleTheme = () => {
    console.log("theme", theme);
    if (theme == "light") {
      dispatch(setDarkTheme());
    } else {
      dispatch(setDefaultTheme());
    }
  };
  const funcIsSub = (id) => {
    if (isSub != id) {
      setIsSub(id);
    } else {
      setIsSub(0);
    }
  };
  useEffect(() => {
    if (notifications > 0) {
      setBildirim(true);
    } else {
      setBildirim(false);
    }
  }, [notifications]);

  useEffect(() => {
    if (
      isActive == "/kullanici-sozlesmesi" ||
      isActive == "/cerez-politikasi" ||
      isActive == "/gizlilik-politikasi" ||
      isActive == "/sss" ||
      isActive == "/kvkk" ||
      isActive == "/sorumluluk-reddi"
    ) {
      funcIsSub(1);
    } else {
      funcIsSub(0);
    }
  }, [isActive]);
  return (
    <div className="SideMenu">
      <div className="SideMenu_top">
        <div className="SideMenu__logo">
          <img src={theme == "dark" ? logo : logoLight} />
          <div className="SideMenu__menu__toggler">
            {showMenu ? (
              <IoCloseSharp size={40} onClick={() => setShowMenu(!showMenu)} />
            ) : (
              <TiThMenu size={30} onClick={() => setShowMenu(!showMenu)} />
            )}
          </div>
        </div>
        <div
          className={
            showMenu
              ? "SideMenu__logo__bottom"
              : "SideMenu__logo__bottom dMobileMenu"
          }
        >
          <Link to="/ana-kumanda">
            <AnaKumanda />
          </Link>

          <div className="SideMenu__profil">
            <Link to="/profil" className="SideMenu__profil__text">
              <div className="SideMenu__profil__text">
                <div className="SideMenu__profil__text__title">
                  {user.username ? user.username : user.name}
                </div>
                <div className="SideMenu__profil__text__subtitle">
                  Profile Git <ArrowLeft />
                </div>
              </div>
            </Link>
            <Link to="/bildirimler" className="SideMenu__profil__icon">
              <div className="SideMenu__profil__icon">
                {bildirim ? (
                  <Lottie options={defaultOptions} height={40} width={40} />
                ) : (
                  <BellIcon color="white" />
                )}
              </div>
            </Link>
          </div>

          <div className="SideMenu__menu">
            <Link
              className={
                isActive == "/tanitim-ve-kullanim"
                  ? "SideMenu__menu__link active"
                  : "SideMenu__menu__link"
              }
              to="/tanitim-ve-kullanim"
            >
              <div className="SideMenu__menu__link__icon">
                <SidemenuTanitim />
              </div>
              <div className="SideMenu__menu__link__text">
                Tanitim & Kullanım
              </div>
            </Link>
            <Link
              className={
                isActive == "/kullanici-sozlesmesi" ||
                isActive == "/cerez-politikasi" ||
                isActive == "/gizlilik-politikasi" ||
                isActive == "/sss" ||
                isActive == "/kvkk" ||
                isActive == "/sorumluluk-reddi"
                  ? "SideMenu__menu__link active"
                  : "SideMenu__menu__link"
              }
              to="#"
              onClick={() => funcIsSub(1)}
            >
              <div className="SideMenu__menu__link__icon">
                <SidemenuSozlesme />
              </div>
              <div className="SideMenu__menu__link__text">
                Sözleşme & Politika
              </div>
            </Link>
            <div className={`SideMenu__submenu ${isSub == 1 ? "show" : ""}`}>
              <ul className="SideMenu__submenu__list">
                <li>
                  <Link to="/kullanici-sozlesmesi">- Kullanıcı Sözleşmesi</Link>
                </li>
                <li>
                  <Link to="/gizlilik-politikasi">- Gizlilik Politikası</Link>
                </li>
                <li>
                  <Link to="/cerez-politikasi">- Çerez Politikası</Link>
                </li>
                <li>
                  <Link to="/sorumluluk-reddi">- Sorumluluk Reddi</Link>
                </li>
                <li>
                  <Link to="/sss">- S.S.S.</Link>
                </li>
                <li>
                  <Link to="/kvkk">- KVKK</Link>
                </li>
              </ul>
            </div>
            <Link
              className={
                isActive == "/destek"
                  ? "SideMenu__menu__link active"
                  : "SideMenu__menu__link"
              }
              to="/destek"
            >
              <div className="SideMenu__menu__link__icon">
                <PiChatsBold />
              </div>
              <div className="SideMenu__menu__link__text">Destek</div>
            </Link>
            <Link
              className={
                isActive == "/paket-satin-al"
                  ? "SideMenu__menu__link active"
                  : "SideMenu__menu__link"
              }
              to="/paket-satin-al"
            >
              <div className="SideMenu__menu__link__icon">
                <SidemenuPaket />
              </div>
              <div className="SideMenu__menu__link__text">Paket Satın Al</div>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={
          showMenu
            ? "Sidemenu_bottom mt-auto"
            : "Sidemenu_bottom dMobileMenu mt-auto"
        }
      >
        <Link to="/alarmlar">
          <div
            className={
              alarms == 0 ? "Sidemenu__alarm" : "Sidemenu__alarm success"
            }
          >
            <div className="Sidemenu__alarm__icontext">
              <div className="Sidemenu__alarm__icon">
                <SidemenuAlarm />
              </div>
              <span className="Sidemenu__alarm__text">Alarmlar</span>
            </div>
            <span className="Sidemenu__alarm__count">{alarms}</span>
          </div>
        </Link>
        {subscription.package && (
          <Link to="/paket-satin-al">
            <div
              className={`Sidemenu__package ${
                subscription.package == "Premium" ? "premium" : "premiumPlus"
              }`}
            >
              <div className="Sidemenu__package__div">
                <div className="Sidemenu__package__left">
                  <div className="Sidemenu__package__left__top">
                    Paket İçeriği
                  </div>
                  <div className="Sidemenu__package__left__bottom">
                    {subscription.package}
                  </div>
                </div>
                <div className="Sidemenu__package__right">
                  <div className="Sidemenu__package__left__top">Kalan Süre</div>
                  <div className="Sidemenu__package__left__bottom">
                    {subscription.remaining} Gün
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
        <div className="Sidemenu__theme">
          <div className="Sidemenu__theme__top">
            <div className="Sidemenu__theme__text">
              <span className="Sidemenu__theme__text__topspan">Tema</span>
              <span className="Sidemenu__theme__text__bottomspan">
                {theme == "dark" ? "Dark" : "Light"}
              </span>
            </div>
            <div
              className="Sidemenu__theme__icon"
              onClick={() => handleTheme()}
            >
              <img
                src={theme == "dark" ? dark_switcher : light_switcher}
                alt="theme"
              />
            </div>
          </div>
          <div className="Sidemenu__theme__bottom">
            © Sirius Algo, Tüm hakları saklıdır. <br />
            Son Güncelleme: {packageJson.last_updated}
            <br />
            Versiyon: {version}
            {/* <br />
            Width: {width}
            <br />
            height: {height} */}
          </div>
          <div className="Sidemenu__theme__cikis">
            <Link className="Sidemenu__theme__cikis__link" to="/uye-cikis">
              <UyeCikis />
              Çıkış Yap
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
