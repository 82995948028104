import React, { useState, useEffect } from "react";
import { DataProvider } from "./context/DataContext";
import "./assets/css/reset.css";
import "./assets/css/normalize.css";
import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { urlDevSystem, urlProSystem } from "./utils/dummyData";
import packageJson from "../package.json";
import { refreshCacheAndReload } from "./utils/utils";
import MainRoutes from "./routes";
import GlobalStyles from "./components/styled/Global.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataWithHeaders, postDataWithHeaders } from "./utils/apiUtils.js";
import { FAVORIESDATA, FILTERS } from "./utils/urls.js";
import { setFilterAction } from "./store/actions/filtersAction.js";
import { setIsMobile } from "./store/reducers/themeSlice.js";
import { setFavoriesAction } from "./store/actions/dataAction.js";
function App() {
  const { innerWidth: width, innerHeight: height } = window;
  const dispatch = useDispatch();
  const [version, setVersion] = useState(
    localStorage.getItem("sirius_version")
  );
  const [lastRunTime, setLastRunTime] = useState(
    localStorage.getItem("lastRunTime")
  );
  const [filters, setFilters] = useState(localStorage.getItem("filters"));
  const access_token = localStorage.getItem("access_token");
  const url_system = localStorage.getItem("urlApi");
  const theme = useSelector((state) => state.theme);
  const body = document.querySelector("body");
  body.setAttribute("data-theme", theme.darkmode);

  const development = false;
  // if (development) {
  //   urlApi = urlDevSystem;
  // } else {
  //   urlApi = urlProSystem;
  // }
  const fetchData = async () => {
    try {
      // API'yi burada çağırabilirsiniz
      const postData = {
        access_token: access_token,
      };
      await postDataWithHeaders(FILTERS, postData, dispatch).then((res) => {
        console.log("filters: ", res);
        dispatch(setFilterAction(res));
      });
      // const data = await response.json();
    } catch (error) {
      console.error("API çağrısı başarısız oldu.", error);
    }
  };
  const fetchFavoriesData = async () => {
    try {
      // API'yi burada çağırabilirsiniz
      const postData = {
        access_token: access_token,
      };
      await postDataWithHeaders(FAVORIESDATA, postData, dispatch).then(
        (res) => {
          dispatch(setFavoriesAction(res.data));
        }
      );
      // const data = await response.json();
    } catch (error) {
      console.error("API çağrısı başarısız oldu.", error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      if (!lastRunTime || currentTime - parseInt(lastRunTime, 10) > 3600000) {
        // Run the function if it's been an hour since the last run
        console.log("Running function");

        fetchData();
        // Call your function here
        setLastRunTime(currentTime.toString());
        localStorage.setItem("lastRunTime", currentTime.toString());
      }
    }, 60000); // Check every minute

    // fetchData();
    return () => clearInterval(interval);
  }, [lastRunTime]);
  useEffect(() => {
    if (!filters) {
      fetchData();
    }
  }, [filters]);
  useEffect(() => {
    if (!url_system) {
      console.log("urlProSystem", url_system, urlProSystem);
      localStorage.setItem("urlApi", urlProSystem);
    }
    if (version) {
      if (packageJson.version !== version) {
        console.log("cleanin caches");
        refreshCacheAndReload();
      }
    } else {
      console.log("version: ", version);
      console.log("setting version: ", packageJson.version);
      localStorage.setItem("sirius_version", packageJson.version);
    }
  }, []);

  useEffect(() => {
    if (width < 768) {
      dispatch(setIsMobile(true));
    } else {
      dispatch(setIsMobile(false));
    }
  }, [width]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchFavoriesData();
  //   }, 15000); //set your time here. repeat every 5 seconds

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <DataProvider>
          <Router>
            <MainRoutes />
          </Router>
        </DataProvider>
      </>
    </ThemeProvider>
  );
}

export default App;
